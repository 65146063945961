/*!
 * Bootstrap v4.5.3 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #534798;
  --purple2: #42388d;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #e4a039;
  --green: #00d819;
  --teal: #20c997;
  --cyan: #a0ccec;
  --white: #fff;
  --gray: #666;
  --gray-dark: #333;
  --primary: #534798;
  --secondary: #42388d;
  --success: #00d819;
  --info: #a0ccec;
  --warning: #e4a039;
  --danger: #dc3545;
  --light: #f8f9fa;
  --light2: #ddd;
  --dark: #333;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1070px;
  --breakpoint-xlbtw: 1341px;
  --breakpoint-xlwd: 1379px;
  --font-family-sans-serif: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.75;
  color: #545454;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 15px;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #534798;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #7b70bc;
  text-decoration: none;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: .75rem;
  padding-bottom: .75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 15px;
  font-family: "Fredoka One", "Brush Script MT", cursive;
  font-weight: 400;
  line-height: 1.2;
  color: #42388d;
}

h1,
.h1 {
  font-size: 2.0625rem;
}

h2,
.h2 {
  font-size: 1.6875rem;
}

h3,
.h3 {
  font-size: 1.5rem;
}

h4,
.h4 {
  font-size: 2rem;
}

h5,
.h5 {
  font-size: 1.75rem;
}

h6,
.h6 {
  font-size: 1.5625rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #d1d1d1;
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: .2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 5px;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: .25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .075);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 2.5px;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: .2rem .4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 7px;
  box-shadow: inset 0 -.1rem 0 rgba(0, 0, 0, .25);
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
  box-shadow: none;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1070px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1038px;
  }
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -16px;
  margin-left: -16px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto,
.col-xlbtw-1,
.col-xlbtw-2,
.col-xlbtw-3,
.col-xlbtw-4,
.col-xlbtw-5,
.col-xlbtw-6,
.col-xlbtw-7,
.col-xlbtw-8,
.col-xlbtw-9,
.col-xlbtw-10,
.col-xlbtw-11,
.col-xlbtw-12,
.col-xlbtw,
.col-xlbtw-auto,
.col-xlwd-1,
.col-xlwd-2,
.col-xlwd-3,
.col-xlwd-4,
.col-xlwd-5,
.col-xlwd-6,
.col-xlwd-7,
.col-xlwd-8,
.col-xlwd-9,
.col-xlwd-10,
.col-xlwd-11,
.col-xlwd-12,
.col-xlwd,
.col-xlwd-auto {
  position: relative;
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.3333333333%;
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.6666666667%;
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.3333333333%;
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.6666666667%;
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.3333333333%;
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.6666666667%;
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.3333333333%;
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.6666666667%;
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.3333333333%;
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.6666666667%;
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -webkit-box-ordinal-group: 14;
  -ms-flex-order: 13;
  order: 13;
}

.order-0 {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -webkit-box-ordinal-group: 9;
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -webkit-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -webkit-box-ordinal-group: 12;
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -webkit-box-ordinal-group: 13;
  -ms-flex-order: 12;
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 1070px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 1341px) {
  .col-xlbtw {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xlbtw-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xlbtw-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xlbtw-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xlbtw-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xlbtw-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xlbtw-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xlbtw-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xlbtw-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xlbtw-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xlbtw-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xlbtw-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xlbtw-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xlbtw-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xlbtw-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xlbtw-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xlbtw-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xlbtw-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xlbtw-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xlbtw-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xlbtw-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xlbtw-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xlbtw-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xlbtw-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xlbtw-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xlbtw-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xlbtw-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xlbtw-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xlbtw-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xlbtw-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xlbtw-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xlbtw-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xlbtw-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xlbtw-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xlbtw-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xlbtw-0 {
    margin-left: 0;
  }
  .offset-xlbtw-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xlbtw-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xlbtw-3 {
    margin-left: 25%;
  }
  .offset-xlbtw-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xlbtw-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xlbtw-6 {
    margin-left: 50%;
  }
  .offset-xlbtw-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xlbtw-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xlbtw-9 {
    margin-left: 75%;
  }
  .offset-xlbtw-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xlbtw-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 1379px) {
  .col-xlwd {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xlwd-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xlwd-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xlwd-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xlwd-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xlwd-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xlwd-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xlwd-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xlwd-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xlwd-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xlwd-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xlwd-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xlwd-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xlwd-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xlwd-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xlwd-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xlwd-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xlwd-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xlwd-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xlwd-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xlwd-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xlwd-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xlwd-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xlwd-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xlwd-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xlwd-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xlwd-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xlwd-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xlwd-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xlwd-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xlwd-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xlwd-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xlwd-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xlwd-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xlwd-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xlwd-0 {
    margin-left: 0;
  }
  .offset-xlwd-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xlwd-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xlwd-3 {
    margin-left: 25%;
  }
  .offset-xlwd-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xlwd-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xlwd-6 {
    margin-left: 50%;
  }
  .offset-xlwd-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xlwd-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xlwd-9 {
    margin-left: 75%;
  }
  .offset-xlwd-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xlwd-11 {
    margin-left: 91.6666666667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 5px;
  color: #212529;
}
.table th,
.table td {
  padding: .75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: .3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, .075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #cfcbe2;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #a69fc9;
}

.table-hover .table-primary:hover {
  background-color: #c0bbd9;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #c0bbd9;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #cac7df;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #9d98c4;
}

.table-hover .table-secondary:hover {
  background-color: #bbb7d6;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #bbb7d6;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #b8f4bf;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #7aeb87;
}

.table-hover .table-success:hover {
  background-color: #a2f1ab;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #a2f1ab;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #e4f1fa;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #cee4f5;
}

.table-hover .table-info:hover {
  background-color: #cee6f6;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #cee6f6;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #f7e4c8;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f1ce98;
}

.table-hover .table-warning:hover {
  background-color: #f4d9b2;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #f4d9b2;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-light2,
.table-light2 > th,
.table-light2 > td {
  background-color: whitesmoke;
}

.table-light2 th,
.table-light2 td,
.table-light2 thead th,
.table-light2 tbody + tbody {
  border-color: #ededed;
}

.table-hover .table-light2:hover {
  background-color: #e8e8e8;
}
.table-hover .table-light2:hover > td,
.table-hover .table-light2:hover > th {
  background-color: #e8e8e8;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c6c6;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #959595;
}

.table-hover .table-dark:hover {
  background-color: #b9b9b9;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9b9b9;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, .075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, .075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, .075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, .05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, .075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1069.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1340.98px) {
  .table-responsive-xlbtw {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xlbtw > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1378.98px) {
  .table-responsive-xlwd {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xlwd > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: 45px;
  padding: .625rem .8125rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #534798;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #cecece;
  border-radius: 7px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0);
  -webkit-transition: border-color .3s ease;
  transition: border-color .3s ease;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    -webkit-transition: none;
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #534798;
}
.form-control:focus {
  color: #534798;
  background-color: #fff;
  border-color: #534798;
  outline: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0), 0 0 10px rgba(0, 0, 0, 0);
}
.form-control::-webkit-input-placeholder {
  color: #666;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #666;
  opacity: 1;
}
.form-control::placeholder {
  color: #666;
  opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #534798;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(.625rem + 2px);
  padding-bottom: calc(.625rem + 2px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(.5rem + 2px);
  padding-bottom: calc(.5rem + 2px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(.25rem + 2px);
  padding-bottom: calc(.25rem + 2px);
  font-size: .875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: .625rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 2px 0;
}
.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + .5rem + 2px);
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: 7px;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 7px;
}

select.form-control[size],
select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: .25rem;
}

.form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: .3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: .75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: .3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .25rem .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, .9);
  border-radius: .25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #28a745;
}
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0 rgba(40, 167, 69, .25);
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
}
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0 rgba(40, 167, 69, .25);
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(40, 167, 69, .25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0 rgba(40, 167, 69, .25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .25rem .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, .9);
  border-radius: .25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0 rgba(220, 53, 69, .25);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0 rgba(220, 53, 69, .25);
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(220, 53, 69, .25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0 rgba(220, 53, 69, .25);
}

.form-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: .25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 700;
  color: #545454;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 2px solid transparent;
  padding: .625rem .8125rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 13px;
  -webkit-transition: color .3s ease, background-color .3s ease, border-color .3s ease;
  transition: color .3s ease, background-color .3s ease, border-color .3s ease;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    -webkit-transition: none;
    transition: none;
  }
}
.btn:hover {
  color: #545454;
  text-decoration: none;
}
.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
}
.btn.disabled,
.btn:disabled {
  opacity: .65;
  box-shadow: none;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0);
}
.btn:not(:disabled):not(.disabled):active:focus,
.btn:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25), 0 0 10px rgba(0, 0, 0, 0);
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #534798;
  border-color: #534798;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0);
}
.btn-primary:hover {
  color: #fff;
  background-color: #453b7e;
  border-color: #403775;
}
.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #453b7e;
  border-color: #403775;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0), 0 0 0 0 rgba(109, 99, 167, .5);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #534798;
  border-color: #534798;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #403775;
  border-color: #3b336d;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0), 0 0 0 0 rgba(109, 99, 167, .5);
}

.btn-secondary {
  color: #fff;
  background-color: #42388d;
  border-color: #42388d;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0);
}
.btn-secondary:hover {
  color: #fff;
  background-color: #352d72;
  border-color: #312a68;
}
.btn-secondary:focus,
.btn-secondary.focus {
  color: #fff;
  background-color: #352d72;
  border-color: #312a68;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0), 0 0 0 0 rgba(94, 86, 158, .5);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #42388d;
  border-color: #42388d;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #312a68;
  border-color: #2d265f;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0), 0 0 0 0 rgba(94, 86, 158, .5);
}

.btn-success {
  color: #fff;
  background-color: #00d819;
  border-color: #00d819;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0);
}
.btn-success:hover {
  color: #fff;
  background-color: #00b215;
  border-color: #00a513;
}
.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: #00b215;
  border-color: #00a513;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0), 0 0 0 0 rgba(38, 222, 60, .5);
}
.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #00d819;
  border-color: #00d819;
}
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #00a513;
  border-color: #009812;
}
.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0), 0 0 0 0 rgba(38, 222, 60, .5);
}

.btn-info {
  color: #212529;
  background-color: #a0ccec;
  border-color: #a0ccec;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0);
}
.btn-info:hover {
  color: #212529;
  background-color: #80bbe6;
  border-color: #76b5e4;
}
.btn-info:focus,
.btn-info.focus {
  color: #212529;
  background-color: #80bbe6;
  border-color: #76b5e4;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0), 0 0 0 0 rgba(141, 179, 207, .5);
}
.btn-info.disabled,
.btn-info:disabled {
  color: #212529;
  background-color: #a0ccec;
  border-color: #a0ccec;
}
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #212529;
  background-color: #76b5e4;
  border-color: #6bafe1;
}
.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0), 0 0 0 0 rgba(141, 179, 207, .5);
}

.btn-warning {
  color: #212529;
  background-color: #e4a039;
  border-color: #e4a039;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0);
}
.btn-warning:hover {
  color: #212529;
  background-color: #d98f1e;
  border-color: #ce871c;
}
.btn-warning:focus,
.btn-warning.focus {
  color: #212529;
  background-color: #d98f1e;
  border-color: #ce871c;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0), 0 0 0 0 rgba(199, 142, 55, .5);
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #e4a039;
  border-color: #e4a039;
}
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #ce871c;
  border-color: #c3801b;
}
.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0), 0 0 0 0 rgba(199, 142, 55, .5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0);
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0), 0 0 0 0 rgba(225, 83, 97, .5);
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0), 0 0 0 0 rgba(225, 83, 97, .5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0);
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus,
.btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0), 0 0 0 0 rgba(216, 217, 219, .5);
}
.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0), 0 0 0 0 rgba(216, 217, 219, .5);
}

.btn-light2 {
  color: #212529;
  background-color: #ddd;
  border-color: #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0);
}
.btn-light2:hover {
  color: #212529;
  background-color: #cacaca;
  border-color: #c4c3c3;
}
.btn-light2:focus,
.btn-light2.focus {
  color: #212529;
  background-color: #cacaca;
  border-color: #c4c3c3;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0), 0 0 0 0 rgba(193, 193, 194, .5);
}
.btn-light2.disabled,
.btn-light2:disabled {
  color: #212529;
  background-color: #ddd;
  border-color: #ddd;
}
.btn-light2:not(:disabled):not(.disabled):active,
.btn-light2:not(:disabled):not(.disabled).active,
.show > .btn-light2.dropdown-toggle {
  color: #212529;
  background-color: #c4c3c3;
  border-color: #bdbdbd;
}
.btn-light2:not(:disabled):not(.disabled):active:focus,
.btn-light2:not(:disabled):not(.disabled).active:focus,
.show > .btn-light2.dropdown-toggle:focus {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0), 0 0 0 0 rgba(193, 193, 194, .5);
}

.btn-dark {
  color: #fff;
  background-color: #333;
  border-color: #333;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0);
}
.btn-dark:hover {
  color: #fff;
  background-color: #202020;
  border-color: #1a1919;
}
.btn-dark:focus,
.btn-dark.focus {
  color: #fff;
  background-color: #202020;
  border-color: #1a1919;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0), 0 0 0 0 rgba(82, 82, 82, .5);
}
.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #333;
  border-color: #333;
}
.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1a1919;
  border-color: #131313;
}
.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0), 0 0 0 0 rgba(82, 82, 82, .5);
}

.btn-outline-primary {
  color: #534798;
  border-color: #534798;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #534798;
  border-color: #534798;
}
.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0 rgba(83, 71, 152, .5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #534798;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #534798;
  border-color: #534798;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0), 0 0 0 0 rgba(83, 71, 152, .5);
}

.btn-outline-secondary {
  color: #42388d;
  border-color: #42388d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #42388d;
  border-color: #42388d;
}
.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0 rgba(66, 56, 141, .5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #42388d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #42388d;
  border-color: #42388d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0), 0 0 0 0 rgba(66, 56, 141, .5);
}

.btn-outline-success {
  color: #00d819;
  border-color: #00d819;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #00d819;
  border-color: #00d819;
}
.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0 rgba(0, 216, 25, .5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #00d819;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #00d819;
  border-color: #00d819;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 216, 25, .5);
}

.btn-outline-info {
  color: #a0ccec;
  border-color: #a0ccec;
}
.btn-outline-info:hover {
  color: #212529;
  background-color: #a0ccec;
  border-color: #a0ccec;
}
.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0 rgba(160, 204, 236, .5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #a0ccec;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #212529;
  background-color: #a0ccec;
  border-color: #a0ccec;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0), 0 0 0 0 rgba(160, 204, 236, .5);
}

.btn-outline-warning {
  color: #e4a039;
  border-color: #e4a039;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #e4a039;
  border-color: #e4a039;
}
.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0 rgba(228, 160, 57, .5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #e4a039;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #e4a039;
  border-color: #e4a039;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0), 0 0 0 0 rgba(228, 160, 57, .5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0 rgba(220, 53, 69, .5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0), 0 0 0 0 rgba(220, 53, 69, .5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 0 rgba(248, 249, 250, .5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0), 0 0 0 0 rgba(248, 249, 250, .5);
}

.btn-outline-light2 {
  color: #ddd;
  border-color: #ddd;
}
.btn-outline-light2:hover {
  color: #212529;
  background-color: #ddd;
  border-color: #ddd;
}
.btn-outline-light2:focus,
.btn-outline-light2.focus {
  box-shadow: 0 0 0 0 rgba(221, 221, 221, .5);
}
.btn-outline-light2.disabled,
.btn-outline-light2:disabled {
  color: #ddd;
  background-color: transparent;
}
.btn-outline-light2:not(:disabled):not(.disabled):active,
.btn-outline-light2:not(:disabled):not(.disabled).active,
.show > .btn-outline-light2.dropdown-toggle {
  color: #212529;
  background-color: #ddd;
  border-color: #ddd;
}
.btn-outline-light2:not(:disabled):not(.disabled):active:focus,
.btn-outline-light2:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light2.dropdown-toggle:focus {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0), 0 0 0 0 rgba(221, 221, 221, .5);
}

.btn-outline-dark {
  color: #333;
  border-color: #333;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #333;
  border-color: #333;
}
.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 0 rgba(51, 51, 51, .5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #333;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #333;
  border-color: #333;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0), 0 0 0 0 rgba(51, 51, 51, .5);
}

.btn-link {
  font-weight: 400;
  color: #534798;
  text-decoration: none;
}
.btn-link:hover {
  color: #7b70bc;
  text-decoration: none;
}
.btn-link:focus,
.btn-link.focus {
  text-decoration: none;
}
.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 15px;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: 7px;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: .5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  -webkit-transition: opacity .3s ease, visibility .3s ease;
  transition: opacity .3s ease, visibility .3s ease;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    -webkit-transition: none;
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height .3s ease, -webkit-transform .3s ease;
  transition: height .3s ease, -webkit-transform .3s ease;
  transition: height .3s ease, transform .3s ease;
  transition: height .3s ease, transform .3s ease, -webkit-transform .3s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    -webkit-transition: none;
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: .5rem 0;
  margin: .125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: .25rem;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .175);
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1070px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1341px) {
  .dropdown-menu-xlbtw-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xlbtw-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1379px) {
  .dropdown-menu-xlwd-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xlwd-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: .125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: 0;
  border-right: .3em solid transparent;
  border-bottom: .3em solid;
  border-left: .3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: .125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid transparent;
  border-right: 0;
  border-bottom: .3em solid transparent;
  border-left: .3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: .125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid transparent;
  border-right: .3em solid;
  border-bottom: .3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: .5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: .25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: .5rem 1.5rem;
  margin-bottom: 0;
  font-size: .875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: .25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -2px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: .609375rem;
  padding-left: .609375rem;
}
.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: .375rem;
  padding-left: .375rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: .75rem;
  padding-left: .75rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0);
}
.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -2px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -2px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -2px;
}

.input-group-prepend {
  margin-right: -2px;
}

.input-group-append {
  margin-left: -2px;
}

.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: .625rem .8125rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 2px solid #ced4da;
  border-radius: 7px;
}
.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 7px;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + .5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: 7px;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 28;
  padding-left: 1.5rem;
  color-adjust: exact;
}

.custom-control-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 14.5rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
  box-shadow: 0 0 0 0 rgba(83, 71, 152, 0);
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0), 0 0 0 .2rem rgba(0, 123, 255, .25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
  box-shadow: 0 0 0 0 rgba(83, 71, 152, 0);
}
.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 13.5rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}
.custom-control-label::after {
  position: absolute;
  top: 13.5rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: .25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
  box-shadow: 0 0 0 0 rgba(83, 71, 152, 0);
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, .5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, .5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, .5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: .5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(13.5rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: .5rem;
  -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
  transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    -webkit-transition: none;
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(.75rem);
  -ms-transform: translateX(.75rem);
  transform: translateX(.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, .5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem 1.75rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075);
  -webkit-appearance: none;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075), 0 0 0 .2rem rgba(0, 123, 255, .25);
}
.custom-select:focus::-ms-value {
  color: #534798;
  background-color: #fff;
}
.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: .75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + .5rem + 2px);
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
}
.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + .75rem);
  padding: .375rem .75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 .25rem .25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(0, 123, 255, .25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(0, 123, 255, .25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(0, 123, 255, .25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 .1rem .25rem rgba(0, 0, 0, .1);
  -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 .25rem .25rem rgba(0, 0, 0, .1);
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 .1rem .25rem rgba(0, 0, 0, .1);
  -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 .25rem .25rem rgba(0, 0, 0, .1);
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: .2rem;
  margin-left: .2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 .1rem .25rem rgba(0, 0, 0, .1);
  -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: .5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: .5rem;
  box-shadow: inset 0 .25rem .25rem rgba(0, 0, 0, .1);
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    -webkit-transition: none;
    transition: none;
  }
}

.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: .5rem 1rem;
}
.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: .25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: .5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-toggler {
  padding: 0 .3125rem;
  font-size: 1.3125rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 6px;
}
.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1069.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1070px) {
  .navbar-expand-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1340.98px) {
  .navbar-expand-xlbtw > .container,
  .navbar-expand-xlbtw > .container-fluid,
  .navbar-expand-xlbtw > .container-sm,
  .navbar-expand-xlbtw > .container-md,
  .navbar-expand-xlbtw > .container-lg,
  .navbar-expand-xlbtw > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1341px) {
  .navbar-expand-xlbtw {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-xlbtw .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-xlbtw .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xlbtw .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }
  .navbar-expand-xlbtw > .container,
  .navbar-expand-xlbtw > .container-fluid,
  .navbar-expand-xlbtw > .container-sm,
  .navbar-expand-xlbtw > .container-md,
  .navbar-expand-xlbtw > .container-lg,
  .navbar-expand-xlbtw > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-xlbtw .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-xlbtw .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1378.98px) {
  .navbar-expand-xlwd > .container,
  .navbar-expand-xlwd > .container-fluid,
  .navbar-expand-xlwd > .container-sm,
  .navbar-expand-xlwd > .container-md,
  .navbar-expand-xlwd > .container-lg,
  .navbar-expand-xlwd > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1379px) {
  .navbar-expand-xlwd {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-xlwd .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-xlwd .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xlwd .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
  }
  .navbar-expand-xlwd > .container,
  .navbar-expand-xlwd > .container-fluid,
  .navbar-expand-xlwd > .container-sm,
  .navbar-expand-xlwd > .container-md,
  .navbar-expand-xlwd > .container-lg,
  .navbar-expand-xlwd > .container-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-xlwd .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-xlwd .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: .5rem;
  padding-left: .5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, .9);
}
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, .9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, .5);
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, .7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, .3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, .9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, .5);
  border-color: rgba(0, 0, 0, .1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, .5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, .9);
}
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, .9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: #534798;
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, .25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #534798;
}

.navbar-dark .navbar-toggler {
  color: #fff;
  border-color: rgba(255, 255, 255, .5);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%23fff' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: #fff;
}
.navbar-dark .navbar-text a {
  color: #534798;
}
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #534798;
}

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: .25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: .75rem;
}

.card-subtitle {
  margin-top: -.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: .75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, .03);
  border-bottom: 1px solid rgba(0, 0, 0, .125);
}
.card-header:first-child {
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}

.card-footer {
  padding: .75rem 1.25rem;
  background-color: rgba(0, 0, 0, .03);
  border-top: 1px solid rgba(0, 0, 0, .125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px);
}

.card-header-tabs {
  margin-right: -.625rem;
  margin-bottom: -.75rem;
  margin-left: -.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -.625rem;
  margin-left: -.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0;
    flex: 1 0 0;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .card-group > .card {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0;
    flex: 1 0 0;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: .75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: .75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: .25rem;
}

.breadcrumb-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: .5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: .5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 13px;
}

.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
}

.page-item:last-child .page-link {
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.pagination-sm .page-link {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.badge {
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    -webkit-transition: none;
    transition: none;
  }
}
a.badge:hover,
a.badge:focus {
  text-decoration: none;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: .6em;
  padding-left: .6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #534798;
}
a.badge-primary:hover,
a.badge-primary:focus {
  color: #fff;
  background-color: #403775;
}
a.badge-primary:focus,
a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(83, 71, 152, .5);
}

.badge-secondary {
  color: #fff;
  background-color: #42388d;
}
a.badge-secondary:hover,
a.badge-secondary:focus {
  color: #fff;
  background-color: #312a68;
}
a.badge-secondary:focus,
a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(66, 56, 141, .5);
}

.badge-success {
  color: #fff;
  background-color: #00d819;
}
a.badge-success:hover,
a.badge-success:focus {
  color: #fff;
  background-color: #00a513;
}
a.badge-success:focus,
a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0, 216, 25, .5);
}

.badge-info {
  color: #212529;
  background-color: #a0ccec;
}
a.badge-info:hover,
a.badge-info:focus {
  color: #212529;
  background-color: #76b5e4;
}
a.badge-info:focus,
a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(160, 204, 236, .5);
}

.badge-warning {
  color: #212529;
  background-color: #e4a039;
}
a.badge-warning:hover,
a.badge-warning:focus {
  color: #212529;
  background-color: #ce871c;
}
a.badge-warning:focus,
a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(228, 160, 57, .5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:hover,
a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger:focus,
a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover,
a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus,
a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5);
}

.badge-light2 {
  color: #212529;
  background-color: #ddd;
}
a.badge-light2:hover,
a.badge-light2:focus {
  color: #212529;
  background-color: #c4c3c3;
}
a.badge-light2:focus,
a.badge-light2.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(221, 221, 221, .5);
}

.badge-dark {
  color: #fff;
  background-color: #333;
}
a.badge-dark:hover,
a.badge-dark:focus {
  color: #fff;
  background-color: #1a1919;
}
a.badge-dark:focus,
a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(51, 51, 51, .5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 15px;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: .75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #2b254f;
  background-color: #dddaea;
  border-color: #cfcbe2;
}
.alert-primary hr {
  border-top-color: #c0bbd9;
}
.alert-primary .alert-link {
  color: #18152c;
}

.alert-secondary {
  color: #221d49;
  background-color: #d9d7e8;
  border-color: #cac7df;
}
.alert-secondary hr {
  border-top-color: #bbb7d6;
}
.alert-secondary .alert-link {
  color: #110f25;
}

.alert-success {
  color: #00700d;
  background-color: #ccf7d1;
  border-color: #b8f4bf;
}
.alert-success hr {
  border-top-color: #a2f1ab;
}
.alert-success .alert-link {
  color: #003d07;
}

.alert-info {
  color: #536a7b;
  background-color: #ecf5fb;
  border-color: #e4f1fa;
}
.alert-info hr {
  border-top-color: #cee6f6;
}
.alert-info .alert-link {
  color: #3e505d;
}

.alert-warning {
  color: #77531e;
  background-color: #faecd7;
  border-color: #f7e4c8;
}
.alert-warning hr {
  border-top-color: #f4d9b2;
}
.alert-warning .alert-link {
  color: #4e3714;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-light2 {
  color: #737373;
  background-color: #f8f8f8;
  border-color: whitesmoke;
}
.alert-light2 hr {
  border-top-color: #e8e8e8;
}
.alert-light2 .alert-link {
  color: #5a5959;
}

.alert-dark {
  color: #1b1b1b;
  background-color: #d6d6d6;
  border-color: #c6c6c6;
}
.alert-dark hr {
  border-top-color: #b9b9b9;
}
.alert-dark .alert-link {
  color: #020101;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: .75rem;
  background-color: #e9ecef;
  border-radius: .25rem;
  box-shadow: inset 0 .1rem .1rem rgba(0, 0, 0, .1);
}

.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  -webkit-transition: width .6s ease;
  transition: width .6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    -webkit-transition: none;
    transition: none;
  }
}

.progress-bar-striped {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.list-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: .25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: .75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: .25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: .25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1070px) {
  .list-group-horizontal-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1341px) {
  .list-group-horizontal-xlbtw {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-xlbtw > .list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xlbtw > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xlbtw > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xlbtw > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xlbtw > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1379px) {
  .list-group-horizontal-xlwd {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-xlwd > .list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xlwd > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xlwd > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xlwd > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xlwd > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #2b254f;
  background-color: #cfcbe2;
}
.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #2b254f;
  background-color: #c0bbd9;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #2b254f;
  border-color: #2b254f;
}

.list-group-item-secondary {
  color: #221d49;
  background-color: #cac7df;
}
.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #221d49;
  background-color: #bbb7d6;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #221d49;
  border-color: #221d49;
}

.list-group-item-success {
  color: #00700d;
  background-color: #b8f4bf;
}
.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #00700d;
  background-color: #a2f1ab;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #00700d;
  border-color: #00700d;
}

.list-group-item-info {
  color: #536a7b;
  background-color: #e4f1fa;
}
.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #536a7b;
  background-color: #cee6f6;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #536a7b;
  border-color: #536a7b;
}

.list-group-item-warning {
  color: #77531e;
  background-color: #f7e4c8;
}
.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #77531e;
  background-color: #f4d9b2;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #77531e;
  border-color: #77531e;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-light2 {
  color: #737373;
  background-color: whitesmoke;
}
.list-group-item-light2.list-group-item-action:hover,
.list-group-item-light2.list-group-item-action:focus {
  color: #737373;
  background-color: #e8e8e8;
}
.list-group-item-light2.list-group-item-action.active {
  color: #fff;
  background-color: #737373;
  border-color: #737373;
}

.list-group-item-dark {
  color: #1b1b1b;
  background-color: #c6c6c6;
}
.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #1b1b1b;
  background-color: #b9b9b9;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1b1b;
  border-color: #1b1b1b;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  -ms-flex-preferred-size: 350px;
  flex-basis: 350px;
  max-width: 350px;
  font-size: .875rem;
  background-color: rgba(255, 255, 255, .85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .1);
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .1);
  opacity: 0;
  border-radius: .25rem;
}
.toast:not(:last-child) {
  margin-bottom: .75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: .25rem .75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, .85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, .05);
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
}

.toast-body {
  padding: .75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform .3s ease-out;
  transition: -webkit-transform .3s ease-out;
  transition: transform .3s ease-out;
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
  -webkit-transform: translate(0, -50px);
  -ms-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    -webkit-transition: none;
    transition: none;
  }
}
.modal.show .modal-dialog {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}
.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .3rem;
  box-shadow: 0 .25rem .5rem rgba(0, 0, 0, .5);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: .5;
}

.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: .75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(.3rem - 1px);
  border-bottom-left-radius: calc(.3rem - 1px);
}
.modal-footer > * {
  margin: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-content {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .5);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1070px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.75;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: .875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: .9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: .8rem;
  height: .4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
  padding: .4rem 0;
}
.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: .4rem .4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 .4rem;
}
.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: .4rem;
  height: .8rem;
}
.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: .4rem .4rem .4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
  padding: .4rem 0;
}
.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 .4rem .4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 .4rem;
}
.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: .4rem;
  height: .8rem;
}
.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: .4rem 0 .4rem .4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: .25rem .5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: .25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.75;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: .875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .3rem;
  box-shadow: 0 .25rem .5rem rgba(0, 0, 0, .2);
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: .5rem;
  margin: 0 .3rem;
}
.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
  margin-bottom: .5rem;
}
.bs-popover-top > .arrow,
.bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-.5rem - 1px);
}
.bs-popover-top > .arrow::before,
.bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: .5rem .5rem 0;
  border-top-color: rgba(0, 0, 0, .25);
}
.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: .5rem .5rem 0;
  border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
  margin-left: .5rem;
}
.bs-popover-right > .arrow,
.bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc(-.5rem - 1px);
  width: .5rem;
  height: 1rem;
  margin: .3rem 0;
}
.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: .5rem .5rem .5rem 0;
  border-right-color: rgba(0, 0, 0, .25);
}
.bs-popover-right > .arrow::after,
.bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: .5rem .5rem .5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
  margin-top: .5rem;
}
.bs-popover-bottom > .arrow,
.bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-.5rem - 1px);
}
.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 .5rem .5rem .5rem;
  border-bottom-color: rgba(0, 0, 0, .25);
}
.bs-popover-bottom > .arrow::after,
.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 .5rem .5rem .5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
  margin-right: .5rem;
}
.bs-popover-left > .arrow,
.bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc(-.5rem - 1px);
  width: .5rem;
  height: 1rem;
  margin: .3rem 0;
}
.bs-popover-left > .arrow::before,
.bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: rgba(0, 0, 0, .25);
}
.bs-popover-left > .arrow::after,
.bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: .5rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: .5rem .75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform .6s ease-in-out;
  transition: -webkit-transform .6s ease-in-out;
  transition: transform .6s ease-in-out;
  transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    -webkit-transition: none;
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  -webkit-transition: opacity 0s .6s;
  transition: opacity 0s .6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    -webkit-transition: none;
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  -webkit-transition: opacity .15s ease;
  transition: opacity .15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    -webkit-transition: none;
    transition: none;
  }
}
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  -webkit-transition: opacity .6s ease;
  transition: opacity .6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    -webkit-transition: none;
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: .25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: .2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
  animation: spinner-grow .75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #534798 !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #403775 !important;
}

.bg-secondary {
  background-color: #42388d !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #312a68 !important;
}

.bg-success {
  background-color: #00d819 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #00a513 !important;
}

.bg-info {
  background-color: #a0ccec !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #76b5e4 !important;
}

.bg-warning {
  background-color: #e4a039 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ce871c !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-light2 {
  background-color: #ddd !important;
}

a.bg-light2:hover,
a.bg-light2:focus,
button.bg-light2:hover,
button.bg-light2:focus {
  background-color: #c4c3c3 !important;
}

.bg-dark {
  background-color: #333 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1a1919 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #ccc !important;
}

.border-top {
  border-top: 1px solid #ccc !important;
}

.border-right {
  border-right: 1px solid #ccc !important;
}

.border-bottom {
  border-bottom: 1px solid #ccc !important;
}

.border-left {
  border-left: 1px solid #ccc !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #534798 !important;
}

.border-secondary {
  border-color: #42388d !important;
}

.border-success {
  border-color: #00d819 !important;
}

.border-info {
  border-color: #a0ccec !important;
}

.border-warning {
  border-color: #e4a039 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-light2 {
  border-color: #ddd !important;
}

.border-dark {
  border-color: #333 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 7px !important;
}

.rounded {
  border-radius: 13px !important;
}

.rounded-top {
  border-top-left-radius: 13px !important;
  border-top-right-radius: 13px !important;
}

.rounded-right {
  border-top-right-radius: 13px !important;
  border-bottom-right-radius: 13px !important;
}

.rounded-bottom {
  border-bottom-right-radius: 13px !important;
  border-bottom-left-radius: 13px !important;
}

.rounded-left {
  border-top-left-radius: 13px !important;
  border-bottom-left-radius: 13px !important;
}

.rounded-lg {
  border-radius: 15px !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1070px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1341px) {
  .d-xlbtw-none {
    display: none !important;
  }
  .d-xlbtw-inline {
    display: inline !important;
  }
  .d-xlbtw-inline-block {
    display: inline-block !important;
  }
  .d-xlbtw-block {
    display: block !important;
  }
  .d-xlbtw-table {
    display: table !important;
  }
  .d-xlbtw-table-row {
    display: table-row !important;
  }
  .d-xlbtw-table-cell {
    display: table-cell !important;
  }
  .d-xlbtw-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xlbtw-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1379px) {
  .d-xlwd-none {
    display: none !important;
  }
  .d-xlwd-inline {
    display: inline !important;
  }
  .d-xlwd-inline-block {
    display: inline-block !important;
  }
  .d-xlwd-block {
    display: block !important;
  }
  .d-xlwd-table {
    display: table !important;
  }
  .d-xlwd-table-row {
    display: table-row !important;
  }
  .d-xlwd-table-cell {
    display: table-cell !important;
  }
  .d-xlwd-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xlwd-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  -ms-grid-row-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  -ms-grid-row-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  -ms-grid-row-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    -ms-grid-row-align: auto !important;
    align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    -ms-grid-row-align: center !important;
    align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    -ms-grid-row-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-md-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-md-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-md-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    -ms-grid-row-align: auto !important;
    align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    -ms-grid-row-align: center !important;
    align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    -ms-grid-row-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    -ms-grid-row-align: auto !important;
    align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    -ms-grid-row-align: center !important;
    align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    -ms-grid-row-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 1070px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    -ms-grid-row-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    -ms-grid-row-align: center !important;
    align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    -ms-grid-row-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 1341px) {
  .flex-xlbtw-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xlbtw-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xlbtw-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xlbtw-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xlbtw-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xlbtw-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xlbtw-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-xlbtw-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xlbtw-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xlbtw-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xlbtw-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-xlbtw-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-xlbtw-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xlbtw-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xlbtw-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xlbtw-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xlbtw-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-xlbtw-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xlbtw-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xlbtw-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xlbtw-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xlbtw-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xlbtw-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xlbtw-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xlbtw-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xlbtw-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xlbtw-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xlbtw-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xlbtw-auto {
    -ms-flex-item-align: auto !important;
    -ms-grid-row-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xlbtw-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xlbtw-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xlbtw-center {
    -ms-flex-item-align: center !important;
    -ms-grid-row-align: center !important;
    align-self: center !important;
  }
  .align-self-xlbtw-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xlbtw-stretch {
    -ms-flex-item-align: stretch !important;
    -ms-grid-row-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 1379px) {
  .flex-xlwd-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xlwd-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xlwd-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xlwd-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xlwd-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xlwd-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xlwd-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-xlwd-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xlwd-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xlwd-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xlwd-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-xlwd-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-xlwd-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xlwd-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xlwd-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xlwd-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xlwd-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-xlwd-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xlwd-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xlwd-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xlwd-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xlwd-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xlwd-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xlwd-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xlwd-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xlwd-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xlwd-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xlwd-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xlwd-auto {
    -ms-flex-item-align: auto !important;
    -ms-grid-row-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xlwd-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xlwd-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xlwd-center {
    -ms-flex-item-align: center !important;
    -ms-grid-row-align: center !important;
    align-self: center !important;
  }
  .align-self-xlwd-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xlwd-stretch {
    -ms-flex-item-align: stretch !important;
    -ms-grid-row-align: stretch !important;
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1070px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

@media (min-width: 1341px) {
  .float-xlbtw-left {
    float: left !important;
  }
  .float-xlbtw-right {
    float: right !important;
  }
  .float-xlbtw-none {
    float: none !important;
  }
}

@media (min-width: 1379px) {
  .float-xlwd-left {
    float: left !important;
  }
  .float-xlwd-right {
    float: right !important;
  }
  .float-xlwd-none {
    float: none !important;
  }
}

.user-select-all {
  -webkit-user-select: all !important;
  -ms-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0 8px rgba(0, 0, 0, .3) !important;
}

.shadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, .3) !important;
}

.shadow-lg {
  box-shadow: 0 0 20px rgba(0, 0, 0, .3) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-33 {
  width: 33.333% !important;
}

.w-50 {
  width: 50% !important;
}

.w-66 {
  width: 66.667% !important;
}

.w-80 {
  width: 80% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-20 {
  height: 20% !important;
}

.h-25 {
  height: 25% !important;
}

.h-33 {
  height: 33.333% !important;
}

.h-50 {
  height: 50% !important;
}

.h-66 {
  height: 66.667% !important;
}

.h-80 {
  height: 80% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 5px !important;
}

.mt-1,
.my-1 {
  margin-top: 5px !important;
}

.mr-1,
.mx-1 {
  margin-right: 5px !important;
}

.mb-1,
.my-1 {
  margin-bottom: 5px !important;
}

.ml-1,
.mx-1 {
  margin-left: 5px !important;
}

.m-2 {
  margin: 10px !important;
}

.mt-2,
.my-2 {
  margin-top: 10px !important;
}

.mr-2,
.mx-2 {
  margin-right: 10px !important;
}

.mb-2,
.my-2 {
  margin-bottom: 10px !important;
}

.ml-2,
.mx-2 {
  margin-left: 10px !important;
}

.m-3 {
  margin: 16px !important;
}

.mt-3,
.my-3 {
  margin-top: 16px !important;
}

.mr-3,
.mx-3 {
  margin-right: 16px !important;
}

.mb-3,
.my-3 {
  margin-bottom: 16px !important;
}

.ml-3,
.mx-3 {
  margin-left: 16px !important;
}

.m-4 {
  margin: 20px !important;
}

.mt-4,
.my-4 {
  margin-top: 20px !important;
}

.mr-4,
.mx-4 {
  margin-right: 20px !important;
}

.mb-4,
.my-4 {
  margin-bottom: 20px !important;
}

.ml-4,
.mx-4 {
  margin-left: 20px !important;
}

.m-5 {
  margin: 25px !important;
}

.mt-5,
.my-5 {
  margin-top: 25px !important;
}

.mr-5,
.mx-5 {
  margin-right: 25px !important;
}

.mb-5,
.my-5 {
  margin-bottom: 25px !important;
}

.ml-5,
.mx-5 {
  margin-left: 25px !important;
}

.m-6 {
  margin: 30px !important;
}

.mt-6,
.my-6 {
  margin-top: 30px !important;
}

.mr-6,
.mx-6 {
  margin-right: 30px !important;
}

.mb-6,
.my-6 {
  margin-bottom: 30px !important;
}

.ml-6,
.mx-6 {
  margin-left: 30px !important;
}

.m-7 {
  margin: 35px !important;
}

.mt-7,
.my-7 {
  margin-top: 35px !important;
}

.mr-7,
.mx-7 {
  margin-right: 35px !important;
}

.mb-7,
.my-7 {
  margin-bottom: 35px !important;
}

.ml-7,
.mx-7 {
  margin-left: 35px !important;
}

.m-8 {
  margin: 40px !important;
}

.mt-8,
.my-8 {
  margin-top: 40px !important;
}

.mr-8,
.mx-8 {
  margin-right: 40px !important;
}

.mb-8,
.my-8 {
  margin-bottom: 40px !important;
}

.ml-8,
.mx-8 {
  margin-left: 40px !important;
}

.m-9 {
  margin: 45px !important;
}

.mt-9,
.my-9 {
  margin-top: 45px !important;
}

.mr-9,
.mx-9 {
  margin-right: 45px !important;
}

.mb-9,
.my-9 {
  margin-bottom: 45px !important;
}

.ml-9,
.mx-9 {
  margin-left: 45px !important;
}

.m-10 {
  margin: 50px !important;
}

.mt-10,
.my-10 {
  margin-top: 50px !important;
}

.mr-10,
.mx-10 {
  margin-right: 50px !important;
}

.mb-10,
.my-10 {
  margin-bottom: 50px !important;
}

.ml-10,
.mx-10 {
  margin-left: 50px !important;
}

.m-11 {
  margin: 55px !important;
}

.mt-11,
.my-11 {
  margin-top: 55px !important;
}

.mr-11,
.mx-11 {
  margin-right: 55px !important;
}

.mb-11,
.my-11 {
  margin-bottom: 55px !important;
}

.ml-11,
.mx-11 {
  margin-left: 55px !important;
}

.m-12 {
  margin: 60px !important;
}

.mt-12,
.my-12 {
  margin-top: 60px !important;
}

.mr-12,
.mx-12 {
  margin-right: 60px !important;
}

.mb-12,
.my-12 {
  margin-bottom: 60px !important;
}

.ml-12,
.mx-12 {
  margin-left: 60px !important;
}

.m-13 {
  margin: 65px !important;
}

.mt-13,
.my-13 {
  margin-top: 65px !important;
}

.mr-13,
.mx-13 {
  margin-right: 65px !important;
}

.mb-13,
.my-13 {
  margin-bottom: 65px !important;
}

.ml-13,
.mx-13 {
  margin-left: 65px !important;
}

.m-14 {
  margin: 70px !important;
}

.mt-14,
.my-14 {
  margin-top: 70px !important;
}

.mr-14,
.mx-14 {
  margin-right: 70px !important;
}

.mb-14,
.my-14 {
  margin-bottom: 70px !important;
}

.ml-14,
.mx-14 {
  margin-left: 70px !important;
}

.m-15 {
  margin: 75px !important;
}

.mt-15,
.my-15 {
  margin-top: 75px !important;
}

.mr-15,
.mx-15 {
  margin-right: 75px !important;
}

.mb-15,
.my-15 {
  margin-bottom: 75px !important;
}

.ml-15,
.mx-15 {
  margin-left: 75px !important;
}

.m-16 {
  margin: 80px !important;
}

.mt-16,
.my-16 {
  margin-top: 80px !important;
}

.mr-16,
.mx-16 {
  margin-right: 80px !important;
}

.mb-16,
.my-16 {
  margin-bottom: 80px !important;
}

.ml-16,
.mx-16 {
  margin-left: 80px !important;
}

.m-17 {
  margin: 85px !important;
}

.mt-17,
.my-17 {
  margin-top: 85px !important;
}

.mr-17,
.mx-17 {
  margin-right: 85px !important;
}

.mb-17,
.my-17 {
  margin-bottom: 85px !important;
}

.ml-17,
.mx-17 {
  margin-left: 85px !important;
}

.m-18 {
  margin: 90px !important;
}

.mt-18,
.my-18 {
  margin-top: 90px !important;
}

.mr-18,
.mx-18 {
  margin-right: 90px !important;
}

.mb-18,
.my-18 {
  margin-bottom: 90px !important;
}

.ml-18,
.mx-18 {
  margin-left: 90px !important;
}

.m-19 {
  margin: 95px !important;
}

.mt-19,
.my-19 {
  margin-top: 95px !important;
}

.mr-19,
.mx-19 {
  margin-right: 95px !important;
}

.mb-19,
.my-19 {
  margin-bottom: 95px !important;
}

.ml-19,
.mx-19 {
  margin-left: 95px !important;
}

.m-20 {
  margin: 100px !important;
}

.mt-20,
.my-20 {
  margin-top: 100px !important;
}

.mr-20,
.mx-20 {
  margin-right: 100px !important;
}

.mb-20,
.my-20 {
  margin-bottom: 100px !important;
}

.ml-20,
.mx-20 {
  margin-left: 100px !important;
}

.m-21 {
  margin: 105px !important;
}

.mt-21,
.my-21 {
  margin-top: 105px !important;
}

.mr-21,
.mx-21 {
  margin-right: 105px !important;
}

.mb-21,
.my-21 {
  margin-bottom: 105px !important;
}

.ml-21,
.mx-21 {
  margin-left: 105px !important;
}

.m-22 {
  margin: 110px !important;
}

.mt-22,
.my-22 {
  margin-top: 110px !important;
}

.mr-22,
.mx-22 {
  margin-right: 110px !important;
}

.mb-22,
.my-22 {
  margin-bottom: 110px !important;
}

.ml-22,
.mx-22 {
  margin-left: 110px !important;
}

.m-23 {
  margin: 115px !important;
}

.mt-23,
.my-23 {
  margin-top: 115px !important;
}

.mr-23,
.mx-23 {
  margin-right: 115px !important;
}

.mb-23,
.my-23 {
  margin-bottom: 115px !important;
}

.ml-23,
.mx-23 {
  margin-left: 115px !important;
}

.m-24 {
  margin: 120px !important;
}

.mt-24,
.my-24 {
  margin-top: 120px !important;
}

.mr-24,
.mx-24 {
  margin-right: 120px !important;
}

.mb-24,
.my-24 {
  margin-bottom: 120px !important;
}

.ml-24,
.mx-24 {
  margin-left: 120px !important;
}

.m-25 {
  margin: 125px !important;
}

.mt-25,
.my-25 {
  margin-top: 125px !important;
}

.mr-25,
.mx-25 {
  margin-right: 125px !important;
}

.mb-25,
.my-25 {
  margin-bottom: 125px !important;
}

.ml-25,
.mx-25 {
  margin-left: 125px !important;
}

.m-26 {
  margin: 130px !important;
}

.mt-26,
.my-26 {
  margin-top: 130px !important;
}

.mr-26,
.mx-26 {
  margin-right: 130px !important;
}

.mb-26,
.my-26 {
  margin-bottom: 130px !important;
}

.ml-26,
.mx-26 {
  margin-left: 130px !important;
}

.m-27 {
  margin: 135px !important;
}

.mt-27,
.my-27 {
  margin-top: 135px !important;
}

.mr-27,
.mx-27 {
  margin-right: 135px !important;
}

.mb-27,
.my-27 {
  margin-bottom: 135px !important;
}

.ml-27,
.mx-27 {
  margin-left: 135px !important;
}

.m-28 {
  margin: 140px !important;
}

.mt-28,
.my-28 {
  margin-top: 140px !important;
}

.mr-28,
.mx-28 {
  margin-right: 140px !important;
}

.mb-28,
.my-28 {
  margin-bottom: 140px !important;
}

.ml-28,
.mx-28 {
  margin-left: 140px !important;
}

.m-29 {
  margin: 145px !important;
}

.mt-29,
.my-29 {
  margin-top: 145px !important;
}

.mr-29,
.mx-29 {
  margin-right: 145px !important;
}

.mb-29,
.my-29 {
  margin-bottom: 145px !important;
}

.ml-29,
.mx-29 {
  margin-left: 145px !important;
}

.m-30 {
  margin: 150px !important;
}

.mt-30,
.my-30 {
  margin-top: 150px !important;
}

.mr-30,
.mx-30 {
  margin-right: 150px !important;
}

.mb-30,
.my-30 {
  margin-bottom: 150px !important;
}

.ml-30,
.mx-30 {
  margin-left: 150px !important;
}

.m-31 {
  margin: 155px !important;
}

.mt-31,
.my-31 {
  margin-top: 155px !important;
}

.mr-31,
.mx-31 {
  margin-right: 155px !important;
}

.mb-31,
.my-31 {
  margin-bottom: 155px !important;
}

.ml-31,
.mx-31 {
  margin-left: 155px !important;
}

.m-32 {
  margin: 160px !important;
}

.mt-32,
.my-32 {
  margin-top: 160px !important;
}

.mr-32,
.mx-32 {
  margin-right: 160px !important;
}

.mb-32,
.my-32 {
  margin-bottom: 160px !important;
}

.ml-32,
.mx-32 {
  margin-left: 160px !important;
}

.m-33 {
  margin: 165px !important;
}

.mt-33,
.my-33 {
  margin-top: 165px !important;
}

.mr-33,
.mx-33 {
  margin-right: 165px !important;
}

.mb-33,
.my-33 {
  margin-bottom: 165px !important;
}

.ml-33,
.mx-33 {
  margin-left: 165px !important;
}

.m-34 {
  margin: 170px !important;
}

.mt-34,
.my-34 {
  margin-top: 170px !important;
}

.mr-34,
.mx-34 {
  margin-right: 170px !important;
}

.mb-34,
.my-34 {
  margin-bottom: 170px !important;
}

.ml-34,
.mx-34 {
  margin-left: 170px !important;
}

.m-35 {
  margin: 175px !important;
}

.mt-35,
.my-35 {
  margin-top: 175px !important;
}

.mr-35,
.mx-35 {
  margin-right: 175px !important;
}

.mb-35,
.my-35 {
  margin-bottom: 175px !important;
}

.ml-35,
.mx-35 {
  margin-left: 175px !important;
}

.m-36 {
  margin: 180px !important;
}

.mt-36,
.my-36 {
  margin-top: 180px !important;
}

.mr-36,
.mx-36 {
  margin-right: 180px !important;
}

.mb-36,
.my-36 {
  margin-bottom: 180px !important;
}

.ml-36,
.mx-36 {
  margin-left: 180px !important;
}

.m-37 {
  margin: 185px !important;
}

.mt-37,
.my-37 {
  margin-top: 185px !important;
}

.mr-37,
.mx-37 {
  margin-right: 185px !important;
}

.mb-37,
.my-37 {
  margin-bottom: 185px !important;
}

.ml-37,
.mx-37 {
  margin-left: 185px !important;
}

.m-38 {
  margin: 190px !important;
}

.mt-38,
.my-38 {
  margin-top: 190px !important;
}

.mr-38,
.mx-38 {
  margin-right: 190px !important;
}

.mb-38,
.my-38 {
  margin-bottom: 190px !important;
}

.ml-38,
.mx-38 {
  margin-left: 190px !important;
}

.m-39 {
  margin: 195px !important;
}

.mt-39,
.my-39 {
  margin-top: 195px !important;
}

.mr-39,
.mx-39 {
  margin-right: 195px !important;
}

.mb-39,
.my-39 {
  margin-bottom: 195px !important;
}

.ml-39,
.mx-39 {
  margin-left: 195px !important;
}

.m-40 {
  margin: 200px !important;
}

.mt-40,
.my-40 {
  margin-top: 200px !important;
}

.mr-40,
.mx-40 {
  margin-right: 200px !important;
}

.mb-40,
.my-40 {
  margin-bottom: 200px !important;
}

.ml-40,
.mx-40 {
  margin-left: 200px !important;
}

.m-41 {
  margin: 205px !important;
}

.mt-41,
.my-41 {
  margin-top: 205px !important;
}

.mr-41,
.mx-41 {
  margin-right: 205px !important;
}

.mb-41,
.my-41 {
  margin-bottom: 205px !important;
}

.ml-41,
.mx-41 {
  margin-left: 205px !important;
}

.m-42 {
  margin: 210px !important;
}

.mt-42,
.my-42 {
  margin-top: 210px !important;
}

.mr-42,
.mx-42 {
  margin-right: 210px !important;
}

.mb-42,
.my-42 {
  margin-bottom: 210px !important;
}

.ml-42,
.mx-42 {
  margin-left: 210px !important;
}

.m-43 {
  margin: 215px !important;
}

.mt-43,
.my-43 {
  margin-top: 215px !important;
}

.mr-43,
.mx-43 {
  margin-right: 215px !important;
}

.mb-43,
.my-43 {
  margin-bottom: 215px !important;
}

.ml-43,
.mx-43 {
  margin-left: 215px !important;
}

.m-44 {
  margin: 220px !important;
}

.mt-44,
.my-44 {
  margin-top: 220px !important;
}

.mr-44,
.mx-44 {
  margin-right: 220px !important;
}

.mb-44,
.my-44 {
  margin-bottom: 220px !important;
}

.ml-44,
.mx-44 {
  margin-left: 220px !important;
}

.m-45 {
  margin: 225px !important;
}

.mt-45,
.my-45 {
  margin-top: 225px !important;
}

.mr-45,
.mx-45 {
  margin-right: 225px !important;
}

.mb-45,
.my-45 {
  margin-bottom: 225px !important;
}

.ml-45,
.mx-45 {
  margin-left: 225px !important;
}

.m-46 {
  margin: 230px !important;
}

.mt-46,
.my-46 {
  margin-top: 230px !important;
}

.mr-46,
.mx-46 {
  margin-right: 230px !important;
}

.mb-46,
.my-46 {
  margin-bottom: 230px !important;
}

.ml-46,
.mx-46 {
  margin-left: 230px !important;
}

.m-47 {
  margin: 235px !important;
}

.mt-47,
.my-47 {
  margin-top: 235px !important;
}

.mr-47,
.mx-47 {
  margin-right: 235px !important;
}

.mb-47,
.my-47 {
  margin-bottom: 235px !important;
}

.ml-47,
.mx-47 {
  margin-left: 235px !important;
}

.m-48 {
  margin: 240px !important;
}

.mt-48,
.my-48 {
  margin-top: 240px !important;
}

.mr-48,
.mx-48 {
  margin-right: 240px !important;
}

.mb-48,
.my-48 {
  margin-bottom: 240px !important;
}

.ml-48,
.mx-48 {
  margin-left: 240px !important;
}

.m-49 {
  margin: 245px !important;
}

.mt-49,
.my-49 {
  margin-top: 245px !important;
}

.mr-49,
.mx-49 {
  margin-right: 245px !important;
}

.mb-49,
.my-49 {
  margin-bottom: 245px !important;
}

.ml-49,
.mx-49 {
  margin-left: 245px !important;
}

.m-50 {
  margin: 250px !important;
}

.mt-50,
.my-50 {
  margin-top: 250px !important;
}

.mr-50,
.mx-50 {
  margin-right: 250px !important;
}

.mb-50,
.my-50 {
  margin-bottom: 250px !important;
}

.ml-50,
.mx-50 {
  margin-left: 250px !important;
}

.m-51 {
  margin: 255px !important;
}

.mt-51,
.my-51 {
  margin-top: 255px !important;
}

.mr-51,
.mx-51 {
  margin-right: 255px !important;
}

.mb-51,
.my-51 {
  margin-bottom: 255px !important;
}

.ml-51,
.mx-51 {
  margin-left: 255px !important;
}

.m-52 {
  margin: 260px !important;
}

.mt-52,
.my-52 {
  margin-top: 260px !important;
}

.mr-52,
.mx-52 {
  margin-right: 260px !important;
}

.mb-52,
.my-52 {
  margin-bottom: 260px !important;
}

.ml-52,
.mx-52 {
  margin-left: 260px !important;
}

.m-53 {
  margin: 265px !important;
}

.mt-53,
.my-53 {
  margin-top: 265px !important;
}

.mr-53,
.mx-53 {
  margin-right: 265px !important;
}

.mb-53,
.my-53 {
  margin-bottom: 265px !important;
}

.ml-53,
.mx-53 {
  margin-left: 265px !important;
}

.m-54 {
  margin: 270px !important;
}

.mt-54,
.my-54 {
  margin-top: 270px !important;
}

.mr-54,
.mx-54 {
  margin-right: 270px !important;
}

.mb-54,
.my-54 {
  margin-bottom: 270px !important;
}

.ml-54,
.mx-54 {
  margin-left: 270px !important;
}

.m-55 {
  margin: 275px !important;
}

.mt-55,
.my-55 {
  margin-top: 275px !important;
}

.mr-55,
.mx-55 {
  margin-right: 275px !important;
}

.mb-55,
.my-55 {
  margin-bottom: 275px !important;
}

.ml-55,
.mx-55 {
  margin-left: 275px !important;
}

.m-56 {
  margin: 280px !important;
}

.mt-56,
.my-56 {
  margin-top: 280px !important;
}

.mr-56,
.mx-56 {
  margin-right: 280px !important;
}

.mb-56,
.my-56 {
  margin-bottom: 280px !important;
}

.ml-56,
.mx-56 {
  margin-left: 280px !important;
}

.m-57 {
  margin: 285px !important;
}

.mt-57,
.my-57 {
  margin-top: 285px !important;
}

.mr-57,
.mx-57 {
  margin-right: 285px !important;
}

.mb-57,
.my-57 {
  margin-bottom: 285px !important;
}

.ml-57,
.mx-57 {
  margin-left: 285px !important;
}

.m-58 {
  margin: 290px !important;
}

.mt-58,
.my-58 {
  margin-top: 290px !important;
}

.mr-58,
.mx-58 {
  margin-right: 290px !important;
}

.mb-58,
.my-58 {
  margin-bottom: 290px !important;
}

.ml-58,
.mx-58 {
  margin-left: 290px !important;
}

.m-59 {
  margin: 295px !important;
}

.mt-59,
.my-59 {
  margin-top: 295px !important;
}

.mr-59,
.mx-59 {
  margin-right: 295px !important;
}

.mb-59,
.my-59 {
  margin-bottom: 295px !important;
}

.ml-59,
.mx-59 {
  margin-left: 295px !important;
}

.m-60 {
  margin: 300px !important;
}

.mt-60,
.my-60 {
  margin-top: 300px !important;
}

.mr-60,
.mx-60 {
  margin-right: 300px !important;
}

.mb-60,
.my-60 {
  margin-bottom: 300px !important;
}

.ml-60,
.mx-60 {
  margin-left: 300px !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 5px !important;
}

.pt-1,
.py-1 {
  padding-top: 5px !important;
}

.pr-1,
.px-1 {
  padding-right: 5px !important;
}

.pb-1,
.py-1 {
  padding-bottom: 5px !important;
}

.pl-1,
.px-1 {
  padding-left: 5px !important;
}

.p-2 {
  padding: 10px !important;
}

.pt-2,
.py-2 {
  padding-top: 10px !important;
}

.pr-2,
.px-2 {
  padding-right: 10px !important;
}

.pb-2,
.py-2 {
  padding-bottom: 10px !important;
}

.pl-2,
.px-2 {
  padding-left: 10px !important;
}

.p-3 {
  padding: 16px !important;
}

.pt-3,
.py-3 {
  padding-top: 16px !important;
}

.pr-3,
.px-3 {
  padding-right: 16px !important;
}

.pb-3,
.py-3 {
  padding-bottom: 16px !important;
}

.pl-3,
.px-3 {
  padding-left: 16px !important;
}

.p-4 {
  padding: 20px !important;
}

.pt-4,
.py-4 {
  padding-top: 20px !important;
}

.pr-4,
.px-4 {
  padding-right: 20px !important;
}

.pb-4,
.py-4 {
  padding-bottom: 20px !important;
}

.pl-4,
.px-4 {
  padding-left: 20px !important;
}

.p-5 {
  padding: 25px !important;
}

.pt-5,
.py-5 {
  padding-top: 25px !important;
}

.pr-5,
.px-5 {
  padding-right: 25px !important;
}

.pb-5,
.py-5 {
  padding-bottom: 25px !important;
}

.pl-5,
.px-5 {
  padding-left: 25px !important;
}

.p-6 {
  padding: 30px !important;
}

.pt-6,
.py-6 {
  padding-top: 30px !important;
}

.pr-6,
.px-6 {
  padding-right: 30px !important;
}

.pb-6,
.py-6 {
  padding-bottom: 30px !important;
}

.pl-6,
.px-6 {
  padding-left: 30px !important;
}

.p-7 {
  padding: 35px !important;
}

.pt-7,
.py-7 {
  padding-top: 35px !important;
}

.pr-7,
.px-7 {
  padding-right: 35px !important;
}

.pb-7,
.py-7 {
  padding-bottom: 35px !important;
}

.pl-7,
.px-7 {
  padding-left: 35px !important;
}

.p-8 {
  padding: 40px !important;
}

.pt-8,
.py-8 {
  padding-top: 40px !important;
}

.pr-8,
.px-8 {
  padding-right: 40px !important;
}

.pb-8,
.py-8 {
  padding-bottom: 40px !important;
}

.pl-8,
.px-8 {
  padding-left: 40px !important;
}

.p-9 {
  padding: 45px !important;
}

.pt-9,
.py-9 {
  padding-top: 45px !important;
}

.pr-9,
.px-9 {
  padding-right: 45px !important;
}

.pb-9,
.py-9 {
  padding-bottom: 45px !important;
}

.pl-9,
.px-9 {
  padding-left: 45px !important;
}

.p-10 {
  padding: 50px !important;
}

.pt-10,
.py-10 {
  padding-top: 50px !important;
}

.pr-10,
.px-10 {
  padding-right: 50px !important;
}

.pb-10,
.py-10 {
  padding-bottom: 50px !important;
}

.pl-10,
.px-10 {
  padding-left: 50px !important;
}

.p-11 {
  padding: 55px !important;
}

.pt-11,
.py-11 {
  padding-top: 55px !important;
}

.pr-11,
.px-11 {
  padding-right: 55px !important;
}

.pb-11,
.py-11 {
  padding-bottom: 55px !important;
}

.pl-11,
.px-11 {
  padding-left: 55px !important;
}

.p-12 {
  padding: 60px !important;
}

.pt-12,
.py-12 {
  padding-top: 60px !important;
}

.pr-12,
.px-12 {
  padding-right: 60px !important;
}

.pb-12,
.py-12 {
  padding-bottom: 60px !important;
}

.pl-12,
.px-12 {
  padding-left: 60px !important;
}

.p-13 {
  padding: 65px !important;
}

.pt-13,
.py-13 {
  padding-top: 65px !important;
}

.pr-13,
.px-13 {
  padding-right: 65px !important;
}

.pb-13,
.py-13 {
  padding-bottom: 65px !important;
}

.pl-13,
.px-13 {
  padding-left: 65px !important;
}

.p-14 {
  padding: 70px !important;
}

.pt-14,
.py-14 {
  padding-top: 70px !important;
}

.pr-14,
.px-14 {
  padding-right: 70px !important;
}

.pb-14,
.py-14 {
  padding-bottom: 70px !important;
}

.pl-14,
.px-14 {
  padding-left: 70px !important;
}

.p-15 {
  padding: 75px !important;
}

.pt-15,
.py-15 {
  padding-top: 75px !important;
}

.pr-15,
.px-15 {
  padding-right: 75px !important;
}

.pb-15,
.py-15 {
  padding-bottom: 75px !important;
}

.pl-15,
.px-15 {
  padding-left: 75px !important;
}

.p-16 {
  padding: 80px !important;
}

.pt-16,
.py-16 {
  padding-top: 80px !important;
}

.pr-16,
.px-16 {
  padding-right: 80px !important;
}

.pb-16,
.py-16 {
  padding-bottom: 80px !important;
}

.pl-16,
.px-16 {
  padding-left: 80px !important;
}

.p-17 {
  padding: 85px !important;
}

.pt-17,
.py-17 {
  padding-top: 85px !important;
}

.pr-17,
.px-17 {
  padding-right: 85px !important;
}

.pb-17,
.py-17 {
  padding-bottom: 85px !important;
}

.pl-17,
.px-17 {
  padding-left: 85px !important;
}

.p-18 {
  padding: 90px !important;
}

.pt-18,
.py-18 {
  padding-top: 90px !important;
}

.pr-18,
.px-18 {
  padding-right: 90px !important;
}

.pb-18,
.py-18 {
  padding-bottom: 90px !important;
}

.pl-18,
.px-18 {
  padding-left: 90px !important;
}

.p-19 {
  padding: 95px !important;
}

.pt-19,
.py-19 {
  padding-top: 95px !important;
}

.pr-19,
.px-19 {
  padding-right: 95px !important;
}

.pb-19,
.py-19 {
  padding-bottom: 95px !important;
}

.pl-19,
.px-19 {
  padding-left: 95px !important;
}

.p-20 {
  padding: 100px !important;
}

.pt-20,
.py-20 {
  padding-top: 100px !important;
}

.pr-20,
.px-20 {
  padding-right: 100px !important;
}

.pb-20,
.py-20 {
  padding-bottom: 100px !important;
}

.pl-20,
.px-20 {
  padding-left: 100px !important;
}

.p-21 {
  padding: 105px !important;
}

.pt-21,
.py-21 {
  padding-top: 105px !important;
}

.pr-21,
.px-21 {
  padding-right: 105px !important;
}

.pb-21,
.py-21 {
  padding-bottom: 105px !important;
}

.pl-21,
.px-21 {
  padding-left: 105px !important;
}

.p-22 {
  padding: 110px !important;
}

.pt-22,
.py-22 {
  padding-top: 110px !important;
}

.pr-22,
.px-22 {
  padding-right: 110px !important;
}

.pb-22,
.py-22 {
  padding-bottom: 110px !important;
}

.pl-22,
.px-22 {
  padding-left: 110px !important;
}

.p-23 {
  padding: 115px !important;
}

.pt-23,
.py-23 {
  padding-top: 115px !important;
}

.pr-23,
.px-23 {
  padding-right: 115px !important;
}

.pb-23,
.py-23 {
  padding-bottom: 115px !important;
}

.pl-23,
.px-23 {
  padding-left: 115px !important;
}

.p-24 {
  padding: 120px !important;
}

.pt-24,
.py-24 {
  padding-top: 120px !important;
}

.pr-24,
.px-24 {
  padding-right: 120px !important;
}

.pb-24,
.py-24 {
  padding-bottom: 120px !important;
}

.pl-24,
.px-24 {
  padding-left: 120px !important;
}

.p-25 {
  padding: 125px !important;
}

.pt-25,
.py-25 {
  padding-top: 125px !important;
}

.pr-25,
.px-25 {
  padding-right: 125px !important;
}

.pb-25,
.py-25 {
  padding-bottom: 125px !important;
}

.pl-25,
.px-25 {
  padding-left: 125px !important;
}

.p-26 {
  padding: 130px !important;
}

.pt-26,
.py-26 {
  padding-top: 130px !important;
}

.pr-26,
.px-26 {
  padding-right: 130px !important;
}

.pb-26,
.py-26 {
  padding-bottom: 130px !important;
}

.pl-26,
.px-26 {
  padding-left: 130px !important;
}

.p-27 {
  padding: 135px !important;
}

.pt-27,
.py-27 {
  padding-top: 135px !important;
}

.pr-27,
.px-27 {
  padding-right: 135px !important;
}

.pb-27,
.py-27 {
  padding-bottom: 135px !important;
}

.pl-27,
.px-27 {
  padding-left: 135px !important;
}

.p-28 {
  padding: 140px !important;
}

.pt-28,
.py-28 {
  padding-top: 140px !important;
}

.pr-28,
.px-28 {
  padding-right: 140px !important;
}

.pb-28,
.py-28 {
  padding-bottom: 140px !important;
}

.pl-28,
.px-28 {
  padding-left: 140px !important;
}

.p-29 {
  padding: 145px !important;
}

.pt-29,
.py-29 {
  padding-top: 145px !important;
}

.pr-29,
.px-29 {
  padding-right: 145px !important;
}

.pb-29,
.py-29 {
  padding-bottom: 145px !important;
}

.pl-29,
.px-29 {
  padding-left: 145px !important;
}

.p-30 {
  padding: 150px !important;
}

.pt-30,
.py-30 {
  padding-top: 150px !important;
}

.pr-30,
.px-30 {
  padding-right: 150px !important;
}

.pb-30,
.py-30 {
  padding-bottom: 150px !important;
}

.pl-30,
.px-30 {
  padding-left: 150px !important;
}

.p-31 {
  padding: 155px !important;
}

.pt-31,
.py-31 {
  padding-top: 155px !important;
}

.pr-31,
.px-31 {
  padding-right: 155px !important;
}

.pb-31,
.py-31 {
  padding-bottom: 155px !important;
}

.pl-31,
.px-31 {
  padding-left: 155px !important;
}

.p-32 {
  padding: 160px !important;
}

.pt-32,
.py-32 {
  padding-top: 160px !important;
}

.pr-32,
.px-32 {
  padding-right: 160px !important;
}

.pb-32,
.py-32 {
  padding-bottom: 160px !important;
}

.pl-32,
.px-32 {
  padding-left: 160px !important;
}

.p-33 {
  padding: 165px !important;
}

.pt-33,
.py-33 {
  padding-top: 165px !important;
}

.pr-33,
.px-33 {
  padding-right: 165px !important;
}

.pb-33,
.py-33 {
  padding-bottom: 165px !important;
}

.pl-33,
.px-33 {
  padding-left: 165px !important;
}

.p-34 {
  padding: 170px !important;
}

.pt-34,
.py-34 {
  padding-top: 170px !important;
}

.pr-34,
.px-34 {
  padding-right: 170px !important;
}

.pb-34,
.py-34 {
  padding-bottom: 170px !important;
}

.pl-34,
.px-34 {
  padding-left: 170px !important;
}

.p-35 {
  padding: 175px !important;
}

.pt-35,
.py-35 {
  padding-top: 175px !important;
}

.pr-35,
.px-35 {
  padding-right: 175px !important;
}

.pb-35,
.py-35 {
  padding-bottom: 175px !important;
}

.pl-35,
.px-35 {
  padding-left: 175px !important;
}

.p-36 {
  padding: 180px !important;
}

.pt-36,
.py-36 {
  padding-top: 180px !important;
}

.pr-36,
.px-36 {
  padding-right: 180px !important;
}

.pb-36,
.py-36 {
  padding-bottom: 180px !important;
}

.pl-36,
.px-36 {
  padding-left: 180px !important;
}

.p-37 {
  padding: 185px !important;
}

.pt-37,
.py-37 {
  padding-top: 185px !important;
}

.pr-37,
.px-37 {
  padding-right: 185px !important;
}

.pb-37,
.py-37 {
  padding-bottom: 185px !important;
}

.pl-37,
.px-37 {
  padding-left: 185px !important;
}

.p-38 {
  padding: 190px !important;
}

.pt-38,
.py-38 {
  padding-top: 190px !important;
}

.pr-38,
.px-38 {
  padding-right: 190px !important;
}

.pb-38,
.py-38 {
  padding-bottom: 190px !important;
}

.pl-38,
.px-38 {
  padding-left: 190px !important;
}

.p-39 {
  padding: 195px !important;
}

.pt-39,
.py-39 {
  padding-top: 195px !important;
}

.pr-39,
.px-39 {
  padding-right: 195px !important;
}

.pb-39,
.py-39 {
  padding-bottom: 195px !important;
}

.pl-39,
.px-39 {
  padding-left: 195px !important;
}

.p-40 {
  padding: 200px !important;
}

.pt-40,
.py-40 {
  padding-top: 200px !important;
}

.pr-40,
.px-40 {
  padding-right: 200px !important;
}

.pb-40,
.py-40 {
  padding-bottom: 200px !important;
}

.pl-40,
.px-40 {
  padding-left: 200px !important;
}

.p-41 {
  padding: 205px !important;
}

.pt-41,
.py-41 {
  padding-top: 205px !important;
}

.pr-41,
.px-41 {
  padding-right: 205px !important;
}

.pb-41,
.py-41 {
  padding-bottom: 205px !important;
}

.pl-41,
.px-41 {
  padding-left: 205px !important;
}

.p-42 {
  padding: 210px !important;
}

.pt-42,
.py-42 {
  padding-top: 210px !important;
}

.pr-42,
.px-42 {
  padding-right: 210px !important;
}

.pb-42,
.py-42 {
  padding-bottom: 210px !important;
}

.pl-42,
.px-42 {
  padding-left: 210px !important;
}

.p-43 {
  padding: 215px !important;
}

.pt-43,
.py-43 {
  padding-top: 215px !important;
}

.pr-43,
.px-43 {
  padding-right: 215px !important;
}

.pb-43,
.py-43 {
  padding-bottom: 215px !important;
}

.pl-43,
.px-43 {
  padding-left: 215px !important;
}

.p-44 {
  padding: 220px !important;
}

.pt-44,
.py-44 {
  padding-top: 220px !important;
}

.pr-44,
.px-44 {
  padding-right: 220px !important;
}

.pb-44,
.py-44 {
  padding-bottom: 220px !important;
}

.pl-44,
.px-44 {
  padding-left: 220px !important;
}

.p-45 {
  padding: 225px !important;
}

.pt-45,
.py-45 {
  padding-top: 225px !important;
}

.pr-45,
.px-45 {
  padding-right: 225px !important;
}

.pb-45,
.py-45 {
  padding-bottom: 225px !important;
}

.pl-45,
.px-45 {
  padding-left: 225px !important;
}

.p-46 {
  padding: 230px !important;
}

.pt-46,
.py-46 {
  padding-top: 230px !important;
}

.pr-46,
.px-46 {
  padding-right: 230px !important;
}

.pb-46,
.py-46 {
  padding-bottom: 230px !important;
}

.pl-46,
.px-46 {
  padding-left: 230px !important;
}

.p-47 {
  padding: 235px !important;
}

.pt-47,
.py-47 {
  padding-top: 235px !important;
}

.pr-47,
.px-47 {
  padding-right: 235px !important;
}

.pb-47,
.py-47 {
  padding-bottom: 235px !important;
}

.pl-47,
.px-47 {
  padding-left: 235px !important;
}

.p-48 {
  padding: 240px !important;
}

.pt-48,
.py-48 {
  padding-top: 240px !important;
}

.pr-48,
.px-48 {
  padding-right: 240px !important;
}

.pb-48,
.py-48 {
  padding-bottom: 240px !important;
}

.pl-48,
.px-48 {
  padding-left: 240px !important;
}

.p-49 {
  padding: 245px !important;
}

.pt-49,
.py-49 {
  padding-top: 245px !important;
}

.pr-49,
.px-49 {
  padding-right: 245px !important;
}

.pb-49,
.py-49 {
  padding-bottom: 245px !important;
}

.pl-49,
.px-49 {
  padding-left: 245px !important;
}

.p-50 {
  padding: 250px !important;
}

.pt-50,
.py-50 {
  padding-top: 250px !important;
}

.pr-50,
.px-50 {
  padding-right: 250px !important;
}

.pb-50,
.py-50 {
  padding-bottom: 250px !important;
}

.pl-50,
.px-50 {
  padding-left: 250px !important;
}

.p-51 {
  padding: 255px !important;
}

.pt-51,
.py-51 {
  padding-top: 255px !important;
}

.pr-51,
.px-51 {
  padding-right: 255px !important;
}

.pb-51,
.py-51 {
  padding-bottom: 255px !important;
}

.pl-51,
.px-51 {
  padding-left: 255px !important;
}

.p-52 {
  padding: 260px !important;
}

.pt-52,
.py-52 {
  padding-top: 260px !important;
}

.pr-52,
.px-52 {
  padding-right: 260px !important;
}

.pb-52,
.py-52 {
  padding-bottom: 260px !important;
}

.pl-52,
.px-52 {
  padding-left: 260px !important;
}

.p-53 {
  padding: 265px !important;
}

.pt-53,
.py-53 {
  padding-top: 265px !important;
}

.pr-53,
.px-53 {
  padding-right: 265px !important;
}

.pb-53,
.py-53 {
  padding-bottom: 265px !important;
}

.pl-53,
.px-53 {
  padding-left: 265px !important;
}

.p-54 {
  padding: 270px !important;
}

.pt-54,
.py-54 {
  padding-top: 270px !important;
}

.pr-54,
.px-54 {
  padding-right: 270px !important;
}

.pb-54,
.py-54 {
  padding-bottom: 270px !important;
}

.pl-54,
.px-54 {
  padding-left: 270px !important;
}

.p-55 {
  padding: 275px !important;
}

.pt-55,
.py-55 {
  padding-top: 275px !important;
}

.pr-55,
.px-55 {
  padding-right: 275px !important;
}

.pb-55,
.py-55 {
  padding-bottom: 275px !important;
}

.pl-55,
.px-55 {
  padding-left: 275px !important;
}

.p-56 {
  padding: 280px !important;
}

.pt-56,
.py-56 {
  padding-top: 280px !important;
}

.pr-56,
.px-56 {
  padding-right: 280px !important;
}

.pb-56,
.py-56 {
  padding-bottom: 280px !important;
}

.pl-56,
.px-56 {
  padding-left: 280px !important;
}

.p-57 {
  padding: 285px !important;
}

.pt-57,
.py-57 {
  padding-top: 285px !important;
}

.pr-57,
.px-57 {
  padding-right: 285px !important;
}

.pb-57,
.py-57 {
  padding-bottom: 285px !important;
}

.pl-57,
.px-57 {
  padding-left: 285px !important;
}

.p-58 {
  padding: 290px !important;
}

.pt-58,
.py-58 {
  padding-top: 290px !important;
}

.pr-58,
.px-58 {
  padding-right: 290px !important;
}

.pb-58,
.py-58 {
  padding-bottom: 290px !important;
}

.pl-58,
.px-58 {
  padding-left: 290px !important;
}

.p-59 {
  padding: 295px !important;
}

.pt-59,
.py-59 {
  padding-top: 295px !important;
}

.pr-59,
.px-59 {
  padding-right: 295px !important;
}

.pb-59,
.py-59 {
  padding-bottom: 295px !important;
}

.pl-59,
.px-59 {
  padding-left: 295px !important;
}

.p-60 {
  padding: 300px !important;
}

.pt-60,
.py-60 {
  padding-top: 300px !important;
}

.pr-60,
.px-60 {
  padding-right: 300px !important;
}

.pb-60,
.py-60 {
  padding-bottom: 300px !important;
}

.pl-60,
.px-60 {
  padding-left: 300px !important;
}

.m-n1 {
  margin: -5px !important;
}

.mt-n1,
.my-n1 {
  margin-top: -5px !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -5px !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -5px !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -5px !important;
}

.m-n2 {
  margin: -10px !important;
}

.mt-n2,
.my-n2 {
  margin-top: -10px !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -10px !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -10px !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -10px !important;
}

.m-n3 {
  margin: -16px !important;
}

.mt-n3,
.my-n3 {
  margin-top: -16px !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -16px !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -16px !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -16px !important;
}

.m-n4 {
  margin: -20px !important;
}

.mt-n4,
.my-n4 {
  margin-top: -20px !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -20px !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -20px !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -20px !important;
}

.m-n5 {
  margin: -25px !important;
}

.mt-n5,
.my-n5 {
  margin-top: -25px !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -25px !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -25px !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -25px !important;
}

.m-n6 {
  margin: -30px !important;
}

.mt-n6,
.my-n6 {
  margin-top: -30px !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -30px !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -30px !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -30px !important;
}

.m-n7 {
  margin: -35px !important;
}

.mt-n7,
.my-n7 {
  margin-top: -35px !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -35px !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -35px !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -35px !important;
}

.m-n8 {
  margin: -40px !important;
}

.mt-n8,
.my-n8 {
  margin-top: -40px !important;
}

.mr-n8,
.mx-n8 {
  margin-right: -40px !important;
}

.mb-n8,
.my-n8 {
  margin-bottom: -40px !important;
}

.ml-n8,
.mx-n8 {
  margin-left: -40px !important;
}

.m-n9 {
  margin: -45px !important;
}

.mt-n9,
.my-n9 {
  margin-top: -45px !important;
}

.mr-n9,
.mx-n9 {
  margin-right: -45px !important;
}

.mb-n9,
.my-n9 {
  margin-bottom: -45px !important;
}

.ml-n9,
.mx-n9 {
  margin-left: -45px !important;
}

.m-n10 {
  margin: -50px !important;
}

.mt-n10,
.my-n10 {
  margin-top: -50px !important;
}

.mr-n10,
.mx-n10 {
  margin-right: -50px !important;
}

.mb-n10,
.my-n10 {
  margin-bottom: -50px !important;
}

.ml-n10,
.mx-n10 {
  margin-left: -50px !important;
}

.m-n11 {
  margin: -55px !important;
}

.mt-n11,
.my-n11 {
  margin-top: -55px !important;
}

.mr-n11,
.mx-n11 {
  margin-right: -55px !important;
}

.mb-n11,
.my-n11 {
  margin-bottom: -55px !important;
}

.ml-n11,
.mx-n11 {
  margin-left: -55px !important;
}

.m-n12 {
  margin: -60px !important;
}

.mt-n12,
.my-n12 {
  margin-top: -60px !important;
}

.mr-n12,
.mx-n12 {
  margin-right: -60px !important;
}

.mb-n12,
.my-n12 {
  margin-bottom: -60px !important;
}

.ml-n12,
.mx-n12 {
  margin-left: -60px !important;
}

.m-n13 {
  margin: -65px !important;
}

.mt-n13,
.my-n13 {
  margin-top: -65px !important;
}

.mr-n13,
.mx-n13 {
  margin-right: -65px !important;
}

.mb-n13,
.my-n13 {
  margin-bottom: -65px !important;
}

.ml-n13,
.mx-n13 {
  margin-left: -65px !important;
}

.m-n14 {
  margin: -70px !important;
}

.mt-n14,
.my-n14 {
  margin-top: -70px !important;
}

.mr-n14,
.mx-n14 {
  margin-right: -70px !important;
}

.mb-n14,
.my-n14 {
  margin-bottom: -70px !important;
}

.ml-n14,
.mx-n14 {
  margin-left: -70px !important;
}

.m-n15 {
  margin: -75px !important;
}

.mt-n15,
.my-n15 {
  margin-top: -75px !important;
}

.mr-n15,
.mx-n15 {
  margin-right: -75px !important;
}

.mb-n15,
.my-n15 {
  margin-bottom: -75px !important;
}

.ml-n15,
.mx-n15 {
  margin-left: -75px !important;
}

.m-n16 {
  margin: -80px !important;
}

.mt-n16,
.my-n16 {
  margin-top: -80px !important;
}

.mr-n16,
.mx-n16 {
  margin-right: -80px !important;
}

.mb-n16,
.my-n16 {
  margin-bottom: -80px !important;
}

.ml-n16,
.mx-n16 {
  margin-left: -80px !important;
}

.m-n17 {
  margin: -85px !important;
}

.mt-n17,
.my-n17 {
  margin-top: -85px !important;
}

.mr-n17,
.mx-n17 {
  margin-right: -85px !important;
}

.mb-n17,
.my-n17 {
  margin-bottom: -85px !important;
}

.ml-n17,
.mx-n17 {
  margin-left: -85px !important;
}

.m-n18 {
  margin: -90px !important;
}

.mt-n18,
.my-n18 {
  margin-top: -90px !important;
}

.mr-n18,
.mx-n18 {
  margin-right: -90px !important;
}

.mb-n18,
.my-n18 {
  margin-bottom: -90px !important;
}

.ml-n18,
.mx-n18 {
  margin-left: -90px !important;
}

.m-n19 {
  margin: -95px !important;
}

.mt-n19,
.my-n19 {
  margin-top: -95px !important;
}

.mr-n19,
.mx-n19 {
  margin-right: -95px !important;
}

.mb-n19,
.my-n19 {
  margin-bottom: -95px !important;
}

.ml-n19,
.mx-n19 {
  margin-left: -95px !important;
}

.m-n20 {
  margin: -100px !important;
}

.mt-n20,
.my-n20 {
  margin-top: -100px !important;
}

.mr-n20,
.mx-n20 {
  margin-right: -100px !important;
}

.mb-n20,
.my-n20 {
  margin-bottom: -100px !important;
}

.ml-n20,
.mx-n20 {
  margin-left: -100px !important;
}

.m-n21 {
  margin: -105px !important;
}

.mt-n21,
.my-n21 {
  margin-top: -105px !important;
}

.mr-n21,
.mx-n21 {
  margin-right: -105px !important;
}

.mb-n21,
.my-n21 {
  margin-bottom: -105px !important;
}

.ml-n21,
.mx-n21 {
  margin-left: -105px !important;
}

.m-n22 {
  margin: -110px !important;
}

.mt-n22,
.my-n22 {
  margin-top: -110px !important;
}

.mr-n22,
.mx-n22 {
  margin-right: -110px !important;
}

.mb-n22,
.my-n22 {
  margin-bottom: -110px !important;
}

.ml-n22,
.mx-n22 {
  margin-left: -110px !important;
}

.m-n23 {
  margin: -115px !important;
}

.mt-n23,
.my-n23 {
  margin-top: -115px !important;
}

.mr-n23,
.mx-n23 {
  margin-right: -115px !important;
}

.mb-n23,
.my-n23 {
  margin-bottom: -115px !important;
}

.ml-n23,
.mx-n23 {
  margin-left: -115px !important;
}

.m-n24 {
  margin: -120px !important;
}

.mt-n24,
.my-n24 {
  margin-top: -120px !important;
}

.mr-n24,
.mx-n24 {
  margin-right: -120px !important;
}

.mb-n24,
.my-n24 {
  margin-bottom: -120px !important;
}

.ml-n24,
.mx-n24 {
  margin-left: -120px !important;
}

.m-n25 {
  margin: -125px !important;
}

.mt-n25,
.my-n25 {
  margin-top: -125px !important;
}

.mr-n25,
.mx-n25 {
  margin-right: -125px !important;
}

.mb-n25,
.my-n25 {
  margin-bottom: -125px !important;
}

.ml-n25,
.mx-n25 {
  margin-left: -125px !important;
}

.m-n26 {
  margin: -130px !important;
}

.mt-n26,
.my-n26 {
  margin-top: -130px !important;
}

.mr-n26,
.mx-n26 {
  margin-right: -130px !important;
}

.mb-n26,
.my-n26 {
  margin-bottom: -130px !important;
}

.ml-n26,
.mx-n26 {
  margin-left: -130px !important;
}

.m-n27 {
  margin: -135px !important;
}

.mt-n27,
.my-n27 {
  margin-top: -135px !important;
}

.mr-n27,
.mx-n27 {
  margin-right: -135px !important;
}

.mb-n27,
.my-n27 {
  margin-bottom: -135px !important;
}

.ml-n27,
.mx-n27 {
  margin-left: -135px !important;
}

.m-n28 {
  margin: -140px !important;
}

.mt-n28,
.my-n28 {
  margin-top: -140px !important;
}

.mr-n28,
.mx-n28 {
  margin-right: -140px !important;
}

.mb-n28,
.my-n28 {
  margin-bottom: -140px !important;
}

.ml-n28,
.mx-n28 {
  margin-left: -140px !important;
}

.m-n29 {
  margin: -145px !important;
}

.mt-n29,
.my-n29 {
  margin-top: -145px !important;
}

.mr-n29,
.mx-n29 {
  margin-right: -145px !important;
}

.mb-n29,
.my-n29 {
  margin-bottom: -145px !important;
}

.ml-n29,
.mx-n29 {
  margin-left: -145px !important;
}

.m-n30 {
  margin: -150px !important;
}

.mt-n30,
.my-n30 {
  margin-top: -150px !important;
}

.mr-n30,
.mx-n30 {
  margin-right: -150px !important;
}

.mb-n30,
.my-n30 {
  margin-bottom: -150px !important;
}

.ml-n30,
.mx-n30 {
  margin-left: -150px !important;
}

.m-n31 {
  margin: -155px !important;
}

.mt-n31,
.my-n31 {
  margin-top: -155px !important;
}

.mr-n31,
.mx-n31 {
  margin-right: -155px !important;
}

.mb-n31,
.my-n31 {
  margin-bottom: -155px !important;
}

.ml-n31,
.mx-n31 {
  margin-left: -155px !important;
}

.m-n32 {
  margin: -160px !important;
}

.mt-n32,
.my-n32 {
  margin-top: -160px !important;
}

.mr-n32,
.mx-n32 {
  margin-right: -160px !important;
}

.mb-n32,
.my-n32 {
  margin-bottom: -160px !important;
}

.ml-n32,
.mx-n32 {
  margin-left: -160px !important;
}

.m-n33 {
  margin: -165px !important;
}

.mt-n33,
.my-n33 {
  margin-top: -165px !important;
}

.mr-n33,
.mx-n33 {
  margin-right: -165px !important;
}

.mb-n33,
.my-n33 {
  margin-bottom: -165px !important;
}

.ml-n33,
.mx-n33 {
  margin-left: -165px !important;
}

.m-n34 {
  margin: -170px !important;
}

.mt-n34,
.my-n34 {
  margin-top: -170px !important;
}

.mr-n34,
.mx-n34 {
  margin-right: -170px !important;
}

.mb-n34,
.my-n34 {
  margin-bottom: -170px !important;
}

.ml-n34,
.mx-n34 {
  margin-left: -170px !important;
}

.m-n35 {
  margin: -175px !important;
}

.mt-n35,
.my-n35 {
  margin-top: -175px !important;
}

.mr-n35,
.mx-n35 {
  margin-right: -175px !important;
}

.mb-n35,
.my-n35 {
  margin-bottom: -175px !important;
}

.ml-n35,
.mx-n35 {
  margin-left: -175px !important;
}

.m-n36 {
  margin: -180px !important;
}

.mt-n36,
.my-n36 {
  margin-top: -180px !important;
}

.mr-n36,
.mx-n36 {
  margin-right: -180px !important;
}

.mb-n36,
.my-n36 {
  margin-bottom: -180px !important;
}

.ml-n36,
.mx-n36 {
  margin-left: -180px !important;
}

.m-n37 {
  margin: -185px !important;
}

.mt-n37,
.my-n37 {
  margin-top: -185px !important;
}

.mr-n37,
.mx-n37 {
  margin-right: -185px !important;
}

.mb-n37,
.my-n37 {
  margin-bottom: -185px !important;
}

.ml-n37,
.mx-n37 {
  margin-left: -185px !important;
}

.m-n38 {
  margin: -190px !important;
}

.mt-n38,
.my-n38 {
  margin-top: -190px !important;
}

.mr-n38,
.mx-n38 {
  margin-right: -190px !important;
}

.mb-n38,
.my-n38 {
  margin-bottom: -190px !important;
}

.ml-n38,
.mx-n38 {
  margin-left: -190px !important;
}

.m-n39 {
  margin: -195px !important;
}

.mt-n39,
.my-n39 {
  margin-top: -195px !important;
}

.mr-n39,
.mx-n39 {
  margin-right: -195px !important;
}

.mb-n39,
.my-n39 {
  margin-bottom: -195px !important;
}

.ml-n39,
.mx-n39 {
  margin-left: -195px !important;
}

.m-n40 {
  margin: -200px !important;
}

.mt-n40,
.my-n40 {
  margin-top: -200px !important;
}

.mr-n40,
.mx-n40 {
  margin-right: -200px !important;
}

.mb-n40,
.my-n40 {
  margin-bottom: -200px !important;
}

.ml-n40,
.mx-n40 {
  margin-left: -200px !important;
}

.m-n41 {
  margin: -205px !important;
}

.mt-n41,
.my-n41 {
  margin-top: -205px !important;
}

.mr-n41,
.mx-n41 {
  margin-right: -205px !important;
}

.mb-n41,
.my-n41 {
  margin-bottom: -205px !important;
}

.ml-n41,
.mx-n41 {
  margin-left: -205px !important;
}

.m-n42 {
  margin: -210px !important;
}

.mt-n42,
.my-n42 {
  margin-top: -210px !important;
}

.mr-n42,
.mx-n42 {
  margin-right: -210px !important;
}

.mb-n42,
.my-n42 {
  margin-bottom: -210px !important;
}

.ml-n42,
.mx-n42 {
  margin-left: -210px !important;
}

.m-n43 {
  margin: -215px !important;
}

.mt-n43,
.my-n43 {
  margin-top: -215px !important;
}

.mr-n43,
.mx-n43 {
  margin-right: -215px !important;
}

.mb-n43,
.my-n43 {
  margin-bottom: -215px !important;
}

.ml-n43,
.mx-n43 {
  margin-left: -215px !important;
}

.m-n44 {
  margin: -220px !important;
}

.mt-n44,
.my-n44 {
  margin-top: -220px !important;
}

.mr-n44,
.mx-n44 {
  margin-right: -220px !important;
}

.mb-n44,
.my-n44 {
  margin-bottom: -220px !important;
}

.ml-n44,
.mx-n44 {
  margin-left: -220px !important;
}

.m-n45 {
  margin: -225px !important;
}

.mt-n45,
.my-n45 {
  margin-top: -225px !important;
}

.mr-n45,
.mx-n45 {
  margin-right: -225px !important;
}

.mb-n45,
.my-n45 {
  margin-bottom: -225px !important;
}

.ml-n45,
.mx-n45 {
  margin-left: -225px !important;
}

.m-n46 {
  margin: -230px !important;
}

.mt-n46,
.my-n46 {
  margin-top: -230px !important;
}

.mr-n46,
.mx-n46 {
  margin-right: -230px !important;
}

.mb-n46,
.my-n46 {
  margin-bottom: -230px !important;
}

.ml-n46,
.mx-n46 {
  margin-left: -230px !important;
}

.m-n47 {
  margin: -235px !important;
}

.mt-n47,
.my-n47 {
  margin-top: -235px !important;
}

.mr-n47,
.mx-n47 {
  margin-right: -235px !important;
}

.mb-n47,
.my-n47 {
  margin-bottom: -235px !important;
}

.ml-n47,
.mx-n47 {
  margin-left: -235px !important;
}

.m-n48 {
  margin: -240px !important;
}

.mt-n48,
.my-n48 {
  margin-top: -240px !important;
}

.mr-n48,
.mx-n48 {
  margin-right: -240px !important;
}

.mb-n48,
.my-n48 {
  margin-bottom: -240px !important;
}

.ml-n48,
.mx-n48 {
  margin-left: -240px !important;
}

.m-n49 {
  margin: -245px !important;
}

.mt-n49,
.my-n49 {
  margin-top: -245px !important;
}

.mr-n49,
.mx-n49 {
  margin-right: -245px !important;
}

.mb-n49,
.my-n49 {
  margin-bottom: -245px !important;
}

.ml-n49,
.mx-n49 {
  margin-left: -245px !important;
}

.m-n50 {
  margin: -250px !important;
}

.mt-n50,
.my-n50 {
  margin-top: -250px !important;
}

.mr-n50,
.mx-n50 {
  margin-right: -250px !important;
}

.mb-n50,
.my-n50 {
  margin-bottom: -250px !important;
}

.ml-n50,
.mx-n50 {
  margin-left: -250px !important;
}

.m-n51 {
  margin: -255px !important;
}

.mt-n51,
.my-n51 {
  margin-top: -255px !important;
}

.mr-n51,
.mx-n51 {
  margin-right: -255px !important;
}

.mb-n51,
.my-n51 {
  margin-bottom: -255px !important;
}

.ml-n51,
.mx-n51 {
  margin-left: -255px !important;
}

.m-n52 {
  margin: -260px !important;
}

.mt-n52,
.my-n52 {
  margin-top: -260px !important;
}

.mr-n52,
.mx-n52 {
  margin-right: -260px !important;
}

.mb-n52,
.my-n52 {
  margin-bottom: -260px !important;
}

.ml-n52,
.mx-n52 {
  margin-left: -260px !important;
}

.m-n53 {
  margin: -265px !important;
}

.mt-n53,
.my-n53 {
  margin-top: -265px !important;
}

.mr-n53,
.mx-n53 {
  margin-right: -265px !important;
}

.mb-n53,
.my-n53 {
  margin-bottom: -265px !important;
}

.ml-n53,
.mx-n53 {
  margin-left: -265px !important;
}

.m-n54 {
  margin: -270px !important;
}

.mt-n54,
.my-n54 {
  margin-top: -270px !important;
}

.mr-n54,
.mx-n54 {
  margin-right: -270px !important;
}

.mb-n54,
.my-n54 {
  margin-bottom: -270px !important;
}

.ml-n54,
.mx-n54 {
  margin-left: -270px !important;
}

.m-n55 {
  margin: -275px !important;
}

.mt-n55,
.my-n55 {
  margin-top: -275px !important;
}

.mr-n55,
.mx-n55 {
  margin-right: -275px !important;
}

.mb-n55,
.my-n55 {
  margin-bottom: -275px !important;
}

.ml-n55,
.mx-n55 {
  margin-left: -275px !important;
}

.m-n56 {
  margin: -280px !important;
}

.mt-n56,
.my-n56 {
  margin-top: -280px !important;
}

.mr-n56,
.mx-n56 {
  margin-right: -280px !important;
}

.mb-n56,
.my-n56 {
  margin-bottom: -280px !important;
}

.ml-n56,
.mx-n56 {
  margin-left: -280px !important;
}

.m-n57 {
  margin: -285px !important;
}

.mt-n57,
.my-n57 {
  margin-top: -285px !important;
}

.mr-n57,
.mx-n57 {
  margin-right: -285px !important;
}

.mb-n57,
.my-n57 {
  margin-bottom: -285px !important;
}

.ml-n57,
.mx-n57 {
  margin-left: -285px !important;
}

.m-n58 {
  margin: -290px !important;
}

.mt-n58,
.my-n58 {
  margin-top: -290px !important;
}

.mr-n58,
.mx-n58 {
  margin-right: -290px !important;
}

.mb-n58,
.my-n58 {
  margin-bottom: -290px !important;
}

.ml-n58,
.mx-n58 {
  margin-left: -290px !important;
}

.m-n59 {
  margin: -295px !important;
}

.mt-n59,
.my-n59 {
  margin-top: -295px !important;
}

.mr-n59,
.mx-n59 {
  margin-right: -295px !important;
}

.mb-n59,
.my-n59 {
  margin-bottom: -295px !important;
}

.ml-n59,
.mx-n59 {
  margin-left: -295px !important;
}

.m-n60 {
  margin: -300px !important;
}

.mt-n60,
.my-n60 {
  margin-top: -300px !important;
}

.mr-n60,
.mx-n60 {
  margin-right: -300px !important;
}

.mb-n60,
.my-n60 {
  margin-bottom: -300px !important;
}

.ml-n60,
.mx-n60 {
  margin-left: -300px !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 5px !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 5px !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 5px !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 5px !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 5px !important;
  }
  .m-sm-2 {
    margin: 10px !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 10px !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 10px !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 10px !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 10px !important;
  }
  .m-sm-3 {
    margin: 16px !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 16px !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 16px !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 16px !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 16px !important;
  }
  .m-sm-4 {
    margin: 20px !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 20px !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 20px !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 20px !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 20px !important;
  }
  .m-sm-5 {
    margin: 25px !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 25px !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 25px !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 25px !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 25px !important;
  }
  .m-sm-6 {
    margin: 30px !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 30px !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 30px !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 30px !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 30px !important;
  }
  .m-sm-7 {
    margin: 35px !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 35px !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 35px !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 35px !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 35px !important;
  }
  .m-sm-8 {
    margin: 40px !important;
  }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 40px !important;
  }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 40px !important;
  }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 40px !important;
  }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 40px !important;
  }
  .m-sm-9 {
    margin: 45px !important;
  }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 45px !important;
  }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 45px !important;
  }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 45px !important;
  }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 45px !important;
  }
  .m-sm-10 {
    margin: 50px !important;
  }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 50px !important;
  }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 50px !important;
  }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 50px !important;
  }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 50px !important;
  }
  .m-sm-11 {
    margin: 55px !important;
  }
  .mt-sm-11,
  .my-sm-11 {
    margin-top: 55px !important;
  }
  .mr-sm-11,
  .mx-sm-11 {
    margin-right: 55px !important;
  }
  .mb-sm-11,
  .my-sm-11 {
    margin-bottom: 55px !important;
  }
  .ml-sm-11,
  .mx-sm-11 {
    margin-left: 55px !important;
  }
  .m-sm-12 {
    margin: 60px !important;
  }
  .mt-sm-12,
  .my-sm-12 {
    margin-top: 60px !important;
  }
  .mr-sm-12,
  .mx-sm-12 {
    margin-right: 60px !important;
  }
  .mb-sm-12,
  .my-sm-12 {
    margin-bottom: 60px !important;
  }
  .ml-sm-12,
  .mx-sm-12 {
    margin-left: 60px !important;
  }
  .m-sm-13 {
    margin: 65px !important;
  }
  .mt-sm-13,
  .my-sm-13 {
    margin-top: 65px !important;
  }
  .mr-sm-13,
  .mx-sm-13 {
    margin-right: 65px !important;
  }
  .mb-sm-13,
  .my-sm-13 {
    margin-bottom: 65px !important;
  }
  .ml-sm-13,
  .mx-sm-13 {
    margin-left: 65px !important;
  }
  .m-sm-14 {
    margin: 70px !important;
  }
  .mt-sm-14,
  .my-sm-14 {
    margin-top: 70px !important;
  }
  .mr-sm-14,
  .mx-sm-14 {
    margin-right: 70px !important;
  }
  .mb-sm-14,
  .my-sm-14 {
    margin-bottom: 70px !important;
  }
  .ml-sm-14,
  .mx-sm-14 {
    margin-left: 70px !important;
  }
  .m-sm-15 {
    margin: 75px !important;
  }
  .mt-sm-15,
  .my-sm-15 {
    margin-top: 75px !important;
  }
  .mr-sm-15,
  .mx-sm-15 {
    margin-right: 75px !important;
  }
  .mb-sm-15,
  .my-sm-15 {
    margin-bottom: 75px !important;
  }
  .ml-sm-15,
  .mx-sm-15 {
    margin-left: 75px !important;
  }
  .m-sm-16 {
    margin: 80px !important;
  }
  .mt-sm-16,
  .my-sm-16 {
    margin-top: 80px !important;
  }
  .mr-sm-16,
  .mx-sm-16 {
    margin-right: 80px !important;
  }
  .mb-sm-16,
  .my-sm-16 {
    margin-bottom: 80px !important;
  }
  .ml-sm-16,
  .mx-sm-16 {
    margin-left: 80px !important;
  }
  .m-sm-17 {
    margin: 85px !important;
  }
  .mt-sm-17,
  .my-sm-17 {
    margin-top: 85px !important;
  }
  .mr-sm-17,
  .mx-sm-17 {
    margin-right: 85px !important;
  }
  .mb-sm-17,
  .my-sm-17 {
    margin-bottom: 85px !important;
  }
  .ml-sm-17,
  .mx-sm-17 {
    margin-left: 85px !important;
  }
  .m-sm-18 {
    margin: 90px !important;
  }
  .mt-sm-18,
  .my-sm-18 {
    margin-top: 90px !important;
  }
  .mr-sm-18,
  .mx-sm-18 {
    margin-right: 90px !important;
  }
  .mb-sm-18,
  .my-sm-18 {
    margin-bottom: 90px !important;
  }
  .ml-sm-18,
  .mx-sm-18 {
    margin-left: 90px !important;
  }
  .m-sm-19 {
    margin: 95px !important;
  }
  .mt-sm-19,
  .my-sm-19 {
    margin-top: 95px !important;
  }
  .mr-sm-19,
  .mx-sm-19 {
    margin-right: 95px !important;
  }
  .mb-sm-19,
  .my-sm-19 {
    margin-bottom: 95px !important;
  }
  .ml-sm-19,
  .mx-sm-19 {
    margin-left: 95px !important;
  }
  .m-sm-20 {
    margin: 100px !important;
  }
  .mt-sm-20,
  .my-sm-20 {
    margin-top: 100px !important;
  }
  .mr-sm-20,
  .mx-sm-20 {
    margin-right: 100px !important;
  }
  .mb-sm-20,
  .my-sm-20 {
    margin-bottom: 100px !important;
  }
  .ml-sm-20,
  .mx-sm-20 {
    margin-left: 100px !important;
  }
  .m-sm-21 {
    margin: 105px !important;
  }
  .mt-sm-21,
  .my-sm-21 {
    margin-top: 105px !important;
  }
  .mr-sm-21,
  .mx-sm-21 {
    margin-right: 105px !important;
  }
  .mb-sm-21,
  .my-sm-21 {
    margin-bottom: 105px !important;
  }
  .ml-sm-21,
  .mx-sm-21 {
    margin-left: 105px !important;
  }
  .m-sm-22 {
    margin: 110px !important;
  }
  .mt-sm-22,
  .my-sm-22 {
    margin-top: 110px !important;
  }
  .mr-sm-22,
  .mx-sm-22 {
    margin-right: 110px !important;
  }
  .mb-sm-22,
  .my-sm-22 {
    margin-bottom: 110px !important;
  }
  .ml-sm-22,
  .mx-sm-22 {
    margin-left: 110px !important;
  }
  .m-sm-23 {
    margin: 115px !important;
  }
  .mt-sm-23,
  .my-sm-23 {
    margin-top: 115px !important;
  }
  .mr-sm-23,
  .mx-sm-23 {
    margin-right: 115px !important;
  }
  .mb-sm-23,
  .my-sm-23 {
    margin-bottom: 115px !important;
  }
  .ml-sm-23,
  .mx-sm-23 {
    margin-left: 115px !important;
  }
  .m-sm-24 {
    margin: 120px !important;
  }
  .mt-sm-24,
  .my-sm-24 {
    margin-top: 120px !important;
  }
  .mr-sm-24,
  .mx-sm-24 {
    margin-right: 120px !important;
  }
  .mb-sm-24,
  .my-sm-24 {
    margin-bottom: 120px !important;
  }
  .ml-sm-24,
  .mx-sm-24 {
    margin-left: 120px !important;
  }
  .m-sm-25 {
    margin: 125px !important;
  }
  .mt-sm-25,
  .my-sm-25 {
    margin-top: 125px !important;
  }
  .mr-sm-25,
  .mx-sm-25 {
    margin-right: 125px !important;
  }
  .mb-sm-25,
  .my-sm-25 {
    margin-bottom: 125px !important;
  }
  .ml-sm-25,
  .mx-sm-25 {
    margin-left: 125px !important;
  }
  .m-sm-26 {
    margin: 130px !important;
  }
  .mt-sm-26,
  .my-sm-26 {
    margin-top: 130px !important;
  }
  .mr-sm-26,
  .mx-sm-26 {
    margin-right: 130px !important;
  }
  .mb-sm-26,
  .my-sm-26 {
    margin-bottom: 130px !important;
  }
  .ml-sm-26,
  .mx-sm-26 {
    margin-left: 130px !important;
  }
  .m-sm-27 {
    margin: 135px !important;
  }
  .mt-sm-27,
  .my-sm-27 {
    margin-top: 135px !important;
  }
  .mr-sm-27,
  .mx-sm-27 {
    margin-right: 135px !important;
  }
  .mb-sm-27,
  .my-sm-27 {
    margin-bottom: 135px !important;
  }
  .ml-sm-27,
  .mx-sm-27 {
    margin-left: 135px !important;
  }
  .m-sm-28 {
    margin: 140px !important;
  }
  .mt-sm-28,
  .my-sm-28 {
    margin-top: 140px !important;
  }
  .mr-sm-28,
  .mx-sm-28 {
    margin-right: 140px !important;
  }
  .mb-sm-28,
  .my-sm-28 {
    margin-bottom: 140px !important;
  }
  .ml-sm-28,
  .mx-sm-28 {
    margin-left: 140px !important;
  }
  .m-sm-29 {
    margin: 145px !important;
  }
  .mt-sm-29,
  .my-sm-29 {
    margin-top: 145px !important;
  }
  .mr-sm-29,
  .mx-sm-29 {
    margin-right: 145px !important;
  }
  .mb-sm-29,
  .my-sm-29 {
    margin-bottom: 145px !important;
  }
  .ml-sm-29,
  .mx-sm-29 {
    margin-left: 145px !important;
  }
  .m-sm-30 {
    margin: 150px !important;
  }
  .mt-sm-30,
  .my-sm-30 {
    margin-top: 150px !important;
  }
  .mr-sm-30,
  .mx-sm-30 {
    margin-right: 150px !important;
  }
  .mb-sm-30,
  .my-sm-30 {
    margin-bottom: 150px !important;
  }
  .ml-sm-30,
  .mx-sm-30 {
    margin-left: 150px !important;
  }
  .m-sm-31 {
    margin: 155px !important;
  }
  .mt-sm-31,
  .my-sm-31 {
    margin-top: 155px !important;
  }
  .mr-sm-31,
  .mx-sm-31 {
    margin-right: 155px !important;
  }
  .mb-sm-31,
  .my-sm-31 {
    margin-bottom: 155px !important;
  }
  .ml-sm-31,
  .mx-sm-31 {
    margin-left: 155px !important;
  }
  .m-sm-32 {
    margin: 160px !important;
  }
  .mt-sm-32,
  .my-sm-32 {
    margin-top: 160px !important;
  }
  .mr-sm-32,
  .mx-sm-32 {
    margin-right: 160px !important;
  }
  .mb-sm-32,
  .my-sm-32 {
    margin-bottom: 160px !important;
  }
  .ml-sm-32,
  .mx-sm-32 {
    margin-left: 160px !important;
  }
  .m-sm-33 {
    margin: 165px !important;
  }
  .mt-sm-33,
  .my-sm-33 {
    margin-top: 165px !important;
  }
  .mr-sm-33,
  .mx-sm-33 {
    margin-right: 165px !important;
  }
  .mb-sm-33,
  .my-sm-33 {
    margin-bottom: 165px !important;
  }
  .ml-sm-33,
  .mx-sm-33 {
    margin-left: 165px !important;
  }
  .m-sm-34 {
    margin: 170px !important;
  }
  .mt-sm-34,
  .my-sm-34 {
    margin-top: 170px !important;
  }
  .mr-sm-34,
  .mx-sm-34 {
    margin-right: 170px !important;
  }
  .mb-sm-34,
  .my-sm-34 {
    margin-bottom: 170px !important;
  }
  .ml-sm-34,
  .mx-sm-34 {
    margin-left: 170px !important;
  }
  .m-sm-35 {
    margin: 175px !important;
  }
  .mt-sm-35,
  .my-sm-35 {
    margin-top: 175px !important;
  }
  .mr-sm-35,
  .mx-sm-35 {
    margin-right: 175px !important;
  }
  .mb-sm-35,
  .my-sm-35 {
    margin-bottom: 175px !important;
  }
  .ml-sm-35,
  .mx-sm-35 {
    margin-left: 175px !important;
  }
  .m-sm-36 {
    margin: 180px !important;
  }
  .mt-sm-36,
  .my-sm-36 {
    margin-top: 180px !important;
  }
  .mr-sm-36,
  .mx-sm-36 {
    margin-right: 180px !important;
  }
  .mb-sm-36,
  .my-sm-36 {
    margin-bottom: 180px !important;
  }
  .ml-sm-36,
  .mx-sm-36 {
    margin-left: 180px !important;
  }
  .m-sm-37 {
    margin: 185px !important;
  }
  .mt-sm-37,
  .my-sm-37 {
    margin-top: 185px !important;
  }
  .mr-sm-37,
  .mx-sm-37 {
    margin-right: 185px !important;
  }
  .mb-sm-37,
  .my-sm-37 {
    margin-bottom: 185px !important;
  }
  .ml-sm-37,
  .mx-sm-37 {
    margin-left: 185px !important;
  }
  .m-sm-38 {
    margin: 190px !important;
  }
  .mt-sm-38,
  .my-sm-38 {
    margin-top: 190px !important;
  }
  .mr-sm-38,
  .mx-sm-38 {
    margin-right: 190px !important;
  }
  .mb-sm-38,
  .my-sm-38 {
    margin-bottom: 190px !important;
  }
  .ml-sm-38,
  .mx-sm-38 {
    margin-left: 190px !important;
  }
  .m-sm-39 {
    margin: 195px !important;
  }
  .mt-sm-39,
  .my-sm-39 {
    margin-top: 195px !important;
  }
  .mr-sm-39,
  .mx-sm-39 {
    margin-right: 195px !important;
  }
  .mb-sm-39,
  .my-sm-39 {
    margin-bottom: 195px !important;
  }
  .ml-sm-39,
  .mx-sm-39 {
    margin-left: 195px !important;
  }
  .m-sm-40 {
    margin: 200px !important;
  }
  .mt-sm-40,
  .my-sm-40 {
    margin-top: 200px !important;
  }
  .mr-sm-40,
  .mx-sm-40 {
    margin-right: 200px !important;
  }
  .mb-sm-40,
  .my-sm-40 {
    margin-bottom: 200px !important;
  }
  .ml-sm-40,
  .mx-sm-40 {
    margin-left: 200px !important;
  }
  .m-sm-41 {
    margin: 205px !important;
  }
  .mt-sm-41,
  .my-sm-41 {
    margin-top: 205px !important;
  }
  .mr-sm-41,
  .mx-sm-41 {
    margin-right: 205px !important;
  }
  .mb-sm-41,
  .my-sm-41 {
    margin-bottom: 205px !important;
  }
  .ml-sm-41,
  .mx-sm-41 {
    margin-left: 205px !important;
  }
  .m-sm-42 {
    margin: 210px !important;
  }
  .mt-sm-42,
  .my-sm-42 {
    margin-top: 210px !important;
  }
  .mr-sm-42,
  .mx-sm-42 {
    margin-right: 210px !important;
  }
  .mb-sm-42,
  .my-sm-42 {
    margin-bottom: 210px !important;
  }
  .ml-sm-42,
  .mx-sm-42 {
    margin-left: 210px !important;
  }
  .m-sm-43 {
    margin: 215px !important;
  }
  .mt-sm-43,
  .my-sm-43 {
    margin-top: 215px !important;
  }
  .mr-sm-43,
  .mx-sm-43 {
    margin-right: 215px !important;
  }
  .mb-sm-43,
  .my-sm-43 {
    margin-bottom: 215px !important;
  }
  .ml-sm-43,
  .mx-sm-43 {
    margin-left: 215px !important;
  }
  .m-sm-44 {
    margin: 220px !important;
  }
  .mt-sm-44,
  .my-sm-44 {
    margin-top: 220px !important;
  }
  .mr-sm-44,
  .mx-sm-44 {
    margin-right: 220px !important;
  }
  .mb-sm-44,
  .my-sm-44 {
    margin-bottom: 220px !important;
  }
  .ml-sm-44,
  .mx-sm-44 {
    margin-left: 220px !important;
  }
  .m-sm-45 {
    margin: 225px !important;
  }
  .mt-sm-45,
  .my-sm-45 {
    margin-top: 225px !important;
  }
  .mr-sm-45,
  .mx-sm-45 {
    margin-right: 225px !important;
  }
  .mb-sm-45,
  .my-sm-45 {
    margin-bottom: 225px !important;
  }
  .ml-sm-45,
  .mx-sm-45 {
    margin-left: 225px !important;
  }
  .m-sm-46 {
    margin: 230px !important;
  }
  .mt-sm-46,
  .my-sm-46 {
    margin-top: 230px !important;
  }
  .mr-sm-46,
  .mx-sm-46 {
    margin-right: 230px !important;
  }
  .mb-sm-46,
  .my-sm-46 {
    margin-bottom: 230px !important;
  }
  .ml-sm-46,
  .mx-sm-46 {
    margin-left: 230px !important;
  }
  .m-sm-47 {
    margin: 235px !important;
  }
  .mt-sm-47,
  .my-sm-47 {
    margin-top: 235px !important;
  }
  .mr-sm-47,
  .mx-sm-47 {
    margin-right: 235px !important;
  }
  .mb-sm-47,
  .my-sm-47 {
    margin-bottom: 235px !important;
  }
  .ml-sm-47,
  .mx-sm-47 {
    margin-left: 235px !important;
  }
  .m-sm-48 {
    margin: 240px !important;
  }
  .mt-sm-48,
  .my-sm-48 {
    margin-top: 240px !important;
  }
  .mr-sm-48,
  .mx-sm-48 {
    margin-right: 240px !important;
  }
  .mb-sm-48,
  .my-sm-48 {
    margin-bottom: 240px !important;
  }
  .ml-sm-48,
  .mx-sm-48 {
    margin-left: 240px !important;
  }
  .m-sm-49 {
    margin: 245px !important;
  }
  .mt-sm-49,
  .my-sm-49 {
    margin-top: 245px !important;
  }
  .mr-sm-49,
  .mx-sm-49 {
    margin-right: 245px !important;
  }
  .mb-sm-49,
  .my-sm-49 {
    margin-bottom: 245px !important;
  }
  .ml-sm-49,
  .mx-sm-49 {
    margin-left: 245px !important;
  }
  .m-sm-50 {
    margin: 250px !important;
  }
  .mt-sm-50,
  .my-sm-50 {
    margin-top: 250px !important;
  }
  .mr-sm-50,
  .mx-sm-50 {
    margin-right: 250px !important;
  }
  .mb-sm-50,
  .my-sm-50 {
    margin-bottom: 250px !important;
  }
  .ml-sm-50,
  .mx-sm-50 {
    margin-left: 250px !important;
  }
  .m-sm-51 {
    margin: 255px !important;
  }
  .mt-sm-51,
  .my-sm-51 {
    margin-top: 255px !important;
  }
  .mr-sm-51,
  .mx-sm-51 {
    margin-right: 255px !important;
  }
  .mb-sm-51,
  .my-sm-51 {
    margin-bottom: 255px !important;
  }
  .ml-sm-51,
  .mx-sm-51 {
    margin-left: 255px !important;
  }
  .m-sm-52 {
    margin: 260px !important;
  }
  .mt-sm-52,
  .my-sm-52 {
    margin-top: 260px !important;
  }
  .mr-sm-52,
  .mx-sm-52 {
    margin-right: 260px !important;
  }
  .mb-sm-52,
  .my-sm-52 {
    margin-bottom: 260px !important;
  }
  .ml-sm-52,
  .mx-sm-52 {
    margin-left: 260px !important;
  }
  .m-sm-53 {
    margin: 265px !important;
  }
  .mt-sm-53,
  .my-sm-53 {
    margin-top: 265px !important;
  }
  .mr-sm-53,
  .mx-sm-53 {
    margin-right: 265px !important;
  }
  .mb-sm-53,
  .my-sm-53 {
    margin-bottom: 265px !important;
  }
  .ml-sm-53,
  .mx-sm-53 {
    margin-left: 265px !important;
  }
  .m-sm-54 {
    margin: 270px !important;
  }
  .mt-sm-54,
  .my-sm-54 {
    margin-top: 270px !important;
  }
  .mr-sm-54,
  .mx-sm-54 {
    margin-right: 270px !important;
  }
  .mb-sm-54,
  .my-sm-54 {
    margin-bottom: 270px !important;
  }
  .ml-sm-54,
  .mx-sm-54 {
    margin-left: 270px !important;
  }
  .m-sm-55 {
    margin: 275px !important;
  }
  .mt-sm-55,
  .my-sm-55 {
    margin-top: 275px !important;
  }
  .mr-sm-55,
  .mx-sm-55 {
    margin-right: 275px !important;
  }
  .mb-sm-55,
  .my-sm-55 {
    margin-bottom: 275px !important;
  }
  .ml-sm-55,
  .mx-sm-55 {
    margin-left: 275px !important;
  }
  .m-sm-56 {
    margin: 280px !important;
  }
  .mt-sm-56,
  .my-sm-56 {
    margin-top: 280px !important;
  }
  .mr-sm-56,
  .mx-sm-56 {
    margin-right: 280px !important;
  }
  .mb-sm-56,
  .my-sm-56 {
    margin-bottom: 280px !important;
  }
  .ml-sm-56,
  .mx-sm-56 {
    margin-left: 280px !important;
  }
  .m-sm-57 {
    margin: 285px !important;
  }
  .mt-sm-57,
  .my-sm-57 {
    margin-top: 285px !important;
  }
  .mr-sm-57,
  .mx-sm-57 {
    margin-right: 285px !important;
  }
  .mb-sm-57,
  .my-sm-57 {
    margin-bottom: 285px !important;
  }
  .ml-sm-57,
  .mx-sm-57 {
    margin-left: 285px !important;
  }
  .m-sm-58 {
    margin: 290px !important;
  }
  .mt-sm-58,
  .my-sm-58 {
    margin-top: 290px !important;
  }
  .mr-sm-58,
  .mx-sm-58 {
    margin-right: 290px !important;
  }
  .mb-sm-58,
  .my-sm-58 {
    margin-bottom: 290px !important;
  }
  .ml-sm-58,
  .mx-sm-58 {
    margin-left: 290px !important;
  }
  .m-sm-59 {
    margin: 295px !important;
  }
  .mt-sm-59,
  .my-sm-59 {
    margin-top: 295px !important;
  }
  .mr-sm-59,
  .mx-sm-59 {
    margin-right: 295px !important;
  }
  .mb-sm-59,
  .my-sm-59 {
    margin-bottom: 295px !important;
  }
  .ml-sm-59,
  .mx-sm-59 {
    margin-left: 295px !important;
  }
  .m-sm-60 {
    margin: 300px !important;
  }
  .mt-sm-60,
  .my-sm-60 {
    margin-top: 300px !important;
  }
  .mr-sm-60,
  .mx-sm-60 {
    margin-right: 300px !important;
  }
  .mb-sm-60,
  .my-sm-60 {
    margin-bottom: 300px !important;
  }
  .ml-sm-60,
  .mx-sm-60 {
    margin-left: 300px !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 5px !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 5px !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 5px !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 5px !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 5px !important;
  }
  .p-sm-2 {
    padding: 10px !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 10px !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 10px !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 10px !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 10px !important;
  }
  .p-sm-3 {
    padding: 16px !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 16px !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 16px !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 16px !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 16px !important;
  }
  .p-sm-4 {
    padding: 20px !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 20px !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 20px !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 20px !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 20px !important;
  }
  .p-sm-5 {
    padding: 25px !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 25px !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 25px !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 25px !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 25px !important;
  }
  .p-sm-6 {
    padding: 30px !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 30px !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 30px !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 30px !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 30px !important;
  }
  .p-sm-7 {
    padding: 35px !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 35px !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 35px !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 35px !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 35px !important;
  }
  .p-sm-8 {
    padding: 40px !important;
  }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 40px !important;
  }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 40px !important;
  }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 40px !important;
  }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 40px !important;
  }
  .p-sm-9 {
    padding: 45px !important;
  }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 45px !important;
  }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 45px !important;
  }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 45px !important;
  }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 45px !important;
  }
  .p-sm-10 {
    padding: 50px !important;
  }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 50px !important;
  }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 50px !important;
  }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 50px !important;
  }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 50px !important;
  }
  .p-sm-11 {
    padding: 55px !important;
  }
  .pt-sm-11,
  .py-sm-11 {
    padding-top: 55px !important;
  }
  .pr-sm-11,
  .px-sm-11 {
    padding-right: 55px !important;
  }
  .pb-sm-11,
  .py-sm-11 {
    padding-bottom: 55px !important;
  }
  .pl-sm-11,
  .px-sm-11 {
    padding-left: 55px !important;
  }
  .p-sm-12 {
    padding: 60px !important;
  }
  .pt-sm-12,
  .py-sm-12 {
    padding-top: 60px !important;
  }
  .pr-sm-12,
  .px-sm-12 {
    padding-right: 60px !important;
  }
  .pb-sm-12,
  .py-sm-12 {
    padding-bottom: 60px !important;
  }
  .pl-sm-12,
  .px-sm-12 {
    padding-left: 60px !important;
  }
  .p-sm-13 {
    padding: 65px !important;
  }
  .pt-sm-13,
  .py-sm-13 {
    padding-top: 65px !important;
  }
  .pr-sm-13,
  .px-sm-13 {
    padding-right: 65px !important;
  }
  .pb-sm-13,
  .py-sm-13 {
    padding-bottom: 65px !important;
  }
  .pl-sm-13,
  .px-sm-13 {
    padding-left: 65px !important;
  }
  .p-sm-14 {
    padding: 70px !important;
  }
  .pt-sm-14,
  .py-sm-14 {
    padding-top: 70px !important;
  }
  .pr-sm-14,
  .px-sm-14 {
    padding-right: 70px !important;
  }
  .pb-sm-14,
  .py-sm-14 {
    padding-bottom: 70px !important;
  }
  .pl-sm-14,
  .px-sm-14 {
    padding-left: 70px !important;
  }
  .p-sm-15 {
    padding: 75px !important;
  }
  .pt-sm-15,
  .py-sm-15 {
    padding-top: 75px !important;
  }
  .pr-sm-15,
  .px-sm-15 {
    padding-right: 75px !important;
  }
  .pb-sm-15,
  .py-sm-15 {
    padding-bottom: 75px !important;
  }
  .pl-sm-15,
  .px-sm-15 {
    padding-left: 75px !important;
  }
  .p-sm-16 {
    padding: 80px !important;
  }
  .pt-sm-16,
  .py-sm-16 {
    padding-top: 80px !important;
  }
  .pr-sm-16,
  .px-sm-16 {
    padding-right: 80px !important;
  }
  .pb-sm-16,
  .py-sm-16 {
    padding-bottom: 80px !important;
  }
  .pl-sm-16,
  .px-sm-16 {
    padding-left: 80px !important;
  }
  .p-sm-17 {
    padding: 85px !important;
  }
  .pt-sm-17,
  .py-sm-17 {
    padding-top: 85px !important;
  }
  .pr-sm-17,
  .px-sm-17 {
    padding-right: 85px !important;
  }
  .pb-sm-17,
  .py-sm-17 {
    padding-bottom: 85px !important;
  }
  .pl-sm-17,
  .px-sm-17 {
    padding-left: 85px !important;
  }
  .p-sm-18 {
    padding: 90px !important;
  }
  .pt-sm-18,
  .py-sm-18 {
    padding-top: 90px !important;
  }
  .pr-sm-18,
  .px-sm-18 {
    padding-right: 90px !important;
  }
  .pb-sm-18,
  .py-sm-18 {
    padding-bottom: 90px !important;
  }
  .pl-sm-18,
  .px-sm-18 {
    padding-left: 90px !important;
  }
  .p-sm-19 {
    padding: 95px !important;
  }
  .pt-sm-19,
  .py-sm-19 {
    padding-top: 95px !important;
  }
  .pr-sm-19,
  .px-sm-19 {
    padding-right: 95px !important;
  }
  .pb-sm-19,
  .py-sm-19 {
    padding-bottom: 95px !important;
  }
  .pl-sm-19,
  .px-sm-19 {
    padding-left: 95px !important;
  }
  .p-sm-20 {
    padding: 100px !important;
  }
  .pt-sm-20,
  .py-sm-20 {
    padding-top: 100px !important;
  }
  .pr-sm-20,
  .px-sm-20 {
    padding-right: 100px !important;
  }
  .pb-sm-20,
  .py-sm-20 {
    padding-bottom: 100px !important;
  }
  .pl-sm-20,
  .px-sm-20 {
    padding-left: 100px !important;
  }
  .p-sm-21 {
    padding: 105px !important;
  }
  .pt-sm-21,
  .py-sm-21 {
    padding-top: 105px !important;
  }
  .pr-sm-21,
  .px-sm-21 {
    padding-right: 105px !important;
  }
  .pb-sm-21,
  .py-sm-21 {
    padding-bottom: 105px !important;
  }
  .pl-sm-21,
  .px-sm-21 {
    padding-left: 105px !important;
  }
  .p-sm-22 {
    padding: 110px !important;
  }
  .pt-sm-22,
  .py-sm-22 {
    padding-top: 110px !important;
  }
  .pr-sm-22,
  .px-sm-22 {
    padding-right: 110px !important;
  }
  .pb-sm-22,
  .py-sm-22 {
    padding-bottom: 110px !important;
  }
  .pl-sm-22,
  .px-sm-22 {
    padding-left: 110px !important;
  }
  .p-sm-23 {
    padding: 115px !important;
  }
  .pt-sm-23,
  .py-sm-23 {
    padding-top: 115px !important;
  }
  .pr-sm-23,
  .px-sm-23 {
    padding-right: 115px !important;
  }
  .pb-sm-23,
  .py-sm-23 {
    padding-bottom: 115px !important;
  }
  .pl-sm-23,
  .px-sm-23 {
    padding-left: 115px !important;
  }
  .p-sm-24 {
    padding: 120px !important;
  }
  .pt-sm-24,
  .py-sm-24 {
    padding-top: 120px !important;
  }
  .pr-sm-24,
  .px-sm-24 {
    padding-right: 120px !important;
  }
  .pb-sm-24,
  .py-sm-24 {
    padding-bottom: 120px !important;
  }
  .pl-sm-24,
  .px-sm-24 {
    padding-left: 120px !important;
  }
  .p-sm-25 {
    padding: 125px !important;
  }
  .pt-sm-25,
  .py-sm-25 {
    padding-top: 125px !important;
  }
  .pr-sm-25,
  .px-sm-25 {
    padding-right: 125px !important;
  }
  .pb-sm-25,
  .py-sm-25 {
    padding-bottom: 125px !important;
  }
  .pl-sm-25,
  .px-sm-25 {
    padding-left: 125px !important;
  }
  .p-sm-26 {
    padding: 130px !important;
  }
  .pt-sm-26,
  .py-sm-26 {
    padding-top: 130px !important;
  }
  .pr-sm-26,
  .px-sm-26 {
    padding-right: 130px !important;
  }
  .pb-sm-26,
  .py-sm-26 {
    padding-bottom: 130px !important;
  }
  .pl-sm-26,
  .px-sm-26 {
    padding-left: 130px !important;
  }
  .p-sm-27 {
    padding: 135px !important;
  }
  .pt-sm-27,
  .py-sm-27 {
    padding-top: 135px !important;
  }
  .pr-sm-27,
  .px-sm-27 {
    padding-right: 135px !important;
  }
  .pb-sm-27,
  .py-sm-27 {
    padding-bottom: 135px !important;
  }
  .pl-sm-27,
  .px-sm-27 {
    padding-left: 135px !important;
  }
  .p-sm-28 {
    padding: 140px !important;
  }
  .pt-sm-28,
  .py-sm-28 {
    padding-top: 140px !important;
  }
  .pr-sm-28,
  .px-sm-28 {
    padding-right: 140px !important;
  }
  .pb-sm-28,
  .py-sm-28 {
    padding-bottom: 140px !important;
  }
  .pl-sm-28,
  .px-sm-28 {
    padding-left: 140px !important;
  }
  .p-sm-29 {
    padding: 145px !important;
  }
  .pt-sm-29,
  .py-sm-29 {
    padding-top: 145px !important;
  }
  .pr-sm-29,
  .px-sm-29 {
    padding-right: 145px !important;
  }
  .pb-sm-29,
  .py-sm-29 {
    padding-bottom: 145px !important;
  }
  .pl-sm-29,
  .px-sm-29 {
    padding-left: 145px !important;
  }
  .p-sm-30 {
    padding: 150px !important;
  }
  .pt-sm-30,
  .py-sm-30 {
    padding-top: 150px !important;
  }
  .pr-sm-30,
  .px-sm-30 {
    padding-right: 150px !important;
  }
  .pb-sm-30,
  .py-sm-30 {
    padding-bottom: 150px !important;
  }
  .pl-sm-30,
  .px-sm-30 {
    padding-left: 150px !important;
  }
  .p-sm-31 {
    padding: 155px !important;
  }
  .pt-sm-31,
  .py-sm-31 {
    padding-top: 155px !important;
  }
  .pr-sm-31,
  .px-sm-31 {
    padding-right: 155px !important;
  }
  .pb-sm-31,
  .py-sm-31 {
    padding-bottom: 155px !important;
  }
  .pl-sm-31,
  .px-sm-31 {
    padding-left: 155px !important;
  }
  .p-sm-32 {
    padding: 160px !important;
  }
  .pt-sm-32,
  .py-sm-32 {
    padding-top: 160px !important;
  }
  .pr-sm-32,
  .px-sm-32 {
    padding-right: 160px !important;
  }
  .pb-sm-32,
  .py-sm-32 {
    padding-bottom: 160px !important;
  }
  .pl-sm-32,
  .px-sm-32 {
    padding-left: 160px !important;
  }
  .p-sm-33 {
    padding: 165px !important;
  }
  .pt-sm-33,
  .py-sm-33 {
    padding-top: 165px !important;
  }
  .pr-sm-33,
  .px-sm-33 {
    padding-right: 165px !important;
  }
  .pb-sm-33,
  .py-sm-33 {
    padding-bottom: 165px !important;
  }
  .pl-sm-33,
  .px-sm-33 {
    padding-left: 165px !important;
  }
  .p-sm-34 {
    padding: 170px !important;
  }
  .pt-sm-34,
  .py-sm-34 {
    padding-top: 170px !important;
  }
  .pr-sm-34,
  .px-sm-34 {
    padding-right: 170px !important;
  }
  .pb-sm-34,
  .py-sm-34 {
    padding-bottom: 170px !important;
  }
  .pl-sm-34,
  .px-sm-34 {
    padding-left: 170px !important;
  }
  .p-sm-35 {
    padding: 175px !important;
  }
  .pt-sm-35,
  .py-sm-35 {
    padding-top: 175px !important;
  }
  .pr-sm-35,
  .px-sm-35 {
    padding-right: 175px !important;
  }
  .pb-sm-35,
  .py-sm-35 {
    padding-bottom: 175px !important;
  }
  .pl-sm-35,
  .px-sm-35 {
    padding-left: 175px !important;
  }
  .p-sm-36 {
    padding: 180px !important;
  }
  .pt-sm-36,
  .py-sm-36 {
    padding-top: 180px !important;
  }
  .pr-sm-36,
  .px-sm-36 {
    padding-right: 180px !important;
  }
  .pb-sm-36,
  .py-sm-36 {
    padding-bottom: 180px !important;
  }
  .pl-sm-36,
  .px-sm-36 {
    padding-left: 180px !important;
  }
  .p-sm-37 {
    padding: 185px !important;
  }
  .pt-sm-37,
  .py-sm-37 {
    padding-top: 185px !important;
  }
  .pr-sm-37,
  .px-sm-37 {
    padding-right: 185px !important;
  }
  .pb-sm-37,
  .py-sm-37 {
    padding-bottom: 185px !important;
  }
  .pl-sm-37,
  .px-sm-37 {
    padding-left: 185px !important;
  }
  .p-sm-38 {
    padding: 190px !important;
  }
  .pt-sm-38,
  .py-sm-38 {
    padding-top: 190px !important;
  }
  .pr-sm-38,
  .px-sm-38 {
    padding-right: 190px !important;
  }
  .pb-sm-38,
  .py-sm-38 {
    padding-bottom: 190px !important;
  }
  .pl-sm-38,
  .px-sm-38 {
    padding-left: 190px !important;
  }
  .p-sm-39 {
    padding: 195px !important;
  }
  .pt-sm-39,
  .py-sm-39 {
    padding-top: 195px !important;
  }
  .pr-sm-39,
  .px-sm-39 {
    padding-right: 195px !important;
  }
  .pb-sm-39,
  .py-sm-39 {
    padding-bottom: 195px !important;
  }
  .pl-sm-39,
  .px-sm-39 {
    padding-left: 195px !important;
  }
  .p-sm-40 {
    padding: 200px !important;
  }
  .pt-sm-40,
  .py-sm-40 {
    padding-top: 200px !important;
  }
  .pr-sm-40,
  .px-sm-40 {
    padding-right: 200px !important;
  }
  .pb-sm-40,
  .py-sm-40 {
    padding-bottom: 200px !important;
  }
  .pl-sm-40,
  .px-sm-40 {
    padding-left: 200px !important;
  }
  .p-sm-41 {
    padding: 205px !important;
  }
  .pt-sm-41,
  .py-sm-41 {
    padding-top: 205px !important;
  }
  .pr-sm-41,
  .px-sm-41 {
    padding-right: 205px !important;
  }
  .pb-sm-41,
  .py-sm-41 {
    padding-bottom: 205px !important;
  }
  .pl-sm-41,
  .px-sm-41 {
    padding-left: 205px !important;
  }
  .p-sm-42 {
    padding: 210px !important;
  }
  .pt-sm-42,
  .py-sm-42 {
    padding-top: 210px !important;
  }
  .pr-sm-42,
  .px-sm-42 {
    padding-right: 210px !important;
  }
  .pb-sm-42,
  .py-sm-42 {
    padding-bottom: 210px !important;
  }
  .pl-sm-42,
  .px-sm-42 {
    padding-left: 210px !important;
  }
  .p-sm-43 {
    padding: 215px !important;
  }
  .pt-sm-43,
  .py-sm-43 {
    padding-top: 215px !important;
  }
  .pr-sm-43,
  .px-sm-43 {
    padding-right: 215px !important;
  }
  .pb-sm-43,
  .py-sm-43 {
    padding-bottom: 215px !important;
  }
  .pl-sm-43,
  .px-sm-43 {
    padding-left: 215px !important;
  }
  .p-sm-44 {
    padding: 220px !important;
  }
  .pt-sm-44,
  .py-sm-44 {
    padding-top: 220px !important;
  }
  .pr-sm-44,
  .px-sm-44 {
    padding-right: 220px !important;
  }
  .pb-sm-44,
  .py-sm-44 {
    padding-bottom: 220px !important;
  }
  .pl-sm-44,
  .px-sm-44 {
    padding-left: 220px !important;
  }
  .p-sm-45 {
    padding: 225px !important;
  }
  .pt-sm-45,
  .py-sm-45 {
    padding-top: 225px !important;
  }
  .pr-sm-45,
  .px-sm-45 {
    padding-right: 225px !important;
  }
  .pb-sm-45,
  .py-sm-45 {
    padding-bottom: 225px !important;
  }
  .pl-sm-45,
  .px-sm-45 {
    padding-left: 225px !important;
  }
  .p-sm-46 {
    padding: 230px !important;
  }
  .pt-sm-46,
  .py-sm-46 {
    padding-top: 230px !important;
  }
  .pr-sm-46,
  .px-sm-46 {
    padding-right: 230px !important;
  }
  .pb-sm-46,
  .py-sm-46 {
    padding-bottom: 230px !important;
  }
  .pl-sm-46,
  .px-sm-46 {
    padding-left: 230px !important;
  }
  .p-sm-47 {
    padding: 235px !important;
  }
  .pt-sm-47,
  .py-sm-47 {
    padding-top: 235px !important;
  }
  .pr-sm-47,
  .px-sm-47 {
    padding-right: 235px !important;
  }
  .pb-sm-47,
  .py-sm-47 {
    padding-bottom: 235px !important;
  }
  .pl-sm-47,
  .px-sm-47 {
    padding-left: 235px !important;
  }
  .p-sm-48 {
    padding: 240px !important;
  }
  .pt-sm-48,
  .py-sm-48 {
    padding-top: 240px !important;
  }
  .pr-sm-48,
  .px-sm-48 {
    padding-right: 240px !important;
  }
  .pb-sm-48,
  .py-sm-48 {
    padding-bottom: 240px !important;
  }
  .pl-sm-48,
  .px-sm-48 {
    padding-left: 240px !important;
  }
  .p-sm-49 {
    padding: 245px !important;
  }
  .pt-sm-49,
  .py-sm-49 {
    padding-top: 245px !important;
  }
  .pr-sm-49,
  .px-sm-49 {
    padding-right: 245px !important;
  }
  .pb-sm-49,
  .py-sm-49 {
    padding-bottom: 245px !important;
  }
  .pl-sm-49,
  .px-sm-49 {
    padding-left: 245px !important;
  }
  .p-sm-50 {
    padding: 250px !important;
  }
  .pt-sm-50,
  .py-sm-50 {
    padding-top: 250px !important;
  }
  .pr-sm-50,
  .px-sm-50 {
    padding-right: 250px !important;
  }
  .pb-sm-50,
  .py-sm-50 {
    padding-bottom: 250px !important;
  }
  .pl-sm-50,
  .px-sm-50 {
    padding-left: 250px !important;
  }
  .p-sm-51 {
    padding: 255px !important;
  }
  .pt-sm-51,
  .py-sm-51 {
    padding-top: 255px !important;
  }
  .pr-sm-51,
  .px-sm-51 {
    padding-right: 255px !important;
  }
  .pb-sm-51,
  .py-sm-51 {
    padding-bottom: 255px !important;
  }
  .pl-sm-51,
  .px-sm-51 {
    padding-left: 255px !important;
  }
  .p-sm-52 {
    padding: 260px !important;
  }
  .pt-sm-52,
  .py-sm-52 {
    padding-top: 260px !important;
  }
  .pr-sm-52,
  .px-sm-52 {
    padding-right: 260px !important;
  }
  .pb-sm-52,
  .py-sm-52 {
    padding-bottom: 260px !important;
  }
  .pl-sm-52,
  .px-sm-52 {
    padding-left: 260px !important;
  }
  .p-sm-53 {
    padding: 265px !important;
  }
  .pt-sm-53,
  .py-sm-53 {
    padding-top: 265px !important;
  }
  .pr-sm-53,
  .px-sm-53 {
    padding-right: 265px !important;
  }
  .pb-sm-53,
  .py-sm-53 {
    padding-bottom: 265px !important;
  }
  .pl-sm-53,
  .px-sm-53 {
    padding-left: 265px !important;
  }
  .p-sm-54 {
    padding: 270px !important;
  }
  .pt-sm-54,
  .py-sm-54 {
    padding-top: 270px !important;
  }
  .pr-sm-54,
  .px-sm-54 {
    padding-right: 270px !important;
  }
  .pb-sm-54,
  .py-sm-54 {
    padding-bottom: 270px !important;
  }
  .pl-sm-54,
  .px-sm-54 {
    padding-left: 270px !important;
  }
  .p-sm-55 {
    padding: 275px !important;
  }
  .pt-sm-55,
  .py-sm-55 {
    padding-top: 275px !important;
  }
  .pr-sm-55,
  .px-sm-55 {
    padding-right: 275px !important;
  }
  .pb-sm-55,
  .py-sm-55 {
    padding-bottom: 275px !important;
  }
  .pl-sm-55,
  .px-sm-55 {
    padding-left: 275px !important;
  }
  .p-sm-56 {
    padding: 280px !important;
  }
  .pt-sm-56,
  .py-sm-56 {
    padding-top: 280px !important;
  }
  .pr-sm-56,
  .px-sm-56 {
    padding-right: 280px !important;
  }
  .pb-sm-56,
  .py-sm-56 {
    padding-bottom: 280px !important;
  }
  .pl-sm-56,
  .px-sm-56 {
    padding-left: 280px !important;
  }
  .p-sm-57 {
    padding: 285px !important;
  }
  .pt-sm-57,
  .py-sm-57 {
    padding-top: 285px !important;
  }
  .pr-sm-57,
  .px-sm-57 {
    padding-right: 285px !important;
  }
  .pb-sm-57,
  .py-sm-57 {
    padding-bottom: 285px !important;
  }
  .pl-sm-57,
  .px-sm-57 {
    padding-left: 285px !important;
  }
  .p-sm-58 {
    padding: 290px !important;
  }
  .pt-sm-58,
  .py-sm-58 {
    padding-top: 290px !important;
  }
  .pr-sm-58,
  .px-sm-58 {
    padding-right: 290px !important;
  }
  .pb-sm-58,
  .py-sm-58 {
    padding-bottom: 290px !important;
  }
  .pl-sm-58,
  .px-sm-58 {
    padding-left: 290px !important;
  }
  .p-sm-59 {
    padding: 295px !important;
  }
  .pt-sm-59,
  .py-sm-59 {
    padding-top: 295px !important;
  }
  .pr-sm-59,
  .px-sm-59 {
    padding-right: 295px !important;
  }
  .pb-sm-59,
  .py-sm-59 {
    padding-bottom: 295px !important;
  }
  .pl-sm-59,
  .px-sm-59 {
    padding-left: 295px !important;
  }
  .p-sm-60 {
    padding: 300px !important;
  }
  .pt-sm-60,
  .py-sm-60 {
    padding-top: 300px !important;
  }
  .pr-sm-60,
  .px-sm-60 {
    padding-right: 300px !important;
  }
  .pb-sm-60,
  .py-sm-60 {
    padding-bottom: 300px !important;
  }
  .pl-sm-60,
  .px-sm-60 {
    padding-left: 300px !important;
  }
  .m-sm-n1 {
    margin: -5px !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -5px !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -5px !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -5px !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -5px !important;
  }
  .m-sm-n2 {
    margin: -10px !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -10px !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -10px !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -10px !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -10px !important;
  }
  .m-sm-n3 {
    margin: -16px !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -16px !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -16px !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -16px !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -16px !important;
  }
  .m-sm-n4 {
    margin: -20px !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -20px !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -20px !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -20px !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -20px !important;
  }
  .m-sm-n5 {
    margin: -25px !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -25px !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -25px !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -25px !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -25px !important;
  }
  .m-sm-n6 {
    margin: -30px !important;
  }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -30px !important;
  }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -30px !important;
  }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -30px !important;
  }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -30px !important;
  }
  .m-sm-n7 {
    margin: -35px !important;
  }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -35px !important;
  }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -35px !important;
  }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -35px !important;
  }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -35px !important;
  }
  .m-sm-n8 {
    margin: -40px !important;
  }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -40px !important;
  }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -40px !important;
  }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -40px !important;
  }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -40px !important;
  }
  .m-sm-n9 {
    margin: -45px !important;
  }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -45px !important;
  }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -45px !important;
  }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -45px !important;
  }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -45px !important;
  }
  .m-sm-n10 {
    margin: -50px !important;
  }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -50px !important;
  }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -50px !important;
  }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -50px !important;
  }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -50px !important;
  }
  .m-sm-n11 {
    margin: -55px !important;
  }
  .mt-sm-n11,
  .my-sm-n11 {
    margin-top: -55px !important;
  }
  .mr-sm-n11,
  .mx-sm-n11 {
    margin-right: -55px !important;
  }
  .mb-sm-n11,
  .my-sm-n11 {
    margin-bottom: -55px !important;
  }
  .ml-sm-n11,
  .mx-sm-n11 {
    margin-left: -55px !important;
  }
  .m-sm-n12 {
    margin: -60px !important;
  }
  .mt-sm-n12,
  .my-sm-n12 {
    margin-top: -60px !important;
  }
  .mr-sm-n12,
  .mx-sm-n12 {
    margin-right: -60px !important;
  }
  .mb-sm-n12,
  .my-sm-n12 {
    margin-bottom: -60px !important;
  }
  .ml-sm-n12,
  .mx-sm-n12 {
    margin-left: -60px !important;
  }
  .m-sm-n13 {
    margin: -65px !important;
  }
  .mt-sm-n13,
  .my-sm-n13 {
    margin-top: -65px !important;
  }
  .mr-sm-n13,
  .mx-sm-n13 {
    margin-right: -65px !important;
  }
  .mb-sm-n13,
  .my-sm-n13 {
    margin-bottom: -65px !important;
  }
  .ml-sm-n13,
  .mx-sm-n13 {
    margin-left: -65px !important;
  }
  .m-sm-n14 {
    margin: -70px !important;
  }
  .mt-sm-n14,
  .my-sm-n14 {
    margin-top: -70px !important;
  }
  .mr-sm-n14,
  .mx-sm-n14 {
    margin-right: -70px !important;
  }
  .mb-sm-n14,
  .my-sm-n14 {
    margin-bottom: -70px !important;
  }
  .ml-sm-n14,
  .mx-sm-n14 {
    margin-left: -70px !important;
  }
  .m-sm-n15 {
    margin: -75px !important;
  }
  .mt-sm-n15,
  .my-sm-n15 {
    margin-top: -75px !important;
  }
  .mr-sm-n15,
  .mx-sm-n15 {
    margin-right: -75px !important;
  }
  .mb-sm-n15,
  .my-sm-n15 {
    margin-bottom: -75px !important;
  }
  .ml-sm-n15,
  .mx-sm-n15 {
    margin-left: -75px !important;
  }
  .m-sm-n16 {
    margin: -80px !important;
  }
  .mt-sm-n16,
  .my-sm-n16 {
    margin-top: -80px !important;
  }
  .mr-sm-n16,
  .mx-sm-n16 {
    margin-right: -80px !important;
  }
  .mb-sm-n16,
  .my-sm-n16 {
    margin-bottom: -80px !important;
  }
  .ml-sm-n16,
  .mx-sm-n16 {
    margin-left: -80px !important;
  }
  .m-sm-n17 {
    margin: -85px !important;
  }
  .mt-sm-n17,
  .my-sm-n17 {
    margin-top: -85px !important;
  }
  .mr-sm-n17,
  .mx-sm-n17 {
    margin-right: -85px !important;
  }
  .mb-sm-n17,
  .my-sm-n17 {
    margin-bottom: -85px !important;
  }
  .ml-sm-n17,
  .mx-sm-n17 {
    margin-left: -85px !important;
  }
  .m-sm-n18 {
    margin: -90px !important;
  }
  .mt-sm-n18,
  .my-sm-n18 {
    margin-top: -90px !important;
  }
  .mr-sm-n18,
  .mx-sm-n18 {
    margin-right: -90px !important;
  }
  .mb-sm-n18,
  .my-sm-n18 {
    margin-bottom: -90px !important;
  }
  .ml-sm-n18,
  .mx-sm-n18 {
    margin-left: -90px !important;
  }
  .m-sm-n19 {
    margin: -95px !important;
  }
  .mt-sm-n19,
  .my-sm-n19 {
    margin-top: -95px !important;
  }
  .mr-sm-n19,
  .mx-sm-n19 {
    margin-right: -95px !important;
  }
  .mb-sm-n19,
  .my-sm-n19 {
    margin-bottom: -95px !important;
  }
  .ml-sm-n19,
  .mx-sm-n19 {
    margin-left: -95px !important;
  }
  .m-sm-n20 {
    margin: -100px !important;
  }
  .mt-sm-n20,
  .my-sm-n20 {
    margin-top: -100px !important;
  }
  .mr-sm-n20,
  .mx-sm-n20 {
    margin-right: -100px !important;
  }
  .mb-sm-n20,
  .my-sm-n20 {
    margin-bottom: -100px !important;
  }
  .ml-sm-n20,
  .mx-sm-n20 {
    margin-left: -100px !important;
  }
  .m-sm-n21 {
    margin: -105px !important;
  }
  .mt-sm-n21,
  .my-sm-n21 {
    margin-top: -105px !important;
  }
  .mr-sm-n21,
  .mx-sm-n21 {
    margin-right: -105px !important;
  }
  .mb-sm-n21,
  .my-sm-n21 {
    margin-bottom: -105px !important;
  }
  .ml-sm-n21,
  .mx-sm-n21 {
    margin-left: -105px !important;
  }
  .m-sm-n22 {
    margin: -110px !important;
  }
  .mt-sm-n22,
  .my-sm-n22 {
    margin-top: -110px !important;
  }
  .mr-sm-n22,
  .mx-sm-n22 {
    margin-right: -110px !important;
  }
  .mb-sm-n22,
  .my-sm-n22 {
    margin-bottom: -110px !important;
  }
  .ml-sm-n22,
  .mx-sm-n22 {
    margin-left: -110px !important;
  }
  .m-sm-n23 {
    margin: -115px !important;
  }
  .mt-sm-n23,
  .my-sm-n23 {
    margin-top: -115px !important;
  }
  .mr-sm-n23,
  .mx-sm-n23 {
    margin-right: -115px !important;
  }
  .mb-sm-n23,
  .my-sm-n23 {
    margin-bottom: -115px !important;
  }
  .ml-sm-n23,
  .mx-sm-n23 {
    margin-left: -115px !important;
  }
  .m-sm-n24 {
    margin: -120px !important;
  }
  .mt-sm-n24,
  .my-sm-n24 {
    margin-top: -120px !important;
  }
  .mr-sm-n24,
  .mx-sm-n24 {
    margin-right: -120px !important;
  }
  .mb-sm-n24,
  .my-sm-n24 {
    margin-bottom: -120px !important;
  }
  .ml-sm-n24,
  .mx-sm-n24 {
    margin-left: -120px !important;
  }
  .m-sm-n25 {
    margin: -125px !important;
  }
  .mt-sm-n25,
  .my-sm-n25 {
    margin-top: -125px !important;
  }
  .mr-sm-n25,
  .mx-sm-n25 {
    margin-right: -125px !important;
  }
  .mb-sm-n25,
  .my-sm-n25 {
    margin-bottom: -125px !important;
  }
  .ml-sm-n25,
  .mx-sm-n25 {
    margin-left: -125px !important;
  }
  .m-sm-n26 {
    margin: -130px !important;
  }
  .mt-sm-n26,
  .my-sm-n26 {
    margin-top: -130px !important;
  }
  .mr-sm-n26,
  .mx-sm-n26 {
    margin-right: -130px !important;
  }
  .mb-sm-n26,
  .my-sm-n26 {
    margin-bottom: -130px !important;
  }
  .ml-sm-n26,
  .mx-sm-n26 {
    margin-left: -130px !important;
  }
  .m-sm-n27 {
    margin: -135px !important;
  }
  .mt-sm-n27,
  .my-sm-n27 {
    margin-top: -135px !important;
  }
  .mr-sm-n27,
  .mx-sm-n27 {
    margin-right: -135px !important;
  }
  .mb-sm-n27,
  .my-sm-n27 {
    margin-bottom: -135px !important;
  }
  .ml-sm-n27,
  .mx-sm-n27 {
    margin-left: -135px !important;
  }
  .m-sm-n28 {
    margin: -140px !important;
  }
  .mt-sm-n28,
  .my-sm-n28 {
    margin-top: -140px !important;
  }
  .mr-sm-n28,
  .mx-sm-n28 {
    margin-right: -140px !important;
  }
  .mb-sm-n28,
  .my-sm-n28 {
    margin-bottom: -140px !important;
  }
  .ml-sm-n28,
  .mx-sm-n28 {
    margin-left: -140px !important;
  }
  .m-sm-n29 {
    margin: -145px !important;
  }
  .mt-sm-n29,
  .my-sm-n29 {
    margin-top: -145px !important;
  }
  .mr-sm-n29,
  .mx-sm-n29 {
    margin-right: -145px !important;
  }
  .mb-sm-n29,
  .my-sm-n29 {
    margin-bottom: -145px !important;
  }
  .ml-sm-n29,
  .mx-sm-n29 {
    margin-left: -145px !important;
  }
  .m-sm-n30 {
    margin: -150px !important;
  }
  .mt-sm-n30,
  .my-sm-n30 {
    margin-top: -150px !important;
  }
  .mr-sm-n30,
  .mx-sm-n30 {
    margin-right: -150px !important;
  }
  .mb-sm-n30,
  .my-sm-n30 {
    margin-bottom: -150px !important;
  }
  .ml-sm-n30,
  .mx-sm-n30 {
    margin-left: -150px !important;
  }
  .m-sm-n31 {
    margin: -155px !important;
  }
  .mt-sm-n31,
  .my-sm-n31 {
    margin-top: -155px !important;
  }
  .mr-sm-n31,
  .mx-sm-n31 {
    margin-right: -155px !important;
  }
  .mb-sm-n31,
  .my-sm-n31 {
    margin-bottom: -155px !important;
  }
  .ml-sm-n31,
  .mx-sm-n31 {
    margin-left: -155px !important;
  }
  .m-sm-n32 {
    margin: -160px !important;
  }
  .mt-sm-n32,
  .my-sm-n32 {
    margin-top: -160px !important;
  }
  .mr-sm-n32,
  .mx-sm-n32 {
    margin-right: -160px !important;
  }
  .mb-sm-n32,
  .my-sm-n32 {
    margin-bottom: -160px !important;
  }
  .ml-sm-n32,
  .mx-sm-n32 {
    margin-left: -160px !important;
  }
  .m-sm-n33 {
    margin: -165px !important;
  }
  .mt-sm-n33,
  .my-sm-n33 {
    margin-top: -165px !important;
  }
  .mr-sm-n33,
  .mx-sm-n33 {
    margin-right: -165px !important;
  }
  .mb-sm-n33,
  .my-sm-n33 {
    margin-bottom: -165px !important;
  }
  .ml-sm-n33,
  .mx-sm-n33 {
    margin-left: -165px !important;
  }
  .m-sm-n34 {
    margin: -170px !important;
  }
  .mt-sm-n34,
  .my-sm-n34 {
    margin-top: -170px !important;
  }
  .mr-sm-n34,
  .mx-sm-n34 {
    margin-right: -170px !important;
  }
  .mb-sm-n34,
  .my-sm-n34 {
    margin-bottom: -170px !important;
  }
  .ml-sm-n34,
  .mx-sm-n34 {
    margin-left: -170px !important;
  }
  .m-sm-n35 {
    margin: -175px !important;
  }
  .mt-sm-n35,
  .my-sm-n35 {
    margin-top: -175px !important;
  }
  .mr-sm-n35,
  .mx-sm-n35 {
    margin-right: -175px !important;
  }
  .mb-sm-n35,
  .my-sm-n35 {
    margin-bottom: -175px !important;
  }
  .ml-sm-n35,
  .mx-sm-n35 {
    margin-left: -175px !important;
  }
  .m-sm-n36 {
    margin: -180px !important;
  }
  .mt-sm-n36,
  .my-sm-n36 {
    margin-top: -180px !important;
  }
  .mr-sm-n36,
  .mx-sm-n36 {
    margin-right: -180px !important;
  }
  .mb-sm-n36,
  .my-sm-n36 {
    margin-bottom: -180px !important;
  }
  .ml-sm-n36,
  .mx-sm-n36 {
    margin-left: -180px !important;
  }
  .m-sm-n37 {
    margin: -185px !important;
  }
  .mt-sm-n37,
  .my-sm-n37 {
    margin-top: -185px !important;
  }
  .mr-sm-n37,
  .mx-sm-n37 {
    margin-right: -185px !important;
  }
  .mb-sm-n37,
  .my-sm-n37 {
    margin-bottom: -185px !important;
  }
  .ml-sm-n37,
  .mx-sm-n37 {
    margin-left: -185px !important;
  }
  .m-sm-n38 {
    margin: -190px !important;
  }
  .mt-sm-n38,
  .my-sm-n38 {
    margin-top: -190px !important;
  }
  .mr-sm-n38,
  .mx-sm-n38 {
    margin-right: -190px !important;
  }
  .mb-sm-n38,
  .my-sm-n38 {
    margin-bottom: -190px !important;
  }
  .ml-sm-n38,
  .mx-sm-n38 {
    margin-left: -190px !important;
  }
  .m-sm-n39 {
    margin: -195px !important;
  }
  .mt-sm-n39,
  .my-sm-n39 {
    margin-top: -195px !important;
  }
  .mr-sm-n39,
  .mx-sm-n39 {
    margin-right: -195px !important;
  }
  .mb-sm-n39,
  .my-sm-n39 {
    margin-bottom: -195px !important;
  }
  .ml-sm-n39,
  .mx-sm-n39 {
    margin-left: -195px !important;
  }
  .m-sm-n40 {
    margin: -200px !important;
  }
  .mt-sm-n40,
  .my-sm-n40 {
    margin-top: -200px !important;
  }
  .mr-sm-n40,
  .mx-sm-n40 {
    margin-right: -200px !important;
  }
  .mb-sm-n40,
  .my-sm-n40 {
    margin-bottom: -200px !important;
  }
  .ml-sm-n40,
  .mx-sm-n40 {
    margin-left: -200px !important;
  }
  .m-sm-n41 {
    margin: -205px !important;
  }
  .mt-sm-n41,
  .my-sm-n41 {
    margin-top: -205px !important;
  }
  .mr-sm-n41,
  .mx-sm-n41 {
    margin-right: -205px !important;
  }
  .mb-sm-n41,
  .my-sm-n41 {
    margin-bottom: -205px !important;
  }
  .ml-sm-n41,
  .mx-sm-n41 {
    margin-left: -205px !important;
  }
  .m-sm-n42 {
    margin: -210px !important;
  }
  .mt-sm-n42,
  .my-sm-n42 {
    margin-top: -210px !important;
  }
  .mr-sm-n42,
  .mx-sm-n42 {
    margin-right: -210px !important;
  }
  .mb-sm-n42,
  .my-sm-n42 {
    margin-bottom: -210px !important;
  }
  .ml-sm-n42,
  .mx-sm-n42 {
    margin-left: -210px !important;
  }
  .m-sm-n43 {
    margin: -215px !important;
  }
  .mt-sm-n43,
  .my-sm-n43 {
    margin-top: -215px !important;
  }
  .mr-sm-n43,
  .mx-sm-n43 {
    margin-right: -215px !important;
  }
  .mb-sm-n43,
  .my-sm-n43 {
    margin-bottom: -215px !important;
  }
  .ml-sm-n43,
  .mx-sm-n43 {
    margin-left: -215px !important;
  }
  .m-sm-n44 {
    margin: -220px !important;
  }
  .mt-sm-n44,
  .my-sm-n44 {
    margin-top: -220px !important;
  }
  .mr-sm-n44,
  .mx-sm-n44 {
    margin-right: -220px !important;
  }
  .mb-sm-n44,
  .my-sm-n44 {
    margin-bottom: -220px !important;
  }
  .ml-sm-n44,
  .mx-sm-n44 {
    margin-left: -220px !important;
  }
  .m-sm-n45 {
    margin: -225px !important;
  }
  .mt-sm-n45,
  .my-sm-n45 {
    margin-top: -225px !important;
  }
  .mr-sm-n45,
  .mx-sm-n45 {
    margin-right: -225px !important;
  }
  .mb-sm-n45,
  .my-sm-n45 {
    margin-bottom: -225px !important;
  }
  .ml-sm-n45,
  .mx-sm-n45 {
    margin-left: -225px !important;
  }
  .m-sm-n46 {
    margin: -230px !important;
  }
  .mt-sm-n46,
  .my-sm-n46 {
    margin-top: -230px !important;
  }
  .mr-sm-n46,
  .mx-sm-n46 {
    margin-right: -230px !important;
  }
  .mb-sm-n46,
  .my-sm-n46 {
    margin-bottom: -230px !important;
  }
  .ml-sm-n46,
  .mx-sm-n46 {
    margin-left: -230px !important;
  }
  .m-sm-n47 {
    margin: -235px !important;
  }
  .mt-sm-n47,
  .my-sm-n47 {
    margin-top: -235px !important;
  }
  .mr-sm-n47,
  .mx-sm-n47 {
    margin-right: -235px !important;
  }
  .mb-sm-n47,
  .my-sm-n47 {
    margin-bottom: -235px !important;
  }
  .ml-sm-n47,
  .mx-sm-n47 {
    margin-left: -235px !important;
  }
  .m-sm-n48 {
    margin: -240px !important;
  }
  .mt-sm-n48,
  .my-sm-n48 {
    margin-top: -240px !important;
  }
  .mr-sm-n48,
  .mx-sm-n48 {
    margin-right: -240px !important;
  }
  .mb-sm-n48,
  .my-sm-n48 {
    margin-bottom: -240px !important;
  }
  .ml-sm-n48,
  .mx-sm-n48 {
    margin-left: -240px !important;
  }
  .m-sm-n49 {
    margin: -245px !important;
  }
  .mt-sm-n49,
  .my-sm-n49 {
    margin-top: -245px !important;
  }
  .mr-sm-n49,
  .mx-sm-n49 {
    margin-right: -245px !important;
  }
  .mb-sm-n49,
  .my-sm-n49 {
    margin-bottom: -245px !important;
  }
  .ml-sm-n49,
  .mx-sm-n49 {
    margin-left: -245px !important;
  }
  .m-sm-n50 {
    margin: -250px !important;
  }
  .mt-sm-n50,
  .my-sm-n50 {
    margin-top: -250px !important;
  }
  .mr-sm-n50,
  .mx-sm-n50 {
    margin-right: -250px !important;
  }
  .mb-sm-n50,
  .my-sm-n50 {
    margin-bottom: -250px !important;
  }
  .ml-sm-n50,
  .mx-sm-n50 {
    margin-left: -250px !important;
  }
  .m-sm-n51 {
    margin: -255px !important;
  }
  .mt-sm-n51,
  .my-sm-n51 {
    margin-top: -255px !important;
  }
  .mr-sm-n51,
  .mx-sm-n51 {
    margin-right: -255px !important;
  }
  .mb-sm-n51,
  .my-sm-n51 {
    margin-bottom: -255px !important;
  }
  .ml-sm-n51,
  .mx-sm-n51 {
    margin-left: -255px !important;
  }
  .m-sm-n52 {
    margin: -260px !important;
  }
  .mt-sm-n52,
  .my-sm-n52 {
    margin-top: -260px !important;
  }
  .mr-sm-n52,
  .mx-sm-n52 {
    margin-right: -260px !important;
  }
  .mb-sm-n52,
  .my-sm-n52 {
    margin-bottom: -260px !important;
  }
  .ml-sm-n52,
  .mx-sm-n52 {
    margin-left: -260px !important;
  }
  .m-sm-n53 {
    margin: -265px !important;
  }
  .mt-sm-n53,
  .my-sm-n53 {
    margin-top: -265px !important;
  }
  .mr-sm-n53,
  .mx-sm-n53 {
    margin-right: -265px !important;
  }
  .mb-sm-n53,
  .my-sm-n53 {
    margin-bottom: -265px !important;
  }
  .ml-sm-n53,
  .mx-sm-n53 {
    margin-left: -265px !important;
  }
  .m-sm-n54 {
    margin: -270px !important;
  }
  .mt-sm-n54,
  .my-sm-n54 {
    margin-top: -270px !important;
  }
  .mr-sm-n54,
  .mx-sm-n54 {
    margin-right: -270px !important;
  }
  .mb-sm-n54,
  .my-sm-n54 {
    margin-bottom: -270px !important;
  }
  .ml-sm-n54,
  .mx-sm-n54 {
    margin-left: -270px !important;
  }
  .m-sm-n55 {
    margin: -275px !important;
  }
  .mt-sm-n55,
  .my-sm-n55 {
    margin-top: -275px !important;
  }
  .mr-sm-n55,
  .mx-sm-n55 {
    margin-right: -275px !important;
  }
  .mb-sm-n55,
  .my-sm-n55 {
    margin-bottom: -275px !important;
  }
  .ml-sm-n55,
  .mx-sm-n55 {
    margin-left: -275px !important;
  }
  .m-sm-n56 {
    margin: -280px !important;
  }
  .mt-sm-n56,
  .my-sm-n56 {
    margin-top: -280px !important;
  }
  .mr-sm-n56,
  .mx-sm-n56 {
    margin-right: -280px !important;
  }
  .mb-sm-n56,
  .my-sm-n56 {
    margin-bottom: -280px !important;
  }
  .ml-sm-n56,
  .mx-sm-n56 {
    margin-left: -280px !important;
  }
  .m-sm-n57 {
    margin: -285px !important;
  }
  .mt-sm-n57,
  .my-sm-n57 {
    margin-top: -285px !important;
  }
  .mr-sm-n57,
  .mx-sm-n57 {
    margin-right: -285px !important;
  }
  .mb-sm-n57,
  .my-sm-n57 {
    margin-bottom: -285px !important;
  }
  .ml-sm-n57,
  .mx-sm-n57 {
    margin-left: -285px !important;
  }
  .m-sm-n58 {
    margin: -290px !important;
  }
  .mt-sm-n58,
  .my-sm-n58 {
    margin-top: -290px !important;
  }
  .mr-sm-n58,
  .mx-sm-n58 {
    margin-right: -290px !important;
  }
  .mb-sm-n58,
  .my-sm-n58 {
    margin-bottom: -290px !important;
  }
  .ml-sm-n58,
  .mx-sm-n58 {
    margin-left: -290px !important;
  }
  .m-sm-n59 {
    margin: -295px !important;
  }
  .mt-sm-n59,
  .my-sm-n59 {
    margin-top: -295px !important;
  }
  .mr-sm-n59,
  .mx-sm-n59 {
    margin-right: -295px !important;
  }
  .mb-sm-n59,
  .my-sm-n59 {
    margin-bottom: -295px !important;
  }
  .ml-sm-n59,
  .mx-sm-n59 {
    margin-left: -295px !important;
  }
  .m-sm-n60 {
    margin: -300px !important;
  }
  .mt-sm-n60,
  .my-sm-n60 {
    margin-top: -300px !important;
  }
  .mr-sm-n60,
  .mx-sm-n60 {
    margin-right: -300px !important;
  }
  .mb-sm-n60,
  .my-sm-n60 {
    margin-bottom: -300px !important;
  }
  .ml-sm-n60,
  .mx-sm-n60 {
    margin-left: -300px !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 5px !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 5px !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 5px !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 5px !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 5px !important;
  }
  .m-md-2 {
    margin: 10px !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 10px !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 10px !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 10px !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 10px !important;
  }
  .m-md-3 {
    margin: 16px !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 16px !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 16px !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 16px !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 16px !important;
  }
  .m-md-4 {
    margin: 20px !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 20px !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 20px !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 20px !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 20px !important;
  }
  .m-md-5 {
    margin: 25px !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 25px !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 25px !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 25px !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 25px !important;
  }
  .m-md-6 {
    margin: 30px !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 30px !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 30px !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 30px !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 30px !important;
  }
  .m-md-7 {
    margin: 35px !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 35px !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 35px !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 35px !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 35px !important;
  }
  .m-md-8 {
    margin: 40px !important;
  }
  .mt-md-8,
  .my-md-8 {
    margin-top: 40px !important;
  }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 40px !important;
  }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 40px !important;
  }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 40px !important;
  }
  .m-md-9 {
    margin: 45px !important;
  }
  .mt-md-9,
  .my-md-9 {
    margin-top: 45px !important;
  }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 45px !important;
  }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 45px !important;
  }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 45px !important;
  }
  .m-md-10 {
    margin: 50px !important;
  }
  .mt-md-10,
  .my-md-10 {
    margin-top: 50px !important;
  }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 50px !important;
  }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 50px !important;
  }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 50px !important;
  }
  .m-md-11 {
    margin: 55px !important;
  }
  .mt-md-11,
  .my-md-11 {
    margin-top: 55px !important;
  }
  .mr-md-11,
  .mx-md-11 {
    margin-right: 55px !important;
  }
  .mb-md-11,
  .my-md-11 {
    margin-bottom: 55px !important;
  }
  .ml-md-11,
  .mx-md-11 {
    margin-left: 55px !important;
  }
  .m-md-12 {
    margin: 60px !important;
  }
  .mt-md-12,
  .my-md-12 {
    margin-top: 60px !important;
  }
  .mr-md-12,
  .mx-md-12 {
    margin-right: 60px !important;
  }
  .mb-md-12,
  .my-md-12 {
    margin-bottom: 60px !important;
  }
  .ml-md-12,
  .mx-md-12 {
    margin-left: 60px !important;
  }
  .m-md-13 {
    margin: 65px !important;
  }
  .mt-md-13,
  .my-md-13 {
    margin-top: 65px !important;
  }
  .mr-md-13,
  .mx-md-13 {
    margin-right: 65px !important;
  }
  .mb-md-13,
  .my-md-13 {
    margin-bottom: 65px !important;
  }
  .ml-md-13,
  .mx-md-13 {
    margin-left: 65px !important;
  }
  .m-md-14 {
    margin: 70px !important;
  }
  .mt-md-14,
  .my-md-14 {
    margin-top: 70px !important;
  }
  .mr-md-14,
  .mx-md-14 {
    margin-right: 70px !important;
  }
  .mb-md-14,
  .my-md-14 {
    margin-bottom: 70px !important;
  }
  .ml-md-14,
  .mx-md-14 {
    margin-left: 70px !important;
  }
  .m-md-15 {
    margin: 75px !important;
  }
  .mt-md-15,
  .my-md-15 {
    margin-top: 75px !important;
  }
  .mr-md-15,
  .mx-md-15 {
    margin-right: 75px !important;
  }
  .mb-md-15,
  .my-md-15 {
    margin-bottom: 75px !important;
  }
  .ml-md-15,
  .mx-md-15 {
    margin-left: 75px !important;
  }
  .m-md-16 {
    margin: 80px !important;
  }
  .mt-md-16,
  .my-md-16 {
    margin-top: 80px !important;
  }
  .mr-md-16,
  .mx-md-16 {
    margin-right: 80px !important;
  }
  .mb-md-16,
  .my-md-16 {
    margin-bottom: 80px !important;
  }
  .ml-md-16,
  .mx-md-16 {
    margin-left: 80px !important;
  }
  .m-md-17 {
    margin: 85px !important;
  }
  .mt-md-17,
  .my-md-17 {
    margin-top: 85px !important;
  }
  .mr-md-17,
  .mx-md-17 {
    margin-right: 85px !important;
  }
  .mb-md-17,
  .my-md-17 {
    margin-bottom: 85px !important;
  }
  .ml-md-17,
  .mx-md-17 {
    margin-left: 85px !important;
  }
  .m-md-18 {
    margin: 90px !important;
  }
  .mt-md-18,
  .my-md-18 {
    margin-top: 90px !important;
  }
  .mr-md-18,
  .mx-md-18 {
    margin-right: 90px !important;
  }
  .mb-md-18,
  .my-md-18 {
    margin-bottom: 90px !important;
  }
  .ml-md-18,
  .mx-md-18 {
    margin-left: 90px !important;
  }
  .m-md-19 {
    margin: 95px !important;
  }
  .mt-md-19,
  .my-md-19 {
    margin-top: 95px !important;
  }
  .mr-md-19,
  .mx-md-19 {
    margin-right: 95px !important;
  }
  .mb-md-19,
  .my-md-19 {
    margin-bottom: 95px !important;
  }
  .ml-md-19,
  .mx-md-19 {
    margin-left: 95px !important;
  }
  .m-md-20 {
    margin: 100px !important;
  }
  .mt-md-20,
  .my-md-20 {
    margin-top: 100px !important;
  }
  .mr-md-20,
  .mx-md-20 {
    margin-right: 100px !important;
  }
  .mb-md-20,
  .my-md-20 {
    margin-bottom: 100px !important;
  }
  .ml-md-20,
  .mx-md-20 {
    margin-left: 100px !important;
  }
  .m-md-21 {
    margin: 105px !important;
  }
  .mt-md-21,
  .my-md-21 {
    margin-top: 105px !important;
  }
  .mr-md-21,
  .mx-md-21 {
    margin-right: 105px !important;
  }
  .mb-md-21,
  .my-md-21 {
    margin-bottom: 105px !important;
  }
  .ml-md-21,
  .mx-md-21 {
    margin-left: 105px !important;
  }
  .m-md-22 {
    margin: 110px !important;
  }
  .mt-md-22,
  .my-md-22 {
    margin-top: 110px !important;
  }
  .mr-md-22,
  .mx-md-22 {
    margin-right: 110px !important;
  }
  .mb-md-22,
  .my-md-22 {
    margin-bottom: 110px !important;
  }
  .ml-md-22,
  .mx-md-22 {
    margin-left: 110px !important;
  }
  .m-md-23 {
    margin: 115px !important;
  }
  .mt-md-23,
  .my-md-23 {
    margin-top: 115px !important;
  }
  .mr-md-23,
  .mx-md-23 {
    margin-right: 115px !important;
  }
  .mb-md-23,
  .my-md-23 {
    margin-bottom: 115px !important;
  }
  .ml-md-23,
  .mx-md-23 {
    margin-left: 115px !important;
  }
  .m-md-24 {
    margin: 120px !important;
  }
  .mt-md-24,
  .my-md-24 {
    margin-top: 120px !important;
  }
  .mr-md-24,
  .mx-md-24 {
    margin-right: 120px !important;
  }
  .mb-md-24,
  .my-md-24 {
    margin-bottom: 120px !important;
  }
  .ml-md-24,
  .mx-md-24 {
    margin-left: 120px !important;
  }
  .m-md-25 {
    margin: 125px !important;
  }
  .mt-md-25,
  .my-md-25 {
    margin-top: 125px !important;
  }
  .mr-md-25,
  .mx-md-25 {
    margin-right: 125px !important;
  }
  .mb-md-25,
  .my-md-25 {
    margin-bottom: 125px !important;
  }
  .ml-md-25,
  .mx-md-25 {
    margin-left: 125px !important;
  }
  .m-md-26 {
    margin: 130px !important;
  }
  .mt-md-26,
  .my-md-26 {
    margin-top: 130px !important;
  }
  .mr-md-26,
  .mx-md-26 {
    margin-right: 130px !important;
  }
  .mb-md-26,
  .my-md-26 {
    margin-bottom: 130px !important;
  }
  .ml-md-26,
  .mx-md-26 {
    margin-left: 130px !important;
  }
  .m-md-27 {
    margin: 135px !important;
  }
  .mt-md-27,
  .my-md-27 {
    margin-top: 135px !important;
  }
  .mr-md-27,
  .mx-md-27 {
    margin-right: 135px !important;
  }
  .mb-md-27,
  .my-md-27 {
    margin-bottom: 135px !important;
  }
  .ml-md-27,
  .mx-md-27 {
    margin-left: 135px !important;
  }
  .m-md-28 {
    margin: 140px !important;
  }
  .mt-md-28,
  .my-md-28 {
    margin-top: 140px !important;
  }
  .mr-md-28,
  .mx-md-28 {
    margin-right: 140px !important;
  }
  .mb-md-28,
  .my-md-28 {
    margin-bottom: 140px !important;
  }
  .ml-md-28,
  .mx-md-28 {
    margin-left: 140px !important;
  }
  .m-md-29 {
    margin: 145px !important;
  }
  .mt-md-29,
  .my-md-29 {
    margin-top: 145px !important;
  }
  .mr-md-29,
  .mx-md-29 {
    margin-right: 145px !important;
  }
  .mb-md-29,
  .my-md-29 {
    margin-bottom: 145px !important;
  }
  .ml-md-29,
  .mx-md-29 {
    margin-left: 145px !important;
  }
  .m-md-30 {
    margin: 150px !important;
  }
  .mt-md-30,
  .my-md-30 {
    margin-top: 150px !important;
  }
  .mr-md-30,
  .mx-md-30 {
    margin-right: 150px !important;
  }
  .mb-md-30,
  .my-md-30 {
    margin-bottom: 150px !important;
  }
  .ml-md-30,
  .mx-md-30 {
    margin-left: 150px !important;
  }
  .m-md-31 {
    margin: 155px !important;
  }
  .mt-md-31,
  .my-md-31 {
    margin-top: 155px !important;
  }
  .mr-md-31,
  .mx-md-31 {
    margin-right: 155px !important;
  }
  .mb-md-31,
  .my-md-31 {
    margin-bottom: 155px !important;
  }
  .ml-md-31,
  .mx-md-31 {
    margin-left: 155px !important;
  }
  .m-md-32 {
    margin: 160px !important;
  }
  .mt-md-32,
  .my-md-32 {
    margin-top: 160px !important;
  }
  .mr-md-32,
  .mx-md-32 {
    margin-right: 160px !important;
  }
  .mb-md-32,
  .my-md-32 {
    margin-bottom: 160px !important;
  }
  .ml-md-32,
  .mx-md-32 {
    margin-left: 160px !important;
  }
  .m-md-33 {
    margin: 165px !important;
  }
  .mt-md-33,
  .my-md-33 {
    margin-top: 165px !important;
  }
  .mr-md-33,
  .mx-md-33 {
    margin-right: 165px !important;
  }
  .mb-md-33,
  .my-md-33 {
    margin-bottom: 165px !important;
  }
  .ml-md-33,
  .mx-md-33 {
    margin-left: 165px !important;
  }
  .m-md-34 {
    margin: 170px !important;
  }
  .mt-md-34,
  .my-md-34 {
    margin-top: 170px !important;
  }
  .mr-md-34,
  .mx-md-34 {
    margin-right: 170px !important;
  }
  .mb-md-34,
  .my-md-34 {
    margin-bottom: 170px !important;
  }
  .ml-md-34,
  .mx-md-34 {
    margin-left: 170px !important;
  }
  .m-md-35 {
    margin: 175px !important;
  }
  .mt-md-35,
  .my-md-35 {
    margin-top: 175px !important;
  }
  .mr-md-35,
  .mx-md-35 {
    margin-right: 175px !important;
  }
  .mb-md-35,
  .my-md-35 {
    margin-bottom: 175px !important;
  }
  .ml-md-35,
  .mx-md-35 {
    margin-left: 175px !important;
  }
  .m-md-36 {
    margin: 180px !important;
  }
  .mt-md-36,
  .my-md-36 {
    margin-top: 180px !important;
  }
  .mr-md-36,
  .mx-md-36 {
    margin-right: 180px !important;
  }
  .mb-md-36,
  .my-md-36 {
    margin-bottom: 180px !important;
  }
  .ml-md-36,
  .mx-md-36 {
    margin-left: 180px !important;
  }
  .m-md-37 {
    margin: 185px !important;
  }
  .mt-md-37,
  .my-md-37 {
    margin-top: 185px !important;
  }
  .mr-md-37,
  .mx-md-37 {
    margin-right: 185px !important;
  }
  .mb-md-37,
  .my-md-37 {
    margin-bottom: 185px !important;
  }
  .ml-md-37,
  .mx-md-37 {
    margin-left: 185px !important;
  }
  .m-md-38 {
    margin: 190px !important;
  }
  .mt-md-38,
  .my-md-38 {
    margin-top: 190px !important;
  }
  .mr-md-38,
  .mx-md-38 {
    margin-right: 190px !important;
  }
  .mb-md-38,
  .my-md-38 {
    margin-bottom: 190px !important;
  }
  .ml-md-38,
  .mx-md-38 {
    margin-left: 190px !important;
  }
  .m-md-39 {
    margin: 195px !important;
  }
  .mt-md-39,
  .my-md-39 {
    margin-top: 195px !important;
  }
  .mr-md-39,
  .mx-md-39 {
    margin-right: 195px !important;
  }
  .mb-md-39,
  .my-md-39 {
    margin-bottom: 195px !important;
  }
  .ml-md-39,
  .mx-md-39 {
    margin-left: 195px !important;
  }
  .m-md-40 {
    margin: 200px !important;
  }
  .mt-md-40,
  .my-md-40 {
    margin-top: 200px !important;
  }
  .mr-md-40,
  .mx-md-40 {
    margin-right: 200px !important;
  }
  .mb-md-40,
  .my-md-40 {
    margin-bottom: 200px !important;
  }
  .ml-md-40,
  .mx-md-40 {
    margin-left: 200px !important;
  }
  .m-md-41 {
    margin: 205px !important;
  }
  .mt-md-41,
  .my-md-41 {
    margin-top: 205px !important;
  }
  .mr-md-41,
  .mx-md-41 {
    margin-right: 205px !important;
  }
  .mb-md-41,
  .my-md-41 {
    margin-bottom: 205px !important;
  }
  .ml-md-41,
  .mx-md-41 {
    margin-left: 205px !important;
  }
  .m-md-42 {
    margin: 210px !important;
  }
  .mt-md-42,
  .my-md-42 {
    margin-top: 210px !important;
  }
  .mr-md-42,
  .mx-md-42 {
    margin-right: 210px !important;
  }
  .mb-md-42,
  .my-md-42 {
    margin-bottom: 210px !important;
  }
  .ml-md-42,
  .mx-md-42 {
    margin-left: 210px !important;
  }
  .m-md-43 {
    margin: 215px !important;
  }
  .mt-md-43,
  .my-md-43 {
    margin-top: 215px !important;
  }
  .mr-md-43,
  .mx-md-43 {
    margin-right: 215px !important;
  }
  .mb-md-43,
  .my-md-43 {
    margin-bottom: 215px !important;
  }
  .ml-md-43,
  .mx-md-43 {
    margin-left: 215px !important;
  }
  .m-md-44 {
    margin: 220px !important;
  }
  .mt-md-44,
  .my-md-44 {
    margin-top: 220px !important;
  }
  .mr-md-44,
  .mx-md-44 {
    margin-right: 220px !important;
  }
  .mb-md-44,
  .my-md-44 {
    margin-bottom: 220px !important;
  }
  .ml-md-44,
  .mx-md-44 {
    margin-left: 220px !important;
  }
  .m-md-45 {
    margin: 225px !important;
  }
  .mt-md-45,
  .my-md-45 {
    margin-top: 225px !important;
  }
  .mr-md-45,
  .mx-md-45 {
    margin-right: 225px !important;
  }
  .mb-md-45,
  .my-md-45 {
    margin-bottom: 225px !important;
  }
  .ml-md-45,
  .mx-md-45 {
    margin-left: 225px !important;
  }
  .m-md-46 {
    margin: 230px !important;
  }
  .mt-md-46,
  .my-md-46 {
    margin-top: 230px !important;
  }
  .mr-md-46,
  .mx-md-46 {
    margin-right: 230px !important;
  }
  .mb-md-46,
  .my-md-46 {
    margin-bottom: 230px !important;
  }
  .ml-md-46,
  .mx-md-46 {
    margin-left: 230px !important;
  }
  .m-md-47 {
    margin: 235px !important;
  }
  .mt-md-47,
  .my-md-47 {
    margin-top: 235px !important;
  }
  .mr-md-47,
  .mx-md-47 {
    margin-right: 235px !important;
  }
  .mb-md-47,
  .my-md-47 {
    margin-bottom: 235px !important;
  }
  .ml-md-47,
  .mx-md-47 {
    margin-left: 235px !important;
  }
  .m-md-48 {
    margin: 240px !important;
  }
  .mt-md-48,
  .my-md-48 {
    margin-top: 240px !important;
  }
  .mr-md-48,
  .mx-md-48 {
    margin-right: 240px !important;
  }
  .mb-md-48,
  .my-md-48 {
    margin-bottom: 240px !important;
  }
  .ml-md-48,
  .mx-md-48 {
    margin-left: 240px !important;
  }
  .m-md-49 {
    margin: 245px !important;
  }
  .mt-md-49,
  .my-md-49 {
    margin-top: 245px !important;
  }
  .mr-md-49,
  .mx-md-49 {
    margin-right: 245px !important;
  }
  .mb-md-49,
  .my-md-49 {
    margin-bottom: 245px !important;
  }
  .ml-md-49,
  .mx-md-49 {
    margin-left: 245px !important;
  }
  .m-md-50 {
    margin: 250px !important;
  }
  .mt-md-50,
  .my-md-50 {
    margin-top: 250px !important;
  }
  .mr-md-50,
  .mx-md-50 {
    margin-right: 250px !important;
  }
  .mb-md-50,
  .my-md-50 {
    margin-bottom: 250px !important;
  }
  .ml-md-50,
  .mx-md-50 {
    margin-left: 250px !important;
  }
  .m-md-51 {
    margin: 255px !important;
  }
  .mt-md-51,
  .my-md-51 {
    margin-top: 255px !important;
  }
  .mr-md-51,
  .mx-md-51 {
    margin-right: 255px !important;
  }
  .mb-md-51,
  .my-md-51 {
    margin-bottom: 255px !important;
  }
  .ml-md-51,
  .mx-md-51 {
    margin-left: 255px !important;
  }
  .m-md-52 {
    margin: 260px !important;
  }
  .mt-md-52,
  .my-md-52 {
    margin-top: 260px !important;
  }
  .mr-md-52,
  .mx-md-52 {
    margin-right: 260px !important;
  }
  .mb-md-52,
  .my-md-52 {
    margin-bottom: 260px !important;
  }
  .ml-md-52,
  .mx-md-52 {
    margin-left: 260px !important;
  }
  .m-md-53 {
    margin: 265px !important;
  }
  .mt-md-53,
  .my-md-53 {
    margin-top: 265px !important;
  }
  .mr-md-53,
  .mx-md-53 {
    margin-right: 265px !important;
  }
  .mb-md-53,
  .my-md-53 {
    margin-bottom: 265px !important;
  }
  .ml-md-53,
  .mx-md-53 {
    margin-left: 265px !important;
  }
  .m-md-54 {
    margin: 270px !important;
  }
  .mt-md-54,
  .my-md-54 {
    margin-top: 270px !important;
  }
  .mr-md-54,
  .mx-md-54 {
    margin-right: 270px !important;
  }
  .mb-md-54,
  .my-md-54 {
    margin-bottom: 270px !important;
  }
  .ml-md-54,
  .mx-md-54 {
    margin-left: 270px !important;
  }
  .m-md-55 {
    margin: 275px !important;
  }
  .mt-md-55,
  .my-md-55 {
    margin-top: 275px !important;
  }
  .mr-md-55,
  .mx-md-55 {
    margin-right: 275px !important;
  }
  .mb-md-55,
  .my-md-55 {
    margin-bottom: 275px !important;
  }
  .ml-md-55,
  .mx-md-55 {
    margin-left: 275px !important;
  }
  .m-md-56 {
    margin: 280px !important;
  }
  .mt-md-56,
  .my-md-56 {
    margin-top: 280px !important;
  }
  .mr-md-56,
  .mx-md-56 {
    margin-right: 280px !important;
  }
  .mb-md-56,
  .my-md-56 {
    margin-bottom: 280px !important;
  }
  .ml-md-56,
  .mx-md-56 {
    margin-left: 280px !important;
  }
  .m-md-57 {
    margin: 285px !important;
  }
  .mt-md-57,
  .my-md-57 {
    margin-top: 285px !important;
  }
  .mr-md-57,
  .mx-md-57 {
    margin-right: 285px !important;
  }
  .mb-md-57,
  .my-md-57 {
    margin-bottom: 285px !important;
  }
  .ml-md-57,
  .mx-md-57 {
    margin-left: 285px !important;
  }
  .m-md-58 {
    margin: 290px !important;
  }
  .mt-md-58,
  .my-md-58 {
    margin-top: 290px !important;
  }
  .mr-md-58,
  .mx-md-58 {
    margin-right: 290px !important;
  }
  .mb-md-58,
  .my-md-58 {
    margin-bottom: 290px !important;
  }
  .ml-md-58,
  .mx-md-58 {
    margin-left: 290px !important;
  }
  .m-md-59 {
    margin: 295px !important;
  }
  .mt-md-59,
  .my-md-59 {
    margin-top: 295px !important;
  }
  .mr-md-59,
  .mx-md-59 {
    margin-right: 295px !important;
  }
  .mb-md-59,
  .my-md-59 {
    margin-bottom: 295px !important;
  }
  .ml-md-59,
  .mx-md-59 {
    margin-left: 295px !important;
  }
  .m-md-60 {
    margin: 300px !important;
  }
  .mt-md-60,
  .my-md-60 {
    margin-top: 300px !important;
  }
  .mr-md-60,
  .mx-md-60 {
    margin-right: 300px !important;
  }
  .mb-md-60,
  .my-md-60 {
    margin-bottom: 300px !important;
  }
  .ml-md-60,
  .mx-md-60 {
    margin-left: 300px !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 5px !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 5px !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 5px !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 5px !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 5px !important;
  }
  .p-md-2 {
    padding: 10px !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 10px !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 10px !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 10px !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 10px !important;
  }
  .p-md-3 {
    padding: 16px !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 16px !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 16px !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 16px !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 16px !important;
  }
  .p-md-4 {
    padding: 20px !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 20px !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 20px !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 20px !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 20px !important;
  }
  .p-md-5 {
    padding: 25px !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 25px !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 25px !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 25px !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 25px !important;
  }
  .p-md-6 {
    padding: 30px !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 30px !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 30px !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 30px !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 30px !important;
  }
  .p-md-7 {
    padding: 35px !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 35px !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 35px !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 35px !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 35px !important;
  }
  .p-md-8 {
    padding: 40px !important;
  }
  .pt-md-8,
  .py-md-8 {
    padding-top: 40px !important;
  }
  .pr-md-8,
  .px-md-8 {
    padding-right: 40px !important;
  }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 40px !important;
  }
  .pl-md-8,
  .px-md-8 {
    padding-left: 40px !important;
  }
  .p-md-9 {
    padding: 45px !important;
  }
  .pt-md-9,
  .py-md-9 {
    padding-top: 45px !important;
  }
  .pr-md-9,
  .px-md-9 {
    padding-right: 45px !important;
  }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 45px !important;
  }
  .pl-md-9,
  .px-md-9 {
    padding-left: 45px !important;
  }
  .p-md-10 {
    padding: 50px !important;
  }
  .pt-md-10,
  .py-md-10 {
    padding-top: 50px !important;
  }
  .pr-md-10,
  .px-md-10 {
    padding-right: 50px !important;
  }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 50px !important;
  }
  .pl-md-10,
  .px-md-10 {
    padding-left: 50px !important;
  }
  .p-md-11 {
    padding: 55px !important;
  }
  .pt-md-11,
  .py-md-11 {
    padding-top: 55px !important;
  }
  .pr-md-11,
  .px-md-11 {
    padding-right: 55px !important;
  }
  .pb-md-11,
  .py-md-11 {
    padding-bottom: 55px !important;
  }
  .pl-md-11,
  .px-md-11 {
    padding-left: 55px !important;
  }
  .p-md-12 {
    padding: 60px !important;
  }
  .pt-md-12,
  .py-md-12 {
    padding-top: 60px !important;
  }
  .pr-md-12,
  .px-md-12 {
    padding-right: 60px !important;
  }
  .pb-md-12,
  .py-md-12 {
    padding-bottom: 60px !important;
  }
  .pl-md-12,
  .px-md-12 {
    padding-left: 60px !important;
  }
  .p-md-13 {
    padding: 65px !important;
  }
  .pt-md-13,
  .py-md-13 {
    padding-top: 65px !important;
  }
  .pr-md-13,
  .px-md-13 {
    padding-right: 65px !important;
  }
  .pb-md-13,
  .py-md-13 {
    padding-bottom: 65px !important;
  }
  .pl-md-13,
  .px-md-13 {
    padding-left: 65px !important;
  }
  .p-md-14 {
    padding: 70px !important;
  }
  .pt-md-14,
  .py-md-14 {
    padding-top: 70px !important;
  }
  .pr-md-14,
  .px-md-14 {
    padding-right: 70px !important;
  }
  .pb-md-14,
  .py-md-14 {
    padding-bottom: 70px !important;
  }
  .pl-md-14,
  .px-md-14 {
    padding-left: 70px !important;
  }
  .p-md-15 {
    padding: 75px !important;
  }
  .pt-md-15,
  .py-md-15 {
    padding-top: 75px !important;
  }
  .pr-md-15,
  .px-md-15 {
    padding-right: 75px !important;
  }
  .pb-md-15,
  .py-md-15 {
    padding-bottom: 75px !important;
  }
  .pl-md-15,
  .px-md-15 {
    padding-left: 75px !important;
  }
  .p-md-16 {
    padding: 80px !important;
  }
  .pt-md-16,
  .py-md-16 {
    padding-top: 80px !important;
  }
  .pr-md-16,
  .px-md-16 {
    padding-right: 80px !important;
  }
  .pb-md-16,
  .py-md-16 {
    padding-bottom: 80px !important;
  }
  .pl-md-16,
  .px-md-16 {
    padding-left: 80px !important;
  }
  .p-md-17 {
    padding: 85px !important;
  }
  .pt-md-17,
  .py-md-17 {
    padding-top: 85px !important;
  }
  .pr-md-17,
  .px-md-17 {
    padding-right: 85px !important;
  }
  .pb-md-17,
  .py-md-17 {
    padding-bottom: 85px !important;
  }
  .pl-md-17,
  .px-md-17 {
    padding-left: 85px !important;
  }
  .p-md-18 {
    padding: 90px !important;
  }
  .pt-md-18,
  .py-md-18 {
    padding-top: 90px !important;
  }
  .pr-md-18,
  .px-md-18 {
    padding-right: 90px !important;
  }
  .pb-md-18,
  .py-md-18 {
    padding-bottom: 90px !important;
  }
  .pl-md-18,
  .px-md-18 {
    padding-left: 90px !important;
  }
  .p-md-19 {
    padding: 95px !important;
  }
  .pt-md-19,
  .py-md-19 {
    padding-top: 95px !important;
  }
  .pr-md-19,
  .px-md-19 {
    padding-right: 95px !important;
  }
  .pb-md-19,
  .py-md-19 {
    padding-bottom: 95px !important;
  }
  .pl-md-19,
  .px-md-19 {
    padding-left: 95px !important;
  }
  .p-md-20 {
    padding: 100px !important;
  }
  .pt-md-20,
  .py-md-20 {
    padding-top: 100px !important;
  }
  .pr-md-20,
  .px-md-20 {
    padding-right: 100px !important;
  }
  .pb-md-20,
  .py-md-20 {
    padding-bottom: 100px !important;
  }
  .pl-md-20,
  .px-md-20 {
    padding-left: 100px !important;
  }
  .p-md-21 {
    padding: 105px !important;
  }
  .pt-md-21,
  .py-md-21 {
    padding-top: 105px !important;
  }
  .pr-md-21,
  .px-md-21 {
    padding-right: 105px !important;
  }
  .pb-md-21,
  .py-md-21 {
    padding-bottom: 105px !important;
  }
  .pl-md-21,
  .px-md-21 {
    padding-left: 105px !important;
  }
  .p-md-22 {
    padding: 110px !important;
  }
  .pt-md-22,
  .py-md-22 {
    padding-top: 110px !important;
  }
  .pr-md-22,
  .px-md-22 {
    padding-right: 110px !important;
  }
  .pb-md-22,
  .py-md-22 {
    padding-bottom: 110px !important;
  }
  .pl-md-22,
  .px-md-22 {
    padding-left: 110px !important;
  }
  .p-md-23 {
    padding: 115px !important;
  }
  .pt-md-23,
  .py-md-23 {
    padding-top: 115px !important;
  }
  .pr-md-23,
  .px-md-23 {
    padding-right: 115px !important;
  }
  .pb-md-23,
  .py-md-23 {
    padding-bottom: 115px !important;
  }
  .pl-md-23,
  .px-md-23 {
    padding-left: 115px !important;
  }
  .p-md-24 {
    padding: 120px !important;
  }
  .pt-md-24,
  .py-md-24 {
    padding-top: 120px !important;
  }
  .pr-md-24,
  .px-md-24 {
    padding-right: 120px !important;
  }
  .pb-md-24,
  .py-md-24 {
    padding-bottom: 120px !important;
  }
  .pl-md-24,
  .px-md-24 {
    padding-left: 120px !important;
  }
  .p-md-25 {
    padding: 125px !important;
  }
  .pt-md-25,
  .py-md-25 {
    padding-top: 125px !important;
  }
  .pr-md-25,
  .px-md-25 {
    padding-right: 125px !important;
  }
  .pb-md-25,
  .py-md-25 {
    padding-bottom: 125px !important;
  }
  .pl-md-25,
  .px-md-25 {
    padding-left: 125px !important;
  }
  .p-md-26 {
    padding: 130px !important;
  }
  .pt-md-26,
  .py-md-26 {
    padding-top: 130px !important;
  }
  .pr-md-26,
  .px-md-26 {
    padding-right: 130px !important;
  }
  .pb-md-26,
  .py-md-26 {
    padding-bottom: 130px !important;
  }
  .pl-md-26,
  .px-md-26 {
    padding-left: 130px !important;
  }
  .p-md-27 {
    padding: 135px !important;
  }
  .pt-md-27,
  .py-md-27 {
    padding-top: 135px !important;
  }
  .pr-md-27,
  .px-md-27 {
    padding-right: 135px !important;
  }
  .pb-md-27,
  .py-md-27 {
    padding-bottom: 135px !important;
  }
  .pl-md-27,
  .px-md-27 {
    padding-left: 135px !important;
  }
  .p-md-28 {
    padding: 140px !important;
  }
  .pt-md-28,
  .py-md-28 {
    padding-top: 140px !important;
  }
  .pr-md-28,
  .px-md-28 {
    padding-right: 140px !important;
  }
  .pb-md-28,
  .py-md-28 {
    padding-bottom: 140px !important;
  }
  .pl-md-28,
  .px-md-28 {
    padding-left: 140px !important;
  }
  .p-md-29 {
    padding: 145px !important;
  }
  .pt-md-29,
  .py-md-29 {
    padding-top: 145px !important;
  }
  .pr-md-29,
  .px-md-29 {
    padding-right: 145px !important;
  }
  .pb-md-29,
  .py-md-29 {
    padding-bottom: 145px !important;
  }
  .pl-md-29,
  .px-md-29 {
    padding-left: 145px !important;
  }
  .p-md-30 {
    padding: 150px !important;
  }
  .pt-md-30,
  .py-md-30 {
    padding-top: 150px !important;
  }
  .pr-md-30,
  .px-md-30 {
    padding-right: 150px !important;
  }
  .pb-md-30,
  .py-md-30 {
    padding-bottom: 150px !important;
  }
  .pl-md-30,
  .px-md-30 {
    padding-left: 150px !important;
  }
  .p-md-31 {
    padding: 155px !important;
  }
  .pt-md-31,
  .py-md-31 {
    padding-top: 155px !important;
  }
  .pr-md-31,
  .px-md-31 {
    padding-right: 155px !important;
  }
  .pb-md-31,
  .py-md-31 {
    padding-bottom: 155px !important;
  }
  .pl-md-31,
  .px-md-31 {
    padding-left: 155px !important;
  }
  .p-md-32 {
    padding: 160px !important;
  }
  .pt-md-32,
  .py-md-32 {
    padding-top: 160px !important;
  }
  .pr-md-32,
  .px-md-32 {
    padding-right: 160px !important;
  }
  .pb-md-32,
  .py-md-32 {
    padding-bottom: 160px !important;
  }
  .pl-md-32,
  .px-md-32 {
    padding-left: 160px !important;
  }
  .p-md-33 {
    padding: 165px !important;
  }
  .pt-md-33,
  .py-md-33 {
    padding-top: 165px !important;
  }
  .pr-md-33,
  .px-md-33 {
    padding-right: 165px !important;
  }
  .pb-md-33,
  .py-md-33 {
    padding-bottom: 165px !important;
  }
  .pl-md-33,
  .px-md-33 {
    padding-left: 165px !important;
  }
  .p-md-34 {
    padding: 170px !important;
  }
  .pt-md-34,
  .py-md-34 {
    padding-top: 170px !important;
  }
  .pr-md-34,
  .px-md-34 {
    padding-right: 170px !important;
  }
  .pb-md-34,
  .py-md-34 {
    padding-bottom: 170px !important;
  }
  .pl-md-34,
  .px-md-34 {
    padding-left: 170px !important;
  }
  .p-md-35 {
    padding: 175px !important;
  }
  .pt-md-35,
  .py-md-35 {
    padding-top: 175px !important;
  }
  .pr-md-35,
  .px-md-35 {
    padding-right: 175px !important;
  }
  .pb-md-35,
  .py-md-35 {
    padding-bottom: 175px !important;
  }
  .pl-md-35,
  .px-md-35 {
    padding-left: 175px !important;
  }
  .p-md-36 {
    padding: 180px !important;
  }
  .pt-md-36,
  .py-md-36 {
    padding-top: 180px !important;
  }
  .pr-md-36,
  .px-md-36 {
    padding-right: 180px !important;
  }
  .pb-md-36,
  .py-md-36 {
    padding-bottom: 180px !important;
  }
  .pl-md-36,
  .px-md-36 {
    padding-left: 180px !important;
  }
  .p-md-37 {
    padding: 185px !important;
  }
  .pt-md-37,
  .py-md-37 {
    padding-top: 185px !important;
  }
  .pr-md-37,
  .px-md-37 {
    padding-right: 185px !important;
  }
  .pb-md-37,
  .py-md-37 {
    padding-bottom: 185px !important;
  }
  .pl-md-37,
  .px-md-37 {
    padding-left: 185px !important;
  }
  .p-md-38 {
    padding: 190px !important;
  }
  .pt-md-38,
  .py-md-38 {
    padding-top: 190px !important;
  }
  .pr-md-38,
  .px-md-38 {
    padding-right: 190px !important;
  }
  .pb-md-38,
  .py-md-38 {
    padding-bottom: 190px !important;
  }
  .pl-md-38,
  .px-md-38 {
    padding-left: 190px !important;
  }
  .p-md-39 {
    padding: 195px !important;
  }
  .pt-md-39,
  .py-md-39 {
    padding-top: 195px !important;
  }
  .pr-md-39,
  .px-md-39 {
    padding-right: 195px !important;
  }
  .pb-md-39,
  .py-md-39 {
    padding-bottom: 195px !important;
  }
  .pl-md-39,
  .px-md-39 {
    padding-left: 195px !important;
  }
  .p-md-40 {
    padding: 200px !important;
  }
  .pt-md-40,
  .py-md-40 {
    padding-top: 200px !important;
  }
  .pr-md-40,
  .px-md-40 {
    padding-right: 200px !important;
  }
  .pb-md-40,
  .py-md-40 {
    padding-bottom: 200px !important;
  }
  .pl-md-40,
  .px-md-40 {
    padding-left: 200px !important;
  }
  .p-md-41 {
    padding: 205px !important;
  }
  .pt-md-41,
  .py-md-41 {
    padding-top: 205px !important;
  }
  .pr-md-41,
  .px-md-41 {
    padding-right: 205px !important;
  }
  .pb-md-41,
  .py-md-41 {
    padding-bottom: 205px !important;
  }
  .pl-md-41,
  .px-md-41 {
    padding-left: 205px !important;
  }
  .p-md-42 {
    padding: 210px !important;
  }
  .pt-md-42,
  .py-md-42 {
    padding-top: 210px !important;
  }
  .pr-md-42,
  .px-md-42 {
    padding-right: 210px !important;
  }
  .pb-md-42,
  .py-md-42 {
    padding-bottom: 210px !important;
  }
  .pl-md-42,
  .px-md-42 {
    padding-left: 210px !important;
  }
  .p-md-43 {
    padding: 215px !important;
  }
  .pt-md-43,
  .py-md-43 {
    padding-top: 215px !important;
  }
  .pr-md-43,
  .px-md-43 {
    padding-right: 215px !important;
  }
  .pb-md-43,
  .py-md-43 {
    padding-bottom: 215px !important;
  }
  .pl-md-43,
  .px-md-43 {
    padding-left: 215px !important;
  }
  .p-md-44 {
    padding: 220px !important;
  }
  .pt-md-44,
  .py-md-44 {
    padding-top: 220px !important;
  }
  .pr-md-44,
  .px-md-44 {
    padding-right: 220px !important;
  }
  .pb-md-44,
  .py-md-44 {
    padding-bottom: 220px !important;
  }
  .pl-md-44,
  .px-md-44 {
    padding-left: 220px !important;
  }
  .p-md-45 {
    padding: 225px !important;
  }
  .pt-md-45,
  .py-md-45 {
    padding-top: 225px !important;
  }
  .pr-md-45,
  .px-md-45 {
    padding-right: 225px !important;
  }
  .pb-md-45,
  .py-md-45 {
    padding-bottom: 225px !important;
  }
  .pl-md-45,
  .px-md-45 {
    padding-left: 225px !important;
  }
  .p-md-46 {
    padding: 230px !important;
  }
  .pt-md-46,
  .py-md-46 {
    padding-top: 230px !important;
  }
  .pr-md-46,
  .px-md-46 {
    padding-right: 230px !important;
  }
  .pb-md-46,
  .py-md-46 {
    padding-bottom: 230px !important;
  }
  .pl-md-46,
  .px-md-46 {
    padding-left: 230px !important;
  }
  .p-md-47 {
    padding: 235px !important;
  }
  .pt-md-47,
  .py-md-47 {
    padding-top: 235px !important;
  }
  .pr-md-47,
  .px-md-47 {
    padding-right: 235px !important;
  }
  .pb-md-47,
  .py-md-47 {
    padding-bottom: 235px !important;
  }
  .pl-md-47,
  .px-md-47 {
    padding-left: 235px !important;
  }
  .p-md-48 {
    padding: 240px !important;
  }
  .pt-md-48,
  .py-md-48 {
    padding-top: 240px !important;
  }
  .pr-md-48,
  .px-md-48 {
    padding-right: 240px !important;
  }
  .pb-md-48,
  .py-md-48 {
    padding-bottom: 240px !important;
  }
  .pl-md-48,
  .px-md-48 {
    padding-left: 240px !important;
  }
  .p-md-49 {
    padding: 245px !important;
  }
  .pt-md-49,
  .py-md-49 {
    padding-top: 245px !important;
  }
  .pr-md-49,
  .px-md-49 {
    padding-right: 245px !important;
  }
  .pb-md-49,
  .py-md-49 {
    padding-bottom: 245px !important;
  }
  .pl-md-49,
  .px-md-49 {
    padding-left: 245px !important;
  }
  .p-md-50 {
    padding: 250px !important;
  }
  .pt-md-50,
  .py-md-50 {
    padding-top: 250px !important;
  }
  .pr-md-50,
  .px-md-50 {
    padding-right: 250px !important;
  }
  .pb-md-50,
  .py-md-50 {
    padding-bottom: 250px !important;
  }
  .pl-md-50,
  .px-md-50 {
    padding-left: 250px !important;
  }
  .p-md-51 {
    padding: 255px !important;
  }
  .pt-md-51,
  .py-md-51 {
    padding-top: 255px !important;
  }
  .pr-md-51,
  .px-md-51 {
    padding-right: 255px !important;
  }
  .pb-md-51,
  .py-md-51 {
    padding-bottom: 255px !important;
  }
  .pl-md-51,
  .px-md-51 {
    padding-left: 255px !important;
  }
  .p-md-52 {
    padding: 260px !important;
  }
  .pt-md-52,
  .py-md-52 {
    padding-top: 260px !important;
  }
  .pr-md-52,
  .px-md-52 {
    padding-right: 260px !important;
  }
  .pb-md-52,
  .py-md-52 {
    padding-bottom: 260px !important;
  }
  .pl-md-52,
  .px-md-52 {
    padding-left: 260px !important;
  }
  .p-md-53 {
    padding: 265px !important;
  }
  .pt-md-53,
  .py-md-53 {
    padding-top: 265px !important;
  }
  .pr-md-53,
  .px-md-53 {
    padding-right: 265px !important;
  }
  .pb-md-53,
  .py-md-53 {
    padding-bottom: 265px !important;
  }
  .pl-md-53,
  .px-md-53 {
    padding-left: 265px !important;
  }
  .p-md-54 {
    padding: 270px !important;
  }
  .pt-md-54,
  .py-md-54 {
    padding-top: 270px !important;
  }
  .pr-md-54,
  .px-md-54 {
    padding-right: 270px !important;
  }
  .pb-md-54,
  .py-md-54 {
    padding-bottom: 270px !important;
  }
  .pl-md-54,
  .px-md-54 {
    padding-left: 270px !important;
  }
  .p-md-55 {
    padding: 275px !important;
  }
  .pt-md-55,
  .py-md-55 {
    padding-top: 275px !important;
  }
  .pr-md-55,
  .px-md-55 {
    padding-right: 275px !important;
  }
  .pb-md-55,
  .py-md-55 {
    padding-bottom: 275px !important;
  }
  .pl-md-55,
  .px-md-55 {
    padding-left: 275px !important;
  }
  .p-md-56 {
    padding: 280px !important;
  }
  .pt-md-56,
  .py-md-56 {
    padding-top: 280px !important;
  }
  .pr-md-56,
  .px-md-56 {
    padding-right: 280px !important;
  }
  .pb-md-56,
  .py-md-56 {
    padding-bottom: 280px !important;
  }
  .pl-md-56,
  .px-md-56 {
    padding-left: 280px !important;
  }
  .p-md-57 {
    padding: 285px !important;
  }
  .pt-md-57,
  .py-md-57 {
    padding-top: 285px !important;
  }
  .pr-md-57,
  .px-md-57 {
    padding-right: 285px !important;
  }
  .pb-md-57,
  .py-md-57 {
    padding-bottom: 285px !important;
  }
  .pl-md-57,
  .px-md-57 {
    padding-left: 285px !important;
  }
  .p-md-58 {
    padding: 290px !important;
  }
  .pt-md-58,
  .py-md-58 {
    padding-top: 290px !important;
  }
  .pr-md-58,
  .px-md-58 {
    padding-right: 290px !important;
  }
  .pb-md-58,
  .py-md-58 {
    padding-bottom: 290px !important;
  }
  .pl-md-58,
  .px-md-58 {
    padding-left: 290px !important;
  }
  .p-md-59 {
    padding: 295px !important;
  }
  .pt-md-59,
  .py-md-59 {
    padding-top: 295px !important;
  }
  .pr-md-59,
  .px-md-59 {
    padding-right: 295px !important;
  }
  .pb-md-59,
  .py-md-59 {
    padding-bottom: 295px !important;
  }
  .pl-md-59,
  .px-md-59 {
    padding-left: 295px !important;
  }
  .p-md-60 {
    padding: 300px !important;
  }
  .pt-md-60,
  .py-md-60 {
    padding-top: 300px !important;
  }
  .pr-md-60,
  .px-md-60 {
    padding-right: 300px !important;
  }
  .pb-md-60,
  .py-md-60 {
    padding-bottom: 300px !important;
  }
  .pl-md-60,
  .px-md-60 {
    padding-left: 300px !important;
  }
  .m-md-n1 {
    margin: -5px !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -5px !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -5px !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -5px !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -5px !important;
  }
  .m-md-n2 {
    margin: -10px !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -10px !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -10px !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -10px !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -10px !important;
  }
  .m-md-n3 {
    margin: -16px !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -16px !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -16px !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -16px !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -16px !important;
  }
  .m-md-n4 {
    margin: -20px !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -20px !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -20px !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -20px !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -20px !important;
  }
  .m-md-n5 {
    margin: -25px !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -25px !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -25px !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -25px !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -25px !important;
  }
  .m-md-n6 {
    margin: -30px !important;
  }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -30px !important;
  }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -30px !important;
  }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -30px !important;
  }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -30px !important;
  }
  .m-md-n7 {
    margin: -35px !important;
  }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -35px !important;
  }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -35px !important;
  }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -35px !important;
  }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -35px !important;
  }
  .m-md-n8 {
    margin: -40px !important;
  }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -40px !important;
  }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -40px !important;
  }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -40px !important;
  }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -40px !important;
  }
  .m-md-n9 {
    margin: -45px !important;
  }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -45px !important;
  }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -45px !important;
  }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -45px !important;
  }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -45px !important;
  }
  .m-md-n10 {
    margin: -50px !important;
  }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -50px !important;
  }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -50px !important;
  }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -50px !important;
  }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -50px !important;
  }
  .m-md-n11 {
    margin: -55px !important;
  }
  .mt-md-n11,
  .my-md-n11 {
    margin-top: -55px !important;
  }
  .mr-md-n11,
  .mx-md-n11 {
    margin-right: -55px !important;
  }
  .mb-md-n11,
  .my-md-n11 {
    margin-bottom: -55px !important;
  }
  .ml-md-n11,
  .mx-md-n11 {
    margin-left: -55px !important;
  }
  .m-md-n12 {
    margin: -60px !important;
  }
  .mt-md-n12,
  .my-md-n12 {
    margin-top: -60px !important;
  }
  .mr-md-n12,
  .mx-md-n12 {
    margin-right: -60px !important;
  }
  .mb-md-n12,
  .my-md-n12 {
    margin-bottom: -60px !important;
  }
  .ml-md-n12,
  .mx-md-n12 {
    margin-left: -60px !important;
  }
  .m-md-n13 {
    margin: -65px !important;
  }
  .mt-md-n13,
  .my-md-n13 {
    margin-top: -65px !important;
  }
  .mr-md-n13,
  .mx-md-n13 {
    margin-right: -65px !important;
  }
  .mb-md-n13,
  .my-md-n13 {
    margin-bottom: -65px !important;
  }
  .ml-md-n13,
  .mx-md-n13 {
    margin-left: -65px !important;
  }
  .m-md-n14 {
    margin: -70px !important;
  }
  .mt-md-n14,
  .my-md-n14 {
    margin-top: -70px !important;
  }
  .mr-md-n14,
  .mx-md-n14 {
    margin-right: -70px !important;
  }
  .mb-md-n14,
  .my-md-n14 {
    margin-bottom: -70px !important;
  }
  .ml-md-n14,
  .mx-md-n14 {
    margin-left: -70px !important;
  }
  .m-md-n15 {
    margin: -75px !important;
  }
  .mt-md-n15,
  .my-md-n15 {
    margin-top: -75px !important;
  }
  .mr-md-n15,
  .mx-md-n15 {
    margin-right: -75px !important;
  }
  .mb-md-n15,
  .my-md-n15 {
    margin-bottom: -75px !important;
  }
  .ml-md-n15,
  .mx-md-n15 {
    margin-left: -75px !important;
  }
  .m-md-n16 {
    margin: -80px !important;
  }
  .mt-md-n16,
  .my-md-n16 {
    margin-top: -80px !important;
  }
  .mr-md-n16,
  .mx-md-n16 {
    margin-right: -80px !important;
  }
  .mb-md-n16,
  .my-md-n16 {
    margin-bottom: -80px !important;
  }
  .ml-md-n16,
  .mx-md-n16 {
    margin-left: -80px !important;
  }
  .m-md-n17 {
    margin: -85px !important;
  }
  .mt-md-n17,
  .my-md-n17 {
    margin-top: -85px !important;
  }
  .mr-md-n17,
  .mx-md-n17 {
    margin-right: -85px !important;
  }
  .mb-md-n17,
  .my-md-n17 {
    margin-bottom: -85px !important;
  }
  .ml-md-n17,
  .mx-md-n17 {
    margin-left: -85px !important;
  }
  .m-md-n18 {
    margin: -90px !important;
  }
  .mt-md-n18,
  .my-md-n18 {
    margin-top: -90px !important;
  }
  .mr-md-n18,
  .mx-md-n18 {
    margin-right: -90px !important;
  }
  .mb-md-n18,
  .my-md-n18 {
    margin-bottom: -90px !important;
  }
  .ml-md-n18,
  .mx-md-n18 {
    margin-left: -90px !important;
  }
  .m-md-n19 {
    margin: -95px !important;
  }
  .mt-md-n19,
  .my-md-n19 {
    margin-top: -95px !important;
  }
  .mr-md-n19,
  .mx-md-n19 {
    margin-right: -95px !important;
  }
  .mb-md-n19,
  .my-md-n19 {
    margin-bottom: -95px !important;
  }
  .ml-md-n19,
  .mx-md-n19 {
    margin-left: -95px !important;
  }
  .m-md-n20 {
    margin: -100px !important;
  }
  .mt-md-n20,
  .my-md-n20 {
    margin-top: -100px !important;
  }
  .mr-md-n20,
  .mx-md-n20 {
    margin-right: -100px !important;
  }
  .mb-md-n20,
  .my-md-n20 {
    margin-bottom: -100px !important;
  }
  .ml-md-n20,
  .mx-md-n20 {
    margin-left: -100px !important;
  }
  .m-md-n21 {
    margin: -105px !important;
  }
  .mt-md-n21,
  .my-md-n21 {
    margin-top: -105px !important;
  }
  .mr-md-n21,
  .mx-md-n21 {
    margin-right: -105px !important;
  }
  .mb-md-n21,
  .my-md-n21 {
    margin-bottom: -105px !important;
  }
  .ml-md-n21,
  .mx-md-n21 {
    margin-left: -105px !important;
  }
  .m-md-n22 {
    margin: -110px !important;
  }
  .mt-md-n22,
  .my-md-n22 {
    margin-top: -110px !important;
  }
  .mr-md-n22,
  .mx-md-n22 {
    margin-right: -110px !important;
  }
  .mb-md-n22,
  .my-md-n22 {
    margin-bottom: -110px !important;
  }
  .ml-md-n22,
  .mx-md-n22 {
    margin-left: -110px !important;
  }
  .m-md-n23 {
    margin: -115px !important;
  }
  .mt-md-n23,
  .my-md-n23 {
    margin-top: -115px !important;
  }
  .mr-md-n23,
  .mx-md-n23 {
    margin-right: -115px !important;
  }
  .mb-md-n23,
  .my-md-n23 {
    margin-bottom: -115px !important;
  }
  .ml-md-n23,
  .mx-md-n23 {
    margin-left: -115px !important;
  }
  .m-md-n24 {
    margin: -120px !important;
  }
  .mt-md-n24,
  .my-md-n24 {
    margin-top: -120px !important;
  }
  .mr-md-n24,
  .mx-md-n24 {
    margin-right: -120px !important;
  }
  .mb-md-n24,
  .my-md-n24 {
    margin-bottom: -120px !important;
  }
  .ml-md-n24,
  .mx-md-n24 {
    margin-left: -120px !important;
  }
  .m-md-n25 {
    margin: -125px !important;
  }
  .mt-md-n25,
  .my-md-n25 {
    margin-top: -125px !important;
  }
  .mr-md-n25,
  .mx-md-n25 {
    margin-right: -125px !important;
  }
  .mb-md-n25,
  .my-md-n25 {
    margin-bottom: -125px !important;
  }
  .ml-md-n25,
  .mx-md-n25 {
    margin-left: -125px !important;
  }
  .m-md-n26 {
    margin: -130px !important;
  }
  .mt-md-n26,
  .my-md-n26 {
    margin-top: -130px !important;
  }
  .mr-md-n26,
  .mx-md-n26 {
    margin-right: -130px !important;
  }
  .mb-md-n26,
  .my-md-n26 {
    margin-bottom: -130px !important;
  }
  .ml-md-n26,
  .mx-md-n26 {
    margin-left: -130px !important;
  }
  .m-md-n27 {
    margin: -135px !important;
  }
  .mt-md-n27,
  .my-md-n27 {
    margin-top: -135px !important;
  }
  .mr-md-n27,
  .mx-md-n27 {
    margin-right: -135px !important;
  }
  .mb-md-n27,
  .my-md-n27 {
    margin-bottom: -135px !important;
  }
  .ml-md-n27,
  .mx-md-n27 {
    margin-left: -135px !important;
  }
  .m-md-n28 {
    margin: -140px !important;
  }
  .mt-md-n28,
  .my-md-n28 {
    margin-top: -140px !important;
  }
  .mr-md-n28,
  .mx-md-n28 {
    margin-right: -140px !important;
  }
  .mb-md-n28,
  .my-md-n28 {
    margin-bottom: -140px !important;
  }
  .ml-md-n28,
  .mx-md-n28 {
    margin-left: -140px !important;
  }
  .m-md-n29 {
    margin: -145px !important;
  }
  .mt-md-n29,
  .my-md-n29 {
    margin-top: -145px !important;
  }
  .mr-md-n29,
  .mx-md-n29 {
    margin-right: -145px !important;
  }
  .mb-md-n29,
  .my-md-n29 {
    margin-bottom: -145px !important;
  }
  .ml-md-n29,
  .mx-md-n29 {
    margin-left: -145px !important;
  }
  .m-md-n30 {
    margin: -150px !important;
  }
  .mt-md-n30,
  .my-md-n30 {
    margin-top: -150px !important;
  }
  .mr-md-n30,
  .mx-md-n30 {
    margin-right: -150px !important;
  }
  .mb-md-n30,
  .my-md-n30 {
    margin-bottom: -150px !important;
  }
  .ml-md-n30,
  .mx-md-n30 {
    margin-left: -150px !important;
  }
  .m-md-n31 {
    margin: -155px !important;
  }
  .mt-md-n31,
  .my-md-n31 {
    margin-top: -155px !important;
  }
  .mr-md-n31,
  .mx-md-n31 {
    margin-right: -155px !important;
  }
  .mb-md-n31,
  .my-md-n31 {
    margin-bottom: -155px !important;
  }
  .ml-md-n31,
  .mx-md-n31 {
    margin-left: -155px !important;
  }
  .m-md-n32 {
    margin: -160px !important;
  }
  .mt-md-n32,
  .my-md-n32 {
    margin-top: -160px !important;
  }
  .mr-md-n32,
  .mx-md-n32 {
    margin-right: -160px !important;
  }
  .mb-md-n32,
  .my-md-n32 {
    margin-bottom: -160px !important;
  }
  .ml-md-n32,
  .mx-md-n32 {
    margin-left: -160px !important;
  }
  .m-md-n33 {
    margin: -165px !important;
  }
  .mt-md-n33,
  .my-md-n33 {
    margin-top: -165px !important;
  }
  .mr-md-n33,
  .mx-md-n33 {
    margin-right: -165px !important;
  }
  .mb-md-n33,
  .my-md-n33 {
    margin-bottom: -165px !important;
  }
  .ml-md-n33,
  .mx-md-n33 {
    margin-left: -165px !important;
  }
  .m-md-n34 {
    margin: -170px !important;
  }
  .mt-md-n34,
  .my-md-n34 {
    margin-top: -170px !important;
  }
  .mr-md-n34,
  .mx-md-n34 {
    margin-right: -170px !important;
  }
  .mb-md-n34,
  .my-md-n34 {
    margin-bottom: -170px !important;
  }
  .ml-md-n34,
  .mx-md-n34 {
    margin-left: -170px !important;
  }
  .m-md-n35 {
    margin: -175px !important;
  }
  .mt-md-n35,
  .my-md-n35 {
    margin-top: -175px !important;
  }
  .mr-md-n35,
  .mx-md-n35 {
    margin-right: -175px !important;
  }
  .mb-md-n35,
  .my-md-n35 {
    margin-bottom: -175px !important;
  }
  .ml-md-n35,
  .mx-md-n35 {
    margin-left: -175px !important;
  }
  .m-md-n36 {
    margin: -180px !important;
  }
  .mt-md-n36,
  .my-md-n36 {
    margin-top: -180px !important;
  }
  .mr-md-n36,
  .mx-md-n36 {
    margin-right: -180px !important;
  }
  .mb-md-n36,
  .my-md-n36 {
    margin-bottom: -180px !important;
  }
  .ml-md-n36,
  .mx-md-n36 {
    margin-left: -180px !important;
  }
  .m-md-n37 {
    margin: -185px !important;
  }
  .mt-md-n37,
  .my-md-n37 {
    margin-top: -185px !important;
  }
  .mr-md-n37,
  .mx-md-n37 {
    margin-right: -185px !important;
  }
  .mb-md-n37,
  .my-md-n37 {
    margin-bottom: -185px !important;
  }
  .ml-md-n37,
  .mx-md-n37 {
    margin-left: -185px !important;
  }
  .m-md-n38 {
    margin: -190px !important;
  }
  .mt-md-n38,
  .my-md-n38 {
    margin-top: -190px !important;
  }
  .mr-md-n38,
  .mx-md-n38 {
    margin-right: -190px !important;
  }
  .mb-md-n38,
  .my-md-n38 {
    margin-bottom: -190px !important;
  }
  .ml-md-n38,
  .mx-md-n38 {
    margin-left: -190px !important;
  }
  .m-md-n39 {
    margin: -195px !important;
  }
  .mt-md-n39,
  .my-md-n39 {
    margin-top: -195px !important;
  }
  .mr-md-n39,
  .mx-md-n39 {
    margin-right: -195px !important;
  }
  .mb-md-n39,
  .my-md-n39 {
    margin-bottom: -195px !important;
  }
  .ml-md-n39,
  .mx-md-n39 {
    margin-left: -195px !important;
  }
  .m-md-n40 {
    margin: -200px !important;
  }
  .mt-md-n40,
  .my-md-n40 {
    margin-top: -200px !important;
  }
  .mr-md-n40,
  .mx-md-n40 {
    margin-right: -200px !important;
  }
  .mb-md-n40,
  .my-md-n40 {
    margin-bottom: -200px !important;
  }
  .ml-md-n40,
  .mx-md-n40 {
    margin-left: -200px !important;
  }
  .m-md-n41 {
    margin: -205px !important;
  }
  .mt-md-n41,
  .my-md-n41 {
    margin-top: -205px !important;
  }
  .mr-md-n41,
  .mx-md-n41 {
    margin-right: -205px !important;
  }
  .mb-md-n41,
  .my-md-n41 {
    margin-bottom: -205px !important;
  }
  .ml-md-n41,
  .mx-md-n41 {
    margin-left: -205px !important;
  }
  .m-md-n42 {
    margin: -210px !important;
  }
  .mt-md-n42,
  .my-md-n42 {
    margin-top: -210px !important;
  }
  .mr-md-n42,
  .mx-md-n42 {
    margin-right: -210px !important;
  }
  .mb-md-n42,
  .my-md-n42 {
    margin-bottom: -210px !important;
  }
  .ml-md-n42,
  .mx-md-n42 {
    margin-left: -210px !important;
  }
  .m-md-n43 {
    margin: -215px !important;
  }
  .mt-md-n43,
  .my-md-n43 {
    margin-top: -215px !important;
  }
  .mr-md-n43,
  .mx-md-n43 {
    margin-right: -215px !important;
  }
  .mb-md-n43,
  .my-md-n43 {
    margin-bottom: -215px !important;
  }
  .ml-md-n43,
  .mx-md-n43 {
    margin-left: -215px !important;
  }
  .m-md-n44 {
    margin: -220px !important;
  }
  .mt-md-n44,
  .my-md-n44 {
    margin-top: -220px !important;
  }
  .mr-md-n44,
  .mx-md-n44 {
    margin-right: -220px !important;
  }
  .mb-md-n44,
  .my-md-n44 {
    margin-bottom: -220px !important;
  }
  .ml-md-n44,
  .mx-md-n44 {
    margin-left: -220px !important;
  }
  .m-md-n45 {
    margin: -225px !important;
  }
  .mt-md-n45,
  .my-md-n45 {
    margin-top: -225px !important;
  }
  .mr-md-n45,
  .mx-md-n45 {
    margin-right: -225px !important;
  }
  .mb-md-n45,
  .my-md-n45 {
    margin-bottom: -225px !important;
  }
  .ml-md-n45,
  .mx-md-n45 {
    margin-left: -225px !important;
  }
  .m-md-n46 {
    margin: -230px !important;
  }
  .mt-md-n46,
  .my-md-n46 {
    margin-top: -230px !important;
  }
  .mr-md-n46,
  .mx-md-n46 {
    margin-right: -230px !important;
  }
  .mb-md-n46,
  .my-md-n46 {
    margin-bottom: -230px !important;
  }
  .ml-md-n46,
  .mx-md-n46 {
    margin-left: -230px !important;
  }
  .m-md-n47 {
    margin: -235px !important;
  }
  .mt-md-n47,
  .my-md-n47 {
    margin-top: -235px !important;
  }
  .mr-md-n47,
  .mx-md-n47 {
    margin-right: -235px !important;
  }
  .mb-md-n47,
  .my-md-n47 {
    margin-bottom: -235px !important;
  }
  .ml-md-n47,
  .mx-md-n47 {
    margin-left: -235px !important;
  }
  .m-md-n48 {
    margin: -240px !important;
  }
  .mt-md-n48,
  .my-md-n48 {
    margin-top: -240px !important;
  }
  .mr-md-n48,
  .mx-md-n48 {
    margin-right: -240px !important;
  }
  .mb-md-n48,
  .my-md-n48 {
    margin-bottom: -240px !important;
  }
  .ml-md-n48,
  .mx-md-n48 {
    margin-left: -240px !important;
  }
  .m-md-n49 {
    margin: -245px !important;
  }
  .mt-md-n49,
  .my-md-n49 {
    margin-top: -245px !important;
  }
  .mr-md-n49,
  .mx-md-n49 {
    margin-right: -245px !important;
  }
  .mb-md-n49,
  .my-md-n49 {
    margin-bottom: -245px !important;
  }
  .ml-md-n49,
  .mx-md-n49 {
    margin-left: -245px !important;
  }
  .m-md-n50 {
    margin: -250px !important;
  }
  .mt-md-n50,
  .my-md-n50 {
    margin-top: -250px !important;
  }
  .mr-md-n50,
  .mx-md-n50 {
    margin-right: -250px !important;
  }
  .mb-md-n50,
  .my-md-n50 {
    margin-bottom: -250px !important;
  }
  .ml-md-n50,
  .mx-md-n50 {
    margin-left: -250px !important;
  }
  .m-md-n51 {
    margin: -255px !important;
  }
  .mt-md-n51,
  .my-md-n51 {
    margin-top: -255px !important;
  }
  .mr-md-n51,
  .mx-md-n51 {
    margin-right: -255px !important;
  }
  .mb-md-n51,
  .my-md-n51 {
    margin-bottom: -255px !important;
  }
  .ml-md-n51,
  .mx-md-n51 {
    margin-left: -255px !important;
  }
  .m-md-n52 {
    margin: -260px !important;
  }
  .mt-md-n52,
  .my-md-n52 {
    margin-top: -260px !important;
  }
  .mr-md-n52,
  .mx-md-n52 {
    margin-right: -260px !important;
  }
  .mb-md-n52,
  .my-md-n52 {
    margin-bottom: -260px !important;
  }
  .ml-md-n52,
  .mx-md-n52 {
    margin-left: -260px !important;
  }
  .m-md-n53 {
    margin: -265px !important;
  }
  .mt-md-n53,
  .my-md-n53 {
    margin-top: -265px !important;
  }
  .mr-md-n53,
  .mx-md-n53 {
    margin-right: -265px !important;
  }
  .mb-md-n53,
  .my-md-n53 {
    margin-bottom: -265px !important;
  }
  .ml-md-n53,
  .mx-md-n53 {
    margin-left: -265px !important;
  }
  .m-md-n54 {
    margin: -270px !important;
  }
  .mt-md-n54,
  .my-md-n54 {
    margin-top: -270px !important;
  }
  .mr-md-n54,
  .mx-md-n54 {
    margin-right: -270px !important;
  }
  .mb-md-n54,
  .my-md-n54 {
    margin-bottom: -270px !important;
  }
  .ml-md-n54,
  .mx-md-n54 {
    margin-left: -270px !important;
  }
  .m-md-n55 {
    margin: -275px !important;
  }
  .mt-md-n55,
  .my-md-n55 {
    margin-top: -275px !important;
  }
  .mr-md-n55,
  .mx-md-n55 {
    margin-right: -275px !important;
  }
  .mb-md-n55,
  .my-md-n55 {
    margin-bottom: -275px !important;
  }
  .ml-md-n55,
  .mx-md-n55 {
    margin-left: -275px !important;
  }
  .m-md-n56 {
    margin: -280px !important;
  }
  .mt-md-n56,
  .my-md-n56 {
    margin-top: -280px !important;
  }
  .mr-md-n56,
  .mx-md-n56 {
    margin-right: -280px !important;
  }
  .mb-md-n56,
  .my-md-n56 {
    margin-bottom: -280px !important;
  }
  .ml-md-n56,
  .mx-md-n56 {
    margin-left: -280px !important;
  }
  .m-md-n57 {
    margin: -285px !important;
  }
  .mt-md-n57,
  .my-md-n57 {
    margin-top: -285px !important;
  }
  .mr-md-n57,
  .mx-md-n57 {
    margin-right: -285px !important;
  }
  .mb-md-n57,
  .my-md-n57 {
    margin-bottom: -285px !important;
  }
  .ml-md-n57,
  .mx-md-n57 {
    margin-left: -285px !important;
  }
  .m-md-n58 {
    margin: -290px !important;
  }
  .mt-md-n58,
  .my-md-n58 {
    margin-top: -290px !important;
  }
  .mr-md-n58,
  .mx-md-n58 {
    margin-right: -290px !important;
  }
  .mb-md-n58,
  .my-md-n58 {
    margin-bottom: -290px !important;
  }
  .ml-md-n58,
  .mx-md-n58 {
    margin-left: -290px !important;
  }
  .m-md-n59 {
    margin: -295px !important;
  }
  .mt-md-n59,
  .my-md-n59 {
    margin-top: -295px !important;
  }
  .mr-md-n59,
  .mx-md-n59 {
    margin-right: -295px !important;
  }
  .mb-md-n59,
  .my-md-n59 {
    margin-bottom: -295px !important;
  }
  .ml-md-n59,
  .mx-md-n59 {
    margin-left: -295px !important;
  }
  .m-md-n60 {
    margin: -300px !important;
  }
  .mt-md-n60,
  .my-md-n60 {
    margin-top: -300px !important;
  }
  .mr-md-n60,
  .mx-md-n60 {
    margin-right: -300px !important;
  }
  .mb-md-n60,
  .my-md-n60 {
    margin-bottom: -300px !important;
  }
  .ml-md-n60,
  .mx-md-n60 {
    margin-left: -300px !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 5px !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 5px !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 5px !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 5px !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 5px !important;
  }
  .m-lg-2 {
    margin: 10px !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 10px !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 10px !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 10px !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 10px !important;
  }
  .m-lg-3 {
    margin: 16px !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 16px !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 16px !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 16px !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 16px !important;
  }
  .m-lg-4 {
    margin: 20px !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 20px !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 20px !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 20px !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 20px !important;
  }
  .m-lg-5 {
    margin: 25px !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 25px !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 25px !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 25px !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 25px !important;
  }
  .m-lg-6 {
    margin: 30px !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 30px !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 30px !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 30px !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 30px !important;
  }
  .m-lg-7 {
    margin: 35px !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 35px !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 35px !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 35px !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 35px !important;
  }
  .m-lg-8 {
    margin: 40px !important;
  }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 40px !important;
  }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 40px !important;
  }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 40px !important;
  }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 40px !important;
  }
  .m-lg-9 {
    margin: 45px !important;
  }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 45px !important;
  }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 45px !important;
  }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 45px !important;
  }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 45px !important;
  }
  .m-lg-10 {
    margin: 50px !important;
  }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 50px !important;
  }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 50px !important;
  }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 50px !important;
  }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 50px !important;
  }
  .m-lg-11 {
    margin: 55px !important;
  }
  .mt-lg-11,
  .my-lg-11 {
    margin-top: 55px !important;
  }
  .mr-lg-11,
  .mx-lg-11 {
    margin-right: 55px !important;
  }
  .mb-lg-11,
  .my-lg-11 {
    margin-bottom: 55px !important;
  }
  .ml-lg-11,
  .mx-lg-11 {
    margin-left: 55px !important;
  }
  .m-lg-12 {
    margin: 60px !important;
  }
  .mt-lg-12,
  .my-lg-12 {
    margin-top: 60px !important;
  }
  .mr-lg-12,
  .mx-lg-12 {
    margin-right: 60px !important;
  }
  .mb-lg-12,
  .my-lg-12 {
    margin-bottom: 60px !important;
  }
  .ml-lg-12,
  .mx-lg-12 {
    margin-left: 60px !important;
  }
  .m-lg-13 {
    margin: 65px !important;
  }
  .mt-lg-13,
  .my-lg-13 {
    margin-top: 65px !important;
  }
  .mr-lg-13,
  .mx-lg-13 {
    margin-right: 65px !important;
  }
  .mb-lg-13,
  .my-lg-13 {
    margin-bottom: 65px !important;
  }
  .ml-lg-13,
  .mx-lg-13 {
    margin-left: 65px !important;
  }
  .m-lg-14 {
    margin: 70px !important;
  }
  .mt-lg-14,
  .my-lg-14 {
    margin-top: 70px !important;
  }
  .mr-lg-14,
  .mx-lg-14 {
    margin-right: 70px !important;
  }
  .mb-lg-14,
  .my-lg-14 {
    margin-bottom: 70px !important;
  }
  .ml-lg-14,
  .mx-lg-14 {
    margin-left: 70px !important;
  }
  .m-lg-15 {
    margin: 75px !important;
  }
  .mt-lg-15,
  .my-lg-15 {
    margin-top: 75px !important;
  }
  .mr-lg-15,
  .mx-lg-15 {
    margin-right: 75px !important;
  }
  .mb-lg-15,
  .my-lg-15 {
    margin-bottom: 75px !important;
  }
  .ml-lg-15,
  .mx-lg-15 {
    margin-left: 75px !important;
  }
  .m-lg-16 {
    margin: 80px !important;
  }
  .mt-lg-16,
  .my-lg-16 {
    margin-top: 80px !important;
  }
  .mr-lg-16,
  .mx-lg-16 {
    margin-right: 80px !important;
  }
  .mb-lg-16,
  .my-lg-16 {
    margin-bottom: 80px !important;
  }
  .ml-lg-16,
  .mx-lg-16 {
    margin-left: 80px !important;
  }
  .m-lg-17 {
    margin: 85px !important;
  }
  .mt-lg-17,
  .my-lg-17 {
    margin-top: 85px !important;
  }
  .mr-lg-17,
  .mx-lg-17 {
    margin-right: 85px !important;
  }
  .mb-lg-17,
  .my-lg-17 {
    margin-bottom: 85px !important;
  }
  .ml-lg-17,
  .mx-lg-17 {
    margin-left: 85px !important;
  }
  .m-lg-18 {
    margin: 90px !important;
  }
  .mt-lg-18,
  .my-lg-18 {
    margin-top: 90px !important;
  }
  .mr-lg-18,
  .mx-lg-18 {
    margin-right: 90px !important;
  }
  .mb-lg-18,
  .my-lg-18 {
    margin-bottom: 90px !important;
  }
  .ml-lg-18,
  .mx-lg-18 {
    margin-left: 90px !important;
  }
  .m-lg-19 {
    margin: 95px !important;
  }
  .mt-lg-19,
  .my-lg-19 {
    margin-top: 95px !important;
  }
  .mr-lg-19,
  .mx-lg-19 {
    margin-right: 95px !important;
  }
  .mb-lg-19,
  .my-lg-19 {
    margin-bottom: 95px !important;
  }
  .ml-lg-19,
  .mx-lg-19 {
    margin-left: 95px !important;
  }
  .m-lg-20 {
    margin: 100px !important;
  }
  .mt-lg-20,
  .my-lg-20 {
    margin-top: 100px !important;
  }
  .mr-lg-20,
  .mx-lg-20 {
    margin-right: 100px !important;
  }
  .mb-lg-20,
  .my-lg-20 {
    margin-bottom: 100px !important;
  }
  .ml-lg-20,
  .mx-lg-20 {
    margin-left: 100px !important;
  }
  .m-lg-21 {
    margin: 105px !important;
  }
  .mt-lg-21,
  .my-lg-21 {
    margin-top: 105px !important;
  }
  .mr-lg-21,
  .mx-lg-21 {
    margin-right: 105px !important;
  }
  .mb-lg-21,
  .my-lg-21 {
    margin-bottom: 105px !important;
  }
  .ml-lg-21,
  .mx-lg-21 {
    margin-left: 105px !important;
  }
  .m-lg-22 {
    margin: 110px !important;
  }
  .mt-lg-22,
  .my-lg-22 {
    margin-top: 110px !important;
  }
  .mr-lg-22,
  .mx-lg-22 {
    margin-right: 110px !important;
  }
  .mb-lg-22,
  .my-lg-22 {
    margin-bottom: 110px !important;
  }
  .ml-lg-22,
  .mx-lg-22 {
    margin-left: 110px !important;
  }
  .m-lg-23 {
    margin: 115px !important;
  }
  .mt-lg-23,
  .my-lg-23 {
    margin-top: 115px !important;
  }
  .mr-lg-23,
  .mx-lg-23 {
    margin-right: 115px !important;
  }
  .mb-lg-23,
  .my-lg-23 {
    margin-bottom: 115px !important;
  }
  .ml-lg-23,
  .mx-lg-23 {
    margin-left: 115px !important;
  }
  .m-lg-24 {
    margin: 120px !important;
  }
  .mt-lg-24,
  .my-lg-24 {
    margin-top: 120px !important;
  }
  .mr-lg-24,
  .mx-lg-24 {
    margin-right: 120px !important;
  }
  .mb-lg-24,
  .my-lg-24 {
    margin-bottom: 120px !important;
  }
  .ml-lg-24,
  .mx-lg-24 {
    margin-left: 120px !important;
  }
  .m-lg-25 {
    margin: 125px !important;
  }
  .mt-lg-25,
  .my-lg-25 {
    margin-top: 125px !important;
  }
  .mr-lg-25,
  .mx-lg-25 {
    margin-right: 125px !important;
  }
  .mb-lg-25,
  .my-lg-25 {
    margin-bottom: 125px !important;
  }
  .ml-lg-25,
  .mx-lg-25 {
    margin-left: 125px !important;
  }
  .m-lg-26 {
    margin: 130px !important;
  }
  .mt-lg-26,
  .my-lg-26 {
    margin-top: 130px !important;
  }
  .mr-lg-26,
  .mx-lg-26 {
    margin-right: 130px !important;
  }
  .mb-lg-26,
  .my-lg-26 {
    margin-bottom: 130px !important;
  }
  .ml-lg-26,
  .mx-lg-26 {
    margin-left: 130px !important;
  }
  .m-lg-27 {
    margin: 135px !important;
  }
  .mt-lg-27,
  .my-lg-27 {
    margin-top: 135px !important;
  }
  .mr-lg-27,
  .mx-lg-27 {
    margin-right: 135px !important;
  }
  .mb-lg-27,
  .my-lg-27 {
    margin-bottom: 135px !important;
  }
  .ml-lg-27,
  .mx-lg-27 {
    margin-left: 135px !important;
  }
  .m-lg-28 {
    margin: 140px !important;
  }
  .mt-lg-28,
  .my-lg-28 {
    margin-top: 140px !important;
  }
  .mr-lg-28,
  .mx-lg-28 {
    margin-right: 140px !important;
  }
  .mb-lg-28,
  .my-lg-28 {
    margin-bottom: 140px !important;
  }
  .ml-lg-28,
  .mx-lg-28 {
    margin-left: 140px !important;
  }
  .m-lg-29 {
    margin: 145px !important;
  }
  .mt-lg-29,
  .my-lg-29 {
    margin-top: 145px !important;
  }
  .mr-lg-29,
  .mx-lg-29 {
    margin-right: 145px !important;
  }
  .mb-lg-29,
  .my-lg-29 {
    margin-bottom: 145px !important;
  }
  .ml-lg-29,
  .mx-lg-29 {
    margin-left: 145px !important;
  }
  .m-lg-30 {
    margin: 150px !important;
  }
  .mt-lg-30,
  .my-lg-30 {
    margin-top: 150px !important;
  }
  .mr-lg-30,
  .mx-lg-30 {
    margin-right: 150px !important;
  }
  .mb-lg-30,
  .my-lg-30 {
    margin-bottom: 150px !important;
  }
  .ml-lg-30,
  .mx-lg-30 {
    margin-left: 150px !important;
  }
  .m-lg-31 {
    margin: 155px !important;
  }
  .mt-lg-31,
  .my-lg-31 {
    margin-top: 155px !important;
  }
  .mr-lg-31,
  .mx-lg-31 {
    margin-right: 155px !important;
  }
  .mb-lg-31,
  .my-lg-31 {
    margin-bottom: 155px !important;
  }
  .ml-lg-31,
  .mx-lg-31 {
    margin-left: 155px !important;
  }
  .m-lg-32 {
    margin: 160px !important;
  }
  .mt-lg-32,
  .my-lg-32 {
    margin-top: 160px !important;
  }
  .mr-lg-32,
  .mx-lg-32 {
    margin-right: 160px !important;
  }
  .mb-lg-32,
  .my-lg-32 {
    margin-bottom: 160px !important;
  }
  .ml-lg-32,
  .mx-lg-32 {
    margin-left: 160px !important;
  }
  .m-lg-33 {
    margin: 165px !important;
  }
  .mt-lg-33,
  .my-lg-33 {
    margin-top: 165px !important;
  }
  .mr-lg-33,
  .mx-lg-33 {
    margin-right: 165px !important;
  }
  .mb-lg-33,
  .my-lg-33 {
    margin-bottom: 165px !important;
  }
  .ml-lg-33,
  .mx-lg-33 {
    margin-left: 165px !important;
  }
  .m-lg-34 {
    margin: 170px !important;
  }
  .mt-lg-34,
  .my-lg-34 {
    margin-top: 170px !important;
  }
  .mr-lg-34,
  .mx-lg-34 {
    margin-right: 170px !important;
  }
  .mb-lg-34,
  .my-lg-34 {
    margin-bottom: 170px !important;
  }
  .ml-lg-34,
  .mx-lg-34 {
    margin-left: 170px !important;
  }
  .m-lg-35 {
    margin: 175px !important;
  }
  .mt-lg-35,
  .my-lg-35 {
    margin-top: 175px !important;
  }
  .mr-lg-35,
  .mx-lg-35 {
    margin-right: 175px !important;
  }
  .mb-lg-35,
  .my-lg-35 {
    margin-bottom: 175px !important;
  }
  .ml-lg-35,
  .mx-lg-35 {
    margin-left: 175px !important;
  }
  .m-lg-36 {
    margin: 180px !important;
  }
  .mt-lg-36,
  .my-lg-36 {
    margin-top: 180px !important;
  }
  .mr-lg-36,
  .mx-lg-36 {
    margin-right: 180px !important;
  }
  .mb-lg-36,
  .my-lg-36 {
    margin-bottom: 180px !important;
  }
  .ml-lg-36,
  .mx-lg-36 {
    margin-left: 180px !important;
  }
  .m-lg-37 {
    margin: 185px !important;
  }
  .mt-lg-37,
  .my-lg-37 {
    margin-top: 185px !important;
  }
  .mr-lg-37,
  .mx-lg-37 {
    margin-right: 185px !important;
  }
  .mb-lg-37,
  .my-lg-37 {
    margin-bottom: 185px !important;
  }
  .ml-lg-37,
  .mx-lg-37 {
    margin-left: 185px !important;
  }
  .m-lg-38 {
    margin: 190px !important;
  }
  .mt-lg-38,
  .my-lg-38 {
    margin-top: 190px !important;
  }
  .mr-lg-38,
  .mx-lg-38 {
    margin-right: 190px !important;
  }
  .mb-lg-38,
  .my-lg-38 {
    margin-bottom: 190px !important;
  }
  .ml-lg-38,
  .mx-lg-38 {
    margin-left: 190px !important;
  }
  .m-lg-39 {
    margin: 195px !important;
  }
  .mt-lg-39,
  .my-lg-39 {
    margin-top: 195px !important;
  }
  .mr-lg-39,
  .mx-lg-39 {
    margin-right: 195px !important;
  }
  .mb-lg-39,
  .my-lg-39 {
    margin-bottom: 195px !important;
  }
  .ml-lg-39,
  .mx-lg-39 {
    margin-left: 195px !important;
  }
  .m-lg-40 {
    margin: 200px !important;
  }
  .mt-lg-40,
  .my-lg-40 {
    margin-top: 200px !important;
  }
  .mr-lg-40,
  .mx-lg-40 {
    margin-right: 200px !important;
  }
  .mb-lg-40,
  .my-lg-40 {
    margin-bottom: 200px !important;
  }
  .ml-lg-40,
  .mx-lg-40 {
    margin-left: 200px !important;
  }
  .m-lg-41 {
    margin: 205px !important;
  }
  .mt-lg-41,
  .my-lg-41 {
    margin-top: 205px !important;
  }
  .mr-lg-41,
  .mx-lg-41 {
    margin-right: 205px !important;
  }
  .mb-lg-41,
  .my-lg-41 {
    margin-bottom: 205px !important;
  }
  .ml-lg-41,
  .mx-lg-41 {
    margin-left: 205px !important;
  }
  .m-lg-42 {
    margin: 210px !important;
  }
  .mt-lg-42,
  .my-lg-42 {
    margin-top: 210px !important;
  }
  .mr-lg-42,
  .mx-lg-42 {
    margin-right: 210px !important;
  }
  .mb-lg-42,
  .my-lg-42 {
    margin-bottom: 210px !important;
  }
  .ml-lg-42,
  .mx-lg-42 {
    margin-left: 210px !important;
  }
  .m-lg-43 {
    margin: 215px !important;
  }
  .mt-lg-43,
  .my-lg-43 {
    margin-top: 215px !important;
  }
  .mr-lg-43,
  .mx-lg-43 {
    margin-right: 215px !important;
  }
  .mb-lg-43,
  .my-lg-43 {
    margin-bottom: 215px !important;
  }
  .ml-lg-43,
  .mx-lg-43 {
    margin-left: 215px !important;
  }
  .m-lg-44 {
    margin: 220px !important;
  }
  .mt-lg-44,
  .my-lg-44 {
    margin-top: 220px !important;
  }
  .mr-lg-44,
  .mx-lg-44 {
    margin-right: 220px !important;
  }
  .mb-lg-44,
  .my-lg-44 {
    margin-bottom: 220px !important;
  }
  .ml-lg-44,
  .mx-lg-44 {
    margin-left: 220px !important;
  }
  .m-lg-45 {
    margin: 225px !important;
  }
  .mt-lg-45,
  .my-lg-45 {
    margin-top: 225px !important;
  }
  .mr-lg-45,
  .mx-lg-45 {
    margin-right: 225px !important;
  }
  .mb-lg-45,
  .my-lg-45 {
    margin-bottom: 225px !important;
  }
  .ml-lg-45,
  .mx-lg-45 {
    margin-left: 225px !important;
  }
  .m-lg-46 {
    margin: 230px !important;
  }
  .mt-lg-46,
  .my-lg-46 {
    margin-top: 230px !important;
  }
  .mr-lg-46,
  .mx-lg-46 {
    margin-right: 230px !important;
  }
  .mb-lg-46,
  .my-lg-46 {
    margin-bottom: 230px !important;
  }
  .ml-lg-46,
  .mx-lg-46 {
    margin-left: 230px !important;
  }
  .m-lg-47 {
    margin: 235px !important;
  }
  .mt-lg-47,
  .my-lg-47 {
    margin-top: 235px !important;
  }
  .mr-lg-47,
  .mx-lg-47 {
    margin-right: 235px !important;
  }
  .mb-lg-47,
  .my-lg-47 {
    margin-bottom: 235px !important;
  }
  .ml-lg-47,
  .mx-lg-47 {
    margin-left: 235px !important;
  }
  .m-lg-48 {
    margin: 240px !important;
  }
  .mt-lg-48,
  .my-lg-48 {
    margin-top: 240px !important;
  }
  .mr-lg-48,
  .mx-lg-48 {
    margin-right: 240px !important;
  }
  .mb-lg-48,
  .my-lg-48 {
    margin-bottom: 240px !important;
  }
  .ml-lg-48,
  .mx-lg-48 {
    margin-left: 240px !important;
  }
  .m-lg-49 {
    margin: 245px !important;
  }
  .mt-lg-49,
  .my-lg-49 {
    margin-top: 245px !important;
  }
  .mr-lg-49,
  .mx-lg-49 {
    margin-right: 245px !important;
  }
  .mb-lg-49,
  .my-lg-49 {
    margin-bottom: 245px !important;
  }
  .ml-lg-49,
  .mx-lg-49 {
    margin-left: 245px !important;
  }
  .m-lg-50 {
    margin: 250px !important;
  }
  .mt-lg-50,
  .my-lg-50 {
    margin-top: 250px !important;
  }
  .mr-lg-50,
  .mx-lg-50 {
    margin-right: 250px !important;
  }
  .mb-lg-50,
  .my-lg-50 {
    margin-bottom: 250px !important;
  }
  .ml-lg-50,
  .mx-lg-50 {
    margin-left: 250px !important;
  }
  .m-lg-51 {
    margin: 255px !important;
  }
  .mt-lg-51,
  .my-lg-51 {
    margin-top: 255px !important;
  }
  .mr-lg-51,
  .mx-lg-51 {
    margin-right: 255px !important;
  }
  .mb-lg-51,
  .my-lg-51 {
    margin-bottom: 255px !important;
  }
  .ml-lg-51,
  .mx-lg-51 {
    margin-left: 255px !important;
  }
  .m-lg-52 {
    margin: 260px !important;
  }
  .mt-lg-52,
  .my-lg-52 {
    margin-top: 260px !important;
  }
  .mr-lg-52,
  .mx-lg-52 {
    margin-right: 260px !important;
  }
  .mb-lg-52,
  .my-lg-52 {
    margin-bottom: 260px !important;
  }
  .ml-lg-52,
  .mx-lg-52 {
    margin-left: 260px !important;
  }
  .m-lg-53 {
    margin: 265px !important;
  }
  .mt-lg-53,
  .my-lg-53 {
    margin-top: 265px !important;
  }
  .mr-lg-53,
  .mx-lg-53 {
    margin-right: 265px !important;
  }
  .mb-lg-53,
  .my-lg-53 {
    margin-bottom: 265px !important;
  }
  .ml-lg-53,
  .mx-lg-53 {
    margin-left: 265px !important;
  }
  .m-lg-54 {
    margin: 270px !important;
  }
  .mt-lg-54,
  .my-lg-54 {
    margin-top: 270px !important;
  }
  .mr-lg-54,
  .mx-lg-54 {
    margin-right: 270px !important;
  }
  .mb-lg-54,
  .my-lg-54 {
    margin-bottom: 270px !important;
  }
  .ml-lg-54,
  .mx-lg-54 {
    margin-left: 270px !important;
  }
  .m-lg-55 {
    margin: 275px !important;
  }
  .mt-lg-55,
  .my-lg-55 {
    margin-top: 275px !important;
  }
  .mr-lg-55,
  .mx-lg-55 {
    margin-right: 275px !important;
  }
  .mb-lg-55,
  .my-lg-55 {
    margin-bottom: 275px !important;
  }
  .ml-lg-55,
  .mx-lg-55 {
    margin-left: 275px !important;
  }
  .m-lg-56 {
    margin: 280px !important;
  }
  .mt-lg-56,
  .my-lg-56 {
    margin-top: 280px !important;
  }
  .mr-lg-56,
  .mx-lg-56 {
    margin-right: 280px !important;
  }
  .mb-lg-56,
  .my-lg-56 {
    margin-bottom: 280px !important;
  }
  .ml-lg-56,
  .mx-lg-56 {
    margin-left: 280px !important;
  }
  .m-lg-57 {
    margin: 285px !important;
  }
  .mt-lg-57,
  .my-lg-57 {
    margin-top: 285px !important;
  }
  .mr-lg-57,
  .mx-lg-57 {
    margin-right: 285px !important;
  }
  .mb-lg-57,
  .my-lg-57 {
    margin-bottom: 285px !important;
  }
  .ml-lg-57,
  .mx-lg-57 {
    margin-left: 285px !important;
  }
  .m-lg-58 {
    margin: 290px !important;
  }
  .mt-lg-58,
  .my-lg-58 {
    margin-top: 290px !important;
  }
  .mr-lg-58,
  .mx-lg-58 {
    margin-right: 290px !important;
  }
  .mb-lg-58,
  .my-lg-58 {
    margin-bottom: 290px !important;
  }
  .ml-lg-58,
  .mx-lg-58 {
    margin-left: 290px !important;
  }
  .m-lg-59 {
    margin: 295px !important;
  }
  .mt-lg-59,
  .my-lg-59 {
    margin-top: 295px !important;
  }
  .mr-lg-59,
  .mx-lg-59 {
    margin-right: 295px !important;
  }
  .mb-lg-59,
  .my-lg-59 {
    margin-bottom: 295px !important;
  }
  .ml-lg-59,
  .mx-lg-59 {
    margin-left: 295px !important;
  }
  .m-lg-60 {
    margin: 300px !important;
  }
  .mt-lg-60,
  .my-lg-60 {
    margin-top: 300px !important;
  }
  .mr-lg-60,
  .mx-lg-60 {
    margin-right: 300px !important;
  }
  .mb-lg-60,
  .my-lg-60 {
    margin-bottom: 300px !important;
  }
  .ml-lg-60,
  .mx-lg-60 {
    margin-left: 300px !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 5px !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 5px !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 5px !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 5px !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 5px !important;
  }
  .p-lg-2 {
    padding: 10px !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 10px !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 10px !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 10px !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 10px !important;
  }
  .p-lg-3 {
    padding: 16px !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 16px !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 16px !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 16px !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 16px !important;
  }
  .p-lg-4 {
    padding: 20px !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 20px !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 20px !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 20px !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 20px !important;
  }
  .p-lg-5 {
    padding: 25px !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 25px !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 25px !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 25px !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 25px !important;
  }
  .p-lg-6 {
    padding: 30px !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 30px !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 30px !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 30px !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 30px !important;
  }
  .p-lg-7 {
    padding: 35px !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 35px !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 35px !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 35px !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 35px !important;
  }
  .p-lg-8 {
    padding: 40px !important;
  }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 40px !important;
  }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 40px !important;
  }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 40px !important;
  }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 40px !important;
  }
  .p-lg-9 {
    padding: 45px !important;
  }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 45px !important;
  }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 45px !important;
  }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 45px !important;
  }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 45px !important;
  }
  .p-lg-10 {
    padding: 50px !important;
  }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 50px !important;
  }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 50px !important;
  }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 50px !important;
  }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 50px !important;
  }
  .p-lg-11 {
    padding: 55px !important;
  }
  .pt-lg-11,
  .py-lg-11 {
    padding-top: 55px !important;
  }
  .pr-lg-11,
  .px-lg-11 {
    padding-right: 55px !important;
  }
  .pb-lg-11,
  .py-lg-11 {
    padding-bottom: 55px !important;
  }
  .pl-lg-11,
  .px-lg-11 {
    padding-left: 55px !important;
  }
  .p-lg-12 {
    padding: 60px !important;
  }
  .pt-lg-12,
  .py-lg-12 {
    padding-top: 60px !important;
  }
  .pr-lg-12,
  .px-lg-12 {
    padding-right: 60px !important;
  }
  .pb-lg-12,
  .py-lg-12 {
    padding-bottom: 60px !important;
  }
  .pl-lg-12,
  .px-lg-12 {
    padding-left: 60px !important;
  }
  .p-lg-13 {
    padding: 65px !important;
  }
  .pt-lg-13,
  .py-lg-13 {
    padding-top: 65px !important;
  }
  .pr-lg-13,
  .px-lg-13 {
    padding-right: 65px !important;
  }
  .pb-lg-13,
  .py-lg-13 {
    padding-bottom: 65px !important;
  }
  .pl-lg-13,
  .px-lg-13 {
    padding-left: 65px !important;
  }
  .p-lg-14 {
    padding: 70px !important;
  }
  .pt-lg-14,
  .py-lg-14 {
    padding-top: 70px !important;
  }
  .pr-lg-14,
  .px-lg-14 {
    padding-right: 70px !important;
  }
  .pb-lg-14,
  .py-lg-14 {
    padding-bottom: 70px !important;
  }
  .pl-lg-14,
  .px-lg-14 {
    padding-left: 70px !important;
  }
  .p-lg-15 {
    padding: 75px !important;
  }
  .pt-lg-15,
  .py-lg-15 {
    padding-top: 75px !important;
  }
  .pr-lg-15,
  .px-lg-15 {
    padding-right: 75px !important;
  }
  .pb-lg-15,
  .py-lg-15 {
    padding-bottom: 75px !important;
  }
  .pl-lg-15,
  .px-lg-15 {
    padding-left: 75px !important;
  }
  .p-lg-16 {
    padding: 80px !important;
  }
  .pt-lg-16,
  .py-lg-16 {
    padding-top: 80px !important;
  }
  .pr-lg-16,
  .px-lg-16 {
    padding-right: 80px !important;
  }
  .pb-lg-16,
  .py-lg-16 {
    padding-bottom: 80px !important;
  }
  .pl-lg-16,
  .px-lg-16 {
    padding-left: 80px !important;
  }
  .p-lg-17 {
    padding: 85px !important;
  }
  .pt-lg-17,
  .py-lg-17 {
    padding-top: 85px !important;
  }
  .pr-lg-17,
  .px-lg-17 {
    padding-right: 85px !important;
  }
  .pb-lg-17,
  .py-lg-17 {
    padding-bottom: 85px !important;
  }
  .pl-lg-17,
  .px-lg-17 {
    padding-left: 85px !important;
  }
  .p-lg-18 {
    padding: 90px !important;
  }
  .pt-lg-18,
  .py-lg-18 {
    padding-top: 90px !important;
  }
  .pr-lg-18,
  .px-lg-18 {
    padding-right: 90px !important;
  }
  .pb-lg-18,
  .py-lg-18 {
    padding-bottom: 90px !important;
  }
  .pl-lg-18,
  .px-lg-18 {
    padding-left: 90px !important;
  }
  .p-lg-19 {
    padding: 95px !important;
  }
  .pt-lg-19,
  .py-lg-19 {
    padding-top: 95px !important;
  }
  .pr-lg-19,
  .px-lg-19 {
    padding-right: 95px !important;
  }
  .pb-lg-19,
  .py-lg-19 {
    padding-bottom: 95px !important;
  }
  .pl-lg-19,
  .px-lg-19 {
    padding-left: 95px !important;
  }
  .p-lg-20 {
    padding: 100px !important;
  }
  .pt-lg-20,
  .py-lg-20 {
    padding-top: 100px !important;
  }
  .pr-lg-20,
  .px-lg-20 {
    padding-right: 100px !important;
  }
  .pb-lg-20,
  .py-lg-20 {
    padding-bottom: 100px !important;
  }
  .pl-lg-20,
  .px-lg-20 {
    padding-left: 100px !important;
  }
  .p-lg-21 {
    padding: 105px !important;
  }
  .pt-lg-21,
  .py-lg-21 {
    padding-top: 105px !important;
  }
  .pr-lg-21,
  .px-lg-21 {
    padding-right: 105px !important;
  }
  .pb-lg-21,
  .py-lg-21 {
    padding-bottom: 105px !important;
  }
  .pl-lg-21,
  .px-lg-21 {
    padding-left: 105px !important;
  }
  .p-lg-22 {
    padding: 110px !important;
  }
  .pt-lg-22,
  .py-lg-22 {
    padding-top: 110px !important;
  }
  .pr-lg-22,
  .px-lg-22 {
    padding-right: 110px !important;
  }
  .pb-lg-22,
  .py-lg-22 {
    padding-bottom: 110px !important;
  }
  .pl-lg-22,
  .px-lg-22 {
    padding-left: 110px !important;
  }
  .p-lg-23 {
    padding: 115px !important;
  }
  .pt-lg-23,
  .py-lg-23 {
    padding-top: 115px !important;
  }
  .pr-lg-23,
  .px-lg-23 {
    padding-right: 115px !important;
  }
  .pb-lg-23,
  .py-lg-23 {
    padding-bottom: 115px !important;
  }
  .pl-lg-23,
  .px-lg-23 {
    padding-left: 115px !important;
  }
  .p-lg-24 {
    padding: 120px !important;
  }
  .pt-lg-24,
  .py-lg-24 {
    padding-top: 120px !important;
  }
  .pr-lg-24,
  .px-lg-24 {
    padding-right: 120px !important;
  }
  .pb-lg-24,
  .py-lg-24 {
    padding-bottom: 120px !important;
  }
  .pl-lg-24,
  .px-lg-24 {
    padding-left: 120px !important;
  }
  .p-lg-25 {
    padding: 125px !important;
  }
  .pt-lg-25,
  .py-lg-25 {
    padding-top: 125px !important;
  }
  .pr-lg-25,
  .px-lg-25 {
    padding-right: 125px !important;
  }
  .pb-lg-25,
  .py-lg-25 {
    padding-bottom: 125px !important;
  }
  .pl-lg-25,
  .px-lg-25 {
    padding-left: 125px !important;
  }
  .p-lg-26 {
    padding: 130px !important;
  }
  .pt-lg-26,
  .py-lg-26 {
    padding-top: 130px !important;
  }
  .pr-lg-26,
  .px-lg-26 {
    padding-right: 130px !important;
  }
  .pb-lg-26,
  .py-lg-26 {
    padding-bottom: 130px !important;
  }
  .pl-lg-26,
  .px-lg-26 {
    padding-left: 130px !important;
  }
  .p-lg-27 {
    padding: 135px !important;
  }
  .pt-lg-27,
  .py-lg-27 {
    padding-top: 135px !important;
  }
  .pr-lg-27,
  .px-lg-27 {
    padding-right: 135px !important;
  }
  .pb-lg-27,
  .py-lg-27 {
    padding-bottom: 135px !important;
  }
  .pl-lg-27,
  .px-lg-27 {
    padding-left: 135px !important;
  }
  .p-lg-28 {
    padding: 140px !important;
  }
  .pt-lg-28,
  .py-lg-28 {
    padding-top: 140px !important;
  }
  .pr-lg-28,
  .px-lg-28 {
    padding-right: 140px !important;
  }
  .pb-lg-28,
  .py-lg-28 {
    padding-bottom: 140px !important;
  }
  .pl-lg-28,
  .px-lg-28 {
    padding-left: 140px !important;
  }
  .p-lg-29 {
    padding: 145px !important;
  }
  .pt-lg-29,
  .py-lg-29 {
    padding-top: 145px !important;
  }
  .pr-lg-29,
  .px-lg-29 {
    padding-right: 145px !important;
  }
  .pb-lg-29,
  .py-lg-29 {
    padding-bottom: 145px !important;
  }
  .pl-lg-29,
  .px-lg-29 {
    padding-left: 145px !important;
  }
  .p-lg-30 {
    padding: 150px !important;
  }
  .pt-lg-30,
  .py-lg-30 {
    padding-top: 150px !important;
  }
  .pr-lg-30,
  .px-lg-30 {
    padding-right: 150px !important;
  }
  .pb-lg-30,
  .py-lg-30 {
    padding-bottom: 150px !important;
  }
  .pl-lg-30,
  .px-lg-30 {
    padding-left: 150px !important;
  }
  .p-lg-31 {
    padding: 155px !important;
  }
  .pt-lg-31,
  .py-lg-31 {
    padding-top: 155px !important;
  }
  .pr-lg-31,
  .px-lg-31 {
    padding-right: 155px !important;
  }
  .pb-lg-31,
  .py-lg-31 {
    padding-bottom: 155px !important;
  }
  .pl-lg-31,
  .px-lg-31 {
    padding-left: 155px !important;
  }
  .p-lg-32 {
    padding: 160px !important;
  }
  .pt-lg-32,
  .py-lg-32 {
    padding-top: 160px !important;
  }
  .pr-lg-32,
  .px-lg-32 {
    padding-right: 160px !important;
  }
  .pb-lg-32,
  .py-lg-32 {
    padding-bottom: 160px !important;
  }
  .pl-lg-32,
  .px-lg-32 {
    padding-left: 160px !important;
  }
  .p-lg-33 {
    padding: 165px !important;
  }
  .pt-lg-33,
  .py-lg-33 {
    padding-top: 165px !important;
  }
  .pr-lg-33,
  .px-lg-33 {
    padding-right: 165px !important;
  }
  .pb-lg-33,
  .py-lg-33 {
    padding-bottom: 165px !important;
  }
  .pl-lg-33,
  .px-lg-33 {
    padding-left: 165px !important;
  }
  .p-lg-34 {
    padding: 170px !important;
  }
  .pt-lg-34,
  .py-lg-34 {
    padding-top: 170px !important;
  }
  .pr-lg-34,
  .px-lg-34 {
    padding-right: 170px !important;
  }
  .pb-lg-34,
  .py-lg-34 {
    padding-bottom: 170px !important;
  }
  .pl-lg-34,
  .px-lg-34 {
    padding-left: 170px !important;
  }
  .p-lg-35 {
    padding: 175px !important;
  }
  .pt-lg-35,
  .py-lg-35 {
    padding-top: 175px !important;
  }
  .pr-lg-35,
  .px-lg-35 {
    padding-right: 175px !important;
  }
  .pb-lg-35,
  .py-lg-35 {
    padding-bottom: 175px !important;
  }
  .pl-lg-35,
  .px-lg-35 {
    padding-left: 175px !important;
  }
  .p-lg-36 {
    padding: 180px !important;
  }
  .pt-lg-36,
  .py-lg-36 {
    padding-top: 180px !important;
  }
  .pr-lg-36,
  .px-lg-36 {
    padding-right: 180px !important;
  }
  .pb-lg-36,
  .py-lg-36 {
    padding-bottom: 180px !important;
  }
  .pl-lg-36,
  .px-lg-36 {
    padding-left: 180px !important;
  }
  .p-lg-37 {
    padding: 185px !important;
  }
  .pt-lg-37,
  .py-lg-37 {
    padding-top: 185px !important;
  }
  .pr-lg-37,
  .px-lg-37 {
    padding-right: 185px !important;
  }
  .pb-lg-37,
  .py-lg-37 {
    padding-bottom: 185px !important;
  }
  .pl-lg-37,
  .px-lg-37 {
    padding-left: 185px !important;
  }
  .p-lg-38 {
    padding: 190px !important;
  }
  .pt-lg-38,
  .py-lg-38 {
    padding-top: 190px !important;
  }
  .pr-lg-38,
  .px-lg-38 {
    padding-right: 190px !important;
  }
  .pb-lg-38,
  .py-lg-38 {
    padding-bottom: 190px !important;
  }
  .pl-lg-38,
  .px-lg-38 {
    padding-left: 190px !important;
  }
  .p-lg-39 {
    padding: 195px !important;
  }
  .pt-lg-39,
  .py-lg-39 {
    padding-top: 195px !important;
  }
  .pr-lg-39,
  .px-lg-39 {
    padding-right: 195px !important;
  }
  .pb-lg-39,
  .py-lg-39 {
    padding-bottom: 195px !important;
  }
  .pl-lg-39,
  .px-lg-39 {
    padding-left: 195px !important;
  }
  .p-lg-40 {
    padding: 200px !important;
  }
  .pt-lg-40,
  .py-lg-40 {
    padding-top: 200px !important;
  }
  .pr-lg-40,
  .px-lg-40 {
    padding-right: 200px !important;
  }
  .pb-lg-40,
  .py-lg-40 {
    padding-bottom: 200px !important;
  }
  .pl-lg-40,
  .px-lg-40 {
    padding-left: 200px !important;
  }
  .p-lg-41 {
    padding: 205px !important;
  }
  .pt-lg-41,
  .py-lg-41 {
    padding-top: 205px !important;
  }
  .pr-lg-41,
  .px-lg-41 {
    padding-right: 205px !important;
  }
  .pb-lg-41,
  .py-lg-41 {
    padding-bottom: 205px !important;
  }
  .pl-lg-41,
  .px-lg-41 {
    padding-left: 205px !important;
  }
  .p-lg-42 {
    padding: 210px !important;
  }
  .pt-lg-42,
  .py-lg-42 {
    padding-top: 210px !important;
  }
  .pr-lg-42,
  .px-lg-42 {
    padding-right: 210px !important;
  }
  .pb-lg-42,
  .py-lg-42 {
    padding-bottom: 210px !important;
  }
  .pl-lg-42,
  .px-lg-42 {
    padding-left: 210px !important;
  }
  .p-lg-43 {
    padding: 215px !important;
  }
  .pt-lg-43,
  .py-lg-43 {
    padding-top: 215px !important;
  }
  .pr-lg-43,
  .px-lg-43 {
    padding-right: 215px !important;
  }
  .pb-lg-43,
  .py-lg-43 {
    padding-bottom: 215px !important;
  }
  .pl-lg-43,
  .px-lg-43 {
    padding-left: 215px !important;
  }
  .p-lg-44 {
    padding: 220px !important;
  }
  .pt-lg-44,
  .py-lg-44 {
    padding-top: 220px !important;
  }
  .pr-lg-44,
  .px-lg-44 {
    padding-right: 220px !important;
  }
  .pb-lg-44,
  .py-lg-44 {
    padding-bottom: 220px !important;
  }
  .pl-lg-44,
  .px-lg-44 {
    padding-left: 220px !important;
  }
  .p-lg-45 {
    padding: 225px !important;
  }
  .pt-lg-45,
  .py-lg-45 {
    padding-top: 225px !important;
  }
  .pr-lg-45,
  .px-lg-45 {
    padding-right: 225px !important;
  }
  .pb-lg-45,
  .py-lg-45 {
    padding-bottom: 225px !important;
  }
  .pl-lg-45,
  .px-lg-45 {
    padding-left: 225px !important;
  }
  .p-lg-46 {
    padding: 230px !important;
  }
  .pt-lg-46,
  .py-lg-46 {
    padding-top: 230px !important;
  }
  .pr-lg-46,
  .px-lg-46 {
    padding-right: 230px !important;
  }
  .pb-lg-46,
  .py-lg-46 {
    padding-bottom: 230px !important;
  }
  .pl-lg-46,
  .px-lg-46 {
    padding-left: 230px !important;
  }
  .p-lg-47 {
    padding: 235px !important;
  }
  .pt-lg-47,
  .py-lg-47 {
    padding-top: 235px !important;
  }
  .pr-lg-47,
  .px-lg-47 {
    padding-right: 235px !important;
  }
  .pb-lg-47,
  .py-lg-47 {
    padding-bottom: 235px !important;
  }
  .pl-lg-47,
  .px-lg-47 {
    padding-left: 235px !important;
  }
  .p-lg-48 {
    padding: 240px !important;
  }
  .pt-lg-48,
  .py-lg-48 {
    padding-top: 240px !important;
  }
  .pr-lg-48,
  .px-lg-48 {
    padding-right: 240px !important;
  }
  .pb-lg-48,
  .py-lg-48 {
    padding-bottom: 240px !important;
  }
  .pl-lg-48,
  .px-lg-48 {
    padding-left: 240px !important;
  }
  .p-lg-49 {
    padding: 245px !important;
  }
  .pt-lg-49,
  .py-lg-49 {
    padding-top: 245px !important;
  }
  .pr-lg-49,
  .px-lg-49 {
    padding-right: 245px !important;
  }
  .pb-lg-49,
  .py-lg-49 {
    padding-bottom: 245px !important;
  }
  .pl-lg-49,
  .px-lg-49 {
    padding-left: 245px !important;
  }
  .p-lg-50 {
    padding: 250px !important;
  }
  .pt-lg-50,
  .py-lg-50 {
    padding-top: 250px !important;
  }
  .pr-lg-50,
  .px-lg-50 {
    padding-right: 250px !important;
  }
  .pb-lg-50,
  .py-lg-50 {
    padding-bottom: 250px !important;
  }
  .pl-lg-50,
  .px-lg-50 {
    padding-left: 250px !important;
  }
  .p-lg-51 {
    padding: 255px !important;
  }
  .pt-lg-51,
  .py-lg-51 {
    padding-top: 255px !important;
  }
  .pr-lg-51,
  .px-lg-51 {
    padding-right: 255px !important;
  }
  .pb-lg-51,
  .py-lg-51 {
    padding-bottom: 255px !important;
  }
  .pl-lg-51,
  .px-lg-51 {
    padding-left: 255px !important;
  }
  .p-lg-52 {
    padding: 260px !important;
  }
  .pt-lg-52,
  .py-lg-52 {
    padding-top: 260px !important;
  }
  .pr-lg-52,
  .px-lg-52 {
    padding-right: 260px !important;
  }
  .pb-lg-52,
  .py-lg-52 {
    padding-bottom: 260px !important;
  }
  .pl-lg-52,
  .px-lg-52 {
    padding-left: 260px !important;
  }
  .p-lg-53 {
    padding: 265px !important;
  }
  .pt-lg-53,
  .py-lg-53 {
    padding-top: 265px !important;
  }
  .pr-lg-53,
  .px-lg-53 {
    padding-right: 265px !important;
  }
  .pb-lg-53,
  .py-lg-53 {
    padding-bottom: 265px !important;
  }
  .pl-lg-53,
  .px-lg-53 {
    padding-left: 265px !important;
  }
  .p-lg-54 {
    padding: 270px !important;
  }
  .pt-lg-54,
  .py-lg-54 {
    padding-top: 270px !important;
  }
  .pr-lg-54,
  .px-lg-54 {
    padding-right: 270px !important;
  }
  .pb-lg-54,
  .py-lg-54 {
    padding-bottom: 270px !important;
  }
  .pl-lg-54,
  .px-lg-54 {
    padding-left: 270px !important;
  }
  .p-lg-55 {
    padding: 275px !important;
  }
  .pt-lg-55,
  .py-lg-55 {
    padding-top: 275px !important;
  }
  .pr-lg-55,
  .px-lg-55 {
    padding-right: 275px !important;
  }
  .pb-lg-55,
  .py-lg-55 {
    padding-bottom: 275px !important;
  }
  .pl-lg-55,
  .px-lg-55 {
    padding-left: 275px !important;
  }
  .p-lg-56 {
    padding: 280px !important;
  }
  .pt-lg-56,
  .py-lg-56 {
    padding-top: 280px !important;
  }
  .pr-lg-56,
  .px-lg-56 {
    padding-right: 280px !important;
  }
  .pb-lg-56,
  .py-lg-56 {
    padding-bottom: 280px !important;
  }
  .pl-lg-56,
  .px-lg-56 {
    padding-left: 280px !important;
  }
  .p-lg-57 {
    padding: 285px !important;
  }
  .pt-lg-57,
  .py-lg-57 {
    padding-top: 285px !important;
  }
  .pr-lg-57,
  .px-lg-57 {
    padding-right: 285px !important;
  }
  .pb-lg-57,
  .py-lg-57 {
    padding-bottom: 285px !important;
  }
  .pl-lg-57,
  .px-lg-57 {
    padding-left: 285px !important;
  }
  .p-lg-58 {
    padding: 290px !important;
  }
  .pt-lg-58,
  .py-lg-58 {
    padding-top: 290px !important;
  }
  .pr-lg-58,
  .px-lg-58 {
    padding-right: 290px !important;
  }
  .pb-lg-58,
  .py-lg-58 {
    padding-bottom: 290px !important;
  }
  .pl-lg-58,
  .px-lg-58 {
    padding-left: 290px !important;
  }
  .p-lg-59 {
    padding: 295px !important;
  }
  .pt-lg-59,
  .py-lg-59 {
    padding-top: 295px !important;
  }
  .pr-lg-59,
  .px-lg-59 {
    padding-right: 295px !important;
  }
  .pb-lg-59,
  .py-lg-59 {
    padding-bottom: 295px !important;
  }
  .pl-lg-59,
  .px-lg-59 {
    padding-left: 295px !important;
  }
  .p-lg-60 {
    padding: 300px !important;
  }
  .pt-lg-60,
  .py-lg-60 {
    padding-top: 300px !important;
  }
  .pr-lg-60,
  .px-lg-60 {
    padding-right: 300px !important;
  }
  .pb-lg-60,
  .py-lg-60 {
    padding-bottom: 300px !important;
  }
  .pl-lg-60,
  .px-lg-60 {
    padding-left: 300px !important;
  }
  .m-lg-n1 {
    margin: -5px !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -5px !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -5px !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -5px !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -5px !important;
  }
  .m-lg-n2 {
    margin: -10px !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -10px !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -10px !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -10px !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -10px !important;
  }
  .m-lg-n3 {
    margin: -16px !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -16px !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -16px !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -16px !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -16px !important;
  }
  .m-lg-n4 {
    margin: -20px !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -20px !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -20px !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -20px !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -20px !important;
  }
  .m-lg-n5 {
    margin: -25px !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -25px !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -25px !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -25px !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -25px !important;
  }
  .m-lg-n6 {
    margin: -30px !important;
  }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -30px !important;
  }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -30px !important;
  }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -30px !important;
  }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -30px !important;
  }
  .m-lg-n7 {
    margin: -35px !important;
  }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -35px !important;
  }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -35px !important;
  }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -35px !important;
  }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -35px !important;
  }
  .m-lg-n8 {
    margin: -40px !important;
  }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -40px !important;
  }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -40px !important;
  }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -40px !important;
  }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -40px !important;
  }
  .m-lg-n9 {
    margin: -45px !important;
  }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -45px !important;
  }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -45px !important;
  }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -45px !important;
  }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -45px !important;
  }
  .m-lg-n10 {
    margin: -50px !important;
  }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -50px !important;
  }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -50px !important;
  }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -50px !important;
  }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -50px !important;
  }
  .m-lg-n11 {
    margin: -55px !important;
  }
  .mt-lg-n11,
  .my-lg-n11 {
    margin-top: -55px !important;
  }
  .mr-lg-n11,
  .mx-lg-n11 {
    margin-right: -55px !important;
  }
  .mb-lg-n11,
  .my-lg-n11 {
    margin-bottom: -55px !important;
  }
  .ml-lg-n11,
  .mx-lg-n11 {
    margin-left: -55px !important;
  }
  .m-lg-n12 {
    margin: -60px !important;
  }
  .mt-lg-n12,
  .my-lg-n12 {
    margin-top: -60px !important;
  }
  .mr-lg-n12,
  .mx-lg-n12 {
    margin-right: -60px !important;
  }
  .mb-lg-n12,
  .my-lg-n12 {
    margin-bottom: -60px !important;
  }
  .ml-lg-n12,
  .mx-lg-n12 {
    margin-left: -60px !important;
  }
  .m-lg-n13 {
    margin: -65px !important;
  }
  .mt-lg-n13,
  .my-lg-n13 {
    margin-top: -65px !important;
  }
  .mr-lg-n13,
  .mx-lg-n13 {
    margin-right: -65px !important;
  }
  .mb-lg-n13,
  .my-lg-n13 {
    margin-bottom: -65px !important;
  }
  .ml-lg-n13,
  .mx-lg-n13 {
    margin-left: -65px !important;
  }
  .m-lg-n14 {
    margin: -70px !important;
  }
  .mt-lg-n14,
  .my-lg-n14 {
    margin-top: -70px !important;
  }
  .mr-lg-n14,
  .mx-lg-n14 {
    margin-right: -70px !important;
  }
  .mb-lg-n14,
  .my-lg-n14 {
    margin-bottom: -70px !important;
  }
  .ml-lg-n14,
  .mx-lg-n14 {
    margin-left: -70px !important;
  }
  .m-lg-n15 {
    margin: -75px !important;
  }
  .mt-lg-n15,
  .my-lg-n15 {
    margin-top: -75px !important;
  }
  .mr-lg-n15,
  .mx-lg-n15 {
    margin-right: -75px !important;
  }
  .mb-lg-n15,
  .my-lg-n15 {
    margin-bottom: -75px !important;
  }
  .ml-lg-n15,
  .mx-lg-n15 {
    margin-left: -75px !important;
  }
  .m-lg-n16 {
    margin: -80px !important;
  }
  .mt-lg-n16,
  .my-lg-n16 {
    margin-top: -80px !important;
  }
  .mr-lg-n16,
  .mx-lg-n16 {
    margin-right: -80px !important;
  }
  .mb-lg-n16,
  .my-lg-n16 {
    margin-bottom: -80px !important;
  }
  .ml-lg-n16,
  .mx-lg-n16 {
    margin-left: -80px !important;
  }
  .m-lg-n17 {
    margin: -85px !important;
  }
  .mt-lg-n17,
  .my-lg-n17 {
    margin-top: -85px !important;
  }
  .mr-lg-n17,
  .mx-lg-n17 {
    margin-right: -85px !important;
  }
  .mb-lg-n17,
  .my-lg-n17 {
    margin-bottom: -85px !important;
  }
  .ml-lg-n17,
  .mx-lg-n17 {
    margin-left: -85px !important;
  }
  .m-lg-n18 {
    margin: -90px !important;
  }
  .mt-lg-n18,
  .my-lg-n18 {
    margin-top: -90px !important;
  }
  .mr-lg-n18,
  .mx-lg-n18 {
    margin-right: -90px !important;
  }
  .mb-lg-n18,
  .my-lg-n18 {
    margin-bottom: -90px !important;
  }
  .ml-lg-n18,
  .mx-lg-n18 {
    margin-left: -90px !important;
  }
  .m-lg-n19 {
    margin: -95px !important;
  }
  .mt-lg-n19,
  .my-lg-n19 {
    margin-top: -95px !important;
  }
  .mr-lg-n19,
  .mx-lg-n19 {
    margin-right: -95px !important;
  }
  .mb-lg-n19,
  .my-lg-n19 {
    margin-bottom: -95px !important;
  }
  .ml-lg-n19,
  .mx-lg-n19 {
    margin-left: -95px !important;
  }
  .m-lg-n20 {
    margin: -100px !important;
  }
  .mt-lg-n20,
  .my-lg-n20 {
    margin-top: -100px !important;
  }
  .mr-lg-n20,
  .mx-lg-n20 {
    margin-right: -100px !important;
  }
  .mb-lg-n20,
  .my-lg-n20 {
    margin-bottom: -100px !important;
  }
  .ml-lg-n20,
  .mx-lg-n20 {
    margin-left: -100px !important;
  }
  .m-lg-n21 {
    margin: -105px !important;
  }
  .mt-lg-n21,
  .my-lg-n21 {
    margin-top: -105px !important;
  }
  .mr-lg-n21,
  .mx-lg-n21 {
    margin-right: -105px !important;
  }
  .mb-lg-n21,
  .my-lg-n21 {
    margin-bottom: -105px !important;
  }
  .ml-lg-n21,
  .mx-lg-n21 {
    margin-left: -105px !important;
  }
  .m-lg-n22 {
    margin: -110px !important;
  }
  .mt-lg-n22,
  .my-lg-n22 {
    margin-top: -110px !important;
  }
  .mr-lg-n22,
  .mx-lg-n22 {
    margin-right: -110px !important;
  }
  .mb-lg-n22,
  .my-lg-n22 {
    margin-bottom: -110px !important;
  }
  .ml-lg-n22,
  .mx-lg-n22 {
    margin-left: -110px !important;
  }
  .m-lg-n23 {
    margin: -115px !important;
  }
  .mt-lg-n23,
  .my-lg-n23 {
    margin-top: -115px !important;
  }
  .mr-lg-n23,
  .mx-lg-n23 {
    margin-right: -115px !important;
  }
  .mb-lg-n23,
  .my-lg-n23 {
    margin-bottom: -115px !important;
  }
  .ml-lg-n23,
  .mx-lg-n23 {
    margin-left: -115px !important;
  }
  .m-lg-n24 {
    margin: -120px !important;
  }
  .mt-lg-n24,
  .my-lg-n24 {
    margin-top: -120px !important;
  }
  .mr-lg-n24,
  .mx-lg-n24 {
    margin-right: -120px !important;
  }
  .mb-lg-n24,
  .my-lg-n24 {
    margin-bottom: -120px !important;
  }
  .ml-lg-n24,
  .mx-lg-n24 {
    margin-left: -120px !important;
  }
  .m-lg-n25 {
    margin: -125px !important;
  }
  .mt-lg-n25,
  .my-lg-n25 {
    margin-top: -125px !important;
  }
  .mr-lg-n25,
  .mx-lg-n25 {
    margin-right: -125px !important;
  }
  .mb-lg-n25,
  .my-lg-n25 {
    margin-bottom: -125px !important;
  }
  .ml-lg-n25,
  .mx-lg-n25 {
    margin-left: -125px !important;
  }
  .m-lg-n26 {
    margin: -130px !important;
  }
  .mt-lg-n26,
  .my-lg-n26 {
    margin-top: -130px !important;
  }
  .mr-lg-n26,
  .mx-lg-n26 {
    margin-right: -130px !important;
  }
  .mb-lg-n26,
  .my-lg-n26 {
    margin-bottom: -130px !important;
  }
  .ml-lg-n26,
  .mx-lg-n26 {
    margin-left: -130px !important;
  }
  .m-lg-n27 {
    margin: -135px !important;
  }
  .mt-lg-n27,
  .my-lg-n27 {
    margin-top: -135px !important;
  }
  .mr-lg-n27,
  .mx-lg-n27 {
    margin-right: -135px !important;
  }
  .mb-lg-n27,
  .my-lg-n27 {
    margin-bottom: -135px !important;
  }
  .ml-lg-n27,
  .mx-lg-n27 {
    margin-left: -135px !important;
  }
  .m-lg-n28 {
    margin: -140px !important;
  }
  .mt-lg-n28,
  .my-lg-n28 {
    margin-top: -140px !important;
  }
  .mr-lg-n28,
  .mx-lg-n28 {
    margin-right: -140px !important;
  }
  .mb-lg-n28,
  .my-lg-n28 {
    margin-bottom: -140px !important;
  }
  .ml-lg-n28,
  .mx-lg-n28 {
    margin-left: -140px !important;
  }
  .m-lg-n29 {
    margin: -145px !important;
  }
  .mt-lg-n29,
  .my-lg-n29 {
    margin-top: -145px !important;
  }
  .mr-lg-n29,
  .mx-lg-n29 {
    margin-right: -145px !important;
  }
  .mb-lg-n29,
  .my-lg-n29 {
    margin-bottom: -145px !important;
  }
  .ml-lg-n29,
  .mx-lg-n29 {
    margin-left: -145px !important;
  }
  .m-lg-n30 {
    margin: -150px !important;
  }
  .mt-lg-n30,
  .my-lg-n30 {
    margin-top: -150px !important;
  }
  .mr-lg-n30,
  .mx-lg-n30 {
    margin-right: -150px !important;
  }
  .mb-lg-n30,
  .my-lg-n30 {
    margin-bottom: -150px !important;
  }
  .ml-lg-n30,
  .mx-lg-n30 {
    margin-left: -150px !important;
  }
  .m-lg-n31 {
    margin: -155px !important;
  }
  .mt-lg-n31,
  .my-lg-n31 {
    margin-top: -155px !important;
  }
  .mr-lg-n31,
  .mx-lg-n31 {
    margin-right: -155px !important;
  }
  .mb-lg-n31,
  .my-lg-n31 {
    margin-bottom: -155px !important;
  }
  .ml-lg-n31,
  .mx-lg-n31 {
    margin-left: -155px !important;
  }
  .m-lg-n32 {
    margin: -160px !important;
  }
  .mt-lg-n32,
  .my-lg-n32 {
    margin-top: -160px !important;
  }
  .mr-lg-n32,
  .mx-lg-n32 {
    margin-right: -160px !important;
  }
  .mb-lg-n32,
  .my-lg-n32 {
    margin-bottom: -160px !important;
  }
  .ml-lg-n32,
  .mx-lg-n32 {
    margin-left: -160px !important;
  }
  .m-lg-n33 {
    margin: -165px !important;
  }
  .mt-lg-n33,
  .my-lg-n33 {
    margin-top: -165px !important;
  }
  .mr-lg-n33,
  .mx-lg-n33 {
    margin-right: -165px !important;
  }
  .mb-lg-n33,
  .my-lg-n33 {
    margin-bottom: -165px !important;
  }
  .ml-lg-n33,
  .mx-lg-n33 {
    margin-left: -165px !important;
  }
  .m-lg-n34 {
    margin: -170px !important;
  }
  .mt-lg-n34,
  .my-lg-n34 {
    margin-top: -170px !important;
  }
  .mr-lg-n34,
  .mx-lg-n34 {
    margin-right: -170px !important;
  }
  .mb-lg-n34,
  .my-lg-n34 {
    margin-bottom: -170px !important;
  }
  .ml-lg-n34,
  .mx-lg-n34 {
    margin-left: -170px !important;
  }
  .m-lg-n35 {
    margin: -175px !important;
  }
  .mt-lg-n35,
  .my-lg-n35 {
    margin-top: -175px !important;
  }
  .mr-lg-n35,
  .mx-lg-n35 {
    margin-right: -175px !important;
  }
  .mb-lg-n35,
  .my-lg-n35 {
    margin-bottom: -175px !important;
  }
  .ml-lg-n35,
  .mx-lg-n35 {
    margin-left: -175px !important;
  }
  .m-lg-n36 {
    margin: -180px !important;
  }
  .mt-lg-n36,
  .my-lg-n36 {
    margin-top: -180px !important;
  }
  .mr-lg-n36,
  .mx-lg-n36 {
    margin-right: -180px !important;
  }
  .mb-lg-n36,
  .my-lg-n36 {
    margin-bottom: -180px !important;
  }
  .ml-lg-n36,
  .mx-lg-n36 {
    margin-left: -180px !important;
  }
  .m-lg-n37 {
    margin: -185px !important;
  }
  .mt-lg-n37,
  .my-lg-n37 {
    margin-top: -185px !important;
  }
  .mr-lg-n37,
  .mx-lg-n37 {
    margin-right: -185px !important;
  }
  .mb-lg-n37,
  .my-lg-n37 {
    margin-bottom: -185px !important;
  }
  .ml-lg-n37,
  .mx-lg-n37 {
    margin-left: -185px !important;
  }
  .m-lg-n38 {
    margin: -190px !important;
  }
  .mt-lg-n38,
  .my-lg-n38 {
    margin-top: -190px !important;
  }
  .mr-lg-n38,
  .mx-lg-n38 {
    margin-right: -190px !important;
  }
  .mb-lg-n38,
  .my-lg-n38 {
    margin-bottom: -190px !important;
  }
  .ml-lg-n38,
  .mx-lg-n38 {
    margin-left: -190px !important;
  }
  .m-lg-n39 {
    margin: -195px !important;
  }
  .mt-lg-n39,
  .my-lg-n39 {
    margin-top: -195px !important;
  }
  .mr-lg-n39,
  .mx-lg-n39 {
    margin-right: -195px !important;
  }
  .mb-lg-n39,
  .my-lg-n39 {
    margin-bottom: -195px !important;
  }
  .ml-lg-n39,
  .mx-lg-n39 {
    margin-left: -195px !important;
  }
  .m-lg-n40 {
    margin: -200px !important;
  }
  .mt-lg-n40,
  .my-lg-n40 {
    margin-top: -200px !important;
  }
  .mr-lg-n40,
  .mx-lg-n40 {
    margin-right: -200px !important;
  }
  .mb-lg-n40,
  .my-lg-n40 {
    margin-bottom: -200px !important;
  }
  .ml-lg-n40,
  .mx-lg-n40 {
    margin-left: -200px !important;
  }
  .m-lg-n41 {
    margin: -205px !important;
  }
  .mt-lg-n41,
  .my-lg-n41 {
    margin-top: -205px !important;
  }
  .mr-lg-n41,
  .mx-lg-n41 {
    margin-right: -205px !important;
  }
  .mb-lg-n41,
  .my-lg-n41 {
    margin-bottom: -205px !important;
  }
  .ml-lg-n41,
  .mx-lg-n41 {
    margin-left: -205px !important;
  }
  .m-lg-n42 {
    margin: -210px !important;
  }
  .mt-lg-n42,
  .my-lg-n42 {
    margin-top: -210px !important;
  }
  .mr-lg-n42,
  .mx-lg-n42 {
    margin-right: -210px !important;
  }
  .mb-lg-n42,
  .my-lg-n42 {
    margin-bottom: -210px !important;
  }
  .ml-lg-n42,
  .mx-lg-n42 {
    margin-left: -210px !important;
  }
  .m-lg-n43 {
    margin: -215px !important;
  }
  .mt-lg-n43,
  .my-lg-n43 {
    margin-top: -215px !important;
  }
  .mr-lg-n43,
  .mx-lg-n43 {
    margin-right: -215px !important;
  }
  .mb-lg-n43,
  .my-lg-n43 {
    margin-bottom: -215px !important;
  }
  .ml-lg-n43,
  .mx-lg-n43 {
    margin-left: -215px !important;
  }
  .m-lg-n44 {
    margin: -220px !important;
  }
  .mt-lg-n44,
  .my-lg-n44 {
    margin-top: -220px !important;
  }
  .mr-lg-n44,
  .mx-lg-n44 {
    margin-right: -220px !important;
  }
  .mb-lg-n44,
  .my-lg-n44 {
    margin-bottom: -220px !important;
  }
  .ml-lg-n44,
  .mx-lg-n44 {
    margin-left: -220px !important;
  }
  .m-lg-n45 {
    margin: -225px !important;
  }
  .mt-lg-n45,
  .my-lg-n45 {
    margin-top: -225px !important;
  }
  .mr-lg-n45,
  .mx-lg-n45 {
    margin-right: -225px !important;
  }
  .mb-lg-n45,
  .my-lg-n45 {
    margin-bottom: -225px !important;
  }
  .ml-lg-n45,
  .mx-lg-n45 {
    margin-left: -225px !important;
  }
  .m-lg-n46 {
    margin: -230px !important;
  }
  .mt-lg-n46,
  .my-lg-n46 {
    margin-top: -230px !important;
  }
  .mr-lg-n46,
  .mx-lg-n46 {
    margin-right: -230px !important;
  }
  .mb-lg-n46,
  .my-lg-n46 {
    margin-bottom: -230px !important;
  }
  .ml-lg-n46,
  .mx-lg-n46 {
    margin-left: -230px !important;
  }
  .m-lg-n47 {
    margin: -235px !important;
  }
  .mt-lg-n47,
  .my-lg-n47 {
    margin-top: -235px !important;
  }
  .mr-lg-n47,
  .mx-lg-n47 {
    margin-right: -235px !important;
  }
  .mb-lg-n47,
  .my-lg-n47 {
    margin-bottom: -235px !important;
  }
  .ml-lg-n47,
  .mx-lg-n47 {
    margin-left: -235px !important;
  }
  .m-lg-n48 {
    margin: -240px !important;
  }
  .mt-lg-n48,
  .my-lg-n48 {
    margin-top: -240px !important;
  }
  .mr-lg-n48,
  .mx-lg-n48 {
    margin-right: -240px !important;
  }
  .mb-lg-n48,
  .my-lg-n48 {
    margin-bottom: -240px !important;
  }
  .ml-lg-n48,
  .mx-lg-n48 {
    margin-left: -240px !important;
  }
  .m-lg-n49 {
    margin: -245px !important;
  }
  .mt-lg-n49,
  .my-lg-n49 {
    margin-top: -245px !important;
  }
  .mr-lg-n49,
  .mx-lg-n49 {
    margin-right: -245px !important;
  }
  .mb-lg-n49,
  .my-lg-n49 {
    margin-bottom: -245px !important;
  }
  .ml-lg-n49,
  .mx-lg-n49 {
    margin-left: -245px !important;
  }
  .m-lg-n50 {
    margin: -250px !important;
  }
  .mt-lg-n50,
  .my-lg-n50 {
    margin-top: -250px !important;
  }
  .mr-lg-n50,
  .mx-lg-n50 {
    margin-right: -250px !important;
  }
  .mb-lg-n50,
  .my-lg-n50 {
    margin-bottom: -250px !important;
  }
  .ml-lg-n50,
  .mx-lg-n50 {
    margin-left: -250px !important;
  }
  .m-lg-n51 {
    margin: -255px !important;
  }
  .mt-lg-n51,
  .my-lg-n51 {
    margin-top: -255px !important;
  }
  .mr-lg-n51,
  .mx-lg-n51 {
    margin-right: -255px !important;
  }
  .mb-lg-n51,
  .my-lg-n51 {
    margin-bottom: -255px !important;
  }
  .ml-lg-n51,
  .mx-lg-n51 {
    margin-left: -255px !important;
  }
  .m-lg-n52 {
    margin: -260px !important;
  }
  .mt-lg-n52,
  .my-lg-n52 {
    margin-top: -260px !important;
  }
  .mr-lg-n52,
  .mx-lg-n52 {
    margin-right: -260px !important;
  }
  .mb-lg-n52,
  .my-lg-n52 {
    margin-bottom: -260px !important;
  }
  .ml-lg-n52,
  .mx-lg-n52 {
    margin-left: -260px !important;
  }
  .m-lg-n53 {
    margin: -265px !important;
  }
  .mt-lg-n53,
  .my-lg-n53 {
    margin-top: -265px !important;
  }
  .mr-lg-n53,
  .mx-lg-n53 {
    margin-right: -265px !important;
  }
  .mb-lg-n53,
  .my-lg-n53 {
    margin-bottom: -265px !important;
  }
  .ml-lg-n53,
  .mx-lg-n53 {
    margin-left: -265px !important;
  }
  .m-lg-n54 {
    margin: -270px !important;
  }
  .mt-lg-n54,
  .my-lg-n54 {
    margin-top: -270px !important;
  }
  .mr-lg-n54,
  .mx-lg-n54 {
    margin-right: -270px !important;
  }
  .mb-lg-n54,
  .my-lg-n54 {
    margin-bottom: -270px !important;
  }
  .ml-lg-n54,
  .mx-lg-n54 {
    margin-left: -270px !important;
  }
  .m-lg-n55 {
    margin: -275px !important;
  }
  .mt-lg-n55,
  .my-lg-n55 {
    margin-top: -275px !important;
  }
  .mr-lg-n55,
  .mx-lg-n55 {
    margin-right: -275px !important;
  }
  .mb-lg-n55,
  .my-lg-n55 {
    margin-bottom: -275px !important;
  }
  .ml-lg-n55,
  .mx-lg-n55 {
    margin-left: -275px !important;
  }
  .m-lg-n56 {
    margin: -280px !important;
  }
  .mt-lg-n56,
  .my-lg-n56 {
    margin-top: -280px !important;
  }
  .mr-lg-n56,
  .mx-lg-n56 {
    margin-right: -280px !important;
  }
  .mb-lg-n56,
  .my-lg-n56 {
    margin-bottom: -280px !important;
  }
  .ml-lg-n56,
  .mx-lg-n56 {
    margin-left: -280px !important;
  }
  .m-lg-n57 {
    margin: -285px !important;
  }
  .mt-lg-n57,
  .my-lg-n57 {
    margin-top: -285px !important;
  }
  .mr-lg-n57,
  .mx-lg-n57 {
    margin-right: -285px !important;
  }
  .mb-lg-n57,
  .my-lg-n57 {
    margin-bottom: -285px !important;
  }
  .ml-lg-n57,
  .mx-lg-n57 {
    margin-left: -285px !important;
  }
  .m-lg-n58 {
    margin: -290px !important;
  }
  .mt-lg-n58,
  .my-lg-n58 {
    margin-top: -290px !important;
  }
  .mr-lg-n58,
  .mx-lg-n58 {
    margin-right: -290px !important;
  }
  .mb-lg-n58,
  .my-lg-n58 {
    margin-bottom: -290px !important;
  }
  .ml-lg-n58,
  .mx-lg-n58 {
    margin-left: -290px !important;
  }
  .m-lg-n59 {
    margin: -295px !important;
  }
  .mt-lg-n59,
  .my-lg-n59 {
    margin-top: -295px !important;
  }
  .mr-lg-n59,
  .mx-lg-n59 {
    margin-right: -295px !important;
  }
  .mb-lg-n59,
  .my-lg-n59 {
    margin-bottom: -295px !important;
  }
  .ml-lg-n59,
  .mx-lg-n59 {
    margin-left: -295px !important;
  }
  .m-lg-n60 {
    margin: -300px !important;
  }
  .mt-lg-n60,
  .my-lg-n60 {
    margin-top: -300px !important;
  }
  .mr-lg-n60,
  .mx-lg-n60 {
    margin-right: -300px !important;
  }
  .mb-lg-n60,
  .my-lg-n60 {
    margin-bottom: -300px !important;
  }
  .ml-lg-n60,
  .mx-lg-n60 {
    margin-left: -300px !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1070px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 5px !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 5px !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 5px !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 5px !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 5px !important;
  }
  .m-xl-2 {
    margin: 10px !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 10px !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 10px !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 10px !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 10px !important;
  }
  .m-xl-3 {
    margin: 16px !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 16px !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 16px !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 16px !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 16px !important;
  }
  .m-xl-4 {
    margin: 20px !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 20px !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 20px !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 20px !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 20px !important;
  }
  .m-xl-5 {
    margin: 25px !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 25px !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 25px !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 25px !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 25px !important;
  }
  .m-xl-6 {
    margin: 30px !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 30px !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 30px !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 30px !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 30px !important;
  }
  .m-xl-7 {
    margin: 35px !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 35px !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 35px !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 35px !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 35px !important;
  }
  .m-xl-8 {
    margin: 40px !important;
  }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 40px !important;
  }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 40px !important;
  }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 40px !important;
  }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 40px !important;
  }
  .m-xl-9 {
    margin: 45px !important;
  }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 45px !important;
  }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 45px !important;
  }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 45px !important;
  }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 45px !important;
  }
  .m-xl-10 {
    margin: 50px !important;
  }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 50px !important;
  }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 50px !important;
  }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 50px !important;
  }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 50px !important;
  }
  .m-xl-11 {
    margin: 55px !important;
  }
  .mt-xl-11,
  .my-xl-11 {
    margin-top: 55px !important;
  }
  .mr-xl-11,
  .mx-xl-11 {
    margin-right: 55px !important;
  }
  .mb-xl-11,
  .my-xl-11 {
    margin-bottom: 55px !important;
  }
  .ml-xl-11,
  .mx-xl-11 {
    margin-left: 55px !important;
  }
  .m-xl-12 {
    margin: 60px !important;
  }
  .mt-xl-12,
  .my-xl-12 {
    margin-top: 60px !important;
  }
  .mr-xl-12,
  .mx-xl-12 {
    margin-right: 60px !important;
  }
  .mb-xl-12,
  .my-xl-12 {
    margin-bottom: 60px !important;
  }
  .ml-xl-12,
  .mx-xl-12 {
    margin-left: 60px !important;
  }
  .m-xl-13 {
    margin: 65px !important;
  }
  .mt-xl-13,
  .my-xl-13 {
    margin-top: 65px !important;
  }
  .mr-xl-13,
  .mx-xl-13 {
    margin-right: 65px !important;
  }
  .mb-xl-13,
  .my-xl-13 {
    margin-bottom: 65px !important;
  }
  .ml-xl-13,
  .mx-xl-13 {
    margin-left: 65px !important;
  }
  .m-xl-14 {
    margin: 70px !important;
  }
  .mt-xl-14,
  .my-xl-14 {
    margin-top: 70px !important;
  }
  .mr-xl-14,
  .mx-xl-14 {
    margin-right: 70px !important;
  }
  .mb-xl-14,
  .my-xl-14 {
    margin-bottom: 70px !important;
  }
  .ml-xl-14,
  .mx-xl-14 {
    margin-left: 70px !important;
  }
  .m-xl-15 {
    margin: 75px !important;
  }
  .mt-xl-15,
  .my-xl-15 {
    margin-top: 75px !important;
  }
  .mr-xl-15,
  .mx-xl-15 {
    margin-right: 75px !important;
  }
  .mb-xl-15,
  .my-xl-15 {
    margin-bottom: 75px !important;
  }
  .ml-xl-15,
  .mx-xl-15 {
    margin-left: 75px !important;
  }
  .m-xl-16 {
    margin: 80px !important;
  }
  .mt-xl-16,
  .my-xl-16 {
    margin-top: 80px !important;
  }
  .mr-xl-16,
  .mx-xl-16 {
    margin-right: 80px !important;
  }
  .mb-xl-16,
  .my-xl-16 {
    margin-bottom: 80px !important;
  }
  .ml-xl-16,
  .mx-xl-16 {
    margin-left: 80px !important;
  }
  .m-xl-17 {
    margin: 85px !important;
  }
  .mt-xl-17,
  .my-xl-17 {
    margin-top: 85px !important;
  }
  .mr-xl-17,
  .mx-xl-17 {
    margin-right: 85px !important;
  }
  .mb-xl-17,
  .my-xl-17 {
    margin-bottom: 85px !important;
  }
  .ml-xl-17,
  .mx-xl-17 {
    margin-left: 85px !important;
  }
  .m-xl-18 {
    margin: 90px !important;
  }
  .mt-xl-18,
  .my-xl-18 {
    margin-top: 90px !important;
  }
  .mr-xl-18,
  .mx-xl-18 {
    margin-right: 90px !important;
  }
  .mb-xl-18,
  .my-xl-18 {
    margin-bottom: 90px !important;
  }
  .ml-xl-18,
  .mx-xl-18 {
    margin-left: 90px !important;
  }
  .m-xl-19 {
    margin: 95px !important;
  }
  .mt-xl-19,
  .my-xl-19 {
    margin-top: 95px !important;
  }
  .mr-xl-19,
  .mx-xl-19 {
    margin-right: 95px !important;
  }
  .mb-xl-19,
  .my-xl-19 {
    margin-bottom: 95px !important;
  }
  .ml-xl-19,
  .mx-xl-19 {
    margin-left: 95px !important;
  }
  .m-xl-20 {
    margin: 100px !important;
  }
  .mt-xl-20,
  .my-xl-20 {
    margin-top: 100px !important;
  }
  .mr-xl-20,
  .mx-xl-20 {
    margin-right: 100px !important;
  }
  .mb-xl-20,
  .my-xl-20 {
    margin-bottom: 100px !important;
  }
  .ml-xl-20,
  .mx-xl-20 {
    margin-left: 100px !important;
  }
  .m-xl-21 {
    margin: 105px !important;
  }
  .mt-xl-21,
  .my-xl-21 {
    margin-top: 105px !important;
  }
  .mr-xl-21,
  .mx-xl-21 {
    margin-right: 105px !important;
  }
  .mb-xl-21,
  .my-xl-21 {
    margin-bottom: 105px !important;
  }
  .ml-xl-21,
  .mx-xl-21 {
    margin-left: 105px !important;
  }
  .m-xl-22 {
    margin: 110px !important;
  }
  .mt-xl-22,
  .my-xl-22 {
    margin-top: 110px !important;
  }
  .mr-xl-22,
  .mx-xl-22 {
    margin-right: 110px !important;
  }
  .mb-xl-22,
  .my-xl-22 {
    margin-bottom: 110px !important;
  }
  .ml-xl-22,
  .mx-xl-22 {
    margin-left: 110px !important;
  }
  .m-xl-23 {
    margin: 115px !important;
  }
  .mt-xl-23,
  .my-xl-23 {
    margin-top: 115px !important;
  }
  .mr-xl-23,
  .mx-xl-23 {
    margin-right: 115px !important;
  }
  .mb-xl-23,
  .my-xl-23 {
    margin-bottom: 115px !important;
  }
  .ml-xl-23,
  .mx-xl-23 {
    margin-left: 115px !important;
  }
  .m-xl-24 {
    margin: 120px !important;
  }
  .mt-xl-24,
  .my-xl-24 {
    margin-top: 120px !important;
  }
  .mr-xl-24,
  .mx-xl-24 {
    margin-right: 120px !important;
  }
  .mb-xl-24,
  .my-xl-24 {
    margin-bottom: 120px !important;
  }
  .ml-xl-24,
  .mx-xl-24 {
    margin-left: 120px !important;
  }
  .m-xl-25 {
    margin: 125px !important;
  }
  .mt-xl-25,
  .my-xl-25 {
    margin-top: 125px !important;
  }
  .mr-xl-25,
  .mx-xl-25 {
    margin-right: 125px !important;
  }
  .mb-xl-25,
  .my-xl-25 {
    margin-bottom: 125px !important;
  }
  .ml-xl-25,
  .mx-xl-25 {
    margin-left: 125px !important;
  }
  .m-xl-26 {
    margin: 130px !important;
  }
  .mt-xl-26,
  .my-xl-26 {
    margin-top: 130px !important;
  }
  .mr-xl-26,
  .mx-xl-26 {
    margin-right: 130px !important;
  }
  .mb-xl-26,
  .my-xl-26 {
    margin-bottom: 130px !important;
  }
  .ml-xl-26,
  .mx-xl-26 {
    margin-left: 130px !important;
  }
  .m-xl-27 {
    margin: 135px !important;
  }
  .mt-xl-27,
  .my-xl-27 {
    margin-top: 135px !important;
  }
  .mr-xl-27,
  .mx-xl-27 {
    margin-right: 135px !important;
  }
  .mb-xl-27,
  .my-xl-27 {
    margin-bottom: 135px !important;
  }
  .ml-xl-27,
  .mx-xl-27 {
    margin-left: 135px !important;
  }
  .m-xl-28 {
    margin: 140px !important;
  }
  .mt-xl-28,
  .my-xl-28 {
    margin-top: 140px !important;
  }
  .mr-xl-28,
  .mx-xl-28 {
    margin-right: 140px !important;
  }
  .mb-xl-28,
  .my-xl-28 {
    margin-bottom: 140px !important;
  }
  .ml-xl-28,
  .mx-xl-28 {
    margin-left: 140px !important;
  }
  .m-xl-29 {
    margin: 145px !important;
  }
  .mt-xl-29,
  .my-xl-29 {
    margin-top: 145px !important;
  }
  .mr-xl-29,
  .mx-xl-29 {
    margin-right: 145px !important;
  }
  .mb-xl-29,
  .my-xl-29 {
    margin-bottom: 145px !important;
  }
  .ml-xl-29,
  .mx-xl-29 {
    margin-left: 145px !important;
  }
  .m-xl-30 {
    margin: 150px !important;
  }
  .mt-xl-30,
  .my-xl-30 {
    margin-top: 150px !important;
  }
  .mr-xl-30,
  .mx-xl-30 {
    margin-right: 150px !important;
  }
  .mb-xl-30,
  .my-xl-30 {
    margin-bottom: 150px !important;
  }
  .ml-xl-30,
  .mx-xl-30 {
    margin-left: 150px !important;
  }
  .m-xl-31 {
    margin: 155px !important;
  }
  .mt-xl-31,
  .my-xl-31 {
    margin-top: 155px !important;
  }
  .mr-xl-31,
  .mx-xl-31 {
    margin-right: 155px !important;
  }
  .mb-xl-31,
  .my-xl-31 {
    margin-bottom: 155px !important;
  }
  .ml-xl-31,
  .mx-xl-31 {
    margin-left: 155px !important;
  }
  .m-xl-32 {
    margin: 160px !important;
  }
  .mt-xl-32,
  .my-xl-32 {
    margin-top: 160px !important;
  }
  .mr-xl-32,
  .mx-xl-32 {
    margin-right: 160px !important;
  }
  .mb-xl-32,
  .my-xl-32 {
    margin-bottom: 160px !important;
  }
  .ml-xl-32,
  .mx-xl-32 {
    margin-left: 160px !important;
  }
  .m-xl-33 {
    margin: 165px !important;
  }
  .mt-xl-33,
  .my-xl-33 {
    margin-top: 165px !important;
  }
  .mr-xl-33,
  .mx-xl-33 {
    margin-right: 165px !important;
  }
  .mb-xl-33,
  .my-xl-33 {
    margin-bottom: 165px !important;
  }
  .ml-xl-33,
  .mx-xl-33 {
    margin-left: 165px !important;
  }
  .m-xl-34 {
    margin: 170px !important;
  }
  .mt-xl-34,
  .my-xl-34 {
    margin-top: 170px !important;
  }
  .mr-xl-34,
  .mx-xl-34 {
    margin-right: 170px !important;
  }
  .mb-xl-34,
  .my-xl-34 {
    margin-bottom: 170px !important;
  }
  .ml-xl-34,
  .mx-xl-34 {
    margin-left: 170px !important;
  }
  .m-xl-35 {
    margin: 175px !important;
  }
  .mt-xl-35,
  .my-xl-35 {
    margin-top: 175px !important;
  }
  .mr-xl-35,
  .mx-xl-35 {
    margin-right: 175px !important;
  }
  .mb-xl-35,
  .my-xl-35 {
    margin-bottom: 175px !important;
  }
  .ml-xl-35,
  .mx-xl-35 {
    margin-left: 175px !important;
  }
  .m-xl-36 {
    margin: 180px !important;
  }
  .mt-xl-36,
  .my-xl-36 {
    margin-top: 180px !important;
  }
  .mr-xl-36,
  .mx-xl-36 {
    margin-right: 180px !important;
  }
  .mb-xl-36,
  .my-xl-36 {
    margin-bottom: 180px !important;
  }
  .ml-xl-36,
  .mx-xl-36 {
    margin-left: 180px !important;
  }
  .m-xl-37 {
    margin: 185px !important;
  }
  .mt-xl-37,
  .my-xl-37 {
    margin-top: 185px !important;
  }
  .mr-xl-37,
  .mx-xl-37 {
    margin-right: 185px !important;
  }
  .mb-xl-37,
  .my-xl-37 {
    margin-bottom: 185px !important;
  }
  .ml-xl-37,
  .mx-xl-37 {
    margin-left: 185px !important;
  }
  .m-xl-38 {
    margin: 190px !important;
  }
  .mt-xl-38,
  .my-xl-38 {
    margin-top: 190px !important;
  }
  .mr-xl-38,
  .mx-xl-38 {
    margin-right: 190px !important;
  }
  .mb-xl-38,
  .my-xl-38 {
    margin-bottom: 190px !important;
  }
  .ml-xl-38,
  .mx-xl-38 {
    margin-left: 190px !important;
  }
  .m-xl-39 {
    margin: 195px !important;
  }
  .mt-xl-39,
  .my-xl-39 {
    margin-top: 195px !important;
  }
  .mr-xl-39,
  .mx-xl-39 {
    margin-right: 195px !important;
  }
  .mb-xl-39,
  .my-xl-39 {
    margin-bottom: 195px !important;
  }
  .ml-xl-39,
  .mx-xl-39 {
    margin-left: 195px !important;
  }
  .m-xl-40 {
    margin: 200px !important;
  }
  .mt-xl-40,
  .my-xl-40 {
    margin-top: 200px !important;
  }
  .mr-xl-40,
  .mx-xl-40 {
    margin-right: 200px !important;
  }
  .mb-xl-40,
  .my-xl-40 {
    margin-bottom: 200px !important;
  }
  .ml-xl-40,
  .mx-xl-40 {
    margin-left: 200px !important;
  }
  .m-xl-41 {
    margin: 205px !important;
  }
  .mt-xl-41,
  .my-xl-41 {
    margin-top: 205px !important;
  }
  .mr-xl-41,
  .mx-xl-41 {
    margin-right: 205px !important;
  }
  .mb-xl-41,
  .my-xl-41 {
    margin-bottom: 205px !important;
  }
  .ml-xl-41,
  .mx-xl-41 {
    margin-left: 205px !important;
  }
  .m-xl-42 {
    margin: 210px !important;
  }
  .mt-xl-42,
  .my-xl-42 {
    margin-top: 210px !important;
  }
  .mr-xl-42,
  .mx-xl-42 {
    margin-right: 210px !important;
  }
  .mb-xl-42,
  .my-xl-42 {
    margin-bottom: 210px !important;
  }
  .ml-xl-42,
  .mx-xl-42 {
    margin-left: 210px !important;
  }
  .m-xl-43 {
    margin: 215px !important;
  }
  .mt-xl-43,
  .my-xl-43 {
    margin-top: 215px !important;
  }
  .mr-xl-43,
  .mx-xl-43 {
    margin-right: 215px !important;
  }
  .mb-xl-43,
  .my-xl-43 {
    margin-bottom: 215px !important;
  }
  .ml-xl-43,
  .mx-xl-43 {
    margin-left: 215px !important;
  }
  .m-xl-44 {
    margin: 220px !important;
  }
  .mt-xl-44,
  .my-xl-44 {
    margin-top: 220px !important;
  }
  .mr-xl-44,
  .mx-xl-44 {
    margin-right: 220px !important;
  }
  .mb-xl-44,
  .my-xl-44 {
    margin-bottom: 220px !important;
  }
  .ml-xl-44,
  .mx-xl-44 {
    margin-left: 220px !important;
  }
  .m-xl-45 {
    margin: 225px !important;
  }
  .mt-xl-45,
  .my-xl-45 {
    margin-top: 225px !important;
  }
  .mr-xl-45,
  .mx-xl-45 {
    margin-right: 225px !important;
  }
  .mb-xl-45,
  .my-xl-45 {
    margin-bottom: 225px !important;
  }
  .ml-xl-45,
  .mx-xl-45 {
    margin-left: 225px !important;
  }
  .m-xl-46 {
    margin: 230px !important;
  }
  .mt-xl-46,
  .my-xl-46 {
    margin-top: 230px !important;
  }
  .mr-xl-46,
  .mx-xl-46 {
    margin-right: 230px !important;
  }
  .mb-xl-46,
  .my-xl-46 {
    margin-bottom: 230px !important;
  }
  .ml-xl-46,
  .mx-xl-46 {
    margin-left: 230px !important;
  }
  .m-xl-47 {
    margin: 235px !important;
  }
  .mt-xl-47,
  .my-xl-47 {
    margin-top: 235px !important;
  }
  .mr-xl-47,
  .mx-xl-47 {
    margin-right: 235px !important;
  }
  .mb-xl-47,
  .my-xl-47 {
    margin-bottom: 235px !important;
  }
  .ml-xl-47,
  .mx-xl-47 {
    margin-left: 235px !important;
  }
  .m-xl-48 {
    margin: 240px !important;
  }
  .mt-xl-48,
  .my-xl-48 {
    margin-top: 240px !important;
  }
  .mr-xl-48,
  .mx-xl-48 {
    margin-right: 240px !important;
  }
  .mb-xl-48,
  .my-xl-48 {
    margin-bottom: 240px !important;
  }
  .ml-xl-48,
  .mx-xl-48 {
    margin-left: 240px !important;
  }
  .m-xl-49 {
    margin: 245px !important;
  }
  .mt-xl-49,
  .my-xl-49 {
    margin-top: 245px !important;
  }
  .mr-xl-49,
  .mx-xl-49 {
    margin-right: 245px !important;
  }
  .mb-xl-49,
  .my-xl-49 {
    margin-bottom: 245px !important;
  }
  .ml-xl-49,
  .mx-xl-49 {
    margin-left: 245px !important;
  }
  .m-xl-50 {
    margin: 250px !important;
  }
  .mt-xl-50,
  .my-xl-50 {
    margin-top: 250px !important;
  }
  .mr-xl-50,
  .mx-xl-50 {
    margin-right: 250px !important;
  }
  .mb-xl-50,
  .my-xl-50 {
    margin-bottom: 250px !important;
  }
  .ml-xl-50,
  .mx-xl-50 {
    margin-left: 250px !important;
  }
  .m-xl-51 {
    margin: 255px !important;
  }
  .mt-xl-51,
  .my-xl-51 {
    margin-top: 255px !important;
  }
  .mr-xl-51,
  .mx-xl-51 {
    margin-right: 255px !important;
  }
  .mb-xl-51,
  .my-xl-51 {
    margin-bottom: 255px !important;
  }
  .ml-xl-51,
  .mx-xl-51 {
    margin-left: 255px !important;
  }
  .m-xl-52 {
    margin: 260px !important;
  }
  .mt-xl-52,
  .my-xl-52 {
    margin-top: 260px !important;
  }
  .mr-xl-52,
  .mx-xl-52 {
    margin-right: 260px !important;
  }
  .mb-xl-52,
  .my-xl-52 {
    margin-bottom: 260px !important;
  }
  .ml-xl-52,
  .mx-xl-52 {
    margin-left: 260px !important;
  }
  .m-xl-53 {
    margin: 265px !important;
  }
  .mt-xl-53,
  .my-xl-53 {
    margin-top: 265px !important;
  }
  .mr-xl-53,
  .mx-xl-53 {
    margin-right: 265px !important;
  }
  .mb-xl-53,
  .my-xl-53 {
    margin-bottom: 265px !important;
  }
  .ml-xl-53,
  .mx-xl-53 {
    margin-left: 265px !important;
  }
  .m-xl-54 {
    margin: 270px !important;
  }
  .mt-xl-54,
  .my-xl-54 {
    margin-top: 270px !important;
  }
  .mr-xl-54,
  .mx-xl-54 {
    margin-right: 270px !important;
  }
  .mb-xl-54,
  .my-xl-54 {
    margin-bottom: 270px !important;
  }
  .ml-xl-54,
  .mx-xl-54 {
    margin-left: 270px !important;
  }
  .m-xl-55 {
    margin: 275px !important;
  }
  .mt-xl-55,
  .my-xl-55 {
    margin-top: 275px !important;
  }
  .mr-xl-55,
  .mx-xl-55 {
    margin-right: 275px !important;
  }
  .mb-xl-55,
  .my-xl-55 {
    margin-bottom: 275px !important;
  }
  .ml-xl-55,
  .mx-xl-55 {
    margin-left: 275px !important;
  }
  .m-xl-56 {
    margin: 280px !important;
  }
  .mt-xl-56,
  .my-xl-56 {
    margin-top: 280px !important;
  }
  .mr-xl-56,
  .mx-xl-56 {
    margin-right: 280px !important;
  }
  .mb-xl-56,
  .my-xl-56 {
    margin-bottom: 280px !important;
  }
  .ml-xl-56,
  .mx-xl-56 {
    margin-left: 280px !important;
  }
  .m-xl-57 {
    margin: 285px !important;
  }
  .mt-xl-57,
  .my-xl-57 {
    margin-top: 285px !important;
  }
  .mr-xl-57,
  .mx-xl-57 {
    margin-right: 285px !important;
  }
  .mb-xl-57,
  .my-xl-57 {
    margin-bottom: 285px !important;
  }
  .ml-xl-57,
  .mx-xl-57 {
    margin-left: 285px !important;
  }
  .m-xl-58 {
    margin: 290px !important;
  }
  .mt-xl-58,
  .my-xl-58 {
    margin-top: 290px !important;
  }
  .mr-xl-58,
  .mx-xl-58 {
    margin-right: 290px !important;
  }
  .mb-xl-58,
  .my-xl-58 {
    margin-bottom: 290px !important;
  }
  .ml-xl-58,
  .mx-xl-58 {
    margin-left: 290px !important;
  }
  .m-xl-59 {
    margin: 295px !important;
  }
  .mt-xl-59,
  .my-xl-59 {
    margin-top: 295px !important;
  }
  .mr-xl-59,
  .mx-xl-59 {
    margin-right: 295px !important;
  }
  .mb-xl-59,
  .my-xl-59 {
    margin-bottom: 295px !important;
  }
  .ml-xl-59,
  .mx-xl-59 {
    margin-left: 295px !important;
  }
  .m-xl-60 {
    margin: 300px !important;
  }
  .mt-xl-60,
  .my-xl-60 {
    margin-top: 300px !important;
  }
  .mr-xl-60,
  .mx-xl-60 {
    margin-right: 300px !important;
  }
  .mb-xl-60,
  .my-xl-60 {
    margin-bottom: 300px !important;
  }
  .ml-xl-60,
  .mx-xl-60 {
    margin-left: 300px !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 5px !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 5px !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 5px !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 5px !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 5px !important;
  }
  .p-xl-2 {
    padding: 10px !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 10px !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 10px !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 10px !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 10px !important;
  }
  .p-xl-3 {
    padding: 16px !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 16px !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 16px !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 16px !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 16px !important;
  }
  .p-xl-4 {
    padding: 20px !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 20px !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 20px !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 20px !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 20px !important;
  }
  .p-xl-5 {
    padding: 25px !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 25px !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 25px !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 25px !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 25px !important;
  }
  .p-xl-6 {
    padding: 30px !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 30px !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 30px !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 30px !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 30px !important;
  }
  .p-xl-7 {
    padding: 35px !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 35px !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 35px !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 35px !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 35px !important;
  }
  .p-xl-8 {
    padding: 40px !important;
  }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 40px !important;
  }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 40px !important;
  }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 40px !important;
  }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 40px !important;
  }
  .p-xl-9 {
    padding: 45px !important;
  }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 45px !important;
  }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 45px !important;
  }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 45px !important;
  }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 45px !important;
  }
  .p-xl-10 {
    padding: 50px !important;
  }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 50px !important;
  }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 50px !important;
  }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 50px !important;
  }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 50px !important;
  }
  .p-xl-11 {
    padding: 55px !important;
  }
  .pt-xl-11,
  .py-xl-11 {
    padding-top: 55px !important;
  }
  .pr-xl-11,
  .px-xl-11 {
    padding-right: 55px !important;
  }
  .pb-xl-11,
  .py-xl-11 {
    padding-bottom: 55px !important;
  }
  .pl-xl-11,
  .px-xl-11 {
    padding-left: 55px !important;
  }
  .p-xl-12 {
    padding: 60px !important;
  }
  .pt-xl-12,
  .py-xl-12 {
    padding-top: 60px !important;
  }
  .pr-xl-12,
  .px-xl-12 {
    padding-right: 60px !important;
  }
  .pb-xl-12,
  .py-xl-12 {
    padding-bottom: 60px !important;
  }
  .pl-xl-12,
  .px-xl-12 {
    padding-left: 60px !important;
  }
  .p-xl-13 {
    padding: 65px !important;
  }
  .pt-xl-13,
  .py-xl-13 {
    padding-top: 65px !important;
  }
  .pr-xl-13,
  .px-xl-13 {
    padding-right: 65px !important;
  }
  .pb-xl-13,
  .py-xl-13 {
    padding-bottom: 65px !important;
  }
  .pl-xl-13,
  .px-xl-13 {
    padding-left: 65px !important;
  }
  .p-xl-14 {
    padding: 70px !important;
  }
  .pt-xl-14,
  .py-xl-14 {
    padding-top: 70px !important;
  }
  .pr-xl-14,
  .px-xl-14 {
    padding-right: 70px !important;
  }
  .pb-xl-14,
  .py-xl-14 {
    padding-bottom: 70px !important;
  }
  .pl-xl-14,
  .px-xl-14 {
    padding-left: 70px !important;
  }
  .p-xl-15 {
    padding: 75px !important;
  }
  .pt-xl-15,
  .py-xl-15 {
    padding-top: 75px !important;
  }
  .pr-xl-15,
  .px-xl-15 {
    padding-right: 75px !important;
  }
  .pb-xl-15,
  .py-xl-15 {
    padding-bottom: 75px !important;
  }
  .pl-xl-15,
  .px-xl-15 {
    padding-left: 75px !important;
  }
  .p-xl-16 {
    padding: 80px !important;
  }
  .pt-xl-16,
  .py-xl-16 {
    padding-top: 80px !important;
  }
  .pr-xl-16,
  .px-xl-16 {
    padding-right: 80px !important;
  }
  .pb-xl-16,
  .py-xl-16 {
    padding-bottom: 80px !important;
  }
  .pl-xl-16,
  .px-xl-16 {
    padding-left: 80px !important;
  }
  .p-xl-17 {
    padding: 85px !important;
  }
  .pt-xl-17,
  .py-xl-17 {
    padding-top: 85px !important;
  }
  .pr-xl-17,
  .px-xl-17 {
    padding-right: 85px !important;
  }
  .pb-xl-17,
  .py-xl-17 {
    padding-bottom: 85px !important;
  }
  .pl-xl-17,
  .px-xl-17 {
    padding-left: 85px !important;
  }
  .p-xl-18 {
    padding: 90px !important;
  }
  .pt-xl-18,
  .py-xl-18 {
    padding-top: 90px !important;
  }
  .pr-xl-18,
  .px-xl-18 {
    padding-right: 90px !important;
  }
  .pb-xl-18,
  .py-xl-18 {
    padding-bottom: 90px !important;
  }
  .pl-xl-18,
  .px-xl-18 {
    padding-left: 90px !important;
  }
  .p-xl-19 {
    padding: 95px !important;
  }
  .pt-xl-19,
  .py-xl-19 {
    padding-top: 95px !important;
  }
  .pr-xl-19,
  .px-xl-19 {
    padding-right: 95px !important;
  }
  .pb-xl-19,
  .py-xl-19 {
    padding-bottom: 95px !important;
  }
  .pl-xl-19,
  .px-xl-19 {
    padding-left: 95px !important;
  }
  .p-xl-20 {
    padding: 100px !important;
  }
  .pt-xl-20,
  .py-xl-20 {
    padding-top: 100px !important;
  }
  .pr-xl-20,
  .px-xl-20 {
    padding-right: 100px !important;
  }
  .pb-xl-20,
  .py-xl-20 {
    padding-bottom: 100px !important;
  }
  .pl-xl-20,
  .px-xl-20 {
    padding-left: 100px !important;
  }
  .p-xl-21 {
    padding: 105px !important;
  }
  .pt-xl-21,
  .py-xl-21 {
    padding-top: 105px !important;
  }
  .pr-xl-21,
  .px-xl-21 {
    padding-right: 105px !important;
  }
  .pb-xl-21,
  .py-xl-21 {
    padding-bottom: 105px !important;
  }
  .pl-xl-21,
  .px-xl-21 {
    padding-left: 105px !important;
  }
  .p-xl-22 {
    padding: 110px !important;
  }
  .pt-xl-22,
  .py-xl-22 {
    padding-top: 110px !important;
  }
  .pr-xl-22,
  .px-xl-22 {
    padding-right: 110px !important;
  }
  .pb-xl-22,
  .py-xl-22 {
    padding-bottom: 110px !important;
  }
  .pl-xl-22,
  .px-xl-22 {
    padding-left: 110px !important;
  }
  .p-xl-23 {
    padding: 115px !important;
  }
  .pt-xl-23,
  .py-xl-23 {
    padding-top: 115px !important;
  }
  .pr-xl-23,
  .px-xl-23 {
    padding-right: 115px !important;
  }
  .pb-xl-23,
  .py-xl-23 {
    padding-bottom: 115px !important;
  }
  .pl-xl-23,
  .px-xl-23 {
    padding-left: 115px !important;
  }
  .p-xl-24 {
    padding: 120px !important;
  }
  .pt-xl-24,
  .py-xl-24 {
    padding-top: 120px !important;
  }
  .pr-xl-24,
  .px-xl-24 {
    padding-right: 120px !important;
  }
  .pb-xl-24,
  .py-xl-24 {
    padding-bottom: 120px !important;
  }
  .pl-xl-24,
  .px-xl-24 {
    padding-left: 120px !important;
  }
  .p-xl-25 {
    padding: 125px !important;
  }
  .pt-xl-25,
  .py-xl-25 {
    padding-top: 125px !important;
  }
  .pr-xl-25,
  .px-xl-25 {
    padding-right: 125px !important;
  }
  .pb-xl-25,
  .py-xl-25 {
    padding-bottom: 125px !important;
  }
  .pl-xl-25,
  .px-xl-25 {
    padding-left: 125px !important;
  }
  .p-xl-26 {
    padding: 130px !important;
  }
  .pt-xl-26,
  .py-xl-26 {
    padding-top: 130px !important;
  }
  .pr-xl-26,
  .px-xl-26 {
    padding-right: 130px !important;
  }
  .pb-xl-26,
  .py-xl-26 {
    padding-bottom: 130px !important;
  }
  .pl-xl-26,
  .px-xl-26 {
    padding-left: 130px !important;
  }
  .p-xl-27 {
    padding: 135px !important;
  }
  .pt-xl-27,
  .py-xl-27 {
    padding-top: 135px !important;
  }
  .pr-xl-27,
  .px-xl-27 {
    padding-right: 135px !important;
  }
  .pb-xl-27,
  .py-xl-27 {
    padding-bottom: 135px !important;
  }
  .pl-xl-27,
  .px-xl-27 {
    padding-left: 135px !important;
  }
  .p-xl-28 {
    padding: 140px !important;
  }
  .pt-xl-28,
  .py-xl-28 {
    padding-top: 140px !important;
  }
  .pr-xl-28,
  .px-xl-28 {
    padding-right: 140px !important;
  }
  .pb-xl-28,
  .py-xl-28 {
    padding-bottom: 140px !important;
  }
  .pl-xl-28,
  .px-xl-28 {
    padding-left: 140px !important;
  }
  .p-xl-29 {
    padding: 145px !important;
  }
  .pt-xl-29,
  .py-xl-29 {
    padding-top: 145px !important;
  }
  .pr-xl-29,
  .px-xl-29 {
    padding-right: 145px !important;
  }
  .pb-xl-29,
  .py-xl-29 {
    padding-bottom: 145px !important;
  }
  .pl-xl-29,
  .px-xl-29 {
    padding-left: 145px !important;
  }
  .p-xl-30 {
    padding: 150px !important;
  }
  .pt-xl-30,
  .py-xl-30 {
    padding-top: 150px !important;
  }
  .pr-xl-30,
  .px-xl-30 {
    padding-right: 150px !important;
  }
  .pb-xl-30,
  .py-xl-30 {
    padding-bottom: 150px !important;
  }
  .pl-xl-30,
  .px-xl-30 {
    padding-left: 150px !important;
  }
  .p-xl-31 {
    padding: 155px !important;
  }
  .pt-xl-31,
  .py-xl-31 {
    padding-top: 155px !important;
  }
  .pr-xl-31,
  .px-xl-31 {
    padding-right: 155px !important;
  }
  .pb-xl-31,
  .py-xl-31 {
    padding-bottom: 155px !important;
  }
  .pl-xl-31,
  .px-xl-31 {
    padding-left: 155px !important;
  }
  .p-xl-32 {
    padding: 160px !important;
  }
  .pt-xl-32,
  .py-xl-32 {
    padding-top: 160px !important;
  }
  .pr-xl-32,
  .px-xl-32 {
    padding-right: 160px !important;
  }
  .pb-xl-32,
  .py-xl-32 {
    padding-bottom: 160px !important;
  }
  .pl-xl-32,
  .px-xl-32 {
    padding-left: 160px !important;
  }
  .p-xl-33 {
    padding: 165px !important;
  }
  .pt-xl-33,
  .py-xl-33 {
    padding-top: 165px !important;
  }
  .pr-xl-33,
  .px-xl-33 {
    padding-right: 165px !important;
  }
  .pb-xl-33,
  .py-xl-33 {
    padding-bottom: 165px !important;
  }
  .pl-xl-33,
  .px-xl-33 {
    padding-left: 165px !important;
  }
  .p-xl-34 {
    padding: 170px !important;
  }
  .pt-xl-34,
  .py-xl-34 {
    padding-top: 170px !important;
  }
  .pr-xl-34,
  .px-xl-34 {
    padding-right: 170px !important;
  }
  .pb-xl-34,
  .py-xl-34 {
    padding-bottom: 170px !important;
  }
  .pl-xl-34,
  .px-xl-34 {
    padding-left: 170px !important;
  }
  .p-xl-35 {
    padding: 175px !important;
  }
  .pt-xl-35,
  .py-xl-35 {
    padding-top: 175px !important;
  }
  .pr-xl-35,
  .px-xl-35 {
    padding-right: 175px !important;
  }
  .pb-xl-35,
  .py-xl-35 {
    padding-bottom: 175px !important;
  }
  .pl-xl-35,
  .px-xl-35 {
    padding-left: 175px !important;
  }
  .p-xl-36 {
    padding: 180px !important;
  }
  .pt-xl-36,
  .py-xl-36 {
    padding-top: 180px !important;
  }
  .pr-xl-36,
  .px-xl-36 {
    padding-right: 180px !important;
  }
  .pb-xl-36,
  .py-xl-36 {
    padding-bottom: 180px !important;
  }
  .pl-xl-36,
  .px-xl-36 {
    padding-left: 180px !important;
  }
  .p-xl-37 {
    padding: 185px !important;
  }
  .pt-xl-37,
  .py-xl-37 {
    padding-top: 185px !important;
  }
  .pr-xl-37,
  .px-xl-37 {
    padding-right: 185px !important;
  }
  .pb-xl-37,
  .py-xl-37 {
    padding-bottom: 185px !important;
  }
  .pl-xl-37,
  .px-xl-37 {
    padding-left: 185px !important;
  }
  .p-xl-38 {
    padding: 190px !important;
  }
  .pt-xl-38,
  .py-xl-38 {
    padding-top: 190px !important;
  }
  .pr-xl-38,
  .px-xl-38 {
    padding-right: 190px !important;
  }
  .pb-xl-38,
  .py-xl-38 {
    padding-bottom: 190px !important;
  }
  .pl-xl-38,
  .px-xl-38 {
    padding-left: 190px !important;
  }
  .p-xl-39 {
    padding: 195px !important;
  }
  .pt-xl-39,
  .py-xl-39 {
    padding-top: 195px !important;
  }
  .pr-xl-39,
  .px-xl-39 {
    padding-right: 195px !important;
  }
  .pb-xl-39,
  .py-xl-39 {
    padding-bottom: 195px !important;
  }
  .pl-xl-39,
  .px-xl-39 {
    padding-left: 195px !important;
  }
  .p-xl-40 {
    padding: 200px !important;
  }
  .pt-xl-40,
  .py-xl-40 {
    padding-top: 200px !important;
  }
  .pr-xl-40,
  .px-xl-40 {
    padding-right: 200px !important;
  }
  .pb-xl-40,
  .py-xl-40 {
    padding-bottom: 200px !important;
  }
  .pl-xl-40,
  .px-xl-40 {
    padding-left: 200px !important;
  }
  .p-xl-41 {
    padding: 205px !important;
  }
  .pt-xl-41,
  .py-xl-41 {
    padding-top: 205px !important;
  }
  .pr-xl-41,
  .px-xl-41 {
    padding-right: 205px !important;
  }
  .pb-xl-41,
  .py-xl-41 {
    padding-bottom: 205px !important;
  }
  .pl-xl-41,
  .px-xl-41 {
    padding-left: 205px !important;
  }
  .p-xl-42 {
    padding: 210px !important;
  }
  .pt-xl-42,
  .py-xl-42 {
    padding-top: 210px !important;
  }
  .pr-xl-42,
  .px-xl-42 {
    padding-right: 210px !important;
  }
  .pb-xl-42,
  .py-xl-42 {
    padding-bottom: 210px !important;
  }
  .pl-xl-42,
  .px-xl-42 {
    padding-left: 210px !important;
  }
  .p-xl-43 {
    padding: 215px !important;
  }
  .pt-xl-43,
  .py-xl-43 {
    padding-top: 215px !important;
  }
  .pr-xl-43,
  .px-xl-43 {
    padding-right: 215px !important;
  }
  .pb-xl-43,
  .py-xl-43 {
    padding-bottom: 215px !important;
  }
  .pl-xl-43,
  .px-xl-43 {
    padding-left: 215px !important;
  }
  .p-xl-44 {
    padding: 220px !important;
  }
  .pt-xl-44,
  .py-xl-44 {
    padding-top: 220px !important;
  }
  .pr-xl-44,
  .px-xl-44 {
    padding-right: 220px !important;
  }
  .pb-xl-44,
  .py-xl-44 {
    padding-bottom: 220px !important;
  }
  .pl-xl-44,
  .px-xl-44 {
    padding-left: 220px !important;
  }
  .p-xl-45 {
    padding: 225px !important;
  }
  .pt-xl-45,
  .py-xl-45 {
    padding-top: 225px !important;
  }
  .pr-xl-45,
  .px-xl-45 {
    padding-right: 225px !important;
  }
  .pb-xl-45,
  .py-xl-45 {
    padding-bottom: 225px !important;
  }
  .pl-xl-45,
  .px-xl-45 {
    padding-left: 225px !important;
  }
  .p-xl-46 {
    padding: 230px !important;
  }
  .pt-xl-46,
  .py-xl-46 {
    padding-top: 230px !important;
  }
  .pr-xl-46,
  .px-xl-46 {
    padding-right: 230px !important;
  }
  .pb-xl-46,
  .py-xl-46 {
    padding-bottom: 230px !important;
  }
  .pl-xl-46,
  .px-xl-46 {
    padding-left: 230px !important;
  }
  .p-xl-47 {
    padding: 235px !important;
  }
  .pt-xl-47,
  .py-xl-47 {
    padding-top: 235px !important;
  }
  .pr-xl-47,
  .px-xl-47 {
    padding-right: 235px !important;
  }
  .pb-xl-47,
  .py-xl-47 {
    padding-bottom: 235px !important;
  }
  .pl-xl-47,
  .px-xl-47 {
    padding-left: 235px !important;
  }
  .p-xl-48 {
    padding: 240px !important;
  }
  .pt-xl-48,
  .py-xl-48 {
    padding-top: 240px !important;
  }
  .pr-xl-48,
  .px-xl-48 {
    padding-right: 240px !important;
  }
  .pb-xl-48,
  .py-xl-48 {
    padding-bottom: 240px !important;
  }
  .pl-xl-48,
  .px-xl-48 {
    padding-left: 240px !important;
  }
  .p-xl-49 {
    padding: 245px !important;
  }
  .pt-xl-49,
  .py-xl-49 {
    padding-top: 245px !important;
  }
  .pr-xl-49,
  .px-xl-49 {
    padding-right: 245px !important;
  }
  .pb-xl-49,
  .py-xl-49 {
    padding-bottom: 245px !important;
  }
  .pl-xl-49,
  .px-xl-49 {
    padding-left: 245px !important;
  }
  .p-xl-50 {
    padding: 250px !important;
  }
  .pt-xl-50,
  .py-xl-50 {
    padding-top: 250px !important;
  }
  .pr-xl-50,
  .px-xl-50 {
    padding-right: 250px !important;
  }
  .pb-xl-50,
  .py-xl-50 {
    padding-bottom: 250px !important;
  }
  .pl-xl-50,
  .px-xl-50 {
    padding-left: 250px !important;
  }
  .p-xl-51 {
    padding: 255px !important;
  }
  .pt-xl-51,
  .py-xl-51 {
    padding-top: 255px !important;
  }
  .pr-xl-51,
  .px-xl-51 {
    padding-right: 255px !important;
  }
  .pb-xl-51,
  .py-xl-51 {
    padding-bottom: 255px !important;
  }
  .pl-xl-51,
  .px-xl-51 {
    padding-left: 255px !important;
  }
  .p-xl-52 {
    padding: 260px !important;
  }
  .pt-xl-52,
  .py-xl-52 {
    padding-top: 260px !important;
  }
  .pr-xl-52,
  .px-xl-52 {
    padding-right: 260px !important;
  }
  .pb-xl-52,
  .py-xl-52 {
    padding-bottom: 260px !important;
  }
  .pl-xl-52,
  .px-xl-52 {
    padding-left: 260px !important;
  }
  .p-xl-53 {
    padding: 265px !important;
  }
  .pt-xl-53,
  .py-xl-53 {
    padding-top: 265px !important;
  }
  .pr-xl-53,
  .px-xl-53 {
    padding-right: 265px !important;
  }
  .pb-xl-53,
  .py-xl-53 {
    padding-bottom: 265px !important;
  }
  .pl-xl-53,
  .px-xl-53 {
    padding-left: 265px !important;
  }
  .p-xl-54 {
    padding: 270px !important;
  }
  .pt-xl-54,
  .py-xl-54 {
    padding-top: 270px !important;
  }
  .pr-xl-54,
  .px-xl-54 {
    padding-right: 270px !important;
  }
  .pb-xl-54,
  .py-xl-54 {
    padding-bottom: 270px !important;
  }
  .pl-xl-54,
  .px-xl-54 {
    padding-left: 270px !important;
  }
  .p-xl-55 {
    padding: 275px !important;
  }
  .pt-xl-55,
  .py-xl-55 {
    padding-top: 275px !important;
  }
  .pr-xl-55,
  .px-xl-55 {
    padding-right: 275px !important;
  }
  .pb-xl-55,
  .py-xl-55 {
    padding-bottom: 275px !important;
  }
  .pl-xl-55,
  .px-xl-55 {
    padding-left: 275px !important;
  }
  .p-xl-56 {
    padding: 280px !important;
  }
  .pt-xl-56,
  .py-xl-56 {
    padding-top: 280px !important;
  }
  .pr-xl-56,
  .px-xl-56 {
    padding-right: 280px !important;
  }
  .pb-xl-56,
  .py-xl-56 {
    padding-bottom: 280px !important;
  }
  .pl-xl-56,
  .px-xl-56 {
    padding-left: 280px !important;
  }
  .p-xl-57 {
    padding: 285px !important;
  }
  .pt-xl-57,
  .py-xl-57 {
    padding-top: 285px !important;
  }
  .pr-xl-57,
  .px-xl-57 {
    padding-right: 285px !important;
  }
  .pb-xl-57,
  .py-xl-57 {
    padding-bottom: 285px !important;
  }
  .pl-xl-57,
  .px-xl-57 {
    padding-left: 285px !important;
  }
  .p-xl-58 {
    padding: 290px !important;
  }
  .pt-xl-58,
  .py-xl-58 {
    padding-top: 290px !important;
  }
  .pr-xl-58,
  .px-xl-58 {
    padding-right: 290px !important;
  }
  .pb-xl-58,
  .py-xl-58 {
    padding-bottom: 290px !important;
  }
  .pl-xl-58,
  .px-xl-58 {
    padding-left: 290px !important;
  }
  .p-xl-59 {
    padding: 295px !important;
  }
  .pt-xl-59,
  .py-xl-59 {
    padding-top: 295px !important;
  }
  .pr-xl-59,
  .px-xl-59 {
    padding-right: 295px !important;
  }
  .pb-xl-59,
  .py-xl-59 {
    padding-bottom: 295px !important;
  }
  .pl-xl-59,
  .px-xl-59 {
    padding-left: 295px !important;
  }
  .p-xl-60 {
    padding: 300px !important;
  }
  .pt-xl-60,
  .py-xl-60 {
    padding-top: 300px !important;
  }
  .pr-xl-60,
  .px-xl-60 {
    padding-right: 300px !important;
  }
  .pb-xl-60,
  .py-xl-60 {
    padding-bottom: 300px !important;
  }
  .pl-xl-60,
  .px-xl-60 {
    padding-left: 300px !important;
  }
  .m-xl-n1 {
    margin: -5px !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -5px !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -5px !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -5px !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -5px !important;
  }
  .m-xl-n2 {
    margin: -10px !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -10px !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -10px !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -10px !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -10px !important;
  }
  .m-xl-n3 {
    margin: -16px !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -16px !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -16px !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -16px !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -16px !important;
  }
  .m-xl-n4 {
    margin: -20px !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -20px !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -20px !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -20px !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -20px !important;
  }
  .m-xl-n5 {
    margin: -25px !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -25px !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -25px !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -25px !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -25px !important;
  }
  .m-xl-n6 {
    margin: -30px !important;
  }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -30px !important;
  }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -30px !important;
  }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -30px !important;
  }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -30px !important;
  }
  .m-xl-n7 {
    margin: -35px !important;
  }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -35px !important;
  }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -35px !important;
  }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -35px !important;
  }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -35px !important;
  }
  .m-xl-n8 {
    margin: -40px !important;
  }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -40px !important;
  }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -40px !important;
  }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -40px !important;
  }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -40px !important;
  }
  .m-xl-n9 {
    margin: -45px !important;
  }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -45px !important;
  }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -45px !important;
  }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -45px !important;
  }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -45px !important;
  }
  .m-xl-n10 {
    margin: -50px !important;
  }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -50px !important;
  }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -50px !important;
  }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -50px !important;
  }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -50px !important;
  }
  .m-xl-n11 {
    margin: -55px !important;
  }
  .mt-xl-n11,
  .my-xl-n11 {
    margin-top: -55px !important;
  }
  .mr-xl-n11,
  .mx-xl-n11 {
    margin-right: -55px !important;
  }
  .mb-xl-n11,
  .my-xl-n11 {
    margin-bottom: -55px !important;
  }
  .ml-xl-n11,
  .mx-xl-n11 {
    margin-left: -55px !important;
  }
  .m-xl-n12 {
    margin: -60px !important;
  }
  .mt-xl-n12,
  .my-xl-n12 {
    margin-top: -60px !important;
  }
  .mr-xl-n12,
  .mx-xl-n12 {
    margin-right: -60px !important;
  }
  .mb-xl-n12,
  .my-xl-n12 {
    margin-bottom: -60px !important;
  }
  .ml-xl-n12,
  .mx-xl-n12 {
    margin-left: -60px !important;
  }
  .m-xl-n13 {
    margin: -65px !important;
  }
  .mt-xl-n13,
  .my-xl-n13 {
    margin-top: -65px !important;
  }
  .mr-xl-n13,
  .mx-xl-n13 {
    margin-right: -65px !important;
  }
  .mb-xl-n13,
  .my-xl-n13 {
    margin-bottom: -65px !important;
  }
  .ml-xl-n13,
  .mx-xl-n13 {
    margin-left: -65px !important;
  }
  .m-xl-n14 {
    margin: -70px !important;
  }
  .mt-xl-n14,
  .my-xl-n14 {
    margin-top: -70px !important;
  }
  .mr-xl-n14,
  .mx-xl-n14 {
    margin-right: -70px !important;
  }
  .mb-xl-n14,
  .my-xl-n14 {
    margin-bottom: -70px !important;
  }
  .ml-xl-n14,
  .mx-xl-n14 {
    margin-left: -70px !important;
  }
  .m-xl-n15 {
    margin: -75px !important;
  }
  .mt-xl-n15,
  .my-xl-n15 {
    margin-top: -75px !important;
  }
  .mr-xl-n15,
  .mx-xl-n15 {
    margin-right: -75px !important;
  }
  .mb-xl-n15,
  .my-xl-n15 {
    margin-bottom: -75px !important;
  }
  .ml-xl-n15,
  .mx-xl-n15 {
    margin-left: -75px !important;
  }
  .m-xl-n16 {
    margin: -80px !important;
  }
  .mt-xl-n16,
  .my-xl-n16 {
    margin-top: -80px !important;
  }
  .mr-xl-n16,
  .mx-xl-n16 {
    margin-right: -80px !important;
  }
  .mb-xl-n16,
  .my-xl-n16 {
    margin-bottom: -80px !important;
  }
  .ml-xl-n16,
  .mx-xl-n16 {
    margin-left: -80px !important;
  }
  .m-xl-n17 {
    margin: -85px !important;
  }
  .mt-xl-n17,
  .my-xl-n17 {
    margin-top: -85px !important;
  }
  .mr-xl-n17,
  .mx-xl-n17 {
    margin-right: -85px !important;
  }
  .mb-xl-n17,
  .my-xl-n17 {
    margin-bottom: -85px !important;
  }
  .ml-xl-n17,
  .mx-xl-n17 {
    margin-left: -85px !important;
  }
  .m-xl-n18 {
    margin: -90px !important;
  }
  .mt-xl-n18,
  .my-xl-n18 {
    margin-top: -90px !important;
  }
  .mr-xl-n18,
  .mx-xl-n18 {
    margin-right: -90px !important;
  }
  .mb-xl-n18,
  .my-xl-n18 {
    margin-bottom: -90px !important;
  }
  .ml-xl-n18,
  .mx-xl-n18 {
    margin-left: -90px !important;
  }
  .m-xl-n19 {
    margin: -95px !important;
  }
  .mt-xl-n19,
  .my-xl-n19 {
    margin-top: -95px !important;
  }
  .mr-xl-n19,
  .mx-xl-n19 {
    margin-right: -95px !important;
  }
  .mb-xl-n19,
  .my-xl-n19 {
    margin-bottom: -95px !important;
  }
  .ml-xl-n19,
  .mx-xl-n19 {
    margin-left: -95px !important;
  }
  .m-xl-n20 {
    margin: -100px !important;
  }
  .mt-xl-n20,
  .my-xl-n20 {
    margin-top: -100px !important;
  }
  .mr-xl-n20,
  .mx-xl-n20 {
    margin-right: -100px !important;
  }
  .mb-xl-n20,
  .my-xl-n20 {
    margin-bottom: -100px !important;
  }
  .ml-xl-n20,
  .mx-xl-n20 {
    margin-left: -100px !important;
  }
  .m-xl-n21 {
    margin: -105px !important;
  }
  .mt-xl-n21,
  .my-xl-n21 {
    margin-top: -105px !important;
  }
  .mr-xl-n21,
  .mx-xl-n21 {
    margin-right: -105px !important;
  }
  .mb-xl-n21,
  .my-xl-n21 {
    margin-bottom: -105px !important;
  }
  .ml-xl-n21,
  .mx-xl-n21 {
    margin-left: -105px !important;
  }
  .m-xl-n22 {
    margin: -110px !important;
  }
  .mt-xl-n22,
  .my-xl-n22 {
    margin-top: -110px !important;
  }
  .mr-xl-n22,
  .mx-xl-n22 {
    margin-right: -110px !important;
  }
  .mb-xl-n22,
  .my-xl-n22 {
    margin-bottom: -110px !important;
  }
  .ml-xl-n22,
  .mx-xl-n22 {
    margin-left: -110px !important;
  }
  .m-xl-n23 {
    margin: -115px !important;
  }
  .mt-xl-n23,
  .my-xl-n23 {
    margin-top: -115px !important;
  }
  .mr-xl-n23,
  .mx-xl-n23 {
    margin-right: -115px !important;
  }
  .mb-xl-n23,
  .my-xl-n23 {
    margin-bottom: -115px !important;
  }
  .ml-xl-n23,
  .mx-xl-n23 {
    margin-left: -115px !important;
  }
  .m-xl-n24 {
    margin: -120px !important;
  }
  .mt-xl-n24,
  .my-xl-n24 {
    margin-top: -120px !important;
  }
  .mr-xl-n24,
  .mx-xl-n24 {
    margin-right: -120px !important;
  }
  .mb-xl-n24,
  .my-xl-n24 {
    margin-bottom: -120px !important;
  }
  .ml-xl-n24,
  .mx-xl-n24 {
    margin-left: -120px !important;
  }
  .m-xl-n25 {
    margin: -125px !important;
  }
  .mt-xl-n25,
  .my-xl-n25 {
    margin-top: -125px !important;
  }
  .mr-xl-n25,
  .mx-xl-n25 {
    margin-right: -125px !important;
  }
  .mb-xl-n25,
  .my-xl-n25 {
    margin-bottom: -125px !important;
  }
  .ml-xl-n25,
  .mx-xl-n25 {
    margin-left: -125px !important;
  }
  .m-xl-n26 {
    margin: -130px !important;
  }
  .mt-xl-n26,
  .my-xl-n26 {
    margin-top: -130px !important;
  }
  .mr-xl-n26,
  .mx-xl-n26 {
    margin-right: -130px !important;
  }
  .mb-xl-n26,
  .my-xl-n26 {
    margin-bottom: -130px !important;
  }
  .ml-xl-n26,
  .mx-xl-n26 {
    margin-left: -130px !important;
  }
  .m-xl-n27 {
    margin: -135px !important;
  }
  .mt-xl-n27,
  .my-xl-n27 {
    margin-top: -135px !important;
  }
  .mr-xl-n27,
  .mx-xl-n27 {
    margin-right: -135px !important;
  }
  .mb-xl-n27,
  .my-xl-n27 {
    margin-bottom: -135px !important;
  }
  .ml-xl-n27,
  .mx-xl-n27 {
    margin-left: -135px !important;
  }
  .m-xl-n28 {
    margin: -140px !important;
  }
  .mt-xl-n28,
  .my-xl-n28 {
    margin-top: -140px !important;
  }
  .mr-xl-n28,
  .mx-xl-n28 {
    margin-right: -140px !important;
  }
  .mb-xl-n28,
  .my-xl-n28 {
    margin-bottom: -140px !important;
  }
  .ml-xl-n28,
  .mx-xl-n28 {
    margin-left: -140px !important;
  }
  .m-xl-n29 {
    margin: -145px !important;
  }
  .mt-xl-n29,
  .my-xl-n29 {
    margin-top: -145px !important;
  }
  .mr-xl-n29,
  .mx-xl-n29 {
    margin-right: -145px !important;
  }
  .mb-xl-n29,
  .my-xl-n29 {
    margin-bottom: -145px !important;
  }
  .ml-xl-n29,
  .mx-xl-n29 {
    margin-left: -145px !important;
  }
  .m-xl-n30 {
    margin: -150px !important;
  }
  .mt-xl-n30,
  .my-xl-n30 {
    margin-top: -150px !important;
  }
  .mr-xl-n30,
  .mx-xl-n30 {
    margin-right: -150px !important;
  }
  .mb-xl-n30,
  .my-xl-n30 {
    margin-bottom: -150px !important;
  }
  .ml-xl-n30,
  .mx-xl-n30 {
    margin-left: -150px !important;
  }
  .m-xl-n31 {
    margin: -155px !important;
  }
  .mt-xl-n31,
  .my-xl-n31 {
    margin-top: -155px !important;
  }
  .mr-xl-n31,
  .mx-xl-n31 {
    margin-right: -155px !important;
  }
  .mb-xl-n31,
  .my-xl-n31 {
    margin-bottom: -155px !important;
  }
  .ml-xl-n31,
  .mx-xl-n31 {
    margin-left: -155px !important;
  }
  .m-xl-n32 {
    margin: -160px !important;
  }
  .mt-xl-n32,
  .my-xl-n32 {
    margin-top: -160px !important;
  }
  .mr-xl-n32,
  .mx-xl-n32 {
    margin-right: -160px !important;
  }
  .mb-xl-n32,
  .my-xl-n32 {
    margin-bottom: -160px !important;
  }
  .ml-xl-n32,
  .mx-xl-n32 {
    margin-left: -160px !important;
  }
  .m-xl-n33 {
    margin: -165px !important;
  }
  .mt-xl-n33,
  .my-xl-n33 {
    margin-top: -165px !important;
  }
  .mr-xl-n33,
  .mx-xl-n33 {
    margin-right: -165px !important;
  }
  .mb-xl-n33,
  .my-xl-n33 {
    margin-bottom: -165px !important;
  }
  .ml-xl-n33,
  .mx-xl-n33 {
    margin-left: -165px !important;
  }
  .m-xl-n34 {
    margin: -170px !important;
  }
  .mt-xl-n34,
  .my-xl-n34 {
    margin-top: -170px !important;
  }
  .mr-xl-n34,
  .mx-xl-n34 {
    margin-right: -170px !important;
  }
  .mb-xl-n34,
  .my-xl-n34 {
    margin-bottom: -170px !important;
  }
  .ml-xl-n34,
  .mx-xl-n34 {
    margin-left: -170px !important;
  }
  .m-xl-n35 {
    margin: -175px !important;
  }
  .mt-xl-n35,
  .my-xl-n35 {
    margin-top: -175px !important;
  }
  .mr-xl-n35,
  .mx-xl-n35 {
    margin-right: -175px !important;
  }
  .mb-xl-n35,
  .my-xl-n35 {
    margin-bottom: -175px !important;
  }
  .ml-xl-n35,
  .mx-xl-n35 {
    margin-left: -175px !important;
  }
  .m-xl-n36 {
    margin: -180px !important;
  }
  .mt-xl-n36,
  .my-xl-n36 {
    margin-top: -180px !important;
  }
  .mr-xl-n36,
  .mx-xl-n36 {
    margin-right: -180px !important;
  }
  .mb-xl-n36,
  .my-xl-n36 {
    margin-bottom: -180px !important;
  }
  .ml-xl-n36,
  .mx-xl-n36 {
    margin-left: -180px !important;
  }
  .m-xl-n37 {
    margin: -185px !important;
  }
  .mt-xl-n37,
  .my-xl-n37 {
    margin-top: -185px !important;
  }
  .mr-xl-n37,
  .mx-xl-n37 {
    margin-right: -185px !important;
  }
  .mb-xl-n37,
  .my-xl-n37 {
    margin-bottom: -185px !important;
  }
  .ml-xl-n37,
  .mx-xl-n37 {
    margin-left: -185px !important;
  }
  .m-xl-n38 {
    margin: -190px !important;
  }
  .mt-xl-n38,
  .my-xl-n38 {
    margin-top: -190px !important;
  }
  .mr-xl-n38,
  .mx-xl-n38 {
    margin-right: -190px !important;
  }
  .mb-xl-n38,
  .my-xl-n38 {
    margin-bottom: -190px !important;
  }
  .ml-xl-n38,
  .mx-xl-n38 {
    margin-left: -190px !important;
  }
  .m-xl-n39 {
    margin: -195px !important;
  }
  .mt-xl-n39,
  .my-xl-n39 {
    margin-top: -195px !important;
  }
  .mr-xl-n39,
  .mx-xl-n39 {
    margin-right: -195px !important;
  }
  .mb-xl-n39,
  .my-xl-n39 {
    margin-bottom: -195px !important;
  }
  .ml-xl-n39,
  .mx-xl-n39 {
    margin-left: -195px !important;
  }
  .m-xl-n40 {
    margin: -200px !important;
  }
  .mt-xl-n40,
  .my-xl-n40 {
    margin-top: -200px !important;
  }
  .mr-xl-n40,
  .mx-xl-n40 {
    margin-right: -200px !important;
  }
  .mb-xl-n40,
  .my-xl-n40 {
    margin-bottom: -200px !important;
  }
  .ml-xl-n40,
  .mx-xl-n40 {
    margin-left: -200px !important;
  }
  .m-xl-n41 {
    margin: -205px !important;
  }
  .mt-xl-n41,
  .my-xl-n41 {
    margin-top: -205px !important;
  }
  .mr-xl-n41,
  .mx-xl-n41 {
    margin-right: -205px !important;
  }
  .mb-xl-n41,
  .my-xl-n41 {
    margin-bottom: -205px !important;
  }
  .ml-xl-n41,
  .mx-xl-n41 {
    margin-left: -205px !important;
  }
  .m-xl-n42 {
    margin: -210px !important;
  }
  .mt-xl-n42,
  .my-xl-n42 {
    margin-top: -210px !important;
  }
  .mr-xl-n42,
  .mx-xl-n42 {
    margin-right: -210px !important;
  }
  .mb-xl-n42,
  .my-xl-n42 {
    margin-bottom: -210px !important;
  }
  .ml-xl-n42,
  .mx-xl-n42 {
    margin-left: -210px !important;
  }
  .m-xl-n43 {
    margin: -215px !important;
  }
  .mt-xl-n43,
  .my-xl-n43 {
    margin-top: -215px !important;
  }
  .mr-xl-n43,
  .mx-xl-n43 {
    margin-right: -215px !important;
  }
  .mb-xl-n43,
  .my-xl-n43 {
    margin-bottom: -215px !important;
  }
  .ml-xl-n43,
  .mx-xl-n43 {
    margin-left: -215px !important;
  }
  .m-xl-n44 {
    margin: -220px !important;
  }
  .mt-xl-n44,
  .my-xl-n44 {
    margin-top: -220px !important;
  }
  .mr-xl-n44,
  .mx-xl-n44 {
    margin-right: -220px !important;
  }
  .mb-xl-n44,
  .my-xl-n44 {
    margin-bottom: -220px !important;
  }
  .ml-xl-n44,
  .mx-xl-n44 {
    margin-left: -220px !important;
  }
  .m-xl-n45 {
    margin: -225px !important;
  }
  .mt-xl-n45,
  .my-xl-n45 {
    margin-top: -225px !important;
  }
  .mr-xl-n45,
  .mx-xl-n45 {
    margin-right: -225px !important;
  }
  .mb-xl-n45,
  .my-xl-n45 {
    margin-bottom: -225px !important;
  }
  .ml-xl-n45,
  .mx-xl-n45 {
    margin-left: -225px !important;
  }
  .m-xl-n46 {
    margin: -230px !important;
  }
  .mt-xl-n46,
  .my-xl-n46 {
    margin-top: -230px !important;
  }
  .mr-xl-n46,
  .mx-xl-n46 {
    margin-right: -230px !important;
  }
  .mb-xl-n46,
  .my-xl-n46 {
    margin-bottom: -230px !important;
  }
  .ml-xl-n46,
  .mx-xl-n46 {
    margin-left: -230px !important;
  }
  .m-xl-n47 {
    margin: -235px !important;
  }
  .mt-xl-n47,
  .my-xl-n47 {
    margin-top: -235px !important;
  }
  .mr-xl-n47,
  .mx-xl-n47 {
    margin-right: -235px !important;
  }
  .mb-xl-n47,
  .my-xl-n47 {
    margin-bottom: -235px !important;
  }
  .ml-xl-n47,
  .mx-xl-n47 {
    margin-left: -235px !important;
  }
  .m-xl-n48 {
    margin: -240px !important;
  }
  .mt-xl-n48,
  .my-xl-n48 {
    margin-top: -240px !important;
  }
  .mr-xl-n48,
  .mx-xl-n48 {
    margin-right: -240px !important;
  }
  .mb-xl-n48,
  .my-xl-n48 {
    margin-bottom: -240px !important;
  }
  .ml-xl-n48,
  .mx-xl-n48 {
    margin-left: -240px !important;
  }
  .m-xl-n49 {
    margin: -245px !important;
  }
  .mt-xl-n49,
  .my-xl-n49 {
    margin-top: -245px !important;
  }
  .mr-xl-n49,
  .mx-xl-n49 {
    margin-right: -245px !important;
  }
  .mb-xl-n49,
  .my-xl-n49 {
    margin-bottom: -245px !important;
  }
  .ml-xl-n49,
  .mx-xl-n49 {
    margin-left: -245px !important;
  }
  .m-xl-n50 {
    margin: -250px !important;
  }
  .mt-xl-n50,
  .my-xl-n50 {
    margin-top: -250px !important;
  }
  .mr-xl-n50,
  .mx-xl-n50 {
    margin-right: -250px !important;
  }
  .mb-xl-n50,
  .my-xl-n50 {
    margin-bottom: -250px !important;
  }
  .ml-xl-n50,
  .mx-xl-n50 {
    margin-left: -250px !important;
  }
  .m-xl-n51 {
    margin: -255px !important;
  }
  .mt-xl-n51,
  .my-xl-n51 {
    margin-top: -255px !important;
  }
  .mr-xl-n51,
  .mx-xl-n51 {
    margin-right: -255px !important;
  }
  .mb-xl-n51,
  .my-xl-n51 {
    margin-bottom: -255px !important;
  }
  .ml-xl-n51,
  .mx-xl-n51 {
    margin-left: -255px !important;
  }
  .m-xl-n52 {
    margin: -260px !important;
  }
  .mt-xl-n52,
  .my-xl-n52 {
    margin-top: -260px !important;
  }
  .mr-xl-n52,
  .mx-xl-n52 {
    margin-right: -260px !important;
  }
  .mb-xl-n52,
  .my-xl-n52 {
    margin-bottom: -260px !important;
  }
  .ml-xl-n52,
  .mx-xl-n52 {
    margin-left: -260px !important;
  }
  .m-xl-n53 {
    margin: -265px !important;
  }
  .mt-xl-n53,
  .my-xl-n53 {
    margin-top: -265px !important;
  }
  .mr-xl-n53,
  .mx-xl-n53 {
    margin-right: -265px !important;
  }
  .mb-xl-n53,
  .my-xl-n53 {
    margin-bottom: -265px !important;
  }
  .ml-xl-n53,
  .mx-xl-n53 {
    margin-left: -265px !important;
  }
  .m-xl-n54 {
    margin: -270px !important;
  }
  .mt-xl-n54,
  .my-xl-n54 {
    margin-top: -270px !important;
  }
  .mr-xl-n54,
  .mx-xl-n54 {
    margin-right: -270px !important;
  }
  .mb-xl-n54,
  .my-xl-n54 {
    margin-bottom: -270px !important;
  }
  .ml-xl-n54,
  .mx-xl-n54 {
    margin-left: -270px !important;
  }
  .m-xl-n55 {
    margin: -275px !important;
  }
  .mt-xl-n55,
  .my-xl-n55 {
    margin-top: -275px !important;
  }
  .mr-xl-n55,
  .mx-xl-n55 {
    margin-right: -275px !important;
  }
  .mb-xl-n55,
  .my-xl-n55 {
    margin-bottom: -275px !important;
  }
  .ml-xl-n55,
  .mx-xl-n55 {
    margin-left: -275px !important;
  }
  .m-xl-n56 {
    margin: -280px !important;
  }
  .mt-xl-n56,
  .my-xl-n56 {
    margin-top: -280px !important;
  }
  .mr-xl-n56,
  .mx-xl-n56 {
    margin-right: -280px !important;
  }
  .mb-xl-n56,
  .my-xl-n56 {
    margin-bottom: -280px !important;
  }
  .ml-xl-n56,
  .mx-xl-n56 {
    margin-left: -280px !important;
  }
  .m-xl-n57 {
    margin: -285px !important;
  }
  .mt-xl-n57,
  .my-xl-n57 {
    margin-top: -285px !important;
  }
  .mr-xl-n57,
  .mx-xl-n57 {
    margin-right: -285px !important;
  }
  .mb-xl-n57,
  .my-xl-n57 {
    margin-bottom: -285px !important;
  }
  .ml-xl-n57,
  .mx-xl-n57 {
    margin-left: -285px !important;
  }
  .m-xl-n58 {
    margin: -290px !important;
  }
  .mt-xl-n58,
  .my-xl-n58 {
    margin-top: -290px !important;
  }
  .mr-xl-n58,
  .mx-xl-n58 {
    margin-right: -290px !important;
  }
  .mb-xl-n58,
  .my-xl-n58 {
    margin-bottom: -290px !important;
  }
  .ml-xl-n58,
  .mx-xl-n58 {
    margin-left: -290px !important;
  }
  .m-xl-n59 {
    margin: -295px !important;
  }
  .mt-xl-n59,
  .my-xl-n59 {
    margin-top: -295px !important;
  }
  .mr-xl-n59,
  .mx-xl-n59 {
    margin-right: -295px !important;
  }
  .mb-xl-n59,
  .my-xl-n59 {
    margin-bottom: -295px !important;
  }
  .ml-xl-n59,
  .mx-xl-n59 {
    margin-left: -295px !important;
  }
  .m-xl-n60 {
    margin: -300px !important;
  }
  .mt-xl-n60,
  .my-xl-n60 {
    margin-top: -300px !important;
  }
  .mr-xl-n60,
  .mx-xl-n60 {
    margin-right: -300px !important;
  }
  .mb-xl-n60,
  .my-xl-n60 {
    margin-bottom: -300px !important;
  }
  .ml-xl-n60,
  .mx-xl-n60 {
    margin-left: -300px !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1341px) {
  .m-xlbtw-0 {
    margin: 0 !important;
  }
  .mt-xlbtw-0,
  .my-xlbtw-0 {
    margin-top: 0 !important;
  }
  .mr-xlbtw-0,
  .mx-xlbtw-0 {
    margin-right: 0 !important;
  }
  .mb-xlbtw-0,
  .my-xlbtw-0 {
    margin-bottom: 0 !important;
  }
  .ml-xlbtw-0,
  .mx-xlbtw-0 {
    margin-left: 0 !important;
  }
  .m-xlbtw-1 {
    margin: 5px !important;
  }
  .mt-xlbtw-1,
  .my-xlbtw-1 {
    margin-top: 5px !important;
  }
  .mr-xlbtw-1,
  .mx-xlbtw-1 {
    margin-right: 5px !important;
  }
  .mb-xlbtw-1,
  .my-xlbtw-1 {
    margin-bottom: 5px !important;
  }
  .ml-xlbtw-1,
  .mx-xlbtw-1 {
    margin-left: 5px !important;
  }
  .m-xlbtw-2 {
    margin: 10px !important;
  }
  .mt-xlbtw-2,
  .my-xlbtw-2 {
    margin-top: 10px !important;
  }
  .mr-xlbtw-2,
  .mx-xlbtw-2 {
    margin-right: 10px !important;
  }
  .mb-xlbtw-2,
  .my-xlbtw-2 {
    margin-bottom: 10px !important;
  }
  .ml-xlbtw-2,
  .mx-xlbtw-2 {
    margin-left: 10px !important;
  }
  .m-xlbtw-3 {
    margin: 16px !important;
  }
  .mt-xlbtw-3,
  .my-xlbtw-3 {
    margin-top: 16px !important;
  }
  .mr-xlbtw-3,
  .mx-xlbtw-3 {
    margin-right: 16px !important;
  }
  .mb-xlbtw-3,
  .my-xlbtw-3 {
    margin-bottom: 16px !important;
  }
  .ml-xlbtw-3,
  .mx-xlbtw-3 {
    margin-left: 16px !important;
  }
  .m-xlbtw-4 {
    margin: 20px !important;
  }
  .mt-xlbtw-4,
  .my-xlbtw-4 {
    margin-top: 20px !important;
  }
  .mr-xlbtw-4,
  .mx-xlbtw-4 {
    margin-right: 20px !important;
  }
  .mb-xlbtw-4,
  .my-xlbtw-4 {
    margin-bottom: 20px !important;
  }
  .ml-xlbtw-4,
  .mx-xlbtw-4 {
    margin-left: 20px !important;
  }
  .m-xlbtw-5 {
    margin: 25px !important;
  }
  .mt-xlbtw-5,
  .my-xlbtw-5 {
    margin-top: 25px !important;
  }
  .mr-xlbtw-5,
  .mx-xlbtw-5 {
    margin-right: 25px !important;
  }
  .mb-xlbtw-5,
  .my-xlbtw-5 {
    margin-bottom: 25px !important;
  }
  .ml-xlbtw-5,
  .mx-xlbtw-5 {
    margin-left: 25px !important;
  }
  .m-xlbtw-6 {
    margin: 30px !important;
  }
  .mt-xlbtw-6,
  .my-xlbtw-6 {
    margin-top: 30px !important;
  }
  .mr-xlbtw-6,
  .mx-xlbtw-6 {
    margin-right: 30px !important;
  }
  .mb-xlbtw-6,
  .my-xlbtw-6 {
    margin-bottom: 30px !important;
  }
  .ml-xlbtw-6,
  .mx-xlbtw-6 {
    margin-left: 30px !important;
  }
  .m-xlbtw-7 {
    margin: 35px !important;
  }
  .mt-xlbtw-7,
  .my-xlbtw-7 {
    margin-top: 35px !important;
  }
  .mr-xlbtw-7,
  .mx-xlbtw-7 {
    margin-right: 35px !important;
  }
  .mb-xlbtw-7,
  .my-xlbtw-7 {
    margin-bottom: 35px !important;
  }
  .ml-xlbtw-7,
  .mx-xlbtw-7 {
    margin-left: 35px !important;
  }
  .m-xlbtw-8 {
    margin: 40px !important;
  }
  .mt-xlbtw-8,
  .my-xlbtw-8 {
    margin-top: 40px !important;
  }
  .mr-xlbtw-8,
  .mx-xlbtw-8 {
    margin-right: 40px !important;
  }
  .mb-xlbtw-8,
  .my-xlbtw-8 {
    margin-bottom: 40px !important;
  }
  .ml-xlbtw-8,
  .mx-xlbtw-8 {
    margin-left: 40px !important;
  }
  .m-xlbtw-9 {
    margin: 45px !important;
  }
  .mt-xlbtw-9,
  .my-xlbtw-9 {
    margin-top: 45px !important;
  }
  .mr-xlbtw-9,
  .mx-xlbtw-9 {
    margin-right: 45px !important;
  }
  .mb-xlbtw-9,
  .my-xlbtw-9 {
    margin-bottom: 45px !important;
  }
  .ml-xlbtw-9,
  .mx-xlbtw-9 {
    margin-left: 45px !important;
  }
  .m-xlbtw-10 {
    margin: 50px !important;
  }
  .mt-xlbtw-10,
  .my-xlbtw-10 {
    margin-top: 50px !important;
  }
  .mr-xlbtw-10,
  .mx-xlbtw-10 {
    margin-right: 50px !important;
  }
  .mb-xlbtw-10,
  .my-xlbtw-10 {
    margin-bottom: 50px !important;
  }
  .ml-xlbtw-10,
  .mx-xlbtw-10 {
    margin-left: 50px !important;
  }
  .m-xlbtw-11 {
    margin: 55px !important;
  }
  .mt-xlbtw-11,
  .my-xlbtw-11 {
    margin-top: 55px !important;
  }
  .mr-xlbtw-11,
  .mx-xlbtw-11 {
    margin-right: 55px !important;
  }
  .mb-xlbtw-11,
  .my-xlbtw-11 {
    margin-bottom: 55px !important;
  }
  .ml-xlbtw-11,
  .mx-xlbtw-11 {
    margin-left: 55px !important;
  }
  .m-xlbtw-12 {
    margin: 60px !important;
  }
  .mt-xlbtw-12,
  .my-xlbtw-12 {
    margin-top: 60px !important;
  }
  .mr-xlbtw-12,
  .mx-xlbtw-12 {
    margin-right: 60px !important;
  }
  .mb-xlbtw-12,
  .my-xlbtw-12 {
    margin-bottom: 60px !important;
  }
  .ml-xlbtw-12,
  .mx-xlbtw-12 {
    margin-left: 60px !important;
  }
  .m-xlbtw-13 {
    margin: 65px !important;
  }
  .mt-xlbtw-13,
  .my-xlbtw-13 {
    margin-top: 65px !important;
  }
  .mr-xlbtw-13,
  .mx-xlbtw-13 {
    margin-right: 65px !important;
  }
  .mb-xlbtw-13,
  .my-xlbtw-13 {
    margin-bottom: 65px !important;
  }
  .ml-xlbtw-13,
  .mx-xlbtw-13 {
    margin-left: 65px !important;
  }
  .m-xlbtw-14 {
    margin: 70px !important;
  }
  .mt-xlbtw-14,
  .my-xlbtw-14 {
    margin-top: 70px !important;
  }
  .mr-xlbtw-14,
  .mx-xlbtw-14 {
    margin-right: 70px !important;
  }
  .mb-xlbtw-14,
  .my-xlbtw-14 {
    margin-bottom: 70px !important;
  }
  .ml-xlbtw-14,
  .mx-xlbtw-14 {
    margin-left: 70px !important;
  }
  .m-xlbtw-15 {
    margin: 75px !important;
  }
  .mt-xlbtw-15,
  .my-xlbtw-15 {
    margin-top: 75px !important;
  }
  .mr-xlbtw-15,
  .mx-xlbtw-15 {
    margin-right: 75px !important;
  }
  .mb-xlbtw-15,
  .my-xlbtw-15 {
    margin-bottom: 75px !important;
  }
  .ml-xlbtw-15,
  .mx-xlbtw-15 {
    margin-left: 75px !important;
  }
  .m-xlbtw-16 {
    margin: 80px !important;
  }
  .mt-xlbtw-16,
  .my-xlbtw-16 {
    margin-top: 80px !important;
  }
  .mr-xlbtw-16,
  .mx-xlbtw-16 {
    margin-right: 80px !important;
  }
  .mb-xlbtw-16,
  .my-xlbtw-16 {
    margin-bottom: 80px !important;
  }
  .ml-xlbtw-16,
  .mx-xlbtw-16 {
    margin-left: 80px !important;
  }
  .m-xlbtw-17 {
    margin: 85px !important;
  }
  .mt-xlbtw-17,
  .my-xlbtw-17 {
    margin-top: 85px !important;
  }
  .mr-xlbtw-17,
  .mx-xlbtw-17 {
    margin-right: 85px !important;
  }
  .mb-xlbtw-17,
  .my-xlbtw-17 {
    margin-bottom: 85px !important;
  }
  .ml-xlbtw-17,
  .mx-xlbtw-17 {
    margin-left: 85px !important;
  }
  .m-xlbtw-18 {
    margin: 90px !important;
  }
  .mt-xlbtw-18,
  .my-xlbtw-18 {
    margin-top: 90px !important;
  }
  .mr-xlbtw-18,
  .mx-xlbtw-18 {
    margin-right: 90px !important;
  }
  .mb-xlbtw-18,
  .my-xlbtw-18 {
    margin-bottom: 90px !important;
  }
  .ml-xlbtw-18,
  .mx-xlbtw-18 {
    margin-left: 90px !important;
  }
  .m-xlbtw-19 {
    margin: 95px !important;
  }
  .mt-xlbtw-19,
  .my-xlbtw-19 {
    margin-top: 95px !important;
  }
  .mr-xlbtw-19,
  .mx-xlbtw-19 {
    margin-right: 95px !important;
  }
  .mb-xlbtw-19,
  .my-xlbtw-19 {
    margin-bottom: 95px !important;
  }
  .ml-xlbtw-19,
  .mx-xlbtw-19 {
    margin-left: 95px !important;
  }
  .m-xlbtw-20 {
    margin: 100px !important;
  }
  .mt-xlbtw-20,
  .my-xlbtw-20 {
    margin-top: 100px !important;
  }
  .mr-xlbtw-20,
  .mx-xlbtw-20 {
    margin-right: 100px !important;
  }
  .mb-xlbtw-20,
  .my-xlbtw-20 {
    margin-bottom: 100px !important;
  }
  .ml-xlbtw-20,
  .mx-xlbtw-20 {
    margin-left: 100px !important;
  }
  .m-xlbtw-21 {
    margin: 105px !important;
  }
  .mt-xlbtw-21,
  .my-xlbtw-21 {
    margin-top: 105px !important;
  }
  .mr-xlbtw-21,
  .mx-xlbtw-21 {
    margin-right: 105px !important;
  }
  .mb-xlbtw-21,
  .my-xlbtw-21 {
    margin-bottom: 105px !important;
  }
  .ml-xlbtw-21,
  .mx-xlbtw-21 {
    margin-left: 105px !important;
  }
  .m-xlbtw-22 {
    margin: 110px !important;
  }
  .mt-xlbtw-22,
  .my-xlbtw-22 {
    margin-top: 110px !important;
  }
  .mr-xlbtw-22,
  .mx-xlbtw-22 {
    margin-right: 110px !important;
  }
  .mb-xlbtw-22,
  .my-xlbtw-22 {
    margin-bottom: 110px !important;
  }
  .ml-xlbtw-22,
  .mx-xlbtw-22 {
    margin-left: 110px !important;
  }
  .m-xlbtw-23 {
    margin: 115px !important;
  }
  .mt-xlbtw-23,
  .my-xlbtw-23 {
    margin-top: 115px !important;
  }
  .mr-xlbtw-23,
  .mx-xlbtw-23 {
    margin-right: 115px !important;
  }
  .mb-xlbtw-23,
  .my-xlbtw-23 {
    margin-bottom: 115px !important;
  }
  .ml-xlbtw-23,
  .mx-xlbtw-23 {
    margin-left: 115px !important;
  }
  .m-xlbtw-24 {
    margin: 120px !important;
  }
  .mt-xlbtw-24,
  .my-xlbtw-24 {
    margin-top: 120px !important;
  }
  .mr-xlbtw-24,
  .mx-xlbtw-24 {
    margin-right: 120px !important;
  }
  .mb-xlbtw-24,
  .my-xlbtw-24 {
    margin-bottom: 120px !important;
  }
  .ml-xlbtw-24,
  .mx-xlbtw-24 {
    margin-left: 120px !important;
  }
  .m-xlbtw-25 {
    margin: 125px !important;
  }
  .mt-xlbtw-25,
  .my-xlbtw-25 {
    margin-top: 125px !important;
  }
  .mr-xlbtw-25,
  .mx-xlbtw-25 {
    margin-right: 125px !important;
  }
  .mb-xlbtw-25,
  .my-xlbtw-25 {
    margin-bottom: 125px !important;
  }
  .ml-xlbtw-25,
  .mx-xlbtw-25 {
    margin-left: 125px !important;
  }
  .m-xlbtw-26 {
    margin: 130px !important;
  }
  .mt-xlbtw-26,
  .my-xlbtw-26 {
    margin-top: 130px !important;
  }
  .mr-xlbtw-26,
  .mx-xlbtw-26 {
    margin-right: 130px !important;
  }
  .mb-xlbtw-26,
  .my-xlbtw-26 {
    margin-bottom: 130px !important;
  }
  .ml-xlbtw-26,
  .mx-xlbtw-26 {
    margin-left: 130px !important;
  }
  .m-xlbtw-27 {
    margin: 135px !important;
  }
  .mt-xlbtw-27,
  .my-xlbtw-27 {
    margin-top: 135px !important;
  }
  .mr-xlbtw-27,
  .mx-xlbtw-27 {
    margin-right: 135px !important;
  }
  .mb-xlbtw-27,
  .my-xlbtw-27 {
    margin-bottom: 135px !important;
  }
  .ml-xlbtw-27,
  .mx-xlbtw-27 {
    margin-left: 135px !important;
  }
  .m-xlbtw-28 {
    margin: 140px !important;
  }
  .mt-xlbtw-28,
  .my-xlbtw-28 {
    margin-top: 140px !important;
  }
  .mr-xlbtw-28,
  .mx-xlbtw-28 {
    margin-right: 140px !important;
  }
  .mb-xlbtw-28,
  .my-xlbtw-28 {
    margin-bottom: 140px !important;
  }
  .ml-xlbtw-28,
  .mx-xlbtw-28 {
    margin-left: 140px !important;
  }
  .m-xlbtw-29 {
    margin: 145px !important;
  }
  .mt-xlbtw-29,
  .my-xlbtw-29 {
    margin-top: 145px !important;
  }
  .mr-xlbtw-29,
  .mx-xlbtw-29 {
    margin-right: 145px !important;
  }
  .mb-xlbtw-29,
  .my-xlbtw-29 {
    margin-bottom: 145px !important;
  }
  .ml-xlbtw-29,
  .mx-xlbtw-29 {
    margin-left: 145px !important;
  }
  .m-xlbtw-30 {
    margin: 150px !important;
  }
  .mt-xlbtw-30,
  .my-xlbtw-30 {
    margin-top: 150px !important;
  }
  .mr-xlbtw-30,
  .mx-xlbtw-30 {
    margin-right: 150px !important;
  }
  .mb-xlbtw-30,
  .my-xlbtw-30 {
    margin-bottom: 150px !important;
  }
  .ml-xlbtw-30,
  .mx-xlbtw-30 {
    margin-left: 150px !important;
  }
  .m-xlbtw-31 {
    margin: 155px !important;
  }
  .mt-xlbtw-31,
  .my-xlbtw-31 {
    margin-top: 155px !important;
  }
  .mr-xlbtw-31,
  .mx-xlbtw-31 {
    margin-right: 155px !important;
  }
  .mb-xlbtw-31,
  .my-xlbtw-31 {
    margin-bottom: 155px !important;
  }
  .ml-xlbtw-31,
  .mx-xlbtw-31 {
    margin-left: 155px !important;
  }
  .m-xlbtw-32 {
    margin: 160px !important;
  }
  .mt-xlbtw-32,
  .my-xlbtw-32 {
    margin-top: 160px !important;
  }
  .mr-xlbtw-32,
  .mx-xlbtw-32 {
    margin-right: 160px !important;
  }
  .mb-xlbtw-32,
  .my-xlbtw-32 {
    margin-bottom: 160px !important;
  }
  .ml-xlbtw-32,
  .mx-xlbtw-32 {
    margin-left: 160px !important;
  }
  .m-xlbtw-33 {
    margin: 165px !important;
  }
  .mt-xlbtw-33,
  .my-xlbtw-33 {
    margin-top: 165px !important;
  }
  .mr-xlbtw-33,
  .mx-xlbtw-33 {
    margin-right: 165px !important;
  }
  .mb-xlbtw-33,
  .my-xlbtw-33 {
    margin-bottom: 165px !important;
  }
  .ml-xlbtw-33,
  .mx-xlbtw-33 {
    margin-left: 165px !important;
  }
  .m-xlbtw-34 {
    margin: 170px !important;
  }
  .mt-xlbtw-34,
  .my-xlbtw-34 {
    margin-top: 170px !important;
  }
  .mr-xlbtw-34,
  .mx-xlbtw-34 {
    margin-right: 170px !important;
  }
  .mb-xlbtw-34,
  .my-xlbtw-34 {
    margin-bottom: 170px !important;
  }
  .ml-xlbtw-34,
  .mx-xlbtw-34 {
    margin-left: 170px !important;
  }
  .m-xlbtw-35 {
    margin: 175px !important;
  }
  .mt-xlbtw-35,
  .my-xlbtw-35 {
    margin-top: 175px !important;
  }
  .mr-xlbtw-35,
  .mx-xlbtw-35 {
    margin-right: 175px !important;
  }
  .mb-xlbtw-35,
  .my-xlbtw-35 {
    margin-bottom: 175px !important;
  }
  .ml-xlbtw-35,
  .mx-xlbtw-35 {
    margin-left: 175px !important;
  }
  .m-xlbtw-36 {
    margin: 180px !important;
  }
  .mt-xlbtw-36,
  .my-xlbtw-36 {
    margin-top: 180px !important;
  }
  .mr-xlbtw-36,
  .mx-xlbtw-36 {
    margin-right: 180px !important;
  }
  .mb-xlbtw-36,
  .my-xlbtw-36 {
    margin-bottom: 180px !important;
  }
  .ml-xlbtw-36,
  .mx-xlbtw-36 {
    margin-left: 180px !important;
  }
  .m-xlbtw-37 {
    margin: 185px !important;
  }
  .mt-xlbtw-37,
  .my-xlbtw-37 {
    margin-top: 185px !important;
  }
  .mr-xlbtw-37,
  .mx-xlbtw-37 {
    margin-right: 185px !important;
  }
  .mb-xlbtw-37,
  .my-xlbtw-37 {
    margin-bottom: 185px !important;
  }
  .ml-xlbtw-37,
  .mx-xlbtw-37 {
    margin-left: 185px !important;
  }
  .m-xlbtw-38 {
    margin: 190px !important;
  }
  .mt-xlbtw-38,
  .my-xlbtw-38 {
    margin-top: 190px !important;
  }
  .mr-xlbtw-38,
  .mx-xlbtw-38 {
    margin-right: 190px !important;
  }
  .mb-xlbtw-38,
  .my-xlbtw-38 {
    margin-bottom: 190px !important;
  }
  .ml-xlbtw-38,
  .mx-xlbtw-38 {
    margin-left: 190px !important;
  }
  .m-xlbtw-39 {
    margin: 195px !important;
  }
  .mt-xlbtw-39,
  .my-xlbtw-39 {
    margin-top: 195px !important;
  }
  .mr-xlbtw-39,
  .mx-xlbtw-39 {
    margin-right: 195px !important;
  }
  .mb-xlbtw-39,
  .my-xlbtw-39 {
    margin-bottom: 195px !important;
  }
  .ml-xlbtw-39,
  .mx-xlbtw-39 {
    margin-left: 195px !important;
  }
  .m-xlbtw-40 {
    margin: 200px !important;
  }
  .mt-xlbtw-40,
  .my-xlbtw-40 {
    margin-top: 200px !important;
  }
  .mr-xlbtw-40,
  .mx-xlbtw-40 {
    margin-right: 200px !important;
  }
  .mb-xlbtw-40,
  .my-xlbtw-40 {
    margin-bottom: 200px !important;
  }
  .ml-xlbtw-40,
  .mx-xlbtw-40 {
    margin-left: 200px !important;
  }
  .m-xlbtw-41 {
    margin: 205px !important;
  }
  .mt-xlbtw-41,
  .my-xlbtw-41 {
    margin-top: 205px !important;
  }
  .mr-xlbtw-41,
  .mx-xlbtw-41 {
    margin-right: 205px !important;
  }
  .mb-xlbtw-41,
  .my-xlbtw-41 {
    margin-bottom: 205px !important;
  }
  .ml-xlbtw-41,
  .mx-xlbtw-41 {
    margin-left: 205px !important;
  }
  .m-xlbtw-42 {
    margin: 210px !important;
  }
  .mt-xlbtw-42,
  .my-xlbtw-42 {
    margin-top: 210px !important;
  }
  .mr-xlbtw-42,
  .mx-xlbtw-42 {
    margin-right: 210px !important;
  }
  .mb-xlbtw-42,
  .my-xlbtw-42 {
    margin-bottom: 210px !important;
  }
  .ml-xlbtw-42,
  .mx-xlbtw-42 {
    margin-left: 210px !important;
  }
  .m-xlbtw-43 {
    margin: 215px !important;
  }
  .mt-xlbtw-43,
  .my-xlbtw-43 {
    margin-top: 215px !important;
  }
  .mr-xlbtw-43,
  .mx-xlbtw-43 {
    margin-right: 215px !important;
  }
  .mb-xlbtw-43,
  .my-xlbtw-43 {
    margin-bottom: 215px !important;
  }
  .ml-xlbtw-43,
  .mx-xlbtw-43 {
    margin-left: 215px !important;
  }
  .m-xlbtw-44 {
    margin: 220px !important;
  }
  .mt-xlbtw-44,
  .my-xlbtw-44 {
    margin-top: 220px !important;
  }
  .mr-xlbtw-44,
  .mx-xlbtw-44 {
    margin-right: 220px !important;
  }
  .mb-xlbtw-44,
  .my-xlbtw-44 {
    margin-bottom: 220px !important;
  }
  .ml-xlbtw-44,
  .mx-xlbtw-44 {
    margin-left: 220px !important;
  }
  .m-xlbtw-45 {
    margin: 225px !important;
  }
  .mt-xlbtw-45,
  .my-xlbtw-45 {
    margin-top: 225px !important;
  }
  .mr-xlbtw-45,
  .mx-xlbtw-45 {
    margin-right: 225px !important;
  }
  .mb-xlbtw-45,
  .my-xlbtw-45 {
    margin-bottom: 225px !important;
  }
  .ml-xlbtw-45,
  .mx-xlbtw-45 {
    margin-left: 225px !important;
  }
  .m-xlbtw-46 {
    margin: 230px !important;
  }
  .mt-xlbtw-46,
  .my-xlbtw-46 {
    margin-top: 230px !important;
  }
  .mr-xlbtw-46,
  .mx-xlbtw-46 {
    margin-right: 230px !important;
  }
  .mb-xlbtw-46,
  .my-xlbtw-46 {
    margin-bottom: 230px !important;
  }
  .ml-xlbtw-46,
  .mx-xlbtw-46 {
    margin-left: 230px !important;
  }
  .m-xlbtw-47 {
    margin: 235px !important;
  }
  .mt-xlbtw-47,
  .my-xlbtw-47 {
    margin-top: 235px !important;
  }
  .mr-xlbtw-47,
  .mx-xlbtw-47 {
    margin-right: 235px !important;
  }
  .mb-xlbtw-47,
  .my-xlbtw-47 {
    margin-bottom: 235px !important;
  }
  .ml-xlbtw-47,
  .mx-xlbtw-47 {
    margin-left: 235px !important;
  }
  .m-xlbtw-48 {
    margin: 240px !important;
  }
  .mt-xlbtw-48,
  .my-xlbtw-48 {
    margin-top: 240px !important;
  }
  .mr-xlbtw-48,
  .mx-xlbtw-48 {
    margin-right: 240px !important;
  }
  .mb-xlbtw-48,
  .my-xlbtw-48 {
    margin-bottom: 240px !important;
  }
  .ml-xlbtw-48,
  .mx-xlbtw-48 {
    margin-left: 240px !important;
  }
  .m-xlbtw-49 {
    margin: 245px !important;
  }
  .mt-xlbtw-49,
  .my-xlbtw-49 {
    margin-top: 245px !important;
  }
  .mr-xlbtw-49,
  .mx-xlbtw-49 {
    margin-right: 245px !important;
  }
  .mb-xlbtw-49,
  .my-xlbtw-49 {
    margin-bottom: 245px !important;
  }
  .ml-xlbtw-49,
  .mx-xlbtw-49 {
    margin-left: 245px !important;
  }
  .m-xlbtw-50 {
    margin: 250px !important;
  }
  .mt-xlbtw-50,
  .my-xlbtw-50 {
    margin-top: 250px !important;
  }
  .mr-xlbtw-50,
  .mx-xlbtw-50 {
    margin-right: 250px !important;
  }
  .mb-xlbtw-50,
  .my-xlbtw-50 {
    margin-bottom: 250px !important;
  }
  .ml-xlbtw-50,
  .mx-xlbtw-50 {
    margin-left: 250px !important;
  }
  .m-xlbtw-51 {
    margin: 255px !important;
  }
  .mt-xlbtw-51,
  .my-xlbtw-51 {
    margin-top: 255px !important;
  }
  .mr-xlbtw-51,
  .mx-xlbtw-51 {
    margin-right: 255px !important;
  }
  .mb-xlbtw-51,
  .my-xlbtw-51 {
    margin-bottom: 255px !important;
  }
  .ml-xlbtw-51,
  .mx-xlbtw-51 {
    margin-left: 255px !important;
  }
  .m-xlbtw-52 {
    margin: 260px !important;
  }
  .mt-xlbtw-52,
  .my-xlbtw-52 {
    margin-top: 260px !important;
  }
  .mr-xlbtw-52,
  .mx-xlbtw-52 {
    margin-right: 260px !important;
  }
  .mb-xlbtw-52,
  .my-xlbtw-52 {
    margin-bottom: 260px !important;
  }
  .ml-xlbtw-52,
  .mx-xlbtw-52 {
    margin-left: 260px !important;
  }
  .m-xlbtw-53 {
    margin: 265px !important;
  }
  .mt-xlbtw-53,
  .my-xlbtw-53 {
    margin-top: 265px !important;
  }
  .mr-xlbtw-53,
  .mx-xlbtw-53 {
    margin-right: 265px !important;
  }
  .mb-xlbtw-53,
  .my-xlbtw-53 {
    margin-bottom: 265px !important;
  }
  .ml-xlbtw-53,
  .mx-xlbtw-53 {
    margin-left: 265px !important;
  }
  .m-xlbtw-54 {
    margin: 270px !important;
  }
  .mt-xlbtw-54,
  .my-xlbtw-54 {
    margin-top: 270px !important;
  }
  .mr-xlbtw-54,
  .mx-xlbtw-54 {
    margin-right: 270px !important;
  }
  .mb-xlbtw-54,
  .my-xlbtw-54 {
    margin-bottom: 270px !important;
  }
  .ml-xlbtw-54,
  .mx-xlbtw-54 {
    margin-left: 270px !important;
  }
  .m-xlbtw-55 {
    margin: 275px !important;
  }
  .mt-xlbtw-55,
  .my-xlbtw-55 {
    margin-top: 275px !important;
  }
  .mr-xlbtw-55,
  .mx-xlbtw-55 {
    margin-right: 275px !important;
  }
  .mb-xlbtw-55,
  .my-xlbtw-55 {
    margin-bottom: 275px !important;
  }
  .ml-xlbtw-55,
  .mx-xlbtw-55 {
    margin-left: 275px !important;
  }
  .m-xlbtw-56 {
    margin: 280px !important;
  }
  .mt-xlbtw-56,
  .my-xlbtw-56 {
    margin-top: 280px !important;
  }
  .mr-xlbtw-56,
  .mx-xlbtw-56 {
    margin-right: 280px !important;
  }
  .mb-xlbtw-56,
  .my-xlbtw-56 {
    margin-bottom: 280px !important;
  }
  .ml-xlbtw-56,
  .mx-xlbtw-56 {
    margin-left: 280px !important;
  }
  .m-xlbtw-57 {
    margin: 285px !important;
  }
  .mt-xlbtw-57,
  .my-xlbtw-57 {
    margin-top: 285px !important;
  }
  .mr-xlbtw-57,
  .mx-xlbtw-57 {
    margin-right: 285px !important;
  }
  .mb-xlbtw-57,
  .my-xlbtw-57 {
    margin-bottom: 285px !important;
  }
  .ml-xlbtw-57,
  .mx-xlbtw-57 {
    margin-left: 285px !important;
  }
  .m-xlbtw-58 {
    margin: 290px !important;
  }
  .mt-xlbtw-58,
  .my-xlbtw-58 {
    margin-top: 290px !important;
  }
  .mr-xlbtw-58,
  .mx-xlbtw-58 {
    margin-right: 290px !important;
  }
  .mb-xlbtw-58,
  .my-xlbtw-58 {
    margin-bottom: 290px !important;
  }
  .ml-xlbtw-58,
  .mx-xlbtw-58 {
    margin-left: 290px !important;
  }
  .m-xlbtw-59 {
    margin: 295px !important;
  }
  .mt-xlbtw-59,
  .my-xlbtw-59 {
    margin-top: 295px !important;
  }
  .mr-xlbtw-59,
  .mx-xlbtw-59 {
    margin-right: 295px !important;
  }
  .mb-xlbtw-59,
  .my-xlbtw-59 {
    margin-bottom: 295px !important;
  }
  .ml-xlbtw-59,
  .mx-xlbtw-59 {
    margin-left: 295px !important;
  }
  .m-xlbtw-60 {
    margin: 300px !important;
  }
  .mt-xlbtw-60,
  .my-xlbtw-60 {
    margin-top: 300px !important;
  }
  .mr-xlbtw-60,
  .mx-xlbtw-60 {
    margin-right: 300px !important;
  }
  .mb-xlbtw-60,
  .my-xlbtw-60 {
    margin-bottom: 300px !important;
  }
  .ml-xlbtw-60,
  .mx-xlbtw-60 {
    margin-left: 300px !important;
  }
  .p-xlbtw-0 {
    padding: 0 !important;
  }
  .pt-xlbtw-0,
  .py-xlbtw-0 {
    padding-top: 0 !important;
  }
  .pr-xlbtw-0,
  .px-xlbtw-0 {
    padding-right: 0 !important;
  }
  .pb-xlbtw-0,
  .py-xlbtw-0 {
    padding-bottom: 0 !important;
  }
  .pl-xlbtw-0,
  .px-xlbtw-0 {
    padding-left: 0 !important;
  }
  .p-xlbtw-1 {
    padding: 5px !important;
  }
  .pt-xlbtw-1,
  .py-xlbtw-1 {
    padding-top: 5px !important;
  }
  .pr-xlbtw-1,
  .px-xlbtw-1 {
    padding-right: 5px !important;
  }
  .pb-xlbtw-1,
  .py-xlbtw-1 {
    padding-bottom: 5px !important;
  }
  .pl-xlbtw-1,
  .px-xlbtw-1 {
    padding-left: 5px !important;
  }
  .p-xlbtw-2 {
    padding: 10px !important;
  }
  .pt-xlbtw-2,
  .py-xlbtw-2 {
    padding-top: 10px !important;
  }
  .pr-xlbtw-2,
  .px-xlbtw-2 {
    padding-right: 10px !important;
  }
  .pb-xlbtw-2,
  .py-xlbtw-2 {
    padding-bottom: 10px !important;
  }
  .pl-xlbtw-2,
  .px-xlbtw-2 {
    padding-left: 10px !important;
  }
  .p-xlbtw-3 {
    padding: 16px !important;
  }
  .pt-xlbtw-3,
  .py-xlbtw-3 {
    padding-top: 16px !important;
  }
  .pr-xlbtw-3,
  .px-xlbtw-3 {
    padding-right: 16px !important;
  }
  .pb-xlbtw-3,
  .py-xlbtw-3 {
    padding-bottom: 16px !important;
  }
  .pl-xlbtw-3,
  .px-xlbtw-3 {
    padding-left: 16px !important;
  }
  .p-xlbtw-4 {
    padding: 20px !important;
  }
  .pt-xlbtw-4,
  .py-xlbtw-4 {
    padding-top: 20px !important;
  }
  .pr-xlbtw-4,
  .px-xlbtw-4 {
    padding-right: 20px !important;
  }
  .pb-xlbtw-4,
  .py-xlbtw-4 {
    padding-bottom: 20px !important;
  }
  .pl-xlbtw-4,
  .px-xlbtw-4 {
    padding-left: 20px !important;
  }
  .p-xlbtw-5 {
    padding: 25px !important;
  }
  .pt-xlbtw-5,
  .py-xlbtw-5 {
    padding-top: 25px !important;
  }
  .pr-xlbtw-5,
  .px-xlbtw-5 {
    padding-right: 25px !important;
  }
  .pb-xlbtw-5,
  .py-xlbtw-5 {
    padding-bottom: 25px !important;
  }
  .pl-xlbtw-5,
  .px-xlbtw-5 {
    padding-left: 25px !important;
  }
  .p-xlbtw-6 {
    padding: 30px !important;
  }
  .pt-xlbtw-6,
  .py-xlbtw-6 {
    padding-top: 30px !important;
  }
  .pr-xlbtw-6,
  .px-xlbtw-6 {
    padding-right: 30px !important;
  }
  .pb-xlbtw-6,
  .py-xlbtw-6 {
    padding-bottom: 30px !important;
  }
  .pl-xlbtw-6,
  .px-xlbtw-6 {
    padding-left: 30px !important;
  }
  .p-xlbtw-7 {
    padding: 35px !important;
  }
  .pt-xlbtw-7,
  .py-xlbtw-7 {
    padding-top: 35px !important;
  }
  .pr-xlbtw-7,
  .px-xlbtw-7 {
    padding-right: 35px !important;
  }
  .pb-xlbtw-7,
  .py-xlbtw-7 {
    padding-bottom: 35px !important;
  }
  .pl-xlbtw-7,
  .px-xlbtw-7 {
    padding-left: 35px !important;
  }
  .p-xlbtw-8 {
    padding: 40px !important;
  }
  .pt-xlbtw-8,
  .py-xlbtw-8 {
    padding-top: 40px !important;
  }
  .pr-xlbtw-8,
  .px-xlbtw-8 {
    padding-right: 40px !important;
  }
  .pb-xlbtw-8,
  .py-xlbtw-8 {
    padding-bottom: 40px !important;
  }
  .pl-xlbtw-8,
  .px-xlbtw-8 {
    padding-left: 40px !important;
  }
  .p-xlbtw-9 {
    padding: 45px !important;
  }
  .pt-xlbtw-9,
  .py-xlbtw-9 {
    padding-top: 45px !important;
  }
  .pr-xlbtw-9,
  .px-xlbtw-9 {
    padding-right: 45px !important;
  }
  .pb-xlbtw-9,
  .py-xlbtw-9 {
    padding-bottom: 45px !important;
  }
  .pl-xlbtw-9,
  .px-xlbtw-9 {
    padding-left: 45px !important;
  }
  .p-xlbtw-10 {
    padding: 50px !important;
  }
  .pt-xlbtw-10,
  .py-xlbtw-10 {
    padding-top: 50px !important;
  }
  .pr-xlbtw-10,
  .px-xlbtw-10 {
    padding-right: 50px !important;
  }
  .pb-xlbtw-10,
  .py-xlbtw-10 {
    padding-bottom: 50px !important;
  }
  .pl-xlbtw-10,
  .px-xlbtw-10 {
    padding-left: 50px !important;
  }
  .p-xlbtw-11 {
    padding: 55px !important;
  }
  .pt-xlbtw-11,
  .py-xlbtw-11 {
    padding-top: 55px !important;
  }
  .pr-xlbtw-11,
  .px-xlbtw-11 {
    padding-right: 55px !important;
  }
  .pb-xlbtw-11,
  .py-xlbtw-11 {
    padding-bottom: 55px !important;
  }
  .pl-xlbtw-11,
  .px-xlbtw-11 {
    padding-left: 55px !important;
  }
  .p-xlbtw-12 {
    padding: 60px !important;
  }
  .pt-xlbtw-12,
  .py-xlbtw-12 {
    padding-top: 60px !important;
  }
  .pr-xlbtw-12,
  .px-xlbtw-12 {
    padding-right: 60px !important;
  }
  .pb-xlbtw-12,
  .py-xlbtw-12 {
    padding-bottom: 60px !important;
  }
  .pl-xlbtw-12,
  .px-xlbtw-12 {
    padding-left: 60px !important;
  }
  .p-xlbtw-13 {
    padding: 65px !important;
  }
  .pt-xlbtw-13,
  .py-xlbtw-13 {
    padding-top: 65px !important;
  }
  .pr-xlbtw-13,
  .px-xlbtw-13 {
    padding-right: 65px !important;
  }
  .pb-xlbtw-13,
  .py-xlbtw-13 {
    padding-bottom: 65px !important;
  }
  .pl-xlbtw-13,
  .px-xlbtw-13 {
    padding-left: 65px !important;
  }
  .p-xlbtw-14 {
    padding: 70px !important;
  }
  .pt-xlbtw-14,
  .py-xlbtw-14 {
    padding-top: 70px !important;
  }
  .pr-xlbtw-14,
  .px-xlbtw-14 {
    padding-right: 70px !important;
  }
  .pb-xlbtw-14,
  .py-xlbtw-14 {
    padding-bottom: 70px !important;
  }
  .pl-xlbtw-14,
  .px-xlbtw-14 {
    padding-left: 70px !important;
  }
  .p-xlbtw-15 {
    padding: 75px !important;
  }
  .pt-xlbtw-15,
  .py-xlbtw-15 {
    padding-top: 75px !important;
  }
  .pr-xlbtw-15,
  .px-xlbtw-15 {
    padding-right: 75px !important;
  }
  .pb-xlbtw-15,
  .py-xlbtw-15 {
    padding-bottom: 75px !important;
  }
  .pl-xlbtw-15,
  .px-xlbtw-15 {
    padding-left: 75px !important;
  }
  .p-xlbtw-16 {
    padding: 80px !important;
  }
  .pt-xlbtw-16,
  .py-xlbtw-16 {
    padding-top: 80px !important;
  }
  .pr-xlbtw-16,
  .px-xlbtw-16 {
    padding-right: 80px !important;
  }
  .pb-xlbtw-16,
  .py-xlbtw-16 {
    padding-bottom: 80px !important;
  }
  .pl-xlbtw-16,
  .px-xlbtw-16 {
    padding-left: 80px !important;
  }
  .p-xlbtw-17 {
    padding: 85px !important;
  }
  .pt-xlbtw-17,
  .py-xlbtw-17 {
    padding-top: 85px !important;
  }
  .pr-xlbtw-17,
  .px-xlbtw-17 {
    padding-right: 85px !important;
  }
  .pb-xlbtw-17,
  .py-xlbtw-17 {
    padding-bottom: 85px !important;
  }
  .pl-xlbtw-17,
  .px-xlbtw-17 {
    padding-left: 85px !important;
  }
  .p-xlbtw-18 {
    padding: 90px !important;
  }
  .pt-xlbtw-18,
  .py-xlbtw-18 {
    padding-top: 90px !important;
  }
  .pr-xlbtw-18,
  .px-xlbtw-18 {
    padding-right: 90px !important;
  }
  .pb-xlbtw-18,
  .py-xlbtw-18 {
    padding-bottom: 90px !important;
  }
  .pl-xlbtw-18,
  .px-xlbtw-18 {
    padding-left: 90px !important;
  }
  .p-xlbtw-19 {
    padding: 95px !important;
  }
  .pt-xlbtw-19,
  .py-xlbtw-19 {
    padding-top: 95px !important;
  }
  .pr-xlbtw-19,
  .px-xlbtw-19 {
    padding-right: 95px !important;
  }
  .pb-xlbtw-19,
  .py-xlbtw-19 {
    padding-bottom: 95px !important;
  }
  .pl-xlbtw-19,
  .px-xlbtw-19 {
    padding-left: 95px !important;
  }
  .p-xlbtw-20 {
    padding: 100px !important;
  }
  .pt-xlbtw-20,
  .py-xlbtw-20 {
    padding-top: 100px !important;
  }
  .pr-xlbtw-20,
  .px-xlbtw-20 {
    padding-right: 100px !important;
  }
  .pb-xlbtw-20,
  .py-xlbtw-20 {
    padding-bottom: 100px !important;
  }
  .pl-xlbtw-20,
  .px-xlbtw-20 {
    padding-left: 100px !important;
  }
  .p-xlbtw-21 {
    padding: 105px !important;
  }
  .pt-xlbtw-21,
  .py-xlbtw-21 {
    padding-top: 105px !important;
  }
  .pr-xlbtw-21,
  .px-xlbtw-21 {
    padding-right: 105px !important;
  }
  .pb-xlbtw-21,
  .py-xlbtw-21 {
    padding-bottom: 105px !important;
  }
  .pl-xlbtw-21,
  .px-xlbtw-21 {
    padding-left: 105px !important;
  }
  .p-xlbtw-22 {
    padding: 110px !important;
  }
  .pt-xlbtw-22,
  .py-xlbtw-22 {
    padding-top: 110px !important;
  }
  .pr-xlbtw-22,
  .px-xlbtw-22 {
    padding-right: 110px !important;
  }
  .pb-xlbtw-22,
  .py-xlbtw-22 {
    padding-bottom: 110px !important;
  }
  .pl-xlbtw-22,
  .px-xlbtw-22 {
    padding-left: 110px !important;
  }
  .p-xlbtw-23 {
    padding: 115px !important;
  }
  .pt-xlbtw-23,
  .py-xlbtw-23 {
    padding-top: 115px !important;
  }
  .pr-xlbtw-23,
  .px-xlbtw-23 {
    padding-right: 115px !important;
  }
  .pb-xlbtw-23,
  .py-xlbtw-23 {
    padding-bottom: 115px !important;
  }
  .pl-xlbtw-23,
  .px-xlbtw-23 {
    padding-left: 115px !important;
  }
  .p-xlbtw-24 {
    padding: 120px !important;
  }
  .pt-xlbtw-24,
  .py-xlbtw-24 {
    padding-top: 120px !important;
  }
  .pr-xlbtw-24,
  .px-xlbtw-24 {
    padding-right: 120px !important;
  }
  .pb-xlbtw-24,
  .py-xlbtw-24 {
    padding-bottom: 120px !important;
  }
  .pl-xlbtw-24,
  .px-xlbtw-24 {
    padding-left: 120px !important;
  }
  .p-xlbtw-25 {
    padding: 125px !important;
  }
  .pt-xlbtw-25,
  .py-xlbtw-25 {
    padding-top: 125px !important;
  }
  .pr-xlbtw-25,
  .px-xlbtw-25 {
    padding-right: 125px !important;
  }
  .pb-xlbtw-25,
  .py-xlbtw-25 {
    padding-bottom: 125px !important;
  }
  .pl-xlbtw-25,
  .px-xlbtw-25 {
    padding-left: 125px !important;
  }
  .p-xlbtw-26 {
    padding: 130px !important;
  }
  .pt-xlbtw-26,
  .py-xlbtw-26 {
    padding-top: 130px !important;
  }
  .pr-xlbtw-26,
  .px-xlbtw-26 {
    padding-right: 130px !important;
  }
  .pb-xlbtw-26,
  .py-xlbtw-26 {
    padding-bottom: 130px !important;
  }
  .pl-xlbtw-26,
  .px-xlbtw-26 {
    padding-left: 130px !important;
  }
  .p-xlbtw-27 {
    padding: 135px !important;
  }
  .pt-xlbtw-27,
  .py-xlbtw-27 {
    padding-top: 135px !important;
  }
  .pr-xlbtw-27,
  .px-xlbtw-27 {
    padding-right: 135px !important;
  }
  .pb-xlbtw-27,
  .py-xlbtw-27 {
    padding-bottom: 135px !important;
  }
  .pl-xlbtw-27,
  .px-xlbtw-27 {
    padding-left: 135px !important;
  }
  .p-xlbtw-28 {
    padding: 140px !important;
  }
  .pt-xlbtw-28,
  .py-xlbtw-28 {
    padding-top: 140px !important;
  }
  .pr-xlbtw-28,
  .px-xlbtw-28 {
    padding-right: 140px !important;
  }
  .pb-xlbtw-28,
  .py-xlbtw-28 {
    padding-bottom: 140px !important;
  }
  .pl-xlbtw-28,
  .px-xlbtw-28 {
    padding-left: 140px !important;
  }
  .p-xlbtw-29 {
    padding: 145px !important;
  }
  .pt-xlbtw-29,
  .py-xlbtw-29 {
    padding-top: 145px !important;
  }
  .pr-xlbtw-29,
  .px-xlbtw-29 {
    padding-right: 145px !important;
  }
  .pb-xlbtw-29,
  .py-xlbtw-29 {
    padding-bottom: 145px !important;
  }
  .pl-xlbtw-29,
  .px-xlbtw-29 {
    padding-left: 145px !important;
  }
  .p-xlbtw-30 {
    padding: 150px !important;
  }
  .pt-xlbtw-30,
  .py-xlbtw-30 {
    padding-top: 150px !important;
  }
  .pr-xlbtw-30,
  .px-xlbtw-30 {
    padding-right: 150px !important;
  }
  .pb-xlbtw-30,
  .py-xlbtw-30 {
    padding-bottom: 150px !important;
  }
  .pl-xlbtw-30,
  .px-xlbtw-30 {
    padding-left: 150px !important;
  }
  .p-xlbtw-31 {
    padding: 155px !important;
  }
  .pt-xlbtw-31,
  .py-xlbtw-31 {
    padding-top: 155px !important;
  }
  .pr-xlbtw-31,
  .px-xlbtw-31 {
    padding-right: 155px !important;
  }
  .pb-xlbtw-31,
  .py-xlbtw-31 {
    padding-bottom: 155px !important;
  }
  .pl-xlbtw-31,
  .px-xlbtw-31 {
    padding-left: 155px !important;
  }
  .p-xlbtw-32 {
    padding: 160px !important;
  }
  .pt-xlbtw-32,
  .py-xlbtw-32 {
    padding-top: 160px !important;
  }
  .pr-xlbtw-32,
  .px-xlbtw-32 {
    padding-right: 160px !important;
  }
  .pb-xlbtw-32,
  .py-xlbtw-32 {
    padding-bottom: 160px !important;
  }
  .pl-xlbtw-32,
  .px-xlbtw-32 {
    padding-left: 160px !important;
  }
  .p-xlbtw-33 {
    padding: 165px !important;
  }
  .pt-xlbtw-33,
  .py-xlbtw-33 {
    padding-top: 165px !important;
  }
  .pr-xlbtw-33,
  .px-xlbtw-33 {
    padding-right: 165px !important;
  }
  .pb-xlbtw-33,
  .py-xlbtw-33 {
    padding-bottom: 165px !important;
  }
  .pl-xlbtw-33,
  .px-xlbtw-33 {
    padding-left: 165px !important;
  }
  .p-xlbtw-34 {
    padding: 170px !important;
  }
  .pt-xlbtw-34,
  .py-xlbtw-34 {
    padding-top: 170px !important;
  }
  .pr-xlbtw-34,
  .px-xlbtw-34 {
    padding-right: 170px !important;
  }
  .pb-xlbtw-34,
  .py-xlbtw-34 {
    padding-bottom: 170px !important;
  }
  .pl-xlbtw-34,
  .px-xlbtw-34 {
    padding-left: 170px !important;
  }
  .p-xlbtw-35 {
    padding: 175px !important;
  }
  .pt-xlbtw-35,
  .py-xlbtw-35 {
    padding-top: 175px !important;
  }
  .pr-xlbtw-35,
  .px-xlbtw-35 {
    padding-right: 175px !important;
  }
  .pb-xlbtw-35,
  .py-xlbtw-35 {
    padding-bottom: 175px !important;
  }
  .pl-xlbtw-35,
  .px-xlbtw-35 {
    padding-left: 175px !important;
  }
  .p-xlbtw-36 {
    padding: 180px !important;
  }
  .pt-xlbtw-36,
  .py-xlbtw-36 {
    padding-top: 180px !important;
  }
  .pr-xlbtw-36,
  .px-xlbtw-36 {
    padding-right: 180px !important;
  }
  .pb-xlbtw-36,
  .py-xlbtw-36 {
    padding-bottom: 180px !important;
  }
  .pl-xlbtw-36,
  .px-xlbtw-36 {
    padding-left: 180px !important;
  }
  .p-xlbtw-37 {
    padding: 185px !important;
  }
  .pt-xlbtw-37,
  .py-xlbtw-37 {
    padding-top: 185px !important;
  }
  .pr-xlbtw-37,
  .px-xlbtw-37 {
    padding-right: 185px !important;
  }
  .pb-xlbtw-37,
  .py-xlbtw-37 {
    padding-bottom: 185px !important;
  }
  .pl-xlbtw-37,
  .px-xlbtw-37 {
    padding-left: 185px !important;
  }
  .p-xlbtw-38 {
    padding: 190px !important;
  }
  .pt-xlbtw-38,
  .py-xlbtw-38 {
    padding-top: 190px !important;
  }
  .pr-xlbtw-38,
  .px-xlbtw-38 {
    padding-right: 190px !important;
  }
  .pb-xlbtw-38,
  .py-xlbtw-38 {
    padding-bottom: 190px !important;
  }
  .pl-xlbtw-38,
  .px-xlbtw-38 {
    padding-left: 190px !important;
  }
  .p-xlbtw-39 {
    padding: 195px !important;
  }
  .pt-xlbtw-39,
  .py-xlbtw-39 {
    padding-top: 195px !important;
  }
  .pr-xlbtw-39,
  .px-xlbtw-39 {
    padding-right: 195px !important;
  }
  .pb-xlbtw-39,
  .py-xlbtw-39 {
    padding-bottom: 195px !important;
  }
  .pl-xlbtw-39,
  .px-xlbtw-39 {
    padding-left: 195px !important;
  }
  .p-xlbtw-40 {
    padding: 200px !important;
  }
  .pt-xlbtw-40,
  .py-xlbtw-40 {
    padding-top: 200px !important;
  }
  .pr-xlbtw-40,
  .px-xlbtw-40 {
    padding-right: 200px !important;
  }
  .pb-xlbtw-40,
  .py-xlbtw-40 {
    padding-bottom: 200px !important;
  }
  .pl-xlbtw-40,
  .px-xlbtw-40 {
    padding-left: 200px !important;
  }
  .p-xlbtw-41 {
    padding: 205px !important;
  }
  .pt-xlbtw-41,
  .py-xlbtw-41 {
    padding-top: 205px !important;
  }
  .pr-xlbtw-41,
  .px-xlbtw-41 {
    padding-right: 205px !important;
  }
  .pb-xlbtw-41,
  .py-xlbtw-41 {
    padding-bottom: 205px !important;
  }
  .pl-xlbtw-41,
  .px-xlbtw-41 {
    padding-left: 205px !important;
  }
  .p-xlbtw-42 {
    padding: 210px !important;
  }
  .pt-xlbtw-42,
  .py-xlbtw-42 {
    padding-top: 210px !important;
  }
  .pr-xlbtw-42,
  .px-xlbtw-42 {
    padding-right: 210px !important;
  }
  .pb-xlbtw-42,
  .py-xlbtw-42 {
    padding-bottom: 210px !important;
  }
  .pl-xlbtw-42,
  .px-xlbtw-42 {
    padding-left: 210px !important;
  }
  .p-xlbtw-43 {
    padding: 215px !important;
  }
  .pt-xlbtw-43,
  .py-xlbtw-43 {
    padding-top: 215px !important;
  }
  .pr-xlbtw-43,
  .px-xlbtw-43 {
    padding-right: 215px !important;
  }
  .pb-xlbtw-43,
  .py-xlbtw-43 {
    padding-bottom: 215px !important;
  }
  .pl-xlbtw-43,
  .px-xlbtw-43 {
    padding-left: 215px !important;
  }
  .p-xlbtw-44 {
    padding: 220px !important;
  }
  .pt-xlbtw-44,
  .py-xlbtw-44 {
    padding-top: 220px !important;
  }
  .pr-xlbtw-44,
  .px-xlbtw-44 {
    padding-right: 220px !important;
  }
  .pb-xlbtw-44,
  .py-xlbtw-44 {
    padding-bottom: 220px !important;
  }
  .pl-xlbtw-44,
  .px-xlbtw-44 {
    padding-left: 220px !important;
  }
  .p-xlbtw-45 {
    padding: 225px !important;
  }
  .pt-xlbtw-45,
  .py-xlbtw-45 {
    padding-top: 225px !important;
  }
  .pr-xlbtw-45,
  .px-xlbtw-45 {
    padding-right: 225px !important;
  }
  .pb-xlbtw-45,
  .py-xlbtw-45 {
    padding-bottom: 225px !important;
  }
  .pl-xlbtw-45,
  .px-xlbtw-45 {
    padding-left: 225px !important;
  }
  .p-xlbtw-46 {
    padding: 230px !important;
  }
  .pt-xlbtw-46,
  .py-xlbtw-46 {
    padding-top: 230px !important;
  }
  .pr-xlbtw-46,
  .px-xlbtw-46 {
    padding-right: 230px !important;
  }
  .pb-xlbtw-46,
  .py-xlbtw-46 {
    padding-bottom: 230px !important;
  }
  .pl-xlbtw-46,
  .px-xlbtw-46 {
    padding-left: 230px !important;
  }
  .p-xlbtw-47 {
    padding: 235px !important;
  }
  .pt-xlbtw-47,
  .py-xlbtw-47 {
    padding-top: 235px !important;
  }
  .pr-xlbtw-47,
  .px-xlbtw-47 {
    padding-right: 235px !important;
  }
  .pb-xlbtw-47,
  .py-xlbtw-47 {
    padding-bottom: 235px !important;
  }
  .pl-xlbtw-47,
  .px-xlbtw-47 {
    padding-left: 235px !important;
  }
  .p-xlbtw-48 {
    padding: 240px !important;
  }
  .pt-xlbtw-48,
  .py-xlbtw-48 {
    padding-top: 240px !important;
  }
  .pr-xlbtw-48,
  .px-xlbtw-48 {
    padding-right: 240px !important;
  }
  .pb-xlbtw-48,
  .py-xlbtw-48 {
    padding-bottom: 240px !important;
  }
  .pl-xlbtw-48,
  .px-xlbtw-48 {
    padding-left: 240px !important;
  }
  .p-xlbtw-49 {
    padding: 245px !important;
  }
  .pt-xlbtw-49,
  .py-xlbtw-49 {
    padding-top: 245px !important;
  }
  .pr-xlbtw-49,
  .px-xlbtw-49 {
    padding-right: 245px !important;
  }
  .pb-xlbtw-49,
  .py-xlbtw-49 {
    padding-bottom: 245px !important;
  }
  .pl-xlbtw-49,
  .px-xlbtw-49 {
    padding-left: 245px !important;
  }
  .p-xlbtw-50 {
    padding: 250px !important;
  }
  .pt-xlbtw-50,
  .py-xlbtw-50 {
    padding-top: 250px !important;
  }
  .pr-xlbtw-50,
  .px-xlbtw-50 {
    padding-right: 250px !important;
  }
  .pb-xlbtw-50,
  .py-xlbtw-50 {
    padding-bottom: 250px !important;
  }
  .pl-xlbtw-50,
  .px-xlbtw-50 {
    padding-left: 250px !important;
  }
  .p-xlbtw-51 {
    padding: 255px !important;
  }
  .pt-xlbtw-51,
  .py-xlbtw-51 {
    padding-top: 255px !important;
  }
  .pr-xlbtw-51,
  .px-xlbtw-51 {
    padding-right: 255px !important;
  }
  .pb-xlbtw-51,
  .py-xlbtw-51 {
    padding-bottom: 255px !important;
  }
  .pl-xlbtw-51,
  .px-xlbtw-51 {
    padding-left: 255px !important;
  }
  .p-xlbtw-52 {
    padding: 260px !important;
  }
  .pt-xlbtw-52,
  .py-xlbtw-52 {
    padding-top: 260px !important;
  }
  .pr-xlbtw-52,
  .px-xlbtw-52 {
    padding-right: 260px !important;
  }
  .pb-xlbtw-52,
  .py-xlbtw-52 {
    padding-bottom: 260px !important;
  }
  .pl-xlbtw-52,
  .px-xlbtw-52 {
    padding-left: 260px !important;
  }
  .p-xlbtw-53 {
    padding: 265px !important;
  }
  .pt-xlbtw-53,
  .py-xlbtw-53 {
    padding-top: 265px !important;
  }
  .pr-xlbtw-53,
  .px-xlbtw-53 {
    padding-right: 265px !important;
  }
  .pb-xlbtw-53,
  .py-xlbtw-53 {
    padding-bottom: 265px !important;
  }
  .pl-xlbtw-53,
  .px-xlbtw-53 {
    padding-left: 265px !important;
  }
  .p-xlbtw-54 {
    padding: 270px !important;
  }
  .pt-xlbtw-54,
  .py-xlbtw-54 {
    padding-top: 270px !important;
  }
  .pr-xlbtw-54,
  .px-xlbtw-54 {
    padding-right: 270px !important;
  }
  .pb-xlbtw-54,
  .py-xlbtw-54 {
    padding-bottom: 270px !important;
  }
  .pl-xlbtw-54,
  .px-xlbtw-54 {
    padding-left: 270px !important;
  }
  .p-xlbtw-55 {
    padding: 275px !important;
  }
  .pt-xlbtw-55,
  .py-xlbtw-55 {
    padding-top: 275px !important;
  }
  .pr-xlbtw-55,
  .px-xlbtw-55 {
    padding-right: 275px !important;
  }
  .pb-xlbtw-55,
  .py-xlbtw-55 {
    padding-bottom: 275px !important;
  }
  .pl-xlbtw-55,
  .px-xlbtw-55 {
    padding-left: 275px !important;
  }
  .p-xlbtw-56 {
    padding: 280px !important;
  }
  .pt-xlbtw-56,
  .py-xlbtw-56 {
    padding-top: 280px !important;
  }
  .pr-xlbtw-56,
  .px-xlbtw-56 {
    padding-right: 280px !important;
  }
  .pb-xlbtw-56,
  .py-xlbtw-56 {
    padding-bottom: 280px !important;
  }
  .pl-xlbtw-56,
  .px-xlbtw-56 {
    padding-left: 280px !important;
  }
  .p-xlbtw-57 {
    padding: 285px !important;
  }
  .pt-xlbtw-57,
  .py-xlbtw-57 {
    padding-top: 285px !important;
  }
  .pr-xlbtw-57,
  .px-xlbtw-57 {
    padding-right: 285px !important;
  }
  .pb-xlbtw-57,
  .py-xlbtw-57 {
    padding-bottom: 285px !important;
  }
  .pl-xlbtw-57,
  .px-xlbtw-57 {
    padding-left: 285px !important;
  }
  .p-xlbtw-58 {
    padding: 290px !important;
  }
  .pt-xlbtw-58,
  .py-xlbtw-58 {
    padding-top: 290px !important;
  }
  .pr-xlbtw-58,
  .px-xlbtw-58 {
    padding-right: 290px !important;
  }
  .pb-xlbtw-58,
  .py-xlbtw-58 {
    padding-bottom: 290px !important;
  }
  .pl-xlbtw-58,
  .px-xlbtw-58 {
    padding-left: 290px !important;
  }
  .p-xlbtw-59 {
    padding: 295px !important;
  }
  .pt-xlbtw-59,
  .py-xlbtw-59 {
    padding-top: 295px !important;
  }
  .pr-xlbtw-59,
  .px-xlbtw-59 {
    padding-right: 295px !important;
  }
  .pb-xlbtw-59,
  .py-xlbtw-59 {
    padding-bottom: 295px !important;
  }
  .pl-xlbtw-59,
  .px-xlbtw-59 {
    padding-left: 295px !important;
  }
  .p-xlbtw-60 {
    padding: 300px !important;
  }
  .pt-xlbtw-60,
  .py-xlbtw-60 {
    padding-top: 300px !important;
  }
  .pr-xlbtw-60,
  .px-xlbtw-60 {
    padding-right: 300px !important;
  }
  .pb-xlbtw-60,
  .py-xlbtw-60 {
    padding-bottom: 300px !important;
  }
  .pl-xlbtw-60,
  .px-xlbtw-60 {
    padding-left: 300px !important;
  }
  .m-xlbtw-n1 {
    margin: -5px !important;
  }
  .mt-xlbtw-n1,
  .my-xlbtw-n1 {
    margin-top: -5px !important;
  }
  .mr-xlbtw-n1,
  .mx-xlbtw-n1 {
    margin-right: -5px !important;
  }
  .mb-xlbtw-n1,
  .my-xlbtw-n1 {
    margin-bottom: -5px !important;
  }
  .ml-xlbtw-n1,
  .mx-xlbtw-n1 {
    margin-left: -5px !important;
  }
  .m-xlbtw-n2 {
    margin: -10px !important;
  }
  .mt-xlbtw-n2,
  .my-xlbtw-n2 {
    margin-top: -10px !important;
  }
  .mr-xlbtw-n2,
  .mx-xlbtw-n2 {
    margin-right: -10px !important;
  }
  .mb-xlbtw-n2,
  .my-xlbtw-n2 {
    margin-bottom: -10px !important;
  }
  .ml-xlbtw-n2,
  .mx-xlbtw-n2 {
    margin-left: -10px !important;
  }
  .m-xlbtw-n3 {
    margin: -16px !important;
  }
  .mt-xlbtw-n3,
  .my-xlbtw-n3 {
    margin-top: -16px !important;
  }
  .mr-xlbtw-n3,
  .mx-xlbtw-n3 {
    margin-right: -16px !important;
  }
  .mb-xlbtw-n3,
  .my-xlbtw-n3 {
    margin-bottom: -16px !important;
  }
  .ml-xlbtw-n3,
  .mx-xlbtw-n3 {
    margin-left: -16px !important;
  }
  .m-xlbtw-n4 {
    margin: -20px !important;
  }
  .mt-xlbtw-n4,
  .my-xlbtw-n4 {
    margin-top: -20px !important;
  }
  .mr-xlbtw-n4,
  .mx-xlbtw-n4 {
    margin-right: -20px !important;
  }
  .mb-xlbtw-n4,
  .my-xlbtw-n4 {
    margin-bottom: -20px !important;
  }
  .ml-xlbtw-n4,
  .mx-xlbtw-n4 {
    margin-left: -20px !important;
  }
  .m-xlbtw-n5 {
    margin: -25px !important;
  }
  .mt-xlbtw-n5,
  .my-xlbtw-n5 {
    margin-top: -25px !important;
  }
  .mr-xlbtw-n5,
  .mx-xlbtw-n5 {
    margin-right: -25px !important;
  }
  .mb-xlbtw-n5,
  .my-xlbtw-n5 {
    margin-bottom: -25px !important;
  }
  .ml-xlbtw-n5,
  .mx-xlbtw-n5 {
    margin-left: -25px !important;
  }
  .m-xlbtw-n6 {
    margin: -30px !important;
  }
  .mt-xlbtw-n6,
  .my-xlbtw-n6 {
    margin-top: -30px !important;
  }
  .mr-xlbtw-n6,
  .mx-xlbtw-n6 {
    margin-right: -30px !important;
  }
  .mb-xlbtw-n6,
  .my-xlbtw-n6 {
    margin-bottom: -30px !important;
  }
  .ml-xlbtw-n6,
  .mx-xlbtw-n6 {
    margin-left: -30px !important;
  }
  .m-xlbtw-n7 {
    margin: -35px !important;
  }
  .mt-xlbtw-n7,
  .my-xlbtw-n7 {
    margin-top: -35px !important;
  }
  .mr-xlbtw-n7,
  .mx-xlbtw-n7 {
    margin-right: -35px !important;
  }
  .mb-xlbtw-n7,
  .my-xlbtw-n7 {
    margin-bottom: -35px !important;
  }
  .ml-xlbtw-n7,
  .mx-xlbtw-n7 {
    margin-left: -35px !important;
  }
  .m-xlbtw-n8 {
    margin: -40px !important;
  }
  .mt-xlbtw-n8,
  .my-xlbtw-n8 {
    margin-top: -40px !important;
  }
  .mr-xlbtw-n8,
  .mx-xlbtw-n8 {
    margin-right: -40px !important;
  }
  .mb-xlbtw-n8,
  .my-xlbtw-n8 {
    margin-bottom: -40px !important;
  }
  .ml-xlbtw-n8,
  .mx-xlbtw-n8 {
    margin-left: -40px !important;
  }
  .m-xlbtw-n9 {
    margin: -45px !important;
  }
  .mt-xlbtw-n9,
  .my-xlbtw-n9 {
    margin-top: -45px !important;
  }
  .mr-xlbtw-n9,
  .mx-xlbtw-n9 {
    margin-right: -45px !important;
  }
  .mb-xlbtw-n9,
  .my-xlbtw-n9 {
    margin-bottom: -45px !important;
  }
  .ml-xlbtw-n9,
  .mx-xlbtw-n9 {
    margin-left: -45px !important;
  }
  .m-xlbtw-n10 {
    margin: -50px !important;
  }
  .mt-xlbtw-n10,
  .my-xlbtw-n10 {
    margin-top: -50px !important;
  }
  .mr-xlbtw-n10,
  .mx-xlbtw-n10 {
    margin-right: -50px !important;
  }
  .mb-xlbtw-n10,
  .my-xlbtw-n10 {
    margin-bottom: -50px !important;
  }
  .ml-xlbtw-n10,
  .mx-xlbtw-n10 {
    margin-left: -50px !important;
  }
  .m-xlbtw-n11 {
    margin: -55px !important;
  }
  .mt-xlbtw-n11,
  .my-xlbtw-n11 {
    margin-top: -55px !important;
  }
  .mr-xlbtw-n11,
  .mx-xlbtw-n11 {
    margin-right: -55px !important;
  }
  .mb-xlbtw-n11,
  .my-xlbtw-n11 {
    margin-bottom: -55px !important;
  }
  .ml-xlbtw-n11,
  .mx-xlbtw-n11 {
    margin-left: -55px !important;
  }
  .m-xlbtw-n12 {
    margin: -60px !important;
  }
  .mt-xlbtw-n12,
  .my-xlbtw-n12 {
    margin-top: -60px !important;
  }
  .mr-xlbtw-n12,
  .mx-xlbtw-n12 {
    margin-right: -60px !important;
  }
  .mb-xlbtw-n12,
  .my-xlbtw-n12 {
    margin-bottom: -60px !important;
  }
  .ml-xlbtw-n12,
  .mx-xlbtw-n12 {
    margin-left: -60px !important;
  }
  .m-xlbtw-n13 {
    margin: -65px !important;
  }
  .mt-xlbtw-n13,
  .my-xlbtw-n13 {
    margin-top: -65px !important;
  }
  .mr-xlbtw-n13,
  .mx-xlbtw-n13 {
    margin-right: -65px !important;
  }
  .mb-xlbtw-n13,
  .my-xlbtw-n13 {
    margin-bottom: -65px !important;
  }
  .ml-xlbtw-n13,
  .mx-xlbtw-n13 {
    margin-left: -65px !important;
  }
  .m-xlbtw-n14 {
    margin: -70px !important;
  }
  .mt-xlbtw-n14,
  .my-xlbtw-n14 {
    margin-top: -70px !important;
  }
  .mr-xlbtw-n14,
  .mx-xlbtw-n14 {
    margin-right: -70px !important;
  }
  .mb-xlbtw-n14,
  .my-xlbtw-n14 {
    margin-bottom: -70px !important;
  }
  .ml-xlbtw-n14,
  .mx-xlbtw-n14 {
    margin-left: -70px !important;
  }
  .m-xlbtw-n15 {
    margin: -75px !important;
  }
  .mt-xlbtw-n15,
  .my-xlbtw-n15 {
    margin-top: -75px !important;
  }
  .mr-xlbtw-n15,
  .mx-xlbtw-n15 {
    margin-right: -75px !important;
  }
  .mb-xlbtw-n15,
  .my-xlbtw-n15 {
    margin-bottom: -75px !important;
  }
  .ml-xlbtw-n15,
  .mx-xlbtw-n15 {
    margin-left: -75px !important;
  }
  .m-xlbtw-n16 {
    margin: -80px !important;
  }
  .mt-xlbtw-n16,
  .my-xlbtw-n16 {
    margin-top: -80px !important;
  }
  .mr-xlbtw-n16,
  .mx-xlbtw-n16 {
    margin-right: -80px !important;
  }
  .mb-xlbtw-n16,
  .my-xlbtw-n16 {
    margin-bottom: -80px !important;
  }
  .ml-xlbtw-n16,
  .mx-xlbtw-n16 {
    margin-left: -80px !important;
  }
  .m-xlbtw-n17 {
    margin: -85px !important;
  }
  .mt-xlbtw-n17,
  .my-xlbtw-n17 {
    margin-top: -85px !important;
  }
  .mr-xlbtw-n17,
  .mx-xlbtw-n17 {
    margin-right: -85px !important;
  }
  .mb-xlbtw-n17,
  .my-xlbtw-n17 {
    margin-bottom: -85px !important;
  }
  .ml-xlbtw-n17,
  .mx-xlbtw-n17 {
    margin-left: -85px !important;
  }
  .m-xlbtw-n18 {
    margin: -90px !important;
  }
  .mt-xlbtw-n18,
  .my-xlbtw-n18 {
    margin-top: -90px !important;
  }
  .mr-xlbtw-n18,
  .mx-xlbtw-n18 {
    margin-right: -90px !important;
  }
  .mb-xlbtw-n18,
  .my-xlbtw-n18 {
    margin-bottom: -90px !important;
  }
  .ml-xlbtw-n18,
  .mx-xlbtw-n18 {
    margin-left: -90px !important;
  }
  .m-xlbtw-n19 {
    margin: -95px !important;
  }
  .mt-xlbtw-n19,
  .my-xlbtw-n19 {
    margin-top: -95px !important;
  }
  .mr-xlbtw-n19,
  .mx-xlbtw-n19 {
    margin-right: -95px !important;
  }
  .mb-xlbtw-n19,
  .my-xlbtw-n19 {
    margin-bottom: -95px !important;
  }
  .ml-xlbtw-n19,
  .mx-xlbtw-n19 {
    margin-left: -95px !important;
  }
  .m-xlbtw-n20 {
    margin: -100px !important;
  }
  .mt-xlbtw-n20,
  .my-xlbtw-n20 {
    margin-top: -100px !important;
  }
  .mr-xlbtw-n20,
  .mx-xlbtw-n20 {
    margin-right: -100px !important;
  }
  .mb-xlbtw-n20,
  .my-xlbtw-n20 {
    margin-bottom: -100px !important;
  }
  .ml-xlbtw-n20,
  .mx-xlbtw-n20 {
    margin-left: -100px !important;
  }
  .m-xlbtw-n21 {
    margin: -105px !important;
  }
  .mt-xlbtw-n21,
  .my-xlbtw-n21 {
    margin-top: -105px !important;
  }
  .mr-xlbtw-n21,
  .mx-xlbtw-n21 {
    margin-right: -105px !important;
  }
  .mb-xlbtw-n21,
  .my-xlbtw-n21 {
    margin-bottom: -105px !important;
  }
  .ml-xlbtw-n21,
  .mx-xlbtw-n21 {
    margin-left: -105px !important;
  }
  .m-xlbtw-n22 {
    margin: -110px !important;
  }
  .mt-xlbtw-n22,
  .my-xlbtw-n22 {
    margin-top: -110px !important;
  }
  .mr-xlbtw-n22,
  .mx-xlbtw-n22 {
    margin-right: -110px !important;
  }
  .mb-xlbtw-n22,
  .my-xlbtw-n22 {
    margin-bottom: -110px !important;
  }
  .ml-xlbtw-n22,
  .mx-xlbtw-n22 {
    margin-left: -110px !important;
  }
  .m-xlbtw-n23 {
    margin: -115px !important;
  }
  .mt-xlbtw-n23,
  .my-xlbtw-n23 {
    margin-top: -115px !important;
  }
  .mr-xlbtw-n23,
  .mx-xlbtw-n23 {
    margin-right: -115px !important;
  }
  .mb-xlbtw-n23,
  .my-xlbtw-n23 {
    margin-bottom: -115px !important;
  }
  .ml-xlbtw-n23,
  .mx-xlbtw-n23 {
    margin-left: -115px !important;
  }
  .m-xlbtw-n24 {
    margin: -120px !important;
  }
  .mt-xlbtw-n24,
  .my-xlbtw-n24 {
    margin-top: -120px !important;
  }
  .mr-xlbtw-n24,
  .mx-xlbtw-n24 {
    margin-right: -120px !important;
  }
  .mb-xlbtw-n24,
  .my-xlbtw-n24 {
    margin-bottom: -120px !important;
  }
  .ml-xlbtw-n24,
  .mx-xlbtw-n24 {
    margin-left: -120px !important;
  }
  .m-xlbtw-n25 {
    margin: -125px !important;
  }
  .mt-xlbtw-n25,
  .my-xlbtw-n25 {
    margin-top: -125px !important;
  }
  .mr-xlbtw-n25,
  .mx-xlbtw-n25 {
    margin-right: -125px !important;
  }
  .mb-xlbtw-n25,
  .my-xlbtw-n25 {
    margin-bottom: -125px !important;
  }
  .ml-xlbtw-n25,
  .mx-xlbtw-n25 {
    margin-left: -125px !important;
  }
  .m-xlbtw-n26 {
    margin: -130px !important;
  }
  .mt-xlbtw-n26,
  .my-xlbtw-n26 {
    margin-top: -130px !important;
  }
  .mr-xlbtw-n26,
  .mx-xlbtw-n26 {
    margin-right: -130px !important;
  }
  .mb-xlbtw-n26,
  .my-xlbtw-n26 {
    margin-bottom: -130px !important;
  }
  .ml-xlbtw-n26,
  .mx-xlbtw-n26 {
    margin-left: -130px !important;
  }
  .m-xlbtw-n27 {
    margin: -135px !important;
  }
  .mt-xlbtw-n27,
  .my-xlbtw-n27 {
    margin-top: -135px !important;
  }
  .mr-xlbtw-n27,
  .mx-xlbtw-n27 {
    margin-right: -135px !important;
  }
  .mb-xlbtw-n27,
  .my-xlbtw-n27 {
    margin-bottom: -135px !important;
  }
  .ml-xlbtw-n27,
  .mx-xlbtw-n27 {
    margin-left: -135px !important;
  }
  .m-xlbtw-n28 {
    margin: -140px !important;
  }
  .mt-xlbtw-n28,
  .my-xlbtw-n28 {
    margin-top: -140px !important;
  }
  .mr-xlbtw-n28,
  .mx-xlbtw-n28 {
    margin-right: -140px !important;
  }
  .mb-xlbtw-n28,
  .my-xlbtw-n28 {
    margin-bottom: -140px !important;
  }
  .ml-xlbtw-n28,
  .mx-xlbtw-n28 {
    margin-left: -140px !important;
  }
  .m-xlbtw-n29 {
    margin: -145px !important;
  }
  .mt-xlbtw-n29,
  .my-xlbtw-n29 {
    margin-top: -145px !important;
  }
  .mr-xlbtw-n29,
  .mx-xlbtw-n29 {
    margin-right: -145px !important;
  }
  .mb-xlbtw-n29,
  .my-xlbtw-n29 {
    margin-bottom: -145px !important;
  }
  .ml-xlbtw-n29,
  .mx-xlbtw-n29 {
    margin-left: -145px !important;
  }
  .m-xlbtw-n30 {
    margin: -150px !important;
  }
  .mt-xlbtw-n30,
  .my-xlbtw-n30 {
    margin-top: -150px !important;
  }
  .mr-xlbtw-n30,
  .mx-xlbtw-n30 {
    margin-right: -150px !important;
  }
  .mb-xlbtw-n30,
  .my-xlbtw-n30 {
    margin-bottom: -150px !important;
  }
  .ml-xlbtw-n30,
  .mx-xlbtw-n30 {
    margin-left: -150px !important;
  }
  .m-xlbtw-n31 {
    margin: -155px !important;
  }
  .mt-xlbtw-n31,
  .my-xlbtw-n31 {
    margin-top: -155px !important;
  }
  .mr-xlbtw-n31,
  .mx-xlbtw-n31 {
    margin-right: -155px !important;
  }
  .mb-xlbtw-n31,
  .my-xlbtw-n31 {
    margin-bottom: -155px !important;
  }
  .ml-xlbtw-n31,
  .mx-xlbtw-n31 {
    margin-left: -155px !important;
  }
  .m-xlbtw-n32 {
    margin: -160px !important;
  }
  .mt-xlbtw-n32,
  .my-xlbtw-n32 {
    margin-top: -160px !important;
  }
  .mr-xlbtw-n32,
  .mx-xlbtw-n32 {
    margin-right: -160px !important;
  }
  .mb-xlbtw-n32,
  .my-xlbtw-n32 {
    margin-bottom: -160px !important;
  }
  .ml-xlbtw-n32,
  .mx-xlbtw-n32 {
    margin-left: -160px !important;
  }
  .m-xlbtw-n33 {
    margin: -165px !important;
  }
  .mt-xlbtw-n33,
  .my-xlbtw-n33 {
    margin-top: -165px !important;
  }
  .mr-xlbtw-n33,
  .mx-xlbtw-n33 {
    margin-right: -165px !important;
  }
  .mb-xlbtw-n33,
  .my-xlbtw-n33 {
    margin-bottom: -165px !important;
  }
  .ml-xlbtw-n33,
  .mx-xlbtw-n33 {
    margin-left: -165px !important;
  }
  .m-xlbtw-n34 {
    margin: -170px !important;
  }
  .mt-xlbtw-n34,
  .my-xlbtw-n34 {
    margin-top: -170px !important;
  }
  .mr-xlbtw-n34,
  .mx-xlbtw-n34 {
    margin-right: -170px !important;
  }
  .mb-xlbtw-n34,
  .my-xlbtw-n34 {
    margin-bottom: -170px !important;
  }
  .ml-xlbtw-n34,
  .mx-xlbtw-n34 {
    margin-left: -170px !important;
  }
  .m-xlbtw-n35 {
    margin: -175px !important;
  }
  .mt-xlbtw-n35,
  .my-xlbtw-n35 {
    margin-top: -175px !important;
  }
  .mr-xlbtw-n35,
  .mx-xlbtw-n35 {
    margin-right: -175px !important;
  }
  .mb-xlbtw-n35,
  .my-xlbtw-n35 {
    margin-bottom: -175px !important;
  }
  .ml-xlbtw-n35,
  .mx-xlbtw-n35 {
    margin-left: -175px !important;
  }
  .m-xlbtw-n36 {
    margin: -180px !important;
  }
  .mt-xlbtw-n36,
  .my-xlbtw-n36 {
    margin-top: -180px !important;
  }
  .mr-xlbtw-n36,
  .mx-xlbtw-n36 {
    margin-right: -180px !important;
  }
  .mb-xlbtw-n36,
  .my-xlbtw-n36 {
    margin-bottom: -180px !important;
  }
  .ml-xlbtw-n36,
  .mx-xlbtw-n36 {
    margin-left: -180px !important;
  }
  .m-xlbtw-n37 {
    margin: -185px !important;
  }
  .mt-xlbtw-n37,
  .my-xlbtw-n37 {
    margin-top: -185px !important;
  }
  .mr-xlbtw-n37,
  .mx-xlbtw-n37 {
    margin-right: -185px !important;
  }
  .mb-xlbtw-n37,
  .my-xlbtw-n37 {
    margin-bottom: -185px !important;
  }
  .ml-xlbtw-n37,
  .mx-xlbtw-n37 {
    margin-left: -185px !important;
  }
  .m-xlbtw-n38 {
    margin: -190px !important;
  }
  .mt-xlbtw-n38,
  .my-xlbtw-n38 {
    margin-top: -190px !important;
  }
  .mr-xlbtw-n38,
  .mx-xlbtw-n38 {
    margin-right: -190px !important;
  }
  .mb-xlbtw-n38,
  .my-xlbtw-n38 {
    margin-bottom: -190px !important;
  }
  .ml-xlbtw-n38,
  .mx-xlbtw-n38 {
    margin-left: -190px !important;
  }
  .m-xlbtw-n39 {
    margin: -195px !important;
  }
  .mt-xlbtw-n39,
  .my-xlbtw-n39 {
    margin-top: -195px !important;
  }
  .mr-xlbtw-n39,
  .mx-xlbtw-n39 {
    margin-right: -195px !important;
  }
  .mb-xlbtw-n39,
  .my-xlbtw-n39 {
    margin-bottom: -195px !important;
  }
  .ml-xlbtw-n39,
  .mx-xlbtw-n39 {
    margin-left: -195px !important;
  }
  .m-xlbtw-n40 {
    margin: -200px !important;
  }
  .mt-xlbtw-n40,
  .my-xlbtw-n40 {
    margin-top: -200px !important;
  }
  .mr-xlbtw-n40,
  .mx-xlbtw-n40 {
    margin-right: -200px !important;
  }
  .mb-xlbtw-n40,
  .my-xlbtw-n40 {
    margin-bottom: -200px !important;
  }
  .ml-xlbtw-n40,
  .mx-xlbtw-n40 {
    margin-left: -200px !important;
  }
  .m-xlbtw-n41 {
    margin: -205px !important;
  }
  .mt-xlbtw-n41,
  .my-xlbtw-n41 {
    margin-top: -205px !important;
  }
  .mr-xlbtw-n41,
  .mx-xlbtw-n41 {
    margin-right: -205px !important;
  }
  .mb-xlbtw-n41,
  .my-xlbtw-n41 {
    margin-bottom: -205px !important;
  }
  .ml-xlbtw-n41,
  .mx-xlbtw-n41 {
    margin-left: -205px !important;
  }
  .m-xlbtw-n42 {
    margin: -210px !important;
  }
  .mt-xlbtw-n42,
  .my-xlbtw-n42 {
    margin-top: -210px !important;
  }
  .mr-xlbtw-n42,
  .mx-xlbtw-n42 {
    margin-right: -210px !important;
  }
  .mb-xlbtw-n42,
  .my-xlbtw-n42 {
    margin-bottom: -210px !important;
  }
  .ml-xlbtw-n42,
  .mx-xlbtw-n42 {
    margin-left: -210px !important;
  }
  .m-xlbtw-n43 {
    margin: -215px !important;
  }
  .mt-xlbtw-n43,
  .my-xlbtw-n43 {
    margin-top: -215px !important;
  }
  .mr-xlbtw-n43,
  .mx-xlbtw-n43 {
    margin-right: -215px !important;
  }
  .mb-xlbtw-n43,
  .my-xlbtw-n43 {
    margin-bottom: -215px !important;
  }
  .ml-xlbtw-n43,
  .mx-xlbtw-n43 {
    margin-left: -215px !important;
  }
  .m-xlbtw-n44 {
    margin: -220px !important;
  }
  .mt-xlbtw-n44,
  .my-xlbtw-n44 {
    margin-top: -220px !important;
  }
  .mr-xlbtw-n44,
  .mx-xlbtw-n44 {
    margin-right: -220px !important;
  }
  .mb-xlbtw-n44,
  .my-xlbtw-n44 {
    margin-bottom: -220px !important;
  }
  .ml-xlbtw-n44,
  .mx-xlbtw-n44 {
    margin-left: -220px !important;
  }
  .m-xlbtw-n45 {
    margin: -225px !important;
  }
  .mt-xlbtw-n45,
  .my-xlbtw-n45 {
    margin-top: -225px !important;
  }
  .mr-xlbtw-n45,
  .mx-xlbtw-n45 {
    margin-right: -225px !important;
  }
  .mb-xlbtw-n45,
  .my-xlbtw-n45 {
    margin-bottom: -225px !important;
  }
  .ml-xlbtw-n45,
  .mx-xlbtw-n45 {
    margin-left: -225px !important;
  }
  .m-xlbtw-n46 {
    margin: -230px !important;
  }
  .mt-xlbtw-n46,
  .my-xlbtw-n46 {
    margin-top: -230px !important;
  }
  .mr-xlbtw-n46,
  .mx-xlbtw-n46 {
    margin-right: -230px !important;
  }
  .mb-xlbtw-n46,
  .my-xlbtw-n46 {
    margin-bottom: -230px !important;
  }
  .ml-xlbtw-n46,
  .mx-xlbtw-n46 {
    margin-left: -230px !important;
  }
  .m-xlbtw-n47 {
    margin: -235px !important;
  }
  .mt-xlbtw-n47,
  .my-xlbtw-n47 {
    margin-top: -235px !important;
  }
  .mr-xlbtw-n47,
  .mx-xlbtw-n47 {
    margin-right: -235px !important;
  }
  .mb-xlbtw-n47,
  .my-xlbtw-n47 {
    margin-bottom: -235px !important;
  }
  .ml-xlbtw-n47,
  .mx-xlbtw-n47 {
    margin-left: -235px !important;
  }
  .m-xlbtw-n48 {
    margin: -240px !important;
  }
  .mt-xlbtw-n48,
  .my-xlbtw-n48 {
    margin-top: -240px !important;
  }
  .mr-xlbtw-n48,
  .mx-xlbtw-n48 {
    margin-right: -240px !important;
  }
  .mb-xlbtw-n48,
  .my-xlbtw-n48 {
    margin-bottom: -240px !important;
  }
  .ml-xlbtw-n48,
  .mx-xlbtw-n48 {
    margin-left: -240px !important;
  }
  .m-xlbtw-n49 {
    margin: -245px !important;
  }
  .mt-xlbtw-n49,
  .my-xlbtw-n49 {
    margin-top: -245px !important;
  }
  .mr-xlbtw-n49,
  .mx-xlbtw-n49 {
    margin-right: -245px !important;
  }
  .mb-xlbtw-n49,
  .my-xlbtw-n49 {
    margin-bottom: -245px !important;
  }
  .ml-xlbtw-n49,
  .mx-xlbtw-n49 {
    margin-left: -245px !important;
  }
  .m-xlbtw-n50 {
    margin: -250px !important;
  }
  .mt-xlbtw-n50,
  .my-xlbtw-n50 {
    margin-top: -250px !important;
  }
  .mr-xlbtw-n50,
  .mx-xlbtw-n50 {
    margin-right: -250px !important;
  }
  .mb-xlbtw-n50,
  .my-xlbtw-n50 {
    margin-bottom: -250px !important;
  }
  .ml-xlbtw-n50,
  .mx-xlbtw-n50 {
    margin-left: -250px !important;
  }
  .m-xlbtw-n51 {
    margin: -255px !important;
  }
  .mt-xlbtw-n51,
  .my-xlbtw-n51 {
    margin-top: -255px !important;
  }
  .mr-xlbtw-n51,
  .mx-xlbtw-n51 {
    margin-right: -255px !important;
  }
  .mb-xlbtw-n51,
  .my-xlbtw-n51 {
    margin-bottom: -255px !important;
  }
  .ml-xlbtw-n51,
  .mx-xlbtw-n51 {
    margin-left: -255px !important;
  }
  .m-xlbtw-n52 {
    margin: -260px !important;
  }
  .mt-xlbtw-n52,
  .my-xlbtw-n52 {
    margin-top: -260px !important;
  }
  .mr-xlbtw-n52,
  .mx-xlbtw-n52 {
    margin-right: -260px !important;
  }
  .mb-xlbtw-n52,
  .my-xlbtw-n52 {
    margin-bottom: -260px !important;
  }
  .ml-xlbtw-n52,
  .mx-xlbtw-n52 {
    margin-left: -260px !important;
  }
  .m-xlbtw-n53 {
    margin: -265px !important;
  }
  .mt-xlbtw-n53,
  .my-xlbtw-n53 {
    margin-top: -265px !important;
  }
  .mr-xlbtw-n53,
  .mx-xlbtw-n53 {
    margin-right: -265px !important;
  }
  .mb-xlbtw-n53,
  .my-xlbtw-n53 {
    margin-bottom: -265px !important;
  }
  .ml-xlbtw-n53,
  .mx-xlbtw-n53 {
    margin-left: -265px !important;
  }
  .m-xlbtw-n54 {
    margin: -270px !important;
  }
  .mt-xlbtw-n54,
  .my-xlbtw-n54 {
    margin-top: -270px !important;
  }
  .mr-xlbtw-n54,
  .mx-xlbtw-n54 {
    margin-right: -270px !important;
  }
  .mb-xlbtw-n54,
  .my-xlbtw-n54 {
    margin-bottom: -270px !important;
  }
  .ml-xlbtw-n54,
  .mx-xlbtw-n54 {
    margin-left: -270px !important;
  }
  .m-xlbtw-n55 {
    margin: -275px !important;
  }
  .mt-xlbtw-n55,
  .my-xlbtw-n55 {
    margin-top: -275px !important;
  }
  .mr-xlbtw-n55,
  .mx-xlbtw-n55 {
    margin-right: -275px !important;
  }
  .mb-xlbtw-n55,
  .my-xlbtw-n55 {
    margin-bottom: -275px !important;
  }
  .ml-xlbtw-n55,
  .mx-xlbtw-n55 {
    margin-left: -275px !important;
  }
  .m-xlbtw-n56 {
    margin: -280px !important;
  }
  .mt-xlbtw-n56,
  .my-xlbtw-n56 {
    margin-top: -280px !important;
  }
  .mr-xlbtw-n56,
  .mx-xlbtw-n56 {
    margin-right: -280px !important;
  }
  .mb-xlbtw-n56,
  .my-xlbtw-n56 {
    margin-bottom: -280px !important;
  }
  .ml-xlbtw-n56,
  .mx-xlbtw-n56 {
    margin-left: -280px !important;
  }
  .m-xlbtw-n57 {
    margin: -285px !important;
  }
  .mt-xlbtw-n57,
  .my-xlbtw-n57 {
    margin-top: -285px !important;
  }
  .mr-xlbtw-n57,
  .mx-xlbtw-n57 {
    margin-right: -285px !important;
  }
  .mb-xlbtw-n57,
  .my-xlbtw-n57 {
    margin-bottom: -285px !important;
  }
  .ml-xlbtw-n57,
  .mx-xlbtw-n57 {
    margin-left: -285px !important;
  }
  .m-xlbtw-n58 {
    margin: -290px !important;
  }
  .mt-xlbtw-n58,
  .my-xlbtw-n58 {
    margin-top: -290px !important;
  }
  .mr-xlbtw-n58,
  .mx-xlbtw-n58 {
    margin-right: -290px !important;
  }
  .mb-xlbtw-n58,
  .my-xlbtw-n58 {
    margin-bottom: -290px !important;
  }
  .ml-xlbtw-n58,
  .mx-xlbtw-n58 {
    margin-left: -290px !important;
  }
  .m-xlbtw-n59 {
    margin: -295px !important;
  }
  .mt-xlbtw-n59,
  .my-xlbtw-n59 {
    margin-top: -295px !important;
  }
  .mr-xlbtw-n59,
  .mx-xlbtw-n59 {
    margin-right: -295px !important;
  }
  .mb-xlbtw-n59,
  .my-xlbtw-n59 {
    margin-bottom: -295px !important;
  }
  .ml-xlbtw-n59,
  .mx-xlbtw-n59 {
    margin-left: -295px !important;
  }
  .m-xlbtw-n60 {
    margin: -300px !important;
  }
  .mt-xlbtw-n60,
  .my-xlbtw-n60 {
    margin-top: -300px !important;
  }
  .mr-xlbtw-n60,
  .mx-xlbtw-n60 {
    margin-right: -300px !important;
  }
  .mb-xlbtw-n60,
  .my-xlbtw-n60 {
    margin-bottom: -300px !important;
  }
  .ml-xlbtw-n60,
  .mx-xlbtw-n60 {
    margin-left: -300px !important;
  }
  .m-xlbtw-auto {
    margin: auto !important;
  }
  .mt-xlbtw-auto,
  .my-xlbtw-auto {
    margin-top: auto !important;
  }
  .mr-xlbtw-auto,
  .mx-xlbtw-auto {
    margin-right: auto !important;
  }
  .mb-xlbtw-auto,
  .my-xlbtw-auto {
    margin-bottom: auto !important;
  }
  .ml-xlbtw-auto,
  .mx-xlbtw-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1379px) {
  .m-xlwd-0 {
    margin: 0 !important;
  }
  .mt-xlwd-0,
  .my-xlwd-0 {
    margin-top: 0 !important;
  }
  .mr-xlwd-0,
  .mx-xlwd-0 {
    margin-right: 0 !important;
  }
  .mb-xlwd-0,
  .my-xlwd-0 {
    margin-bottom: 0 !important;
  }
  .ml-xlwd-0,
  .mx-xlwd-0 {
    margin-left: 0 !important;
  }
  .m-xlwd-1 {
    margin: 5px !important;
  }
  .mt-xlwd-1,
  .my-xlwd-1 {
    margin-top: 5px !important;
  }
  .mr-xlwd-1,
  .mx-xlwd-1 {
    margin-right: 5px !important;
  }
  .mb-xlwd-1,
  .my-xlwd-1 {
    margin-bottom: 5px !important;
  }
  .ml-xlwd-1,
  .mx-xlwd-1 {
    margin-left: 5px !important;
  }
  .m-xlwd-2 {
    margin: 10px !important;
  }
  .mt-xlwd-2,
  .my-xlwd-2 {
    margin-top: 10px !important;
  }
  .mr-xlwd-2,
  .mx-xlwd-2 {
    margin-right: 10px !important;
  }
  .mb-xlwd-2,
  .my-xlwd-2 {
    margin-bottom: 10px !important;
  }
  .ml-xlwd-2,
  .mx-xlwd-2 {
    margin-left: 10px !important;
  }
  .m-xlwd-3 {
    margin: 16px !important;
  }
  .mt-xlwd-3,
  .my-xlwd-3 {
    margin-top: 16px !important;
  }
  .mr-xlwd-3,
  .mx-xlwd-3 {
    margin-right: 16px !important;
  }
  .mb-xlwd-3,
  .my-xlwd-3 {
    margin-bottom: 16px !important;
  }
  .ml-xlwd-3,
  .mx-xlwd-3 {
    margin-left: 16px !important;
  }
  .m-xlwd-4 {
    margin: 20px !important;
  }
  .mt-xlwd-4,
  .my-xlwd-4 {
    margin-top: 20px !important;
  }
  .mr-xlwd-4,
  .mx-xlwd-4 {
    margin-right: 20px !important;
  }
  .mb-xlwd-4,
  .my-xlwd-4 {
    margin-bottom: 20px !important;
  }
  .ml-xlwd-4,
  .mx-xlwd-4 {
    margin-left: 20px !important;
  }
  .m-xlwd-5 {
    margin: 25px !important;
  }
  .mt-xlwd-5,
  .my-xlwd-5 {
    margin-top: 25px !important;
  }
  .mr-xlwd-5,
  .mx-xlwd-5 {
    margin-right: 25px !important;
  }
  .mb-xlwd-5,
  .my-xlwd-5 {
    margin-bottom: 25px !important;
  }
  .ml-xlwd-5,
  .mx-xlwd-5 {
    margin-left: 25px !important;
  }
  .m-xlwd-6 {
    margin: 30px !important;
  }
  .mt-xlwd-6,
  .my-xlwd-6 {
    margin-top: 30px !important;
  }
  .mr-xlwd-6,
  .mx-xlwd-6 {
    margin-right: 30px !important;
  }
  .mb-xlwd-6,
  .my-xlwd-6 {
    margin-bottom: 30px !important;
  }
  .ml-xlwd-6,
  .mx-xlwd-6 {
    margin-left: 30px !important;
  }
  .m-xlwd-7 {
    margin: 35px !important;
  }
  .mt-xlwd-7,
  .my-xlwd-7 {
    margin-top: 35px !important;
  }
  .mr-xlwd-7,
  .mx-xlwd-7 {
    margin-right: 35px !important;
  }
  .mb-xlwd-7,
  .my-xlwd-7 {
    margin-bottom: 35px !important;
  }
  .ml-xlwd-7,
  .mx-xlwd-7 {
    margin-left: 35px !important;
  }
  .m-xlwd-8 {
    margin: 40px !important;
  }
  .mt-xlwd-8,
  .my-xlwd-8 {
    margin-top: 40px !important;
  }
  .mr-xlwd-8,
  .mx-xlwd-8 {
    margin-right: 40px !important;
  }
  .mb-xlwd-8,
  .my-xlwd-8 {
    margin-bottom: 40px !important;
  }
  .ml-xlwd-8,
  .mx-xlwd-8 {
    margin-left: 40px !important;
  }
  .m-xlwd-9 {
    margin: 45px !important;
  }
  .mt-xlwd-9,
  .my-xlwd-9 {
    margin-top: 45px !important;
  }
  .mr-xlwd-9,
  .mx-xlwd-9 {
    margin-right: 45px !important;
  }
  .mb-xlwd-9,
  .my-xlwd-9 {
    margin-bottom: 45px !important;
  }
  .ml-xlwd-9,
  .mx-xlwd-9 {
    margin-left: 45px !important;
  }
  .m-xlwd-10 {
    margin: 50px !important;
  }
  .mt-xlwd-10,
  .my-xlwd-10 {
    margin-top: 50px !important;
  }
  .mr-xlwd-10,
  .mx-xlwd-10 {
    margin-right: 50px !important;
  }
  .mb-xlwd-10,
  .my-xlwd-10 {
    margin-bottom: 50px !important;
  }
  .ml-xlwd-10,
  .mx-xlwd-10 {
    margin-left: 50px !important;
  }
  .m-xlwd-11 {
    margin: 55px !important;
  }
  .mt-xlwd-11,
  .my-xlwd-11 {
    margin-top: 55px !important;
  }
  .mr-xlwd-11,
  .mx-xlwd-11 {
    margin-right: 55px !important;
  }
  .mb-xlwd-11,
  .my-xlwd-11 {
    margin-bottom: 55px !important;
  }
  .ml-xlwd-11,
  .mx-xlwd-11 {
    margin-left: 55px !important;
  }
  .m-xlwd-12 {
    margin: 60px !important;
  }
  .mt-xlwd-12,
  .my-xlwd-12 {
    margin-top: 60px !important;
  }
  .mr-xlwd-12,
  .mx-xlwd-12 {
    margin-right: 60px !important;
  }
  .mb-xlwd-12,
  .my-xlwd-12 {
    margin-bottom: 60px !important;
  }
  .ml-xlwd-12,
  .mx-xlwd-12 {
    margin-left: 60px !important;
  }
  .m-xlwd-13 {
    margin: 65px !important;
  }
  .mt-xlwd-13,
  .my-xlwd-13 {
    margin-top: 65px !important;
  }
  .mr-xlwd-13,
  .mx-xlwd-13 {
    margin-right: 65px !important;
  }
  .mb-xlwd-13,
  .my-xlwd-13 {
    margin-bottom: 65px !important;
  }
  .ml-xlwd-13,
  .mx-xlwd-13 {
    margin-left: 65px !important;
  }
  .m-xlwd-14 {
    margin: 70px !important;
  }
  .mt-xlwd-14,
  .my-xlwd-14 {
    margin-top: 70px !important;
  }
  .mr-xlwd-14,
  .mx-xlwd-14 {
    margin-right: 70px !important;
  }
  .mb-xlwd-14,
  .my-xlwd-14 {
    margin-bottom: 70px !important;
  }
  .ml-xlwd-14,
  .mx-xlwd-14 {
    margin-left: 70px !important;
  }
  .m-xlwd-15 {
    margin: 75px !important;
  }
  .mt-xlwd-15,
  .my-xlwd-15 {
    margin-top: 75px !important;
  }
  .mr-xlwd-15,
  .mx-xlwd-15 {
    margin-right: 75px !important;
  }
  .mb-xlwd-15,
  .my-xlwd-15 {
    margin-bottom: 75px !important;
  }
  .ml-xlwd-15,
  .mx-xlwd-15 {
    margin-left: 75px !important;
  }
  .m-xlwd-16 {
    margin: 80px !important;
  }
  .mt-xlwd-16,
  .my-xlwd-16 {
    margin-top: 80px !important;
  }
  .mr-xlwd-16,
  .mx-xlwd-16 {
    margin-right: 80px !important;
  }
  .mb-xlwd-16,
  .my-xlwd-16 {
    margin-bottom: 80px !important;
  }
  .ml-xlwd-16,
  .mx-xlwd-16 {
    margin-left: 80px !important;
  }
  .m-xlwd-17 {
    margin: 85px !important;
  }
  .mt-xlwd-17,
  .my-xlwd-17 {
    margin-top: 85px !important;
  }
  .mr-xlwd-17,
  .mx-xlwd-17 {
    margin-right: 85px !important;
  }
  .mb-xlwd-17,
  .my-xlwd-17 {
    margin-bottom: 85px !important;
  }
  .ml-xlwd-17,
  .mx-xlwd-17 {
    margin-left: 85px !important;
  }
  .m-xlwd-18 {
    margin: 90px !important;
  }
  .mt-xlwd-18,
  .my-xlwd-18 {
    margin-top: 90px !important;
  }
  .mr-xlwd-18,
  .mx-xlwd-18 {
    margin-right: 90px !important;
  }
  .mb-xlwd-18,
  .my-xlwd-18 {
    margin-bottom: 90px !important;
  }
  .ml-xlwd-18,
  .mx-xlwd-18 {
    margin-left: 90px !important;
  }
  .m-xlwd-19 {
    margin: 95px !important;
  }
  .mt-xlwd-19,
  .my-xlwd-19 {
    margin-top: 95px !important;
  }
  .mr-xlwd-19,
  .mx-xlwd-19 {
    margin-right: 95px !important;
  }
  .mb-xlwd-19,
  .my-xlwd-19 {
    margin-bottom: 95px !important;
  }
  .ml-xlwd-19,
  .mx-xlwd-19 {
    margin-left: 95px !important;
  }
  .m-xlwd-20 {
    margin: 100px !important;
  }
  .mt-xlwd-20,
  .my-xlwd-20 {
    margin-top: 100px !important;
  }
  .mr-xlwd-20,
  .mx-xlwd-20 {
    margin-right: 100px !important;
  }
  .mb-xlwd-20,
  .my-xlwd-20 {
    margin-bottom: 100px !important;
  }
  .ml-xlwd-20,
  .mx-xlwd-20 {
    margin-left: 100px !important;
  }
  .m-xlwd-21 {
    margin: 105px !important;
  }
  .mt-xlwd-21,
  .my-xlwd-21 {
    margin-top: 105px !important;
  }
  .mr-xlwd-21,
  .mx-xlwd-21 {
    margin-right: 105px !important;
  }
  .mb-xlwd-21,
  .my-xlwd-21 {
    margin-bottom: 105px !important;
  }
  .ml-xlwd-21,
  .mx-xlwd-21 {
    margin-left: 105px !important;
  }
  .m-xlwd-22 {
    margin: 110px !important;
  }
  .mt-xlwd-22,
  .my-xlwd-22 {
    margin-top: 110px !important;
  }
  .mr-xlwd-22,
  .mx-xlwd-22 {
    margin-right: 110px !important;
  }
  .mb-xlwd-22,
  .my-xlwd-22 {
    margin-bottom: 110px !important;
  }
  .ml-xlwd-22,
  .mx-xlwd-22 {
    margin-left: 110px !important;
  }
  .m-xlwd-23 {
    margin: 115px !important;
  }
  .mt-xlwd-23,
  .my-xlwd-23 {
    margin-top: 115px !important;
  }
  .mr-xlwd-23,
  .mx-xlwd-23 {
    margin-right: 115px !important;
  }
  .mb-xlwd-23,
  .my-xlwd-23 {
    margin-bottom: 115px !important;
  }
  .ml-xlwd-23,
  .mx-xlwd-23 {
    margin-left: 115px !important;
  }
  .m-xlwd-24 {
    margin: 120px !important;
  }
  .mt-xlwd-24,
  .my-xlwd-24 {
    margin-top: 120px !important;
  }
  .mr-xlwd-24,
  .mx-xlwd-24 {
    margin-right: 120px !important;
  }
  .mb-xlwd-24,
  .my-xlwd-24 {
    margin-bottom: 120px !important;
  }
  .ml-xlwd-24,
  .mx-xlwd-24 {
    margin-left: 120px !important;
  }
  .m-xlwd-25 {
    margin: 125px !important;
  }
  .mt-xlwd-25,
  .my-xlwd-25 {
    margin-top: 125px !important;
  }
  .mr-xlwd-25,
  .mx-xlwd-25 {
    margin-right: 125px !important;
  }
  .mb-xlwd-25,
  .my-xlwd-25 {
    margin-bottom: 125px !important;
  }
  .ml-xlwd-25,
  .mx-xlwd-25 {
    margin-left: 125px !important;
  }
  .m-xlwd-26 {
    margin: 130px !important;
  }
  .mt-xlwd-26,
  .my-xlwd-26 {
    margin-top: 130px !important;
  }
  .mr-xlwd-26,
  .mx-xlwd-26 {
    margin-right: 130px !important;
  }
  .mb-xlwd-26,
  .my-xlwd-26 {
    margin-bottom: 130px !important;
  }
  .ml-xlwd-26,
  .mx-xlwd-26 {
    margin-left: 130px !important;
  }
  .m-xlwd-27 {
    margin: 135px !important;
  }
  .mt-xlwd-27,
  .my-xlwd-27 {
    margin-top: 135px !important;
  }
  .mr-xlwd-27,
  .mx-xlwd-27 {
    margin-right: 135px !important;
  }
  .mb-xlwd-27,
  .my-xlwd-27 {
    margin-bottom: 135px !important;
  }
  .ml-xlwd-27,
  .mx-xlwd-27 {
    margin-left: 135px !important;
  }
  .m-xlwd-28 {
    margin: 140px !important;
  }
  .mt-xlwd-28,
  .my-xlwd-28 {
    margin-top: 140px !important;
  }
  .mr-xlwd-28,
  .mx-xlwd-28 {
    margin-right: 140px !important;
  }
  .mb-xlwd-28,
  .my-xlwd-28 {
    margin-bottom: 140px !important;
  }
  .ml-xlwd-28,
  .mx-xlwd-28 {
    margin-left: 140px !important;
  }
  .m-xlwd-29 {
    margin: 145px !important;
  }
  .mt-xlwd-29,
  .my-xlwd-29 {
    margin-top: 145px !important;
  }
  .mr-xlwd-29,
  .mx-xlwd-29 {
    margin-right: 145px !important;
  }
  .mb-xlwd-29,
  .my-xlwd-29 {
    margin-bottom: 145px !important;
  }
  .ml-xlwd-29,
  .mx-xlwd-29 {
    margin-left: 145px !important;
  }
  .m-xlwd-30 {
    margin: 150px !important;
  }
  .mt-xlwd-30,
  .my-xlwd-30 {
    margin-top: 150px !important;
  }
  .mr-xlwd-30,
  .mx-xlwd-30 {
    margin-right: 150px !important;
  }
  .mb-xlwd-30,
  .my-xlwd-30 {
    margin-bottom: 150px !important;
  }
  .ml-xlwd-30,
  .mx-xlwd-30 {
    margin-left: 150px !important;
  }
  .m-xlwd-31 {
    margin: 155px !important;
  }
  .mt-xlwd-31,
  .my-xlwd-31 {
    margin-top: 155px !important;
  }
  .mr-xlwd-31,
  .mx-xlwd-31 {
    margin-right: 155px !important;
  }
  .mb-xlwd-31,
  .my-xlwd-31 {
    margin-bottom: 155px !important;
  }
  .ml-xlwd-31,
  .mx-xlwd-31 {
    margin-left: 155px !important;
  }
  .m-xlwd-32 {
    margin: 160px !important;
  }
  .mt-xlwd-32,
  .my-xlwd-32 {
    margin-top: 160px !important;
  }
  .mr-xlwd-32,
  .mx-xlwd-32 {
    margin-right: 160px !important;
  }
  .mb-xlwd-32,
  .my-xlwd-32 {
    margin-bottom: 160px !important;
  }
  .ml-xlwd-32,
  .mx-xlwd-32 {
    margin-left: 160px !important;
  }
  .m-xlwd-33 {
    margin: 165px !important;
  }
  .mt-xlwd-33,
  .my-xlwd-33 {
    margin-top: 165px !important;
  }
  .mr-xlwd-33,
  .mx-xlwd-33 {
    margin-right: 165px !important;
  }
  .mb-xlwd-33,
  .my-xlwd-33 {
    margin-bottom: 165px !important;
  }
  .ml-xlwd-33,
  .mx-xlwd-33 {
    margin-left: 165px !important;
  }
  .m-xlwd-34 {
    margin: 170px !important;
  }
  .mt-xlwd-34,
  .my-xlwd-34 {
    margin-top: 170px !important;
  }
  .mr-xlwd-34,
  .mx-xlwd-34 {
    margin-right: 170px !important;
  }
  .mb-xlwd-34,
  .my-xlwd-34 {
    margin-bottom: 170px !important;
  }
  .ml-xlwd-34,
  .mx-xlwd-34 {
    margin-left: 170px !important;
  }
  .m-xlwd-35 {
    margin: 175px !important;
  }
  .mt-xlwd-35,
  .my-xlwd-35 {
    margin-top: 175px !important;
  }
  .mr-xlwd-35,
  .mx-xlwd-35 {
    margin-right: 175px !important;
  }
  .mb-xlwd-35,
  .my-xlwd-35 {
    margin-bottom: 175px !important;
  }
  .ml-xlwd-35,
  .mx-xlwd-35 {
    margin-left: 175px !important;
  }
  .m-xlwd-36 {
    margin: 180px !important;
  }
  .mt-xlwd-36,
  .my-xlwd-36 {
    margin-top: 180px !important;
  }
  .mr-xlwd-36,
  .mx-xlwd-36 {
    margin-right: 180px !important;
  }
  .mb-xlwd-36,
  .my-xlwd-36 {
    margin-bottom: 180px !important;
  }
  .ml-xlwd-36,
  .mx-xlwd-36 {
    margin-left: 180px !important;
  }
  .m-xlwd-37 {
    margin: 185px !important;
  }
  .mt-xlwd-37,
  .my-xlwd-37 {
    margin-top: 185px !important;
  }
  .mr-xlwd-37,
  .mx-xlwd-37 {
    margin-right: 185px !important;
  }
  .mb-xlwd-37,
  .my-xlwd-37 {
    margin-bottom: 185px !important;
  }
  .ml-xlwd-37,
  .mx-xlwd-37 {
    margin-left: 185px !important;
  }
  .m-xlwd-38 {
    margin: 190px !important;
  }
  .mt-xlwd-38,
  .my-xlwd-38 {
    margin-top: 190px !important;
  }
  .mr-xlwd-38,
  .mx-xlwd-38 {
    margin-right: 190px !important;
  }
  .mb-xlwd-38,
  .my-xlwd-38 {
    margin-bottom: 190px !important;
  }
  .ml-xlwd-38,
  .mx-xlwd-38 {
    margin-left: 190px !important;
  }
  .m-xlwd-39 {
    margin: 195px !important;
  }
  .mt-xlwd-39,
  .my-xlwd-39 {
    margin-top: 195px !important;
  }
  .mr-xlwd-39,
  .mx-xlwd-39 {
    margin-right: 195px !important;
  }
  .mb-xlwd-39,
  .my-xlwd-39 {
    margin-bottom: 195px !important;
  }
  .ml-xlwd-39,
  .mx-xlwd-39 {
    margin-left: 195px !important;
  }
  .m-xlwd-40 {
    margin: 200px !important;
  }
  .mt-xlwd-40,
  .my-xlwd-40 {
    margin-top: 200px !important;
  }
  .mr-xlwd-40,
  .mx-xlwd-40 {
    margin-right: 200px !important;
  }
  .mb-xlwd-40,
  .my-xlwd-40 {
    margin-bottom: 200px !important;
  }
  .ml-xlwd-40,
  .mx-xlwd-40 {
    margin-left: 200px !important;
  }
  .m-xlwd-41 {
    margin: 205px !important;
  }
  .mt-xlwd-41,
  .my-xlwd-41 {
    margin-top: 205px !important;
  }
  .mr-xlwd-41,
  .mx-xlwd-41 {
    margin-right: 205px !important;
  }
  .mb-xlwd-41,
  .my-xlwd-41 {
    margin-bottom: 205px !important;
  }
  .ml-xlwd-41,
  .mx-xlwd-41 {
    margin-left: 205px !important;
  }
  .m-xlwd-42 {
    margin: 210px !important;
  }
  .mt-xlwd-42,
  .my-xlwd-42 {
    margin-top: 210px !important;
  }
  .mr-xlwd-42,
  .mx-xlwd-42 {
    margin-right: 210px !important;
  }
  .mb-xlwd-42,
  .my-xlwd-42 {
    margin-bottom: 210px !important;
  }
  .ml-xlwd-42,
  .mx-xlwd-42 {
    margin-left: 210px !important;
  }
  .m-xlwd-43 {
    margin: 215px !important;
  }
  .mt-xlwd-43,
  .my-xlwd-43 {
    margin-top: 215px !important;
  }
  .mr-xlwd-43,
  .mx-xlwd-43 {
    margin-right: 215px !important;
  }
  .mb-xlwd-43,
  .my-xlwd-43 {
    margin-bottom: 215px !important;
  }
  .ml-xlwd-43,
  .mx-xlwd-43 {
    margin-left: 215px !important;
  }
  .m-xlwd-44 {
    margin: 220px !important;
  }
  .mt-xlwd-44,
  .my-xlwd-44 {
    margin-top: 220px !important;
  }
  .mr-xlwd-44,
  .mx-xlwd-44 {
    margin-right: 220px !important;
  }
  .mb-xlwd-44,
  .my-xlwd-44 {
    margin-bottom: 220px !important;
  }
  .ml-xlwd-44,
  .mx-xlwd-44 {
    margin-left: 220px !important;
  }
  .m-xlwd-45 {
    margin: 225px !important;
  }
  .mt-xlwd-45,
  .my-xlwd-45 {
    margin-top: 225px !important;
  }
  .mr-xlwd-45,
  .mx-xlwd-45 {
    margin-right: 225px !important;
  }
  .mb-xlwd-45,
  .my-xlwd-45 {
    margin-bottom: 225px !important;
  }
  .ml-xlwd-45,
  .mx-xlwd-45 {
    margin-left: 225px !important;
  }
  .m-xlwd-46 {
    margin: 230px !important;
  }
  .mt-xlwd-46,
  .my-xlwd-46 {
    margin-top: 230px !important;
  }
  .mr-xlwd-46,
  .mx-xlwd-46 {
    margin-right: 230px !important;
  }
  .mb-xlwd-46,
  .my-xlwd-46 {
    margin-bottom: 230px !important;
  }
  .ml-xlwd-46,
  .mx-xlwd-46 {
    margin-left: 230px !important;
  }
  .m-xlwd-47 {
    margin: 235px !important;
  }
  .mt-xlwd-47,
  .my-xlwd-47 {
    margin-top: 235px !important;
  }
  .mr-xlwd-47,
  .mx-xlwd-47 {
    margin-right: 235px !important;
  }
  .mb-xlwd-47,
  .my-xlwd-47 {
    margin-bottom: 235px !important;
  }
  .ml-xlwd-47,
  .mx-xlwd-47 {
    margin-left: 235px !important;
  }
  .m-xlwd-48 {
    margin: 240px !important;
  }
  .mt-xlwd-48,
  .my-xlwd-48 {
    margin-top: 240px !important;
  }
  .mr-xlwd-48,
  .mx-xlwd-48 {
    margin-right: 240px !important;
  }
  .mb-xlwd-48,
  .my-xlwd-48 {
    margin-bottom: 240px !important;
  }
  .ml-xlwd-48,
  .mx-xlwd-48 {
    margin-left: 240px !important;
  }
  .m-xlwd-49 {
    margin: 245px !important;
  }
  .mt-xlwd-49,
  .my-xlwd-49 {
    margin-top: 245px !important;
  }
  .mr-xlwd-49,
  .mx-xlwd-49 {
    margin-right: 245px !important;
  }
  .mb-xlwd-49,
  .my-xlwd-49 {
    margin-bottom: 245px !important;
  }
  .ml-xlwd-49,
  .mx-xlwd-49 {
    margin-left: 245px !important;
  }
  .m-xlwd-50 {
    margin: 250px !important;
  }
  .mt-xlwd-50,
  .my-xlwd-50 {
    margin-top: 250px !important;
  }
  .mr-xlwd-50,
  .mx-xlwd-50 {
    margin-right: 250px !important;
  }
  .mb-xlwd-50,
  .my-xlwd-50 {
    margin-bottom: 250px !important;
  }
  .ml-xlwd-50,
  .mx-xlwd-50 {
    margin-left: 250px !important;
  }
  .m-xlwd-51 {
    margin: 255px !important;
  }
  .mt-xlwd-51,
  .my-xlwd-51 {
    margin-top: 255px !important;
  }
  .mr-xlwd-51,
  .mx-xlwd-51 {
    margin-right: 255px !important;
  }
  .mb-xlwd-51,
  .my-xlwd-51 {
    margin-bottom: 255px !important;
  }
  .ml-xlwd-51,
  .mx-xlwd-51 {
    margin-left: 255px !important;
  }
  .m-xlwd-52 {
    margin: 260px !important;
  }
  .mt-xlwd-52,
  .my-xlwd-52 {
    margin-top: 260px !important;
  }
  .mr-xlwd-52,
  .mx-xlwd-52 {
    margin-right: 260px !important;
  }
  .mb-xlwd-52,
  .my-xlwd-52 {
    margin-bottom: 260px !important;
  }
  .ml-xlwd-52,
  .mx-xlwd-52 {
    margin-left: 260px !important;
  }
  .m-xlwd-53 {
    margin: 265px !important;
  }
  .mt-xlwd-53,
  .my-xlwd-53 {
    margin-top: 265px !important;
  }
  .mr-xlwd-53,
  .mx-xlwd-53 {
    margin-right: 265px !important;
  }
  .mb-xlwd-53,
  .my-xlwd-53 {
    margin-bottom: 265px !important;
  }
  .ml-xlwd-53,
  .mx-xlwd-53 {
    margin-left: 265px !important;
  }
  .m-xlwd-54 {
    margin: 270px !important;
  }
  .mt-xlwd-54,
  .my-xlwd-54 {
    margin-top: 270px !important;
  }
  .mr-xlwd-54,
  .mx-xlwd-54 {
    margin-right: 270px !important;
  }
  .mb-xlwd-54,
  .my-xlwd-54 {
    margin-bottom: 270px !important;
  }
  .ml-xlwd-54,
  .mx-xlwd-54 {
    margin-left: 270px !important;
  }
  .m-xlwd-55 {
    margin: 275px !important;
  }
  .mt-xlwd-55,
  .my-xlwd-55 {
    margin-top: 275px !important;
  }
  .mr-xlwd-55,
  .mx-xlwd-55 {
    margin-right: 275px !important;
  }
  .mb-xlwd-55,
  .my-xlwd-55 {
    margin-bottom: 275px !important;
  }
  .ml-xlwd-55,
  .mx-xlwd-55 {
    margin-left: 275px !important;
  }
  .m-xlwd-56 {
    margin: 280px !important;
  }
  .mt-xlwd-56,
  .my-xlwd-56 {
    margin-top: 280px !important;
  }
  .mr-xlwd-56,
  .mx-xlwd-56 {
    margin-right: 280px !important;
  }
  .mb-xlwd-56,
  .my-xlwd-56 {
    margin-bottom: 280px !important;
  }
  .ml-xlwd-56,
  .mx-xlwd-56 {
    margin-left: 280px !important;
  }
  .m-xlwd-57 {
    margin: 285px !important;
  }
  .mt-xlwd-57,
  .my-xlwd-57 {
    margin-top: 285px !important;
  }
  .mr-xlwd-57,
  .mx-xlwd-57 {
    margin-right: 285px !important;
  }
  .mb-xlwd-57,
  .my-xlwd-57 {
    margin-bottom: 285px !important;
  }
  .ml-xlwd-57,
  .mx-xlwd-57 {
    margin-left: 285px !important;
  }
  .m-xlwd-58 {
    margin: 290px !important;
  }
  .mt-xlwd-58,
  .my-xlwd-58 {
    margin-top: 290px !important;
  }
  .mr-xlwd-58,
  .mx-xlwd-58 {
    margin-right: 290px !important;
  }
  .mb-xlwd-58,
  .my-xlwd-58 {
    margin-bottom: 290px !important;
  }
  .ml-xlwd-58,
  .mx-xlwd-58 {
    margin-left: 290px !important;
  }
  .m-xlwd-59 {
    margin: 295px !important;
  }
  .mt-xlwd-59,
  .my-xlwd-59 {
    margin-top: 295px !important;
  }
  .mr-xlwd-59,
  .mx-xlwd-59 {
    margin-right: 295px !important;
  }
  .mb-xlwd-59,
  .my-xlwd-59 {
    margin-bottom: 295px !important;
  }
  .ml-xlwd-59,
  .mx-xlwd-59 {
    margin-left: 295px !important;
  }
  .m-xlwd-60 {
    margin: 300px !important;
  }
  .mt-xlwd-60,
  .my-xlwd-60 {
    margin-top: 300px !important;
  }
  .mr-xlwd-60,
  .mx-xlwd-60 {
    margin-right: 300px !important;
  }
  .mb-xlwd-60,
  .my-xlwd-60 {
    margin-bottom: 300px !important;
  }
  .ml-xlwd-60,
  .mx-xlwd-60 {
    margin-left: 300px !important;
  }
  .p-xlwd-0 {
    padding: 0 !important;
  }
  .pt-xlwd-0,
  .py-xlwd-0 {
    padding-top: 0 !important;
  }
  .pr-xlwd-0,
  .px-xlwd-0 {
    padding-right: 0 !important;
  }
  .pb-xlwd-0,
  .py-xlwd-0 {
    padding-bottom: 0 !important;
  }
  .pl-xlwd-0,
  .px-xlwd-0 {
    padding-left: 0 !important;
  }
  .p-xlwd-1 {
    padding: 5px !important;
  }
  .pt-xlwd-1,
  .py-xlwd-1 {
    padding-top: 5px !important;
  }
  .pr-xlwd-1,
  .px-xlwd-1 {
    padding-right: 5px !important;
  }
  .pb-xlwd-1,
  .py-xlwd-1 {
    padding-bottom: 5px !important;
  }
  .pl-xlwd-1,
  .px-xlwd-1 {
    padding-left: 5px !important;
  }
  .p-xlwd-2 {
    padding: 10px !important;
  }
  .pt-xlwd-2,
  .py-xlwd-2 {
    padding-top: 10px !important;
  }
  .pr-xlwd-2,
  .px-xlwd-2 {
    padding-right: 10px !important;
  }
  .pb-xlwd-2,
  .py-xlwd-2 {
    padding-bottom: 10px !important;
  }
  .pl-xlwd-2,
  .px-xlwd-2 {
    padding-left: 10px !important;
  }
  .p-xlwd-3 {
    padding: 16px !important;
  }
  .pt-xlwd-3,
  .py-xlwd-3 {
    padding-top: 16px !important;
  }
  .pr-xlwd-3,
  .px-xlwd-3 {
    padding-right: 16px !important;
  }
  .pb-xlwd-3,
  .py-xlwd-3 {
    padding-bottom: 16px !important;
  }
  .pl-xlwd-3,
  .px-xlwd-3 {
    padding-left: 16px !important;
  }
  .p-xlwd-4 {
    padding: 20px !important;
  }
  .pt-xlwd-4,
  .py-xlwd-4 {
    padding-top: 20px !important;
  }
  .pr-xlwd-4,
  .px-xlwd-4 {
    padding-right: 20px !important;
  }
  .pb-xlwd-4,
  .py-xlwd-4 {
    padding-bottom: 20px !important;
  }
  .pl-xlwd-4,
  .px-xlwd-4 {
    padding-left: 20px !important;
  }
  .p-xlwd-5 {
    padding: 25px !important;
  }
  .pt-xlwd-5,
  .py-xlwd-5 {
    padding-top: 25px !important;
  }
  .pr-xlwd-5,
  .px-xlwd-5 {
    padding-right: 25px !important;
  }
  .pb-xlwd-5,
  .py-xlwd-5 {
    padding-bottom: 25px !important;
  }
  .pl-xlwd-5,
  .px-xlwd-5 {
    padding-left: 25px !important;
  }
  .p-xlwd-6 {
    padding: 30px !important;
  }
  .pt-xlwd-6,
  .py-xlwd-6 {
    padding-top: 30px !important;
  }
  .pr-xlwd-6,
  .px-xlwd-6 {
    padding-right: 30px !important;
  }
  .pb-xlwd-6,
  .py-xlwd-6 {
    padding-bottom: 30px !important;
  }
  .pl-xlwd-6,
  .px-xlwd-6 {
    padding-left: 30px !important;
  }
  .p-xlwd-7 {
    padding: 35px !important;
  }
  .pt-xlwd-7,
  .py-xlwd-7 {
    padding-top: 35px !important;
  }
  .pr-xlwd-7,
  .px-xlwd-7 {
    padding-right: 35px !important;
  }
  .pb-xlwd-7,
  .py-xlwd-7 {
    padding-bottom: 35px !important;
  }
  .pl-xlwd-7,
  .px-xlwd-7 {
    padding-left: 35px !important;
  }
  .p-xlwd-8 {
    padding: 40px !important;
  }
  .pt-xlwd-8,
  .py-xlwd-8 {
    padding-top: 40px !important;
  }
  .pr-xlwd-8,
  .px-xlwd-8 {
    padding-right: 40px !important;
  }
  .pb-xlwd-8,
  .py-xlwd-8 {
    padding-bottom: 40px !important;
  }
  .pl-xlwd-8,
  .px-xlwd-8 {
    padding-left: 40px !important;
  }
  .p-xlwd-9 {
    padding: 45px !important;
  }
  .pt-xlwd-9,
  .py-xlwd-9 {
    padding-top: 45px !important;
  }
  .pr-xlwd-9,
  .px-xlwd-9 {
    padding-right: 45px !important;
  }
  .pb-xlwd-9,
  .py-xlwd-9 {
    padding-bottom: 45px !important;
  }
  .pl-xlwd-9,
  .px-xlwd-9 {
    padding-left: 45px !important;
  }
  .p-xlwd-10 {
    padding: 50px !important;
  }
  .pt-xlwd-10,
  .py-xlwd-10 {
    padding-top: 50px !important;
  }
  .pr-xlwd-10,
  .px-xlwd-10 {
    padding-right: 50px !important;
  }
  .pb-xlwd-10,
  .py-xlwd-10 {
    padding-bottom: 50px !important;
  }
  .pl-xlwd-10,
  .px-xlwd-10 {
    padding-left: 50px !important;
  }
  .p-xlwd-11 {
    padding: 55px !important;
  }
  .pt-xlwd-11,
  .py-xlwd-11 {
    padding-top: 55px !important;
  }
  .pr-xlwd-11,
  .px-xlwd-11 {
    padding-right: 55px !important;
  }
  .pb-xlwd-11,
  .py-xlwd-11 {
    padding-bottom: 55px !important;
  }
  .pl-xlwd-11,
  .px-xlwd-11 {
    padding-left: 55px !important;
  }
  .p-xlwd-12 {
    padding: 60px !important;
  }
  .pt-xlwd-12,
  .py-xlwd-12 {
    padding-top: 60px !important;
  }
  .pr-xlwd-12,
  .px-xlwd-12 {
    padding-right: 60px !important;
  }
  .pb-xlwd-12,
  .py-xlwd-12 {
    padding-bottom: 60px !important;
  }
  .pl-xlwd-12,
  .px-xlwd-12 {
    padding-left: 60px !important;
  }
  .p-xlwd-13 {
    padding: 65px !important;
  }
  .pt-xlwd-13,
  .py-xlwd-13 {
    padding-top: 65px !important;
  }
  .pr-xlwd-13,
  .px-xlwd-13 {
    padding-right: 65px !important;
  }
  .pb-xlwd-13,
  .py-xlwd-13 {
    padding-bottom: 65px !important;
  }
  .pl-xlwd-13,
  .px-xlwd-13 {
    padding-left: 65px !important;
  }
  .p-xlwd-14 {
    padding: 70px !important;
  }
  .pt-xlwd-14,
  .py-xlwd-14 {
    padding-top: 70px !important;
  }
  .pr-xlwd-14,
  .px-xlwd-14 {
    padding-right: 70px !important;
  }
  .pb-xlwd-14,
  .py-xlwd-14 {
    padding-bottom: 70px !important;
  }
  .pl-xlwd-14,
  .px-xlwd-14 {
    padding-left: 70px !important;
  }
  .p-xlwd-15 {
    padding: 75px !important;
  }
  .pt-xlwd-15,
  .py-xlwd-15 {
    padding-top: 75px !important;
  }
  .pr-xlwd-15,
  .px-xlwd-15 {
    padding-right: 75px !important;
  }
  .pb-xlwd-15,
  .py-xlwd-15 {
    padding-bottom: 75px !important;
  }
  .pl-xlwd-15,
  .px-xlwd-15 {
    padding-left: 75px !important;
  }
  .p-xlwd-16 {
    padding: 80px !important;
  }
  .pt-xlwd-16,
  .py-xlwd-16 {
    padding-top: 80px !important;
  }
  .pr-xlwd-16,
  .px-xlwd-16 {
    padding-right: 80px !important;
  }
  .pb-xlwd-16,
  .py-xlwd-16 {
    padding-bottom: 80px !important;
  }
  .pl-xlwd-16,
  .px-xlwd-16 {
    padding-left: 80px !important;
  }
  .p-xlwd-17 {
    padding: 85px !important;
  }
  .pt-xlwd-17,
  .py-xlwd-17 {
    padding-top: 85px !important;
  }
  .pr-xlwd-17,
  .px-xlwd-17 {
    padding-right: 85px !important;
  }
  .pb-xlwd-17,
  .py-xlwd-17 {
    padding-bottom: 85px !important;
  }
  .pl-xlwd-17,
  .px-xlwd-17 {
    padding-left: 85px !important;
  }
  .p-xlwd-18 {
    padding: 90px !important;
  }
  .pt-xlwd-18,
  .py-xlwd-18 {
    padding-top: 90px !important;
  }
  .pr-xlwd-18,
  .px-xlwd-18 {
    padding-right: 90px !important;
  }
  .pb-xlwd-18,
  .py-xlwd-18 {
    padding-bottom: 90px !important;
  }
  .pl-xlwd-18,
  .px-xlwd-18 {
    padding-left: 90px !important;
  }
  .p-xlwd-19 {
    padding: 95px !important;
  }
  .pt-xlwd-19,
  .py-xlwd-19 {
    padding-top: 95px !important;
  }
  .pr-xlwd-19,
  .px-xlwd-19 {
    padding-right: 95px !important;
  }
  .pb-xlwd-19,
  .py-xlwd-19 {
    padding-bottom: 95px !important;
  }
  .pl-xlwd-19,
  .px-xlwd-19 {
    padding-left: 95px !important;
  }
  .p-xlwd-20 {
    padding: 100px !important;
  }
  .pt-xlwd-20,
  .py-xlwd-20 {
    padding-top: 100px !important;
  }
  .pr-xlwd-20,
  .px-xlwd-20 {
    padding-right: 100px !important;
  }
  .pb-xlwd-20,
  .py-xlwd-20 {
    padding-bottom: 100px !important;
  }
  .pl-xlwd-20,
  .px-xlwd-20 {
    padding-left: 100px !important;
  }
  .p-xlwd-21 {
    padding: 105px !important;
  }
  .pt-xlwd-21,
  .py-xlwd-21 {
    padding-top: 105px !important;
  }
  .pr-xlwd-21,
  .px-xlwd-21 {
    padding-right: 105px !important;
  }
  .pb-xlwd-21,
  .py-xlwd-21 {
    padding-bottom: 105px !important;
  }
  .pl-xlwd-21,
  .px-xlwd-21 {
    padding-left: 105px !important;
  }
  .p-xlwd-22 {
    padding: 110px !important;
  }
  .pt-xlwd-22,
  .py-xlwd-22 {
    padding-top: 110px !important;
  }
  .pr-xlwd-22,
  .px-xlwd-22 {
    padding-right: 110px !important;
  }
  .pb-xlwd-22,
  .py-xlwd-22 {
    padding-bottom: 110px !important;
  }
  .pl-xlwd-22,
  .px-xlwd-22 {
    padding-left: 110px !important;
  }
  .p-xlwd-23 {
    padding: 115px !important;
  }
  .pt-xlwd-23,
  .py-xlwd-23 {
    padding-top: 115px !important;
  }
  .pr-xlwd-23,
  .px-xlwd-23 {
    padding-right: 115px !important;
  }
  .pb-xlwd-23,
  .py-xlwd-23 {
    padding-bottom: 115px !important;
  }
  .pl-xlwd-23,
  .px-xlwd-23 {
    padding-left: 115px !important;
  }
  .p-xlwd-24 {
    padding: 120px !important;
  }
  .pt-xlwd-24,
  .py-xlwd-24 {
    padding-top: 120px !important;
  }
  .pr-xlwd-24,
  .px-xlwd-24 {
    padding-right: 120px !important;
  }
  .pb-xlwd-24,
  .py-xlwd-24 {
    padding-bottom: 120px !important;
  }
  .pl-xlwd-24,
  .px-xlwd-24 {
    padding-left: 120px !important;
  }
  .p-xlwd-25 {
    padding: 125px !important;
  }
  .pt-xlwd-25,
  .py-xlwd-25 {
    padding-top: 125px !important;
  }
  .pr-xlwd-25,
  .px-xlwd-25 {
    padding-right: 125px !important;
  }
  .pb-xlwd-25,
  .py-xlwd-25 {
    padding-bottom: 125px !important;
  }
  .pl-xlwd-25,
  .px-xlwd-25 {
    padding-left: 125px !important;
  }
  .p-xlwd-26 {
    padding: 130px !important;
  }
  .pt-xlwd-26,
  .py-xlwd-26 {
    padding-top: 130px !important;
  }
  .pr-xlwd-26,
  .px-xlwd-26 {
    padding-right: 130px !important;
  }
  .pb-xlwd-26,
  .py-xlwd-26 {
    padding-bottom: 130px !important;
  }
  .pl-xlwd-26,
  .px-xlwd-26 {
    padding-left: 130px !important;
  }
  .p-xlwd-27 {
    padding: 135px !important;
  }
  .pt-xlwd-27,
  .py-xlwd-27 {
    padding-top: 135px !important;
  }
  .pr-xlwd-27,
  .px-xlwd-27 {
    padding-right: 135px !important;
  }
  .pb-xlwd-27,
  .py-xlwd-27 {
    padding-bottom: 135px !important;
  }
  .pl-xlwd-27,
  .px-xlwd-27 {
    padding-left: 135px !important;
  }
  .p-xlwd-28 {
    padding: 140px !important;
  }
  .pt-xlwd-28,
  .py-xlwd-28 {
    padding-top: 140px !important;
  }
  .pr-xlwd-28,
  .px-xlwd-28 {
    padding-right: 140px !important;
  }
  .pb-xlwd-28,
  .py-xlwd-28 {
    padding-bottom: 140px !important;
  }
  .pl-xlwd-28,
  .px-xlwd-28 {
    padding-left: 140px !important;
  }
  .p-xlwd-29 {
    padding: 145px !important;
  }
  .pt-xlwd-29,
  .py-xlwd-29 {
    padding-top: 145px !important;
  }
  .pr-xlwd-29,
  .px-xlwd-29 {
    padding-right: 145px !important;
  }
  .pb-xlwd-29,
  .py-xlwd-29 {
    padding-bottom: 145px !important;
  }
  .pl-xlwd-29,
  .px-xlwd-29 {
    padding-left: 145px !important;
  }
  .p-xlwd-30 {
    padding: 150px !important;
  }
  .pt-xlwd-30,
  .py-xlwd-30 {
    padding-top: 150px !important;
  }
  .pr-xlwd-30,
  .px-xlwd-30 {
    padding-right: 150px !important;
  }
  .pb-xlwd-30,
  .py-xlwd-30 {
    padding-bottom: 150px !important;
  }
  .pl-xlwd-30,
  .px-xlwd-30 {
    padding-left: 150px !important;
  }
  .p-xlwd-31 {
    padding: 155px !important;
  }
  .pt-xlwd-31,
  .py-xlwd-31 {
    padding-top: 155px !important;
  }
  .pr-xlwd-31,
  .px-xlwd-31 {
    padding-right: 155px !important;
  }
  .pb-xlwd-31,
  .py-xlwd-31 {
    padding-bottom: 155px !important;
  }
  .pl-xlwd-31,
  .px-xlwd-31 {
    padding-left: 155px !important;
  }
  .p-xlwd-32 {
    padding: 160px !important;
  }
  .pt-xlwd-32,
  .py-xlwd-32 {
    padding-top: 160px !important;
  }
  .pr-xlwd-32,
  .px-xlwd-32 {
    padding-right: 160px !important;
  }
  .pb-xlwd-32,
  .py-xlwd-32 {
    padding-bottom: 160px !important;
  }
  .pl-xlwd-32,
  .px-xlwd-32 {
    padding-left: 160px !important;
  }
  .p-xlwd-33 {
    padding: 165px !important;
  }
  .pt-xlwd-33,
  .py-xlwd-33 {
    padding-top: 165px !important;
  }
  .pr-xlwd-33,
  .px-xlwd-33 {
    padding-right: 165px !important;
  }
  .pb-xlwd-33,
  .py-xlwd-33 {
    padding-bottom: 165px !important;
  }
  .pl-xlwd-33,
  .px-xlwd-33 {
    padding-left: 165px !important;
  }
  .p-xlwd-34 {
    padding: 170px !important;
  }
  .pt-xlwd-34,
  .py-xlwd-34 {
    padding-top: 170px !important;
  }
  .pr-xlwd-34,
  .px-xlwd-34 {
    padding-right: 170px !important;
  }
  .pb-xlwd-34,
  .py-xlwd-34 {
    padding-bottom: 170px !important;
  }
  .pl-xlwd-34,
  .px-xlwd-34 {
    padding-left: 170px !important;
  }
  .p-xlwd-35 {
    padding: 175px !important;
  }
  .pt-xlwd-35,
  .py-xlwd-35 {
    padding-top: 175px !important;
  }
  .pr-xlwd-35,
  .px-xlwd-35 {
    padding-right: 175px !important;
  }
  .pb-xlwd-35,
  .py-xlwd-35 {
    padding-bottom: 175px !important;
  }
  .pl-xlwd-35,
  .px-xlwd-35 {
    padding-left: 175px !important;
  }
  .p-xlwd-36 {
    padding: 180px !important;
  }
  .pt-xlwd-36,
  .py-xlwd-36 {
    padding-top: 180px !important;
  }
  .pr-xlwd-36,
  .px-xlwd-36 {
    padding-right: 180px !important;
  }
  .pb-xlwd-36,
  .py-xlwd-36 {
    padding-bottom: 180px !important;
  }
  .pl-xlwd-36,
  .px-xlwd-36 {
    padding-left: 180px !important;
  }
  .p-xlwd-37 {
    padding: 185px !important;
  }
  .pt-xlwd-37,
  .py-xlwd-37 {
    padding-top: 185px !important;
  }
  .pr-xlwd-37,
  .px-xlwd-37 {
    padding-right: 185px !important;
  }
  .pb-xlwd-37,
  .py-xlwd-37 {
    padding-bottom: 185px !important;
  }
  .pl-xlwd-37,
  .px-xlwd-37 {
    padding-left: 185px !important;
  }
  .p-xlwd-38 {
    padding: 190px !important;
  }
  .pt-xlwd-38,
  .py-xlwd-38 {
    padding-top: 190px !important;
  }
  .pr-xlwd-38,
  .px-xlwd-38 {
    padding-right: 190px !important;
  }
  .pb-xlwd-38,
  .py-xlwd-38 {
    padding-bottom: 190px !important;
  }
  .pl-xlwd-38,
  .px-xlwd-38 {
    padding-left: 190px !important;
  }
  .p-xlwd-39 {
    padding: 195px !important;
  }
  .pt-xlwd-39,
  .py-xlwd-39 {
    padding-top: 195px !important;
  }
  .pr-xlwd-39,
  .px-xlwd-39 {
    padding-right: 195px !important;
  }
  .pb-xlwd-39,
  .py-xlwd-39 {
    padding-bottom: 195px !important;
  }
  .pl-xlwd-39,
  .px-xlwd-39 {
    padding-left: 195px !important;
  }
  .p-xlwd-40 {
    padding: 200px !important;
  }
  .pt-xlwd-40,
  .py-xlwd-40 {
    padding-top: 200px !important;
  }
  .pr-xlwd-40,
  .px-xlwd-40 {
    padding-right: 200px !important;
  }
  .pb-xlwd-40,
  .py-xlwd-40 {
    padding-bottom: 200px !important;
  }
  .pl-xlwd-40,
  .px-xlwd-40 {
    padding-left: 200px !important;
  }
  .p-xlwd-41 {
    padding: 205px !important;
  }
  .pt-xlwd-41,
  .py-xlwd-41 {
    padding-top: 205px !important;
  }
  .pr-xlwd-41,
  .px-xlwd-41 {
    padding-right: 205px !important;
  }
  .pb-xlwd-41,
  .py-xlwd-41 {
    padding-bottom: 205px !important;
  }
  .pl-xlwd-41,
  .px-xlwd-41 {
    padding-left: 205px !important;
  }
  .p-xlwd-42 {
    padding: 210px !important;
  }
  .pt-xlwd-42,
  .py-xlwd-42 {
    padding-top: 210px !important;
  }
  .pr-xlwd-42,
  .px-xlwd-42 {
    padding-right: 210px !important;
  }
  .pb-xlwd-42,
  .py-xlwd-42 {
    padding-bottom: 210px !important;
  }
  .pl-xlwd-42,
  .px-xlwd-42 {
    padding-left: 210px !important;
  }
  .p-xlwd-43 {
    padding: 215px !important;
  }
  .pt-xlwd-43,
  .py-xlwd-43 {
    padding-top: 215px !important;
  }
  .pr-xlwd-43,
  .px-xlwd-43 {
    padding-right: 215px !important;
  }
  .pb-xlwd-43,
  .py-xlwd-43 {
    padding-bottom: 215px !important;
  }
  .pl-xlwd-43,
  .px-xlwd-43 {
    padding-left: 215px !important;
  }
  .p-xlwd-44 {
    padding: 220px !important;
  }
  .pt-xlwd-44,
  .py-xlwd-44 {
    padding-top: 220px !important;
  }
  .pr-xlwd-44,
  .px-xlwd-44 {
    padding-right: 220px !important;
  }
  .pb-xlwd-44,
  .py-xlwd-44 {
    padding-bottom: 220px !important;
  }
  .pl-xlwd-44,
  .px-xlwd-44 {
    padding-left: 220px !important;
  }
  .p-xlwd-45 {
    padding: 225px !important;
  }
  .pt-xlwd-45,
  .py-xlwd-45 {
    padding-top: 225px !important;
  }
  .pr-xlwd-45,
  .px-xlwd-45 {
    padding-right: 225px !important;
  }
  .pb-xlwd-45,
  .py-xlwd-45 {
    padding-bottom: 225px !important;
  }
  .pl-xlwd-45,
  .px-xlwd-45 {
    padding-left: 225px !important;
  }
  .p-xlwd-46 {
    padding: 230px !important;
  }
  .pt-xlwd-46,
  .py-xlwd-46 {
    padding-top: 230px !important;
  }
  .pr-xlwd-46,
  .px-xlwd-46 {
    padding-right: 230px !important;
  }
  .pb-xlwd-46,
  .py-xlwd-46 {
    padding-bottom: 230px !important;
  }
  .pl-xlwd-46,
  .px-xlwd-46 {
    padding-left: 230px !important;
  }
  .p-xlwd-47 {
    padding: 235px !important;
  }
  .pt-xlwd-47,
  .py-xlwd-47 {
    padding-top: 235px !important;
  }
  .pr-xlwd-47,
  .px-xlwd-47 {
    padding-right: 235px !important;
  }
  .pb-xlwd-47,
  .py-xlwd-47 {
    padding-bottom: 235px !important;
  }
  .pl-xlwd-47,
  .px-xlwd-47 {
    padding-left: 235px !important;
  }
  .p-xlwd-48 {
    padding: 240px !important;
  }
  .pt-xlwd-48,
  .py-xlwd-48 {
    padding-top: 240px !important;
  }
  .pr-xlwd-48,
  .px-xlwd-48 {
    padding-right: 240px !important;
  }
  .pb-xlwd-48,
  .py-xlwd-48 {
    padding-bottom: 240px !important;
  }
  .pl-xlwd-48,
  .px-xlwd-48 {
    padding-left: 240px !important;
  }
  .p-xlwd-49 {
    padding: 245px !important;
  }
  .pt-xlwd-49,
  .py-xlwd-49 {
    padding-top: 245px !important;
  }
  .pr-xlwd-49,
  .px-xlwd-49 {
    padding-right: 245px !important;
  }
  .pb-xlwd-49,
  .py-xlwd-49 {
    padding-bottom: 245px !important;
  }
  .pl-xlwd-49,
  .px-xlwd-49 {
    padding-left: 245px !important;
  }
  .p-xlwd-50 {
    padding: 250px !important;
  }
  .pt-xlwd-50,
  .py-xlwd-50 {
    padding-top: 250px !important;
  }
  .pr-xlwd-50,
  .px-xlwd-50 {
    padding-right: 250px !important;
  }
  .pb-xlwd-50,
  .py-xlwd-50 {
    padding-bottom: 250px !important;
  }
  .pl-xlwd-50,
  .px-xlwd-50 {
    padding-left: 250px !important;
  }
  .p-xlwd-51 {
    padding: 255px !important;
  }
  .pt-xlwd-51,
  .py-xlwd-51 {
    padding-top: 255px !important;
  }
  .pr-xlwd-51,
  .px-xlwd-51 {
    padding-right: 255px !important;
  }
  .pb-xlwd-51,
  .py-xlwd-51 {
    padding-bottom: 255px !important;
  }
  .pl-xlwd-51,
  .px-xlwd-51 {
    padding-left: 255px !important;
  }
  .p-xlwd-52 {
    padding: 260px !important;
  }
  .pt-xlwd-52,
  .py-xlwd-52 {
    padding-top: 260px !important;
  }
  .pr-xlwd-52,
  .px-xlwd-52 {
    padding-right: 260px !important;
  }
  .pb-xlwd-52,
  .py-xlwd-52 {
    padding-bottom: 260px !important;
  }
  .pl-xlwd-52,
  .px-xlwd-52 {
    padding-left: 260px !important;
  }
  .p-xlwd-53 {
    padding: 265px !important;
  }
  .pt-xlwd-53,
  .py-xlwd-53 {
    padding-top: 265px !important;
  }
  .pr-xlwd-53,
  .px-xlwd-53 {
    padding-right: 265px !important;
  }
  .pb-xlwd-53,
  .py-xlwd-53 {
    padding-bottom: 265px !important;
  }
  .pl-xlwd-53,
  .px-xlwd-53 {
    padding-left: 265px !important;
  }
  .p-xlwd-54 {
    padding: 270px !important;
  }
  .pt-xlwd-54,
  .py-xlwd-54 {
    padding-top: 270px !important;
  }
  .pr-xlwd-54,
  .px-xlwd-54 {
    padding-right: 270px !important;
  }
  .pb-xlwd-54,
  .py-xlwd-54 {
    padding-bottom: 270px !important;
  }
  .pl-xlwd-54,
  .px-xlwd-54 {
    padding-left: 270px !important;
  }
  .p-xlwd-55 {
    padding: 275px !important;
  }
  .pt-xlwd-55,
  .py-xlwd-55 {
    padding-top: 275px !important;
  }
  .pr-xlwd-55,
  .px-xlwd-55 {
    padding-right: 275px !important;
  }
  .pb-xlwd-55,
  .py-xlwd-55 {
    padding-bottom: 275px !important;
  }
  .pl-xlwd-55,
  .px-xlwd-55 {
    padding-left: 275px !important;
  }
  .p-xlwd-56 {
    padding: 280px !important;
  }
  .pt-xlwd-56,
  .py-xlwd-56 {
    padding-top: 280px !important;
  }
  .pr-xlwd-56,
  .px-xlwd-56 {
    padding-right: 280px !important;
  }
  .pb-xlwd-56,
  .py-xlwd-56 {
    padding-bottom: 280px !important;
  }
  .pl-xlwd-56,
  .px-xlwd-56 {
    padding-left: 280px !important;
  }
  .p-xlwd-57 {
    padding: 285px !important;
  }
  .pt-xlwd-57,
  .py-xlwd-57 {
    padding-top: 285px !important;
  }
  .pr-xlwd-57,
  .px-xlwd-57 {
    padding-right: 285px !important;
  }
  .pb-xlwd-57,
  .py-xlwd-57 {
    padding-bottom: 285px !important;
  }
  .pl-xlwd-57,
  .px-xlwd-57 {
    padding-left: 285px !important;
  }
  .p-xlwd-58 {
    padding: 290px !important;
  }
  .pt-xlwd-58,
  .py-xlwd-58 {
    padding-top: 290px !important;
  }
  .pr-xlwd-58,
  .px-xlwd-58 {
    padding-right: 290px !important;
  }
  .pb-xlwd-58,
  .py-xlwd-58 {
    padding-bottom: 290px !important;
  }
  .pl-xlwd-58,
  .px-xlwd-58 {
    padding-left: 290px !important;
  }
  .p-xlwd-59 {
    padding: 295px !important;
  }
  .pt-xlwd-59,
  .py-xlwd-59 {
    padding-top: 295px !important;
  }
  .pr-xlwd-59,
  .px-xlwd-59 {
    padding-right: 295px !important;
  }
  .pb-xlwd-59,
  .py-xlwd-59 {
    padding-bottom: 295px !important;
  }
  .pl-xlwd-59,
  .px-xlwd-59 {
    padding-left: 295px !important;
  }
  .p-xlwd-60 {
    padding: 300px !important;
  }
  .pt-xlwd-60,
  .py-xlwd-60 {
    padding-top: 300px !important;
  }
  .pr-xlwd-60,
  .px-xlwd-60 {
    padding-right: 300px !important;
  }
  .pb-xlwd-60,
  .py-xlwd-60 {
    padding-bottom: 300px !important;
  }
  .pl-xlwd-60,
  .px-xlwd-60 {
    padding-left: 300px !important;
  }
  .m-xlwd-n1 {
    margin: -5px !important;
  }
  .mt-xlwd-n1,
  .my-xlwd-n1 {
    margin-top: -5px !important;
  }
  .mr-xlwd-n1,
  .mx-xlwd-n1 {
    margin-right: -5px !important;
  }
  .mb-xlwd-n1,
  .my-xlwd-n1 {
    margin-bottom: -5px !important;
  }
  .ml-xlwd-n1,
  .mx-xlwd-n1 {
    margin-left: -5px !important;
  }
  .m-xlwd-n2 {
    margin: -10px !important;
  }
  .mt-xlwd-n2,
  .my-xlwd-n2 {
    margin-top: -10px !important;
  }
  .mr-xlwd-n2,
  .mx-xlwd-n2 {
    margin-right: -10px !important;
  }
  .mb-xlwd-n2,
  .my-xlwd-n2 {
    margin-bottom: -10px !important;
  }
  .ml-xlwd-n2,
  .mx-xlwd-n2 {
    margin-left: -10px !important;
  }
  .m-xlwd-n3 {
    margin: -16px !important;
  }
  .mt-xlwd-n3,
  .my-xlwd-n3 {
    margin-top: -16px !important;
  }
  .mr-xlwd-n3,
  .mx-xlwd-n3 {
    margin-right: -16px !important;
  }
  .mb-xlwd-n3,
  .my-xlwd-n3 {
    margin-bottom: -16px !important;
  }
  .ml-xlwd-n3,
  .mx-xlwd-n3 {
    margin-left: -16px !important;
  }
  .m-xlwd-n4 {
    margin: -20px !important;
  }
  .mt-xlwd-n4,
  .my-xlwd-n4 {
    margin-top: -20px !important;
  }
  .mr-xlwd-n4,
  .mx-xlwd-n4 {
    margin-right: -20px !important;
  }
  .mb-xlwd-n4,
  .my-xlwd-n4 {
    margin-bottom: -20px !important;
  }
  .ml-xlwd-n4,
  .mx-xlwd-n4 {
    margin-left: -20px !important;
  }
  .m-xlwd-n5 {
    margin: -25px !important;
  }
  .mt-xlwd-n5,
  .my-xlwd-n5 {
    margin-top: -25px !important;
  }
  .mr-xlwd-n5,
  .mx-xlwd-n5 {
    margin-right: -25px !important;
  }
  .mb-xlwd-n5,
  .my-xlwd-n5 {
    margin-bottom: -25px !important;
  }
  .ml-xlwd-n5,
  .mx-xlwd-n5 {
    margin-left: -25px !important;
  }
  .m-xlwd-n6 {
    margin: -30px !important;
  }
  .mt-xlwd-n6,
  .my-xlwd-n6 {
    margin-top: -30px !important;
  }
  .mr-xlwd-n6,
  .mx-xlwd-n6 {
    margin-right: -30px !important;
  }
  .mb-xlwd-n6,
  .my-xlwd-n6 {
    margin-bottom: -30px !important;
  }
  .ml-xlwd-n6,
  .mx-xlwd-n6 {
    margin-left: -30px !important;
  }
  .m-xlwd-n7 {
    margin: -35px !important;
  }
  .mt-xlwd-n7,
  .my-xlwd-n7 {
    margin-top: -35px !important;
  }
  .mr-xlwd-n7,
  .mx-xlwd-n7 {
    margin-right: -35px !important;
  }
  .mb-xlwd-n7,
  .my-xlwd-n7 {
    margin-bottom: -35px !important;
  }
  .ml-xlwd-n7,
  .mx-xlwd-n7 {
    margin-left: -35px !important;
  }
  .m-xlwd-n8 {
    margin: -40px !important;
  }
  .mt-xlwd-n8,
  .my-xlwd-n8 {
    margin-top: -40px !important;
  }
  .mr-xlwd-n8,
  .mx-xlwd-n8 {
    margin-right: -40px !important;
  }
  .mb-xlwd-n8,
  .my-xlwd-n8 {
    margin-bottom: -40px !important;
  }
  .ml-xlwd-n8,
  .mx-xlwd-n8 {
    margin-left: -40px !important;
  }
  .m-xlwd-n9 {
    margin: -45px !important;
  }
  .mt-xlwd-n9,
  .my-xlwd-n9 {
    margin-top: -45px !important;
  }
  .mr-xlwd-n9,
  .mx-xlwd-n9 {
    margin-right: -45px !important;
  }
  .mb-xlwd-n9,
  .my-xlwd-n9 {
    margin-bottom: -45px !important;
  }
  .ml-xlwd-n9,
  .mx-xlwd-n9 {
    margin-left: -45px !important;
  }
  .m-xlwd-n10 {
    margin: -50px !important;
  }
  .mt-xlwd-n10,
  .my-xlwd-n10 {
    margin-top: -50px !important;
  }
  .mr-xlwd-n10,
  .mx-xlwd-n10 {
    margin-right: -50px !important;
  }
  .mb-xlwd-n10,
  .my-xlwd-n10 {
    margin-bottom: -50px !important;
  }
  .ml-xlwd-n10,
  .mx-xlwd-n10 {
    margin-left: -50px !important;
  }
  .m-xlwd-n11 {
    margin: -55px !important;
  }
  .mt-xlwd-n11,
  .my-xlwd-n11 {
    margin-top: -55px !important;
  }
  .mr-xlwd-n11,
  .mx-xlwd-n11 {
    margin-right: -55px !important;
  }
  .mb-xlwd-n11,
  .my-xlwd-n11 {
    margin-bottom: -55px !important;
  }
  .ml-xlwd-n11,
  .mx-xlwd-n11 {
    margin-left: -55px !important;
  }
  .m-xlwd-n12 {
    margin: -60px !important;
  }
  .mt-xlwd-n12,
  .my-xlwd-n12 {
    margin-top: -60px !important;
  }
  .mr-xlwd-n12,
  .mx-xlwd-n12 {
    margin-right: -60px !important;
  }
  .mb-xlwd-n12,
  .my-xlwd-n12 {
    margin-bottom: -60px !important;
  }
  .ml-xlwd-n12,
  .mx-xlwd-n12 {
    margin-left: -60px !important;
  }
  .m-xlwd-n13 {
    margin: -65px !important;
  }
  .mt-xlwd-n13,
  .my-xlwd-n13 {
    margin-top: -65px !important;
  }
  .mr-xlwd-n13,
  .mx-xlwd-n13 {
    margin-right: -65px !important;
  }
  .mb-xlwd-n13,
  .my-xlwd-n13 {
    margin-bottom: -65px !important;
  }
  .ml-xlwd-n13,
  .mx-xlwd-n13 {
    margin-left: -65px !important;
  }
  .m-xlwd-n14 {
    margin: -70px !important;
  }
  .mt-xlwd-n14,
  .my-xlwd-n14 {
    margin-top: -70px !important;
  }
  .mr-xlwd-n14,
  .mx-xlwd-n14 {
    margin-right: -70px !important;
  }
  .mb-xlwd-n14,
  .my-xlwd-n14 {
    margin-bottom: -70px !important;
  }
  .ml-xlwd-n14,
  .mx-xlwd-n14 {
    margin-left: -70px !important;
  }
  .m-xlwd-n15 {
    margin: -75px !important;
  }
  .mt-xlwd-n15,
  .my-xlwd-n15 {
    margin-top: -75px !important;
  }
  .mr-xlwd-n15,
  .mx-xlwd-n15 {
    margin-right: -75px !important;
  }
  .mb-xlwd-n15,
  .my-xlwd-n15 {
    margin-bottom: -75px !important;
  }
  .ml-xlwd-n15,
  .mx-xlwd-n15 {
    margin-left: -75px !important;
  }
  .m-xlwd-n16 {
    margin: -80px !important;
  }
  .mt-xlwd-n16,
  .my-xlwd-n16 {
    margin-top: -80px !important;
  }
  .mr-xlwd-n16,
  .mx-xlwd-n16 {
    margin-right: -80px !important;
  }
  .mb-xlwd-n16,
  .my-xlwd-n16 {
    margin-bottom: -80px !important;
  }
  .ml-xlwd-n16,
  .mx-xlwd-n16 {
    margin-left: -80px !important;
  }
  .m-xlwd-n17 {
    margin: -85px !important;
  }
  .mt-xlwd-n17,
  .my-xlwd-n17 {
    margin-top: -85px !important;
  }
  .mr-xlwd-n17,
  .mx-xlwd-n17 {
    margin-right: -85px !important;
  }
  .mb-xlwd-n17,
  .my-xlwd-n17 {
    margin-bottom: -85px !important;
  }
  .ml-xlwd-n17,
  .mx-xlwd-n17 {
    margin-left: -85px !important;
  }
  .m-xlwd-n18 {
    margin: -90px !important;
  }
  .mt-xlwd-n18,
  .my-xlwd-n18 {
    margin-top: -90px !important;
  }
  .mr-xlwd-n18,
  .mx-xlwd-n18 {
    margin-right: -90px !important;
  }
  .mb-xlwd-n18,
  .my-xlwd-n18 {
    margin-bottom: -90px !important;
  }
  .ml-xlwd-n18,
  .mx-xlwd-n18 {
    margin-left: -90px !important;
  }
  .m-xlwd-n19 {
    margin: -95px !important;
  }
  .mt-xlwd-n19,
  .my-xlwd-n19 {
    margin-top: -95px !important;
  }
  .mr-xlwd-n19,
  .mx-xlwd-n19 {
    margin-right: -95px !important;
  }
  .mb-xlwd-n19,
  .my-xlwd-n19 {
    margin-bottom: -95px !important;
  }
  .ml-xlwd-n19,
  .mx-xlwd-n19 {
    margin-left: -95px !important;
  }
  .m-xlwd-n20 {
    margin: -100px !important;
  }
  .mt-xlwd-n20,
  .my-xlwd-n20 {
    margin-top: -100px !important;
  }
  .mr-xlwd-n20,
  .mx-xlwd-n20 {
    margin-right: -100px !important;
  }
  .mb-xlwd-n20,
  .my-xlwd-n20 {
    margin-bottom: -100px !important;
  }
  .ml-xlwd-n20,
  .mx-xlwd-n20 {
    margin-left: -100px !important;
  }
  .m-xlwd-n21 {
    margin: -105px !important;
  }
  .mt-xlwd-n21,
  .my-xlwd-n21 {
    margin-top: -105px !important;
  }
  .mr-xlwd-n21,
  .mx-xlwd-n21 {
    margin-right: -105px !important;
  }
  .mb-xlwd-n21,
  .my-xlwd-n21 {
    margin-bottom: -105px !important;
  }
  .ml-xlwd-n21,
  .mx-xlwd-n21 {
    margin-left: -105px !important;
  }
  .m-xlwd-n22 {
    margin: -110px !important;
  }
  .mt-xlwd-n22,
  .my-xlwd-n22 {
    margin-top: -110px !important;
  }
  .mr-xlwd-n22,
  .mx-xlwd-n22 {
    margin-right: -110px !important;
  }
  .mb-xlwd-n22,
  .my-xlwd-n22 {
    margin-bottom: -110px !important;
  }
  .ml-xlwd-n22,
  .mx-xlwd-n22 {
    margin-left: -110px !important;
  }
  .m-xlwd-n23 {
    margin: -115px !important;
  }
  .mt-xlwd-n23,
  .my-xlwd-n23 {
    margin-top: -115px !important;
  }
  .mr-xlwd-n23,
  .mx-xlwd-n23 {
    margin-right: -115px !important;
  }
  .mb-xlwd-n23,
  .my-xlwd-n23 {
    margin-bottom: -115px !important;
  }
  .ml-xlwd-n23,
  .mx-xlwd-n23 {
    margin-left: -115px !important;
  }
  .m-xlwd-n24 {
    margin: -120px !important;
  }
  .mt-xlwd-n24,
  .my-xlwd-n24 {
    margin-top: -120px !important;
  }
  .mr-xlwd-n24,
  .mx-xlwd-n24 {
    margin-right: -120px !important;
  }
  .mb-xlwd-n24,
  .my-xlwd-n24 {
    margin-bottom: -120px !important;
  }
  .ml-xlwd-n24,
  .mx-xlwd-n24 {
    margin-left: -120px !important;
  }
  .m-xlwd-n25 {
    margin: -125px !important;
  }
  .mt-xlwd-n25,
  .my-xlwd-n25 {
    margin-top: -125px !important;
  }
  .mr-xlwd-n25,
  .mx-xlwd-n25 {
    margin-right: -125px !important;
  }
  .mb-xlwd-n25,
  .my-xlwd-n25 {
    margin-bottom: -125px !important;
  }
  .ml-xlwd-n25,
  .mx-xlwd-n25 {
    margin-left: -125px !important;
  }
  .m-xlwd-n26 {
    margin: -130px !important;
  }
  .mt-xlwd-n26,
  .my-xlwd-n26 {
    margin-top: -130px !important;
  }
  .mr-xlwd-n26,
  .mx-xlwd-n26 {
    margin-right: -130px !important;
  }
  .mb-xlwd-n26,
  .my-xlwd-n26 {
    margin-bottom: -130px !important;
  }
  .ml-xlwd-n26,
  .mx-xlwd-n26 {
    margin-left: -130px !important;
  }
  .m-xlwd-n27 {
    margin: -135px !important;
  }
  .mt-xlwd-n27,
  .my-xlwd-n27 {
    margin-top: -135px !important;
  }
  .mr-xlwd-n27,
  .mx-xlwd-n27 {
    margin-right: -135px !important;
  }
  .mb-xlwd-n27,
  .my-xlwd-n27 {
    margin-bottom: -135px !important;
  }
  .ml-xlwd-n27,
  .mx-xlwd-n27 {
    margin-left: -135px !important;
  }
  .m-xlwd-n28 {
    margin: -140px !important;
  }
  .mt-xlwd-n28,
  .my-xlwd-n28 {
    margin-top: -140px !important;
  }
  .mr-xlwd-n28,
  .mx-xlwd-n28 {
    margin-right: -140px !important;
  }
  .mb-xlwd-n28,
  .my-xlwd-n28 {
    margin-bottom: -140px !important;
  }
  .ml-xlwd-n28,
  .mx-xlwd-n28 {
    margin-left: -140px !important;
  }
  .m-xlwd-n29 {
    margin: -145px !important;
  }
  .mt-xlwd-n29,
  .my-xlwd-n29 {
    margin-top: -145px !important;
  }
  .mr-xlwd-n29,
  .mx-xlwd-n29 {
    margin-right: -145px !important;
  }
  .mb-xlwd-n29,
  .my-xlwd-n29 {
    margin-bottom: -145px !important;
  }
  .ml-xlwd-n29,
  .mx-xlwd-n29 {
    margin-left: -145px !important;
  }
  .m-xlwd-n30 {
    margin: -150px !important;
  }
  .mt-xlwd-n30,
  .my-xlwd-n30 {
    margin-top: -150px !important;
  }
  .mr-xlwd-n30,
  .mx-xlwd-n30 {
    margin-right: -150px !important;
  }
  .mb-xlwd-n30,
  .my-xlwd-n30 {
    margin-bottom: -150px !important;
  }
  .ml-xlwd-n30,
  .mx-xlwd-n30 {
    margin-left: -150px !important;
  }
  .m-xlwd-n31 {
    margin: -155px !important;
  }
  .mt-xlwd-n31,
  .my-xlwd-n31 {
    margin-top: -155px !important;
  }
  .mr-xlwd-n31,
  .mx-xlwd-n31 {
    margin-right: -155px !important;
  }
  .mb-xlwd-n31,
  .my-xlwd-n31 {
    margin-bottom: -155px !important;
  }
  .ml-xlwd-n31,
  .mx-xlwd-n31 {
    margin-left: -155px !important;
  }
  .m-xlwd-n32 {
    margin: -160px !important;
  }
  .mt-xlwd-n32,
  .my-xlwd-n32 {
    margin-top: -160px !important;
  }
  .mr-xlwd-n32,
  .mx-xlwd-n32 {
    margin-right: -160px !important;
  }
  .mb-xlwd-n32,
  .my-xlwd-n32 {
    margin-bottom: -160px !important;
  }
  .ml-xlwd-n32,
  .mx-xlwd-n32 {
    margin-left: -160px !important;
  }
  .m-xlwd-n33 {
    margin: -165px !important;
  }
  .mt-xlwd-n33,
  .my-xlwd-n33 {
    margin-top: -165px !important;
  }
  .mr-xlwd-n33,
  .mx-xlwd-n33 {
    margin-right: -165px !important;
  }
  .mb-xlwd-n33,
  .my-xlwd-n33 {
    margin-bottom: -165px !important;
  }
  .ml-xlwd-n33,
  .mx-xlwd-n33 {
    margin-left: -165px !important;
  }
  .m-xlwd-n34 {
    margin: -170px !important;
  }
  .mt-xlwd-n34,
  .my-xlwd-n34 {
    margin-top: -170px !important;
  }
  .mr-xlwd-n34,
  .mx-xlwd-n34 {
    margin-right: -170px !important;
  }
  .mb-xlwd-n34,
  .my-xlwd-n34 {
    margin-bottom: -170px !important;
  }
  .ml-xlwd-n34,
  .mx-xlwd-n34 {
    margin-left: -170px !important;
  }
  .m-xlwd-n35 {
    margin: -175px !important;
  }
  .mt-xlwd-n35,
  .my-xlwd-n35 {
    margin-top: -175px !important;
  }
  .mr-xlwd-n35,
  .mx-xlwd-n35 {
    margin-right: -175px !important;
  }
  .mb-xlwd-n35,
  .my-xlwd-n35 {
    margin-bottom: -175px !important;
  }
  .ml-xlwd-n35,
  .mx-xlwd-n35 {
    margin-left: -175px !important;
  }
  .m-xlwd-n36 {
    margin: -180px !important;
  }
  .mt-xlwd-n36,
  .my-xlwd-n36 {
    margin-top: -180px !important;
  }
  .mr-xlwd-n36,
  .mx-xlwd-n36 {
    margin-right: -180px !important;
  }
  .mb-xlwd-n36,
  .my-xlwd-n36 {
    margin-bottom: -180px !important;
  }
  .ml-xlwd-n36,
  .mx-xlwd-n36 {
    margin-left: -180px !important;
  }
  .m-xlwd-n37 {
    margin: -185px !important;
  }
  .mt-xlwd-n37,
  .my-xlwd-n37 {
    margin-top: -185px !important;
  }
  .mr-xlwd-n37,
  .mx-xlwd-n37 {
    margin-right: -185px !important;
  }
  .mb-xlwd-n37,
  .my-xlwd-n37 {
    margin-bottom: -185px !important;
  }
  .ml-xlwd-n37,
  .mx-xlwd-n37 {
    margin-left: -185px !important;
  }
  .m-xlwd-n38 {
    margin: -190px !important;
  }
  .mt-xlwd-n38,
  .my-xlwd-n38 {
    margin-top: -190px !important;
  }
  .mr-xlwd-n38,
  .mx-xlwd-n38 {
    margin-right: -190px !important;
  }
  .mb-xlwd-n38,
  .my-xlwd-n38 {
    margin-bottom: -190px !important;
  }
  .ml-xlwd-n38,
  .mx-xlwd-n38 {
    margin-left: -190px !important;
  }
  .m-xlwd-n39 {
    margin: -195px !important;
  }
  .mt-xlwd-n39,
  .my-xlwd-n39 {
    margin-top: -195px !important;
  }
  .mr-xlwd-n39,
  .mx-xlwd-n39 {
    margin-right: -195px !important;
  }
  .mb-xlwd-n39,
  .my-xlwd-n39 {
    margin-bottom: -195px !important;
  }
  .ml-xlwd-n39,
  .mx-xlwd-n39 {
    margin-left: -195px !important;
  }
  .m-xlwd-n40 {
    margin: -200px !important;
  }
  .mt-xlwd-n40,
  .my-xlwd-n40 {
    margin-top: -200px !important;
  }
  .mr-xlwd-n40,
  .mx-xlwd-n40 {
    margin-right: -200px !important;
  }
  .mb-xlwd-n40,
  .my-xlwd-n40 {
    margin-bottom: -200px !important;
  }
  .ml-xlwd-n40,
  .mx-xlwd-n40 {
    margin-left: -200px !important;
  }
  .m-xlwd-n41 {
    margin: -205px !important;
  }
  .mt-xlwd-n41,
  .my-xlwd-n41 {
    margin-top: -205px !important;
  }
  .mr-xlwd-n41,
  .mx-xlwd-n41 {
    margin-right: -205px !important;
  }
  .mb-xlwd-n41,
  .my-xlwd-n41 {
    margin-bottom: -205px !important;
  }
  .ml-xlwd-n41,
  .mx-xlwd-n41 {
    margin-left: -205px !important;
  }
  .m-xlwd-n42 {
    margin: -210px !important;
  }
  .mt-xlwd-n42,
  .my-xlwd-n42 {
    margin-top: -210px !important;
  }
  .mr-xlwd-n42,
  .mx-xlwd-n42 {
    margin-right: -210px !important;
  }
  .mb-xlwd-n42,
  .my-xlwd-n42 {
    margin-bottom: -210px !important;
  }
  .ml-xlwd-n42,
  .mx-xlwd-n42 {
    margin-left: -210px !important;
  }
  .m-xlwd-n43 {
    margin: -215px !important;
  }
  .mt-xlwd-n43,
  .my-xlwd-n43 {
    margin-top: -215px !important;
  }
  .mr-xlwd-n43,
  .mx-xlwd-n43 {
    margin-right: -215px !important;
  }
  .mb-xlwd-n43,
  .my-xlwd-n43 {
    margin-bottom: -215px !important;
  }
  .ml-xlwd-n43,
  .mx-xlwd-n43 {
    margin-left: -215px !important;
  }
  .m-xlwd-n44 {
    margin: -220px !important;
  }
  .mt-xlwd-n44,
  .my-xlwd-n44 {
    margin-top: -220px !important;
  }
  .mr-xlwd-n44,
  .mx-xlwd-n44 {
    margin-right: -220px !important;
  }
  .mb-xlwd-n44,
  .my-xlwd-n44 {
    margin-bottom: -220px !important;
  }
  .ml-xlwd-n44,
  .mx-xlwd-n44 {
    margin-left: -220px !important;
  }
  .m-xlwd-n45 {
    margin: -225px !important;
  }
  .mt-xlwd-n45,
  .my-xlwd-n45 {
    margin-top: -225px !important;
  }
  .mr-xlwd-n45,
  .mx-xlwd-n45 {
    margin-right: -225px !important;
  }
  .mb-xlwd-n45,
  .my-xlwd-n45 {
    margin-bottom: -225px !important;
  }
  .ml-xlwd-n45,
  .mx-xlwd-n45 {
    margin-left: -225px !important;
  }
  .m-xlwd-n46 {
    margin: -230px !important;
  }
  .mt-xlwd-n46,
  .my-xlwd-n46 {
    margin-top: -230px !important;
  }
  .mr-xlwd-n46,
  .mx-xlwd-n46 {
    margin-right: -230px !important;
  }
  .mb-xlwd-n46,
  .my-xlwd-n46 {
    margin-bottom: -230px !important;
  }
  .ml-xlwd-n46,
  .mx-xlwd-n46 {
    margin-left: -230px !important;
  }
  .m-xlwd-n47 {
    margin: -235px !important;
  }
  .mt-xlwd-n47,
  .my-xlwd-n47 {
    margin-top: -235px !important;
  }
  .mr-xlwd-n47,
  .mx-xlwd-n47 {
    margin-right: -235px !important;
  }
  .mb-xlwd-n47,
  .my-xlwd-n47 {
    margin-bottom: -235px !important;
  }
  .ml-xlwd-n47,
  .mx-xlwd-n47 {
    margin-left: -235px !important;
  }
  .m-xlwd-n48 {
    margin: -240px !important;
  }
  .mt-xlwd-n48,
  .my-xlwd-n48 {
    margin-top: -240px !important;
  }
  .mr-xlwd-n48,
  .mx-xlwd-n48 {
    margin-right: -240px !important;
  }
  .mb-xlwd-n48,
  .my-xlwd-n48 {
    margin-bottom: -240px !important;
  }
  .ml-xlwd-n48,
  .mx-xlwd-n48 {
    margin-left: -240px !important;
  }
  .m-xlwd-n49 {
    margin: -245px !important;
  }
  .mt-xlwd-n49,
  .my-xlwd-n49 {
    margin-top: -245px !important;
  }
  .mr-xlwd-n49,
  .mx-xlwd-n49 {
    margin-right: -245px !important;
  }
  .mb-xlwd-n49,
  .my-xlwd-n49 {
    margin-bottom: -245px !important;
  }
  .ml-xlwd-n49,
  .mx-xlwd-n49 {
    margin-left: -245px !important;
  }
  .m-xlwd-n50 {
    margin: -250px !important;
  }
  .mt-xlwd-n50,
  .my-xlwd-n50 {
    margin-top: -250px !important;
  }
  .mr-xlwd-n50,
  .mx-xlwd-n50 {
    margin-right: -250px !important;
  }
  .mb-xlwd-n50,
  .my-xlwd-n50 {
    margin-bottom: -250px !important;
  }
  .ml-xlwd-n50,
  .mx-xlwd-n50 {
    margin-left: -250px !important;
  }
  .m-xlwd-n51 {
    margin: -255px !important;
  }
  .mt-xlwd-n51,
  .my-xlwd-n51 {
    margin-top: -255px !important;
  }
  .mr-xlwd-n51,
  .mx-xlwd-n51 {
    margin-right: -255px !important;
  }
  .mb-xlwd-n51,
  .my-xlwd-n51 {
    margin-bottom: -255px !important;
  }
  .ml-xlwd-n51,
  .mx-xlwd-n51 {
    margin-left: -255px !important;
  }
  .m-xlwd-n52 {
    margin: -260px !important;
  }
  .mt-xlwd-n52,
  .my-xlwd-n52 {
    margin-top: -260px !important;
  }
  .mr-xlwd-n52,
  .mx-xlwd-n52 {
    margin-right: -260px !important;
  }
  .mb-xlwd-n52,
  .my-xlwd-n52 {
    margin-bottom: -260px !important;
  }
  .ml-xlwd-n52,
  .mx-xlwd-n52 {
    margin-left: -260px !important;
  }
  .m-xlwd-n53 {
    margin: -265px !important;
  }
  .mt-xlwd-n53,
  .my-xlwd-n53 {
    margin-top: -265px !important;
  }
  .mr-xlwd-n53,
  .mx-xlwd-n53 {
    margin-right: -265px !important;
  }
  .mb-xlwd-n53,
  .my-xlwd-n53 {
    margin-bottom: -265px !important;
  }
  .ml-xlwd-n53,
  .mx-xlwd-n53 {
    margin-left: -265px !important;
  }
  .m-xlwd-n54 {
    margin: -270px !important;
  }
  .mt-xlwd-n54,
  .my-xlwd-n54 {
    margin-top: -270px !important;
  }
  .mr-xlwd-n54,
  .mx-xlwd-n54 {
    margin-right: -270px !important;
  }
  .mb-xlwd-n54,
  .my-xlwd-n54 {
    margin-bottom: -270px !important;
  }
  .ml-xlwd-n54,
  .mx-xlwd-n54 {
    margin-left: -270px !important;
  }
  .m-xlwd-n55 {
    margin: -275px !important;
  }
  .mt-xlwd-n55,
  .my-xlwd-n55 {
    margin-top: -275px !important;
  }
  .mr-xlwd-n55,
  .mx-xlwd-n55 {
    margin-right: -275px !important;
  }
  .mb-xlwd-n55,
  .my-xlwd-n55 {
    margin-bottom: -275px !important;
  }
  .ml-xlwd-n55,
  .mx-xlwd-n55 {
    margin-left: -275px !important;
  }
  .m-xlwd-n56 {
    margin: -280px !important;
  }
  .mt-xlwd-n56,
  .my-xlwd-n56 {
    margin-top: -280px !important;
  }
  .mr-xlwd-n56,
  .mx-xlwd-n56 {
    margin-right: -280px !important;
  }
  .mb-xlwd-n56,
  .my-xlwd-n56 {
    margin-bottom: -280px !important;
  }
  .ml-xlwd-n56,
  .mx-xlwd-n56 {
    margin-left: -280px !important;
  }
  .m-xlwd-n57 {
    margin: -285px !important;
  }
  .mt-xlwd-n57,
  .my-xlwd-n57 {
    margin-top: -285px !important;
  }
  .mr-xlwd-n57,
  .mx-xlwd-n57 {
    margin-right: -285px !important;
  }
  .mb-xlwd-n57,
  .my-xlwd-n57 {
    margin-bottom: -285px !important;
  }
  .ml-xlwd-n57,
  .mx-xlwd-n57 {
    margin-left: -285px !important;
  }
  .m-xlwd-n58 {
    margin: -290px !important;
  }
  .mt-xlwd-n58,
  .my-xlwd-n58 {
    margin-top: -290px !important;
  }
  .mr-xlwd-n58,
  .mx-xlwd-n58 {
    margin-right: -290px !important;
  }
  .mb-xlwd-n58,
  .my-xlwd-n58 {
    margin-bottom: -290px !important;
  }
  .ml-xlwd-n58,
  .mx-xlwd-n58 {
    margin-left: -290px !important;
  }
  .m-xlwd-n59 {
    margin: -295px !important;
  }
  .mt-xlwd-n59,
  .my-xlwd-n59 {
    margin-top: -295px !important;
  }
  .mr-xlwd-n59,
  .mx-xlwd-n59 {
    margin-right: -295px !important;
  }
  .mb-xlwd-n59,
  .my-xlwd-n59 {
    margin-bottom: -295px !important;
  }
  .ml-xlwd-n59,
  .mx-xlwd-n59 {
    margin-left: -295px !important;
  }
  .m-xlwd-n60 {
    margin: -300px !important;
  }
  .mt-xlwd-n60,
  .my-xlwd-n60 {
    margin-top: -300px !important;
  }
  .mr-xlwd-n60,
  .mx-xlwd-n60 {
    margin-right: -300px !important;
  }
  .mb-xlwd-n60,
  .my-xlwd-n60 {
    margin-bottom: -300px !important;
  }
  .ml-xlwd-n60,
  .mx-xlwd-n60 {
    margin-left: -300px !important;
  }
  .m-xlwd-auto {
    margin: auto !important;
  }
  .mt-xlwd-auto,
  .my-xlwd-auto {
    margin-top: auto !important;
  }
  .mr-xlwd-auto,
  .mx-xlwd-auto {
    margin-right: auto !important;
  }
  .mb-xlwd-auto,
  .my-xlwd-auto {
    margin-bottom: auto !important;
  }
  .ml-xlwd-auto,
  .mx-xlwd-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1070px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1341px) {
  .text-xlbtw-left {
    text-align: left !important;
  }
  .text-xlbtw-right {
    text-align: right !important;
  }
  .text-xlbtw-center {
    text-align: center !important;
  }
}

@media (min-width: 1379px) {
  .text-xlwd-left {
    text-align: left !important;
  }
  .text-xlwd-right {
    text-align: right !important;
  }
  .text-xlwd-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #534798 !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #372f64 !important;
}

.text-secondary {
  color: #42388d !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #282256 !important;
}

.text-success {
  color: #00d819 !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #008c10 !important;
}

.text-info {
  color: #a0ccec !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #60aadf !important;
}

.text-warning {
  color: #e4a039 !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #b77819 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #cbd3da !important;
}

.text-light2 {
  color: #ddd !important;
}

a.text-light2:hover,
a.text-light2:focus {
  color: #b7b7b7 !important;
}

.text-dark {
  color: #333 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #0d0d0d !important;
}

.text-body {
  color: #545454 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, .5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, .5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}