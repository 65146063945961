.special-shadow {
  text-shadow: 0 0.05rem 0.1rem rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 5rem rgba(0, 0, 0, 0.5);
}

.full-screen-height {
  height: 100vh;
}

#background-container {
  background-image: url("");
  background-size: cover;
  background-position: center;
}

.BGI1 {
  background-image: url("https://petscorner.petbond.ie/wp-content/uploads/2022/01/Screenshot-2019-10-14-at-09.22.57.png");
  background-size: cover;
  background-position: center;
}
.BGI2 {
  background-image: url("https://images.unsplash.com/photo-1543466835-00a7907e9de1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1548&q=80");
  background-size: cover;
  background-position: center;
}
.BGI3 {
  background-image: url("https://petscorner.petbond.ie/wp-content/uploads/2022/01/coker-2634079_1920-800x800-1.jpg");
  background-size: cover;
  background-position: center;
}
/* MAIN VID */

.mainVid {
  width: 100%;
  height: 100vh;
}

.videoVid {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contentVid {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}
.overlayVid {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

/* MISSION VID */

.MmainVid {
  width: 100%;
  height: 100vh;
}

.MvideoVid {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.McontentVid {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}
.MoverlayVid {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.chover:hover {
  transform: scale(1.05);
}

/* TESTING PAGE HEADERS */

.testingHeader {
  width: 100%;
  height: 50vh;
}

.signup-page {

}

.signup-header {
  border: none!important;
}

.signup-header .subtitle {
  font-size: 15px;
  margin-bottom: 16px;
}

.signup-header p.small {
  font-size: 13px;
  padding-left: 0;
  padding-right: 65px;
}

.signup-form .form-control {
  border: 2px solid rgba(206, 206, 206, 0.4);
}

.signup-form .um-cusCheckBox .cusCheckLabel:before {
  border: 2px solid rgb(206, 206, 206, 0.4);
}

.select-with-icon select {
  appearance: none;
}

.select-with-icon:focus-within .icn, .select-with-icon:hover .icn {
  color: #534798;
}

.select-with-icon .icn {
  width: 27px;
  height: 15px;
}

.inputWithHelp .icn {
  width: 28px;
  height: 28px;
  top: 9px!important;
}

.inputWithHelp .input-help-tooltip {
  position: absolute;
  left: calc(100% + 10px);
  top: 0;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, .3) !important;
  border-radius: 8px;
  z-index: 99;
  min-width: 300px;
  padding: 8px;
  transition: all 200ms ease;
}

.signup-section-title {
  margin: 10px 0 0;
}

.row:has(.benefits-box-wrapper) {
  flex-direction: column-reverse;
}

.benefits-box {
  color: #61489D;
  overflow: hidden;
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  margin-bottom: 24px;
}

.benefits-box ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: "Fredoka";
  font-weight: 300;
}

.benefits-box ul li {
  font-size: 16px;
  line-height: 19px;
  padding: 17px;
  display: flex;
  align-items: center;
  font-weight: 700;
}

.benefits-box ul li svg {
  margin-right: 18px;
  width: 20px;
  flex-shrink: 0;
}

@media only screen and (min-width: 580px) {
  .signup-page {
    padding: 39px 20px;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .3) !important;
  }

  .benefits-box ul li {
    font-size: 17px;
  }

  .benefits-box ul li svg {
    width: 22px;
  }
}
@media only screen and (min-width: 992px) {
  .row:has(.benefits-box-wrapper) {
    flex-direction: row;
  }

  .signup-header {
    border-bottom: 1px solid #42388d!important;
    margin-bottom: 24px;
  }

  .signup-form {
    padding-right: 65px!important;
    border-right: 1px solid #ccc !important
  }

  .benefits-box-wrapper {
    padding-left: 65px !important;
    display: flex;
    flex-direction: column;
  }

  .benefits-box-wrapper.no-padding {
    padding-left: 0!important;
  }

  .benefits-box {
    background: #61489D;
    color: #ffffff;
    padding: 40px;
    border-radius: 16px;
    flex: 1 1 0;
    margin-bottom: 0;
  }

  .benefits-box.no-stretch {
    flex: unset;
  }

  .benefits-box ul li {
    font-size: 24px;
    line-height: 29px;
    padding: 21px 0;
    border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
  }

  .benefits-box ul li:first-of-type {
    padding-top: 0;
  }

  .benefits-box ul li svg {
    margin-right: 24px;
    width: 24px;
  }
}

.seller-benefits {
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(231,231,231,0.3) 50%, rgba(255,255,255,1) 100%);
  padding: 16px;
  margin: 0 -16px;
}

.seller-benefits .intro .intro-text p{
  font-size: 17px;
  line-height: 25px;
}
.seller-benefits .intro .intro-image{
  width: 100%;
  text-align: center;
}

.seller-benefits .intro .intro-image img {
  max-width: 155px;
}

.seller-benefits .benefits-purple-list {
  background: #61489D;
  color: #ffffff;
  padding: 20px;
  border-radius: 8px;
}

.seller-benefits .benefits-purple-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: "Fredoka";
  font-weight: 300;
}

.seller-benefits .benefits-purple-list ul li {
  font-size: 16px;
  line-height: 19px;
  padding: 0;
  display: flex;
  align-items: center;
  font-weight: 700;
  margin-bottom: 16px;
}

.seller-benefits .benefits-purple-list ul li:last-of-type {
  margin-bottom: 0;
}

.seller-benefits .benefits-purple-list ul li svg {
  margin-right: 18px;
  width: 24px;
  flex-shrink: 0;
}

.seller-benefits .benefits-purple-list .deposits {
  margin-top: 30px;
  text-align: center;
}

.seller-benefits .benefits-purple-list .deposits h2 {
  color: white;
  font-size: 24px;
}

.seller-benefits .benefits-purple-list .deposits img {
  width: 100%;
  mix-blend-mode: luminosity;
  margin: 10px auto;
  max-width: 350px;
}

.seller-benefits .benefit-section.first-section {
  background-position: right 0;
}

.seller-benefits .benefit-section {
  background-image: url('/public/images/background-circle.svg');
  background-repeat: no-repeat;
  background-size: 240px;
  padding: 10px 16px 0;
  margin: 50px -16px 0;
}

.seller-benefits .benefit-section .section-description .section-title .section-id {
  color: #61489D;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 12px;
}

.seller-benefits .benefit-section .section-description .section-title h2 {
  color: #61489D;
  margin-bottom: 12px;
  font-size: 24px!important;
}

.seller-benefits .benefit-section .section-description p {
  font-size: 17px;
  line-height: 25px;
}

.seller-benefits .benefit-section .section-image {
  margin: 24px 0;
}

.seller-benefits .benefit-section .section-image img {
  width: 100%;
  border-radius: 20px;
}

.seller-benefits .benefit-section.first-section {
  background-position: right 0 top 0;
}

.seller-benefits .benefit-section.first-section .section-image {
  overflow: hidden;
}

.seller-benefits .benefit-section.first-section .section-image img {
  width: 150%;
}

.seller-benefits .benefit-section.second-section {
  background-position: left -125px top 0;
}

.seller-benefits .benefit-section.third-section {
  background-position: right -25px top 0;
}

.seller-benefits .benefit-section.fourth-section {
  background-position: left -25px top 0;
}

@media only screen and (min-width: 500px) {
  .seller-benefits {
    padding: 24px;
    border-radius: 16px;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.3);
  }

  .seller-benefits .intro .intro-image img {
    max-width: 200px;
  }

  .seller-benefits .benefits-purple-list .deposits h2 {
    font-size: 26px;
  }
}

@media only screen and (min-width: 768px) {
  .seller-benefits .intro {
    display: flex;
  }

  .seller-benefits .intro .intro-text h1 {
    font-size: 40px;
  }

  .seller-benefits .intro .intro-text {
    flex: 1 1 0;
  }

  .seller-benefits .intro .intro-image {
    width: 33.3%;
    margin-top: auto;
  }

  .seller-benefits .benefits-purple-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 24px;
    border-radius: 16px;
  }

  .seller-benefits .benefits-purple-list ul {
    border-right: 1px solid rgba(255,255,255,0.2)
  }

  .seller-benefits .benefits-purple-list ul li {
    font-size: 18px;
  }

  .seller-benefits .benefits-purple-list .deposits {
    margin-top: 0;
  }

  .seller-benefits .benefit-section {
    background-size: 293px;
    display: flex;
    justify-content: space-between;
    gap: 24px;
  }

  .seller-benefits .benefit-section .section-description {
    margin-top: 50px;
    width: 40%;
  }

  .seller-benefits .benefit-section .section-description .section-title {
    display: flex;
  }

  .seller-benefits .benefit-section .section-description .section-title .section-id {
    line-height: 1.2;
    width: 50px;
    flex-shrink: 0;
  }

  .seller-benefits .benefit-section .section-description p {
    padding-left: 50px;
  }

  .seller-benefits .benefit-section .section-image {
    margin: 0;
    flex: 1 1 0;
  }

  .seller-benefits .benefit-section.first-section .section-image img {
    width: 100%;
  }

  .seller-benefits .benefit-section.second-section, .seller-benefits .benefit-section.fourth-section {
    flex-direction: row-reverse;
  }

  .seller-benefits .benefit-section.first-section {
    background-position: right 70% top 0;
    padding-bottom: 50px;
  }

  .seller-benefits .benefit-section.second-section {
     background-position: right 0 top 40px;
    margin-top: 0;
   }

  .seller-benefits .benefit-section.third-section {
     background-position: left 10% top 10px;
   }

  .seller-benefits .benefit-section.fourth-section {
     background-position: right 0 top 30px;
   }
}

@media only screen and (min-width: 992px) {
  .seller-benefits {
    padding: 48px;
  }

  .seller-benefits .intro .intro-text p {
    font-size: 20px;
    line-height: 1.5;
  }

  .seller-benefits .benefits-purple-list {
    padding: 30px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .seller-benefits .benefits-purple-list ul {
    grid-column: span 2 / span 2;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 24px;
    column-gap: 32px;
  }

  .seller-benefits .benefits-purple-list ul li {
    margin: 0;
    font-size: 20px;
  }

  .seller-benefits .benefits-purple-list ul li.item-2 {
    grid-row-start: 2;
  }

  .seller-benefits .benefits-purple-list ul li.item-3 {
    grid-row-start: 3;
  }

  .seller-benefits .benefits-purple-list ul li.item-4 {
    grid-row-start: 1;
    grid-column-start: 2;
  }

  .seller-benefits .benefits-purple-list ul li.item-5 {
    grid-row-start: 2;
    grid-column-start: 2;
  }

  .seller-benefits .benefits-purple-list .deposits {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .seller-benefits .benefits-purple-list .deposits img {
    margin: 0;
  }

  .seller-benefits .benefit-section {
    background-size: 393px;
  }

  .seller-benefits .benefit-section .section-description .section-title .section-id {
    padding-top: 2px;
    font-size: 30px;
    width: 60px;
  }

  .seller-benefits .benefit-section .section-description .section-title h2 {
    font-size: 30px!important;
  }

  .seller-benefits .benefit-section .section-description p {
    font-size: 20px;
    line-height: 1.5;
    padding-left: 60px;
  }

  .seller-benefits .benefit-section.first-section {
    background-position: right 70% top 40px;
  }

  .seller-benefits .benefit-section.fourth-section {
    background-position: right 50px top 30px;
  }
}



