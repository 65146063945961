/*UMER*/

/* .um-pbrRegForm {
  padding-top: 70px;
}

.um-pbrRegForm .um-regMethodBlock h2,
.um-pbrRegForm .um-regMethodBlock .h2 {
  margin-bottom: 140px;
}

.um-pbrRegForm .um-vlbAsidWrap {
  padding: 120px 25px 50px;
  position: relative;
  margin-bottom: 50px;
}

.um-pbrRegForm .um-vlbAsidWrap h3,
.um-pbrRegForm .um-vlbAsidWrap .h3 {
  font-size: 19px;
  font-weight: 400;
  margin-bottom: 24px;
}

.um-pbrRegForm .um-vlbAsidWrap .vlbsIcnWrap {
  border: 5px solid #a0ccec;
  border-radius: 50%;
  background-color: #42388d;
  font-size: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 75px;
  height: 75px;
  margin: -150px auto 10px;
}

.um-pbrRegForm .um-vlbAsidWrap .vlbsIcnWrap .vlbIcn {
  color: #fff;
}

.um-pbrRegForm .um-vlbAsidWrap .um-formBtnWrap .btnFormWidth {
  min-width: 190px;
} */

/*Adnan CSS*/
/* .bio-holder {
  overflow: hidden;
}
.bio-holder p {
  margin: 0 0 20px;
}
.bio-holder .btn {
  margin: 0 15px 0 0;
}
.bio-pic {
  float: left;
  width: 135px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px 0 0;
  overflow: hidden;
  position: relative;
  border-radius: 7px;
}
.pichold {
  max-width: 135px;
  width: 100%;
  max-height: 131px;
  min-height: 131px;
  height: 100%;
  background: #e5e5e5;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.pichold img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}
.edit_photo {
  text-decoration: underline;
  font-size: 14px;
  color: #545454;
  cursor: pointer;
  transition: all 0.35s ease;
  display: inline-block;
  margin-bottom: 0;
  margin-top: 8px;
}
.edit_photo:hover {
  text-decoration: none;
}
.biotextblock {
  overflow: hidden;
  font-size: 14px;
  line-height: 22px;
  max-width: 570px;
  word-wrap: break-word;
}
.aaRwTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.licensing-holder {
  overflow: hidden;
}
.licensing-holder .col-row {
  display: flex;
  justify-content: space-between;
}
.licensing-holder .form-group {
  display: block;
  width: 46%;
}
.licensing-holder .form-group .form-control {
  max-width: 250px;
}
.btn.small {
  font-size: 16px;
  font-weight: normal;
  min-width: 120px;
  padding: 8px 21px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  border-radius: 6px;
}
.btn.small-xs {
  font-size: 14px;
  font-weight: 100;
  min-width: 100px;
  padding: 5px 10px;
  border-radius: 5px;
}
.licensing-holder .um-formBtnWrap .btn {
  background-color: #42388d;
}
.bitches-attach {
  margin: 0;
}
.bitches-attach p {
  font-weight: bold;
  color: #42388d;
  margin: 0 0 20px;
}
.bitches-attach ul {
  list-style: none;
  margin: 0;
  padding: 0;
  color: #666666;
}
.bitches-attach ul li.error {
  color: #ff0000;
}
.editButton {
  transition: opacity 0.35s ease;
}
.editButton:hover {
  opacity: 0.8;
}
.editButton:focus {
  outline: none;
}
.bio-form {
  width: 500px;
}
.bio-form .row {
  margin-bottom: 20px;
}
.bio-form textarea {
  height: 131px;
  resize: none;
}
.bio-form .textarea {
  padding-left: 0;
}
.bio-form .btn {
  float: right;
}
.no-margin {
  margin-bottom: 0 !important;
}
.margin-bottom-10 {
  margin-bottom: 10px !important;
}
.margin-bottom-50 {
  margin-bottom: 50px !important;
}
.fancybox-content {
  border-radius: 5px;
}
.bitches-attach form {
  margin: 0 0 10px;
}
.bitches-attach .um-formBtnWrap .btn {
  background-color: #42388d;
  font-size: 13px;
  padding: 10px 21px;
  display: inline-block;
}
.aaAdvPetPost {
  overflow: hidden;
}
.aaAdvPetPost .aaLeftWrap {
  float: left;
  position: relative;
  padding: 0 11% 0 0;
  text-align: center;
}
.aaAdvPetPost .aaLeftWrap.nospace {
  padding: 0 3% 0 0;
}
.aaAdvPetPost .aaLeftWrap img {
  display: block;
  width: 100%;
  height: auto;
  margin: 0 0 15px;
}
.aaAdvPetPost .aaLeftWrap a {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 10px;
}
.aaAdvPetPost .aaLeftWrap .btnbox {
  position: absolute;
  top: 0;
  right: 50px;
  width: 30px;
}
.aaAdvPetPost .aaRightWrap {
  overflow: hidden;
  max-width: 355px;
}
.aaRwTitle {
  font-size: 20px;
  line-height: 16px;
  border-bottom: 1px solid #534798;
  padding-bottom: 15px;
  margin-bottom: 27px;
}
.aaDbInfoRow {
  margin-bottom: 68px;
}
.aaAdvPetPost .aaAdvTitle {
  margin-top: -4px;
  font-size: 17px;
  line-height: 25px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
  margin-bottom: 5px;
  font-weight: normal;
}
.aaAdvPetPost .aaAdvdetailList {
  margin-left: 46px;
  font-size: 14px;
  line-height: 20px;
}
.aaAdvPetPost .aaAdvdetailList li {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #ccc;
  font-weight: normal;
}
.aaAdvtPetList {
  overflow: hidden;
}
.aaAdvtPetList li {
  margin: 0 0 30px;
}
.um-profilePicWrap.add {
  margin: 0;
}
.um-bddFormWrap .um-bddFormSlct {
  margin: 0 0 15px;
}
.stripbox {
  font-size: 19px;
  line-height: 1.2;
  color: #fff;
  background: #42388d;
  border-radius: 15px;
  text-align: center;
  padding: 25px 10px;
}
.stripbox p {
  margin: 0 0 30px;
}
.dashboardWrap .btn {
  font-weight: normal;
}
.connectbreeder {
  font-size: 14px;
  color: #666;
}
.connectbreeder h3 {
  font-size: 16px;
  font-weight: normal;
  padding-top: 30px;
  margin: 0;
}
.connectbreeder .btn.small-xs {
  display: inline-block;
  background-color: #cccccc;
  margin-left: 10px;
}
.connectbreeder .btn.small {
  display: inline-block;
  background-color: #cccccc;
}
.connectbreeder .btn.small-xs.red {
  background-color: #ff0000;
}
.connectbreeder p {
  margin: 0 0 10px;
}
.connectbreeder .um-profilePicWrap {
  max-width: 324px;
}
.connectbreeder textarea.form-control {
  height: 150px;
}
.connectbreederfooter .btn {
  background: #e4a039;
  margin-bottom: 20px;
}
.connectbreederfooter select {
  width: 300px;
  margin-bottom: 30px;
}
.mainBanner {
  overflow: hidden;
  position: relative;
}
.mainBanner .holder {
  max-width: 710px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}
.mainBanner .heart {
  position: absolute;
  left: -92px;
  top: 0;
  z-index: -1;
}
.mainBanner h1 {
  font-size: 45px;
  line-height: 50px;
  margin: 0;
}
.mainBanner .dots {
  position: absolute;
  left: 450px;
  top: 0;
}
.mainBanner .bgimage {
  width: 100%;
  height: auto;
  display: block;
}
.mainBanner .bannertxt {
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
}
.css3checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--form-background);
  font: inherit;
  color: #cecece;
  width: 26px;
  height: 26px;
  border: 0.15em solid #cecece;
  border-radius: 7px;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  margin: 0 0 0 7px;
  cursor: pointer;
}
.advertpet .checkboxlabel.right .css3checkbox {
  margin: 0 20px 0 0;
}
.css3checkbox:before {
  content: '';
  width: 1em;
  height: 1em;
  background-color: #6455a9;
  border-radius: 3px;
  opacity: 0;
}
.css3checkbox:checked::before {
  opacity: 1;
}
.css3checkbox:focus {
  outline: none;
}
.css3checkbox:disabled {
  --form-control-color: var(--form-control-disabled);
  color: var(--form-control-disabled);
  cursor: not-allowed;
}
.advertpet .checkboxlabel {
  margin: 0 0 0 20px;
  display: inline-flex;
  cursor: pointer;
}
.advertpet .checkboxlabel.right {
  margin: 0 20px 0 0;
}
.advertpet textarea.form-control {
  height: 200px;
}
.advertpet .btn-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.advertpet .btn-box a {
  margin: 0 15px;
}
.progressdots {
  margin: 0;
}
.progressdots li {
  width: 10px;
  height: 10px;
  background: #42388d;
  opacity: 0.3;
  display: inline-block;
  vertical-align: middle;
  margin: 0 3px;
  border-radius: 50%;
}
.progressdots li.active {
  opacity: 1;
}
.advertpet .ortext {
  margin: 0;
  position: absolute;
  left: 0;
  top: 7px;
  font-size: 14px;
}
.um-bddInputWrap {
  position: relative;
}
.advertpet .um-bddInputWrap .form-control {
  max-width: 100%;
}
.advertpet .um-bddInputWrap .icon {
  position: absolute;
  top: 6px;
  right: 6px;
  background-color: #fff;
  padding: 2px 10px;
}
.advertpet .btn.grey {
  background: rgb(153, 153, 153);
}
.btncert {
  margin-right: 15px;
} */
.editInfoModal .modal-dialog {
  max-width: 535px;
}
.addChipModal .modal-content {
  border-radius: 7px;
  box-shadow: none;
  border: 0;
}
.addChipModal .modal-dialog {
  max-width: 650px;
}
.addChipModal .modal-body {
  padding: 50px 30px;
}
